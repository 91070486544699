<template>
  <div
    v-if="message"
    class="tw-fixed tw-bottom-20 tw-left-1/2 tw-transform tw--translate-x-1/2 tw-p-4 tw-bg-gray-800 tw-rounded tw-text-white tw-z-50"
    :class="{
      'tw-bg-green-800': type === 'success',
      'tw-bg-red-800': type === 'error',
      'tw-bg-blue-800': type === 'info',
    }"
  >
    {{ message }}
  </div>
</template>

<script>
export default {
  props: {
    message: String,
    type: {
      type: String,
      default: 'info',
    },
  },
};
</script>
