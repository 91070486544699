<template>
  <section v-if="screenManager.isLoading">
    <div class="container">
      <h1 class="is-size-2 has-text-centered">Loading...</h1>
    </div>
  </section>
  <section v-else class="section">
    <div
      v-if="!screenManager.isLoading && !screenManager.group"
      style="text-align: center; margin-top: 20px; font-size: 130%;"
    >
      <b>404</b> - Geen groep gevonden.
    </div>
    <div
      v-else
      class="is-flex is-justify-content-center is-flex-wrap-wrap"
    >
      <!-- EDIT GROUP COLUMN -->
      <div class="my-4" style="min-width: 450px;">
        <div v-if="editor.group" class="box">
          <div class="field">
            <label class="label" style="font-size: 14px;">Groep naam</label>
            <text-input
              @change="editor.onChangeName"
              :value="editor.group.name"
              :data-id="'group-edit-name?name=' + editor.group.name"
            />
          </div>

          <div class="field">
            <label class="label" style="font-size: 14px;">Bijbehorende service</label>
            <multiple-select-dropdown
              :items="editor.availableApps"
              display="name"
              data-id="group-edit-apps"
              :preselect="editor.group.apps"
              @change="(selectedItems) => (
                screenManager.editor.setGroupInfo({ apps: selectedItems})
              )"
            />
          </div>

          <div class="field">
            <label class="label" style="font-size: 14px;">Bijbehorende project</label>
            <dropdown
              :items="projectDropdown"
              :preselect="editor.group.project"
              :data-id="'group-edit-project?project=' + editor.group.project"
              display="label"
              @change="(selectedItem) => editor.setGroupInfo({ project: selectedItem.label })"
            />
          </div>

          <label class="checkbox">
            <input
              type="checkbox"
              @change="() => editor.setGroupInfo({ is_private: !editor.group.is_private })"
              :checked="editor.group.is_private"
              :data-id="'group-edit-privacy?private=' + editor.group.is_private"
            >
            Privé groep
          </label>

          <div class="buttons is-right">
            <button
              class="button is-ecorus-green mt-4"
              data-id="group-edit-submit"
              :disabled="editor.buttonDisabled"
              @click="() => editor.updateInfo(this.$route.params.name)"
            >Aanpassingen opslaan</button>
          </div>
        </div>
      </div>
      <!-- ADDRESS LISTS COLUMN -->
      <div class="my-4 mx-4" style="min-width: 748px">
        <container class="is-flex is-justify-content-center">
          <group-address-list :list="unassignedList" />
          <!-- List Controls -->
          <column-centered padding="px-2">
            <clickable-icon-md
              icon="fas fa-arrow-alt-circle-right"
              dataId="group-edit-addresses-assign"
              @click="moveSelectedItems('unassigned', 'assigned')"
            />
            <clickable-icon-md
              icon="fas fa-arrows-alt-h"
              dataId="group-edit-addresses-swap"
              @click="swapSelectedItems('unassigned', 'assigned')"
            />
            <clickable-icon-md
              icon="fas fa-arrow-alt-circle-left"
              dataId="group-edit-addresses-unassign"
              @click="moveSelectedItems('assigned', 'unassigned')"
            />
          </column-centered>
          <group-address-list :list="assignedList" />
        </container>
        <p
          class="is-size-7 mt-2 mb-4 is-block"
          style="max-width: 350px; margin-left: auto; text-align: right;"
        >
          Dit icoontje <i class="fas fa-info-circle"></i>
          betekend dat een adres al in een of meer groepen te vinden is. Hou je muis erover
          om te zien welke groepen dat zijn.
        </p>
        <div class="buttons is-right">
          <button-normal
            color="ecorus-green"
            spacing="mx-0 mt-1"
            label="Aanpassingen opslaan"
            data-id="group-edit-addresses-submit"
            @click="() => editor.updateAddresses(
              this.$route.params.name,
              assignedList.items,
            )"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { GroupAddressList } from '@/components/GroupEditScreen';
import { GroupEditScreenManager } from '@/logic/GroupEditScreen';

@Component({
  name: 'GroupEditView',
  components: { GroupAddressList },
})
export default class GroupEditView extends Vue {
  private screenManager = new GroupEditScreenManager(this.$router);

  get editor() { return this.screenManager.editor; }
  get listController() { return this.screenManager.listController; }
  get assignedList() { return this.listController.instance('assigned'); }
  get unassignedList() { return this.listController.instance('unassigned'); }
  get projectDropdown() {
    if (!this.editor) return [];

    const dropdown = this.editor.projectOptions.map((project) => ({
      label: project,
      handler: () => this.editor!.setGroupInfo({ project }),
    }));
    return dropdown;
  }

  get moveSelectedItems() {
    return this.listController.moveSelectedItems;
  }
  get swapSelectedItems() {
    return this.listController.swapSelectedItems;
  }
}
</script>

<style lang="scss" scoped>
::v-deep .fa-info-circle {
  color: #333 !important;
  font-size: 16px !important;
  display: inline-block;
  margin-left: auto;
  text-align: right;
}
</style>
