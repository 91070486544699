<template>
  <div class="call-centre">
    <div class="header">
      <div>
        <div class="input-field">
          <div class="dropdown" :class="{ 'is-active': manager.isActive }">
            <div class="dropdown-trigger">
              <input
                class="input"
                type="text"
                placeholder="Search address"
                :value="manager.query"
                @input="manager.setQuery"
                @blur="() => (isActive = false)"
                @click="() => (isActive = true)"
              />
            </div>
            <div class="dropdown-menu" id="dropdown-menu" role="menu">
              <div v-if="manager.isAddressesLoading" class="dropdown-content">
                <span class="dropdown-item">Loading...</span>
              </div>
              <div
                v-else-if="manager.addresses.length > 0"
                class="dropdown-content"
              >
                <a
                  v-for="address in manager.addresses"
                  :key="address"
                  class="dropdown-item"
                  @click="() => manager.setSelectedAddress(address)"
                >
                  {{ address }}
                </a>
              </div>
              <div v-else-if="manager.query.length > 2" class="dropdown-content">
                <span class="dropdown-item">No results found</span>
              </div>
            </div>
          </div>
        </div>
        <p class="ml-3 p-2">Search for an address above to view data</p>
      </div>
      <div v-if="selectedAddress && !manager.isAddressLoading" class="title">
        <span class="address"><b>{{ address }}</b></span>
        <span><p>
        <span v-if="selectedAddress.woco">
          {{ selectedAddress.woco }},
        </span>
        <span v-if="selectedAddress.groups" >
          {{ selectedAddress.groups[0] }}
        </span></p></span>
      </div>
      <button
        v-if="!manager.dashboard.isLoading"
        class="button is-info ml-3 mt-4"
        @click="manager.exportExcel"
      >
        Export excel
      </button>
    </div>
    <div class="columns">
      <div class="column is-narrow is-flex is-align-items-center">
        <dashboard :manager="manager" v-if="showDashboard" />
        <button @click="() => showDashboard = !showDashboard" class="button ml-1 p-2">
          <i class="fas fa-angle-right" v-if="!showDashboard"></i>
          <i class="fas fa-angle-left" v-else></i>
        </button>
      </div>
      <div class="column mt-5 ml-5" v-if="manager.isAddressLoading">
        Loading...
      </div>
      <div class="column mt-5 ml-5" v-else-if="manager.isInvalidAddress">
        Something went wrong. Please try again.
      </div>
      <div class="column" v-else-if="selectedAddress">
        <div class="actions">
          <div v-if="manager.actions.length > 0" ref="scrollable" class="actions-list mb-3">
            <div
              v-for="action in manager.actions"
              :key="action.id"
              class="card m-2"
              :class="{
                inbound: action.is_inbound,
                outbound: !action.is_inbound,
                'related-action-resolved': manager.selectedAction
                  && action.related_action_id === manager.selectedAction.id
                  && manager.selectedAction.is_resolved,
                'related-action-unresolved': manager.selectedAction
                  && action.related_action_id === manager.selectedAction.id
                  && !manager.selectedAction.is_resolved,
                'selected-action-resolved': manager.selectedAction
                  && action.id === manager.selectedAction.id && action.is_resolved,
                'selected-action-unresolved': manager.selectedAction
                  && action.id === manager.selectedAction.id && !action.is_resolved,
              }"
            >
              <div class="card-content">
                <div class="content">
                  <span>
                    <b>{{ action.subject }}</b>, <b>{{ action.type }}</b>
                    <span class="ml-1">
                      <i class="fas fa-arrow-right" v-if="action.is_inbound"></i>
                      <i class="fas fa-arrow-left" v-else></i>
                      {{ action.is_inbound ? "IN" : " OUT" }}, {{ action.context }},
                    </span>
                    <span>
                      <span><i>{{ action.created_by }}</i></span>,
                      <span>{{ manager.formatDate(new Date(action.created_at)) }}</span>
                    </span>
                  </span>
                  <p>{{ action.value }} </p>
                </div>
              </div>
              <footer class="card-footer">
                <a class="card-footer-item" v-if="action.isLoading">Loading...</a>
                <a
                  v-else-if="action.has_follow_up && !action.is_resolved"
                  class="card-footer-item"
                  @click="() => resolve(action)"
                  >Resolve</a
                >
              </footer>
            </div>
          </div>
          <div v-else class="actions-list mb-3 ml-2">
            <p>No comments have been added to this address yet.</p>
          </div>
          <div class="form">
            <textarea
              v-model="manager.action.value"
              class="textarea"
              :placeholder="`${manager.action.type}...`"
              rows="2"
            ></textarea>
            <div class="options">
              <div class="dropdowns is-align-items-center">
                <dropdown
                  display="label"
                  class="mr-2"
                  :preselect="manager.action.context"
                  :items="contextDropdown"
                  @change="(item) => item.handler()"
                />
                <div class="labels mr-2">
                  <label
                    class="ml-1 radio"
                    style="display: flex; align-items: flex-end"
                  >
                    <input
                      type="radio"
                      @change="() => (manager.action.is_inbound = true)"
                      :checked="manager.action.is_inbound"
                    />
                    <span class="pl-1">Inbound</span>
                  </label>
                  <label
                    class="ml-1 radio"
                    style="display: flex; align-items: flex-end"
                  >
                    <input
                      type="radio"
                      @change="() => (manager.action.is_inbound = false)"
                      :checked="!manager.action.is_inbound"
                    />
                    <span class="pl-1">Outbound</span>
                  </label>
                </div>
                  <dropdown
                    class="mr-2"
                    display="label"
                    width="145"
                    :preselect="manager.action.subject"
                    :items="subjectDropdown"
                    @change="(item) => item.handler()"
                  />
                  <dropdown
                    class="mr-2"
                    display="label"
                    :preselect="manager.action.type"
                    :items="typeDropdown"
                    @change="(item) => item.handler()"
                  />
                  <dropdown
                    class="mr-2"
                    display="label"
                    width="90"
                    :items="relatedActionDropdown"
                    @change="(item) => item.handler()"
                  />

                <label
                  class="mr-2 checkbox"
                  style="display: flex; align-items: flex-end"
                >
                  <span class="pr-1">Follow up</span>
                  <input
                    type="checkbox"
                    @change="
                      () =>
                        (manager.action.has_follow_up = !manager.action.has_follow_up)
                    "
                    :checked="manager.action.has_follow_up"
                  />
                </label>
              </div>
              <button
                :disabled="!manager.action.value"
                class="button is-info is-small"
                :class="{ 'is-loading': manager.action.isLoading }"
                @click="create"
              >
                Log
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="column mt-5 ml-5"></div>
      <div class="column" v-if="
        selectedAddress
        && !manager.isAddressLoading
        && !manager.isInvalidAddress"
      >
        <dropdown
          display="label"
          margin="mb-4"
          :preselect="statusDropdown[0]"
          :items="statusDropdown"
          @change="(item) => item.handler()"
        />
        <div class="box details">
          <span class="pb-3" style="font-size: 1.1rem"><b>{{ address }}</b></span>
          <p
            v-if="selectedAddress.groups"
            style="font-size: 1.1em; text-decoration: underline;"
          >
            <a target="_blank" :href="taskUrl">Go to tasks</a>
          </p>
          <client-data
            v-if="manager.selectedStatus === 'Client'"
            :client="this.manager.selectedAddress"
          />
          <design-data
            v-else-if="manager.selectedStatus === 'Design'"
            :client="this.manager.selectedAddress"
          />
          <sales-data
            v-else-if="manager.selectedStatus === 'Sales'"
            :client="this.manager.selectedAddress"
          />
          <installation-data
            v-else-if="manager.selectedStatus === 'Installation'"
            :client="this.manager.selectedAddress"
          />
          <eleena-data
            v-else-if="manager.selectedStatus === 'Eleena'"
            :client="this.manager.selectedAddress"
            :manager="this.manager"
          />
          <maintenance-data
            v-else-if="manager.selectedStatus === 'Maintenance'"
            :client="this.manager.selectedAddress"
            :manager="this.manager"
          />
          <inspection-data
            v-else-if="manager.selectedStatus === 'Inspection'"
            :client="this.manager.selectedAddress"
            />
          <CallCentreTickets
            :address="this.manager.fullAddress"
            v-else-if="manager.selectedStatus === 'Tickets'"
            :client="this.manager.selectedAddress"
          />
        </div>
      </div>
      <div class="column" v-else></div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { Action, CallCentreScreenManager } from '@/logic/CallCentreScreen';
import {
  ClientData,
  DesignData,
  SalesData,
  InstallationData,
  MaintenanceData,
  EleenaData,
  Dashboard,
  InspectionData,
  CallCentreTickets,
} from '@/components/CallCentreScreen';
import { events } from '@/util';

@Component({
  components: {
    ClientData,
    DesignData,
    SalesData,
    InstallationData,
    EleenaData,
    Dashboard,
    MaintenanceData,
    InspectionData,
    CallCentreTickets,
  },
})
export default class CallCentreView extends Vue {
  public manager = new CallCentreScreenManager(this.$router);

  public showDashboard = true;

  get taskUrl() {
    let { selectedStatus } = this.manager;

    if (selectedStatus === 'Eleena') selectedStatus = 'Installation';
    if (selectedStatus === 'Client') selectedStatus = 'Design';

    const queryParams = [
      `woco=${this.manager.selectedAddress?.woco}`,
      `project=${this.manager.selectedAddress?.project}`,
      `group=${this.manager.selectedAddress?.groups[0]}`,
      `panes=${selectedStatus}Pane`,
      `address=${this.manager.fullAddress}`,
      `detail=${selectedStatus.toLowerCase()}`,
      `search=${this.manager.fullAddress}`,
    ];
    return `/#/tasks?${queryParams.join('&')}`;
  }

  get selectedAddress() {
    return this.manager.selectedAddress;
  }

  get relatedActionDropdown() {
    const actions = this.manager.actions.filter((action) => action.has_follow_up);
    const dropdown = actions.map((action) => ({
      label: action.value.substring(0, 100),
      handler: () => { this.manager.action.related_action_id = action.id; },
    }));
    dropdown.unshift({
      label: 'Related',
      handler: () => { this.manager.action.related_action_id = null; },
    });
    return dropdown;
  }

  get statusDropdown() {
    const statuses = this.manager.possibleStatuses;
    statuses.push('Tickets')
    const dropdown = statuses.map((value) => ({
      label: value,
      handler: () => this.manager.setSelectedStatus(value),
    }));
    return dropdown;
  }

  get typeDropdown() {
    const types = this.manager.action.possibleTypes;
    const dropdown = types.map((value) => ({
      label: value,
      handler: () => {
        if (value === 'Complaint') {
          this.manager.action.has_follow_up = true;
          this.manager.action.is_inbound = true;
        }
        this.manager.action.type = value;
      },
    }));
    return dropdown;
  }

  get subjectDropdown() {
    const types = this.manager.action.possibleSubjects;
    const dropdown = types.map((value) => ({
      label: value,
      handler: () => {
        this.manager.action.subject = value;
      },
    }));
    return dropdown;
  }

  get contextDropdown() {
    const types = this.manager.action.possibleContext;
    const dropdown = types.map((value) => ({
      label: value,
      handler: () => {
        this.manager.action.context = value;
      },
    }));
    return dropdown;
  }

  get address() {
    console.log(this.manager);
    if (!this.manager.selectedAddress || !this.manager.fullAddress) return '';
    return this.manager.fullAddress;
  }

  public async create() {
    await this.manager.action.create();
    this.manager.setActions(this.manager.fullAddress);

    this.manager.action = new Action();
    this.manager.dashboard.setActions();
    this.manager.action.address = this.address;
    this.manager.action.woco = this.manager.selectedAddress!.woco;

    this.$nextTick(() => {
      (this.$refs.scrollable as HTMLElement).scrollTop = 0;
    });
  }

  public async resolve(action: Action) {
    await action.resolve();

    this.manager.dashboard.setActions();
  }

  protected onClientUpdate() {
    if (!this.manager.selectedAddress) return;

    const { street, house_number, postal_code } = this.manager.selectedAddress!.address;
    const address = this.manager.selectedAddress!.fullAddress;
    this.manager.setSelectedAddress(address);
  }

  updated() {
    this.$nextTick(() => {
      if (!this.manager.actionWasSelected) return;
      let offsetTop = 0;
      const resolvedElements = document.getElementsByClassName('selected-action-resolved');
      const unresolvedElements = document.getElementsByClassName('selected-action-unresolved');
      if (resolvedElements.length === 0 && unresolvedElements.length === 0) return;
      if (resolvedElements.length > 0) {
        const element = resolvedElements[0] as HTMLElement;
        offsetTop = element.offsetTop;
      }
      if (unresolvedElements.length > 0) {
        const element = unresolvedElements[0] as HTMLElement;
        offsetTop = element.offsetTop;
      }
      const scrollable = this.$refs.scrollable as HTMLElement;
      if (scrollable) {
        scrollable.scrollTop = offsetTop - 173;
        this.manager.actionWasSelected = false;
      }
    });
  }

  beforeDestroy() {
    events.off('client-updated', this.onClientUpdate);
  }

  mounted() {
    events.on('client-updated', this.onClientUpdate);
  }
}
</script>

<style lang="scss">
.call-centre {
  font-size: 0.8em;
  padding: 1rem;

  .details {
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0.35rem;
      height: 0.35rem;
      color: #868686;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
      border-radius: 10px;
      cursor: grab;
    }
  }

  .columns {
    height: 75vh;

    .column {
      height: 100%;
      padding: 0.3em;

      .box {
        height: 90%;
      }

      .actions {
        height: 100%;
        display: flex;
        flex-direction: column;

        .actions-list {
          overflow-y: scroll;
          flex-grow: 1;
          scroll-behavior: smooth;

          &::-webkit-scrollbar {
            width: 0.35rem;
            height: 0.35rem;
            color: #868686;
          }

          &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
            border-radius: 10px;
            cursor: grab;
          }

          .inbound {
            background-color: #edf6fa;
            .card-footer {
              border-top: 1px solid #b4d3e1;

              .card-footer-item {
                color: #4f8196;
              }
            }
          }

          .outbound {
            background-color: #eaf7e1;
            .card-footer {
              border-top: 1px solid #b0c99d;

              .card-footer-item {
                color: #5c7e43;
              }
            }
          }

          .related-action-resolved {
            border: 0.2em solid #7eea69;
            box-shadow: inset 0 0 0.5rem #7eea69;
          }

          .related-action-unresolved {
            border: 0.2em solid #fa6645;
            box-shadow: inset 0 0 0.5rem #fa6645;
          }

          .selected-action-resolved {
            color: #161616;
            background-color: #7eea69;
          }

          .selected-action-unresolved {
          color: #161616;
          background-color: #fa6645;
            .card-footer {
              border-top: 1px solid #540f00;

              .card-footer-item {
                color: #540f00;
              }
            }
          }
        }
      }
    }

    .card {
      .card-content {
        padding: 0.7rem;
        font-size: 0.9em;
        white-space: pre-line;
        user-select: text;

        .content {
          display: flex;
          flex-direction: column;

          p:not(:last-child) {
            margin-bottom: 0.2em;
          }
        }
      }

      .card-footer-item {
        padding: 0.3rem;
        font-size: 0.9em;
      }
    }

    .form {
      display: flex;
      flex-direction: column;

      .textarea {
        min-width: auto;
      }

      .options {
        margin-top: 1em;
        display: flex;
        flex-direction: row;

        .dropdowns {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          row-gap: 0.5em;
        }
      }
    }

    .selected-address {
      margin: 1rem;
      display: flex;

      & > * {
        width: 50%;
      }
    }

    .address-results {
      border: 1px solid black;
      padding: 0.7rem 0;
      width: 30rem;
      z-index: 30;

      .item {
        cursor: pointer;
        padding: 0 0.1rem;

        &:hover {
          background-color: lightblue;
        }
      }
    }
  }
}

.header {
  display: flex;

  .title {
    margin-left: 2rem;
    margin-top: 0.8rem;
    font-size: 1rem;

    .address {
      font-size: 1.2rem;
    }
  }

  .input-field {
    padding-top: 1rem;
    margin-left: 1rem;

    .dropdown {
      width: 25rem;

      .dropdown-content {
        overflow-y: scroll;
        width: 25rem;
        max-height: 30rem;

        .dropdown-item {
          font-size: 0.65rem;
          padding: 0.05rem 0.6rem;
        }

        &::-webkit-scrollbar {
          width: 0.35rem;
          height: 0.35rem;
          color: #868686;
        }

        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
          border-radius: 10px;
          cursor: grab;
        }
      }

      .dropdown-trigger {
        width: 100%;
      }
    }
  }
}
</style>
