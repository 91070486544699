<template>
  <div v-if="components.eleena_data" class="box eleena">
    <!-- DATA -->
    <details-box-heading label="Eleena" />
    <div class="box-content">
      <template v-for="[field, fieldValue] in Object.entries(components.eleena_data)">
        <details-static-data-field
          v-if="field === 'production'"
          :key="`eleena-${field}`"
          :field="capitalized(field)"
        >
          <a :href="eleenaGraphURL" target="_blank" title="Eleena graph">
            <span :class="['tag', getProductionColor(fieldValue)]" style="height: 1.3em;">
              {{ fieldValue }}
            </span>
          </a>
        </details-static-data-field>

        <details-static-data-field
          v-else-if="field === 'date_activated'"
          :key="`eleena-${field}`"
          :field="capitalized(field)"
          :value="fieldValue && formatDateTime(fieldValue)"
        />

        <details-static-data-field
          v-else-if="field !== 'alarms'"
          :key="`eleena-${field}`"
          :field="capitalized(field)"
          :value="fieldValue"
        />
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import {
  isDateTimeField,
  formatDateTime,
} from '@/util';
import DetailsPaneBase from './DetailsPaneBase.vue';

@Component({
  name: 'DetailsEleenaBox',
})
export default class DetailsTicketBox extends DetailsPaneBase {
  get eleenaGraphURL() {
    const baseURL = 'https://reporting.eleena.eu/client-overview/';

    const { postalCode, houseNumber } = this.selectedMasterItem;
    const digits = postalCode.replace(' ', '').slice(0, 4);
    const ext = postalCode.replace(' ', '').slice(-2);

    const queryParams = [
      `postcode=${digits}`,
      `postcode_ext=${ext}`,
      `housenmbr=${houseNumber}`,
    ];

    return `${baseURL}?${queryParams.join('&')}`;
  }

  get eleenaAlarms() {
    return this.components.eleena_data && this.components.eleena_data.alarms
      ? this.components.eleena_data.alarms
      : [];
  }

  protected capitalized(str: string) {
    return (str[0].toUpperCase() + str.slice(1)).replace(/[_-]/g, ' ');
  }

  protected formatDateTime(input: string) {
    return formatDateTime(input);
  }

  protected getNumericEleenaProductionValue(value: string) {
    if (!value) return value;

    const match = value.match(/(?<number>\d+\.?\d*)/);
    return match ? Number(match.groups!.number) : value;
  }

  protected getProductionColor(value: string) {
    const numericValue = this.getNumericEleenaProductionValue(value);
    if (numericValue >= 20) return 'is-success';
    if (numericValue > 0) return 'is-orange';
    return 'is-danger';
  }
}
</script>
