<template>
  <div class="pane-container is-flex">
    <div ref="container" class="pane-row is-flex">
      <template v-if="controller.isReady">
      <!-- PANE -->
        <div
          v-for="(pane, index) in panes"
          :key="`pane-${pane.id}`"
          :id="`pane-${pane.id}`"
          class="pane mx-2 is-relative"
        >
          <!-- PANE CONTENT -->
          <div class="pane-content is-relative">
            <component :is="pane.component" v-bind="pane.props" />
            <!-- <button-delete
              @click="controller.closePane(pane)"
              style="position: absolute; top: 0; right: 0;"
            /> -->
          </div>
          <!-- PANE SLIDER -->
          <div
            v-if="index !== panes.length - 1"
            class="pane-slider"
            @mousedown="(event) => controller.onMouseDown(event, pane)"
          ></div>
        </div>
      </template>
      <!-- PANE CONTROLS -->
    </div>
    <div class="pane-row-controls is-flex" style="max-width: 50px;">
      <button-small label="+" @click="controller.addPane()" dataId="task-add-pane"/>
    </div>
  </div>
</template>

<script lang="ts">
import {
  Component, Vue, Prop,
} from 'vue-property-decorator';
import DynamicPaneController from '@/logic/common/DynamicPaneController';

interface Pane {
  component: string;
  props?: { [key: string]: unknown };
}

interface DynamicPaneConfig {
  pane: Pane;
  minPaneWidth: number;
  maxPanes: number;
  minPanes: number;
}

@Component({ name: 'DynamicPaneContainer' })
export default class DynamicPaneContainer extends Vue {
  @Prop() config!: DynamicPaneConfig;

  protected margin = 16; // px -> mx-2 = 0.5rem on both sides, 1rem = 16px

  private controller: DynamicPaneController = new DynamicPaneController({
    ...this.config,
    margin: this.margin,
  });

  get panes() {
    return this.controller.panes;
  }

  mounted() {
    this.controller.init(this.$refs.container as HTMLDivElement);
  }

  beforeDestroy() {
    this.controller.beforeDestroy();
  }
}
</script>

<style lang="scss" scoped>
::v-deep

.pane {
  flex: 1;
  min-width: 6.5rem;
}

.pane-slider {
  position: absolute;
  width: 1rem;
  top: 0;
  right: -1rem;
  height: 100%;
  cursor: w-resize;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 100%;
    background-color: black;
  }
}
</style>
