var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-flex tw-h-screen tw-gap-5 tw-p-5"},[_c('div',{staticClass:"tw-flex tw-flex-col tw-w-full tw-max-w-[600px]"},[_c(_setup.FormField,{staticClass:"tw-w-full",attrs:{"label":"Zoek adres of serial nummer","type":"text"},model:{value:(_setup.queryInput),callback:function ($$v) {_setup.queryInput=$$v},expression:"queryInput"}}),(_setup.error)?_c('div',[_vm._v("Something went wrong")]):_c(_setup.GenericTable,{attrs:{"columns":_setup.columns,"data":_setup.meters,"has-next-page":_setup.hasNextPage,"is-loading":_setup.isLoading},on:{"fetchNextPage":function($event){return _setup.fetchNextPage()}},scopedSlots:_vm._u([{key:"cell-address",fn:function({ row }){return [_c('router-link',{staticClass:"tw-text-gray-800 hover:tw-underline",attrs:{"to":{
            name: 'polling-detail',
            params: { id: row.serial_number },
          }}},[_vm._v(" "+_vm._s(row.street)+" "+_vm._s(row.house_number)+" "+_vm._s(row.zip_code)+" "+_vm._s(row.zip_code_ext)+" ")])]}},{key:"cell-status",fn:function({ row }){return [_c('div',{staticClass:"tw-flex tw-items-center"},[_c('span',{staticClass:"tw-w-3 tw-h-3 tw-rounded-full",class:{
              'tw-bg-red-500': row.alive === false,
              'tw-bg-green-500': row.alive === true,
            }}),_c('div',{staticClass:"tw-ml-3"},[_vm._v(" "+_vm._s(row.status)+" ")])])]}}])})],1),(_vm.$route.params.id)?_c('div',{staticClass:"tw-flex-auto"},[_c(_setup.PollingDetail,{attrs:{"id":_vm.$route.params.id}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }