<template>
  <info-container title="Comments">
    <div class="tw-flex tw-flex-col">
      <div class="tw-mb-1 tw-flex tw-flex-wrap tw-max-h-40 tw-overflow-y-auto tw-flex-grow">
        <div v-if="isLoading">Loading...</div>
        <div v-else-if="comments.length > 0">
          <div
            v-for="comment in comments"
            :key="comment.id"
            class="tw-card tw-mb-3 tw-cursor-pointer tw-p-2 tw-border tw-border-gray-200 tw-rounded-lg tw-relative"
            :class="{
              'tw-bg-blue-100': comment.is_inbound,
              'tw-bg-green-100': !comment.is_inbound,
            }"
            @click="starComment(comment.id)"
          >
            <span
              v-if="comment.id == starredComment"
              class="fas fa-star tw-text-gray-400 tw-text-xs tw-absolute tw-top-2 tw-right-2"
            />

            <p class="tw-text-md">{{ comment.value }}</p>
            <p class="tw-text-sm">
              {{ comment.created_by }}, {{ formatDateTimeAlt(comment.created_at, true) }}
            </p>
          </div>
        </div>

        <div v-else class="tw-text-gray-500">No Internal comments for this address yet.</div>
      </div>

      <textarea
        class="tw-mt-2 tw-p-2 tw-border tw-border-gray-200"
        placeholder="Comment..."
        rows="1"
        :disabled="isLoading"
        v-model="newComment"
        :class="{
          'tw-border-green-500': result.type === 'Success',
          'tw-border-red-500': result.type === 'Error',
        }"
        s
      ></textarea>
      <label class="tw-flex tw-items-center tw-space-x-2 tw-mt-2">
        <input
          type="checkbox"
          v-model="notify"
          class="tw-form-checkbox tw-h-5 tw-w-5 tw-text-indigo-600"
        />
        <span class="tw-text-md">Notify</span>
      </label>

      <button
        :disabled="!newComment"
        class="tw-bg-gray-500 tw-rounded tw-p-1 tw-text-white tw-mt-3"
        :class="{
          'tw-opacity-50 tw-cursor-not-allowed': !newComment,
          'tw-btn-primary': !isLoading,
        }"
        @click="createComment"
      >
        <span
          v-if="isLoading"
          class="tw-spinner-border tw-spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        Add new comment
      </button>
    </div>
    <notification-message :message="notification.message" :type="notification.type" />
  </info-container>
</template>

<script setup>
import fetchData from '@/util/fetch';
import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router/composables';
import InfoContainer from '@/components/shared/InfoContainer.vue';
import NotificationMessage from './NotificationMessage.vue';
import { formatDateTimeAlt } from '@/util';
import { actions } from '@/store';

const props = defineProps({
  starredComment: {
    type: String,
    required: false,
  },
  woco: {
    type: String,
    required: false,
  },
});

const route = useRoute();
const queryClient = useQueryClient();

const type = computed(() => route.params?.type);
const address = computed(() => route.params?.address);

const newComment = ref('');
const notify = ref(false);
const result = ref({ type: '', message: '' });
const notification = ref({ message: '', type: '' });

const convertedType = computed(() => {
  switch (type.value) {
    case 'installation':
      return 'Installatie';
    case 'sales':
      return 'Werving';
    case 'design':
      return 'Algemeen/proces';
    case 'inspection':
      return 'Schouwing';
    case 'maintenance':
      return 'Onderhoud';
    default:
      return 'installatie';
  }
});

const {
  data: comments,
  isLoading,
  refetch,
} = useQuery({
  queryKey: computed(() => ['comments', address.value]),
  queryFn: async () => {
    try {
      const result = await fetchData(`/api/v1/actions/address/${address.value}`);
      return result.data.actions.filter(
        (action) => action.context === 'Internal' && action.subject === convertedType.value
      );
    } catch (error) {
      console.error('Error fetching comments:', error);
      throw error;
    }
  },
  refetchOnWindowFocus: false,
});

const { mutate: createComment } = useMutation({
  mutationFn: async () => {
    try {
      const payload = {
        address: address.value,
        type: 'Comment',
        value: newComment.value.trim(),
        notify: notify.value,
        context: 'Internal',
        subject: convertedType.value,
        has_follow_up: false,
        is_inbound: true,
        is_resolved: false,
        related_action_id: null,
        created_by: actions.auth.readUser(),
        woco: props.woco,
      };
      const response = await fetchData(`/api/v1/actions`, {
        method: 'POST',
        body: JSON.stringify(payload),
      });
      return { data: response.data, message: response.message, status: response.status };
    } catch (error) {
      console.error('Error creating comment:', error);
      throw error;
    }
  },
  onSuccess: (data) => {
    console.log('Data:', data, data.message);
    if (data.status === 'error') {
      console.error('Server Error:', data.message);
      notification.value = { message: 'Failed to add comment', type: 'error' };
      onError(new Error('Server Error'));
    } else {
      refetch();
      newComment.value = '';
      notify.value = false;
      notification.value = { message: 'Comment added successfully', type: 'success' };
      setTimeout(() => {
        notification.value = { message: '', type: '' };
      }, 3000);
    }
  },
});

const { mutate: starComment } = useMutation({
  mutationFn: async (commentId) => {
    try {
      const payload = {
        comment_id: commentId,
      };
      const response = await fetchData(
        `/api/v1/address/${address.value}/task_type/${type.value}/data`,
        {
          method: 'PUT',
          body: JSON.stringify(payload),
        }
      );
      console.log('Response:', response);
      return { data: response.data, message: response.message, status: response.status };
    } catch (error) {
      console.error('Error creating comment:', error);
      throw error;
    }
  },
  onSuccess: (data) => {
    console.log('Data:', data, data.status);
    if (data.status === 'error') {
      console.error('Server Error:', data.message);
      notification.value = { message: 'Failed to add comment', type: 'error' };
      onError(new Error('Server Error'));
    } else {
      console.log('else?');
      queryClient.invalidateQueries({ queryKey: ['task'] });
      notification.value = { message: 'New comment is starred successfully', type: 'success' };
      setTimeout(() => {
        notification.value = { message: '', type: '' };
      }, 3000);
    }
  },
});
</script>
