<template>
  <div :class="classList">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({ name: 'ColumnCentered' })
export default class ColumnCentered extends Vue {
  @Prop({ default: '' }) icon!: string;
  @Prop({ default: '' }) padding!: string;
  @Prop({ default: '' }) margin!: string;

  classList = [
    'is-flex',
    'is-flex-direction-column',
    'is-justify-content-center',
    'is-align-content-center',
    'has-text-centered',
    this.padding,
    this.margin,
  ]
}
</script>
