// screen names
export const SCREEN_HOME = 'Home';
export const SCREEN_CLIENTS = 'Clients';
export const SCREEN_GROUP_EDIT = 'GroupEdit';
export const SCREEN_TASKS = 'Tasks';
export const SCREEN_INTERACTIONS = 'Interactions';
export const SCREEN_CALL_CENTRE = 'Call Centre';
export const SCREEN_TICKETS = 'Tickets';
export const SCREEN_PROJECTS = 'Projects';
export const SCREEN_PROJECTS_EDIT = 'Project';
export const SCREEN_PARTNER_ORDERS = 'Bestellingen';
export const SCREEN_PARTNER_ORDER_DETAILS = 'Bestelling';
export const SCREEN_NOT_FOUND = '404';
export const SCREEN_TEST = 'Test';
export const SCREEN_DASHBOARD = 'Dashboard';

// status
export const STATUS_OK = 'OK';
export const STATUS_WARNING = 'warning';
export const STATUS_ERROR = 'error';
export const STATUS_SUCCESS = 'success';
export const STATUS_ALLOWED = 'allowed';
export const STATUS_UNAUTHORIZED = 'unauthorized';
export const STATUS_FORBIDDEN = 'forbidden';
export const STATUS_NOT_FOUND = 'not_found';

// permissions
export const PERMISSION_GROUPS_USER = 'groups_users';
export const PERMISSION_ADMINISTRATORS = 'administrators';

export const PERMISSION_ERP_USERS = 'erp_users';
export const PERMISSION_ERP_ADMINS = 'erp_admins';

export const PERMISSION_VIEW_SCREEN_DASHBOARD = 'ERP_VIEW_SCREEN_DASHBOARD';
export const PERMISSION_VIEW_SCREEN_CLIENTS = 'ERP_VIEW_SCREEN_CLIENTS';
export const PERMISSION_VIEW_SCREEN_TASKS = 'ERP_VIEW_SCREEN_TASKS';
export const PERMISSION_VIEW_SCREEN_PROJECTS = 'ERP_VIEW_SCREEN_PROJECTS';
export const PERMISSION_EDIT_SCREEN_PROJECTS = 'ERP_EDIT_SCREEN_PROJECTS';
export const PERMISSION_CREATE_SCREEN_PROJECTS = 'ERP_CREATE_SCREEN_PROJECTS';
export const PERMISSION_VIEW_SCREEN_CALL_CENTRE = 'ERP_VIEW_SCREEN_CALL_CENTRE';

export const PERMISSION_VIEW_PANE_CLIENTS = 'ERP_VIEW_PANE_CLIENTS';
export const PERMISSION_VIEW_PANE_DESIGN = 'ERP_VIEW_PANE_DESIGN';
export const PERMISSION_VIEW_PANE_FINANCE = 'ERP_VIEW_PANE_FINANCE';
export const PERMISSION_VIEW_PANE_INSTALLATION = 'ERP_VIEW_PANE_INSTALLATION';
export const PERMISSION_VIEW_PANE_INSPECTION = 'ERP_VIEW_PANE_INSPECTION';
export const PERMISSION_VIEW_PANE_SALES = 'ERP_VIEW_PANE_SALES';
export const PERMISSION_VIEW_PANE_MAINTENANCE = 'ERP_VIEW_PANE_MAINTENANCE';

export const PERMISSION_ACTION_COPY_ADDRESSES = 'ERP_ACTION_COPY_ADDRESSES';
export const PERMISSION_ACTION_EDIT_PRIVATE_GROUP = 'ERP_ACTION_EDIT_PRIVATE_GROUP';

// namespaces (events)
export const NAMESPACE_GLOBAL = 'global';

// event names
export const EVENT_RESIZE_START = 'resize-start';
export const EVENT_RESIZE_END = 'resize-end';
export const EVENT_SCROLL_START = 'scroll-start';
export const EVENT_SCROLL_END = 'scroll-end';

// task types
export const TASK_TYPE_DESIGN = 'design';
export const TASK_TYPE_SALES = 'sales';
export const TASK_TYPE_INSTALLATION = 'installation';
export const TASK_TYPE_FINANCE = 'finance';
export const TASK_TYPE_INSPECTION = 'inspection';
export const TASK_TYPE_CLIENT = 'client';
export const TASK_TYPE_MAINTENANCE = 'maintenance';

export const monitoringOptions = [
  { value: 'no', label: 'Geen monitoring' },
  { value: 'monitoring_only', label: 'Alleen monitoring' },
  { value: 'monitoring_maintenance', label: 'Monitoring en onderhoud' },
]

export const ticketStatuses = {
  created: 'Te plannen',
  planned: 'Gepland',
  ongoing: 'Bezig',
  finished: 'Afgerond',
  partner_approved: 'Goedgekeurd',
  rejected: 'Afgekeurd',
  approved: 'Ecorus GG',
  cancelled: 'Geannuleerd',
};

export const ticketStatusRingColorClass = {
  created: 'tw-ring-red-200',
  planned: 'tw-ring-gray-600',
  ongoing: 'tw-ring-yellow-400',
  finished: 'tw-ring-blue-300',
  partner_approved: 'tw-ring-[#8bd728]',
  rejected: 'tw-ring-red-400',
  approved: 'tw-ring-[#486f14]',
  cancelled: 'tw-ring-red-700',
};

export const ticketStatusBgColorClass = {
  created: 'tw-bg-red-200',
  planned: 'tw-bg-gray-600 tw-text-white',
  ongoing: 'tw-bg-yellow-400',
  finished: 'tw-bg-blue-300',
  partner_approved: 'tw-bg-[#8bd728]',
  rejected: 'tw-bg-red-400',
  approved: 'tw-bg-[#486f14] tw-text-white',
  cancelled: 'tw-bg-red-700 tw-text-white',
};
