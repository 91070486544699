<template>
  <section v-if="manager.isLoading">
    <div class="container">
      <h1 class="is-size-2 has-text-centered">Loading...</h1>
    </div>
  </section>
  <section v-else class="p-2 dashboard-screen">

    <div class="dashboard-controls is-flex is-align-items-center mb-1">
      <dashboard-project-selection :manager="manager" />
      <week-range-picker
        :key="`${weekRange.start && weekRange.start.text}-${weekRange.end && weekRange.end.text}`"
        :start="weekRange.start"
        :end="weekRange.end"
        @change="onWeekRangeChange"
      />
      <button-delete
        v-if="weekRange.start || weekRange.end"
        class="mx-1"
        @click="resetWeekRange"
      />
      <input
        type="checkbox"
        name="show-ytd"
        v-model="manager.showYTD"
      />
      <label for="show-ytd"
        class="mx-1"
        style="font-size: 0.7rem;"
      >
        Show YTD
      </label>
      <dropdown
        class="dashboard-table-selector"
        display="text"
        :items="tableOptions"
        :preselect="currentTable"
        @change="(option) => currentTable = option"
      />
    </div>

    <div v-if="currentTable.value === 0" class="table-container is-flex mb-2">
      <dashboard-table-status-totals
        :manager="manager"
        :expanded-row="expandedRowType"
        @click="onClickExpandRow"
      />
      <div class="table-wrapper">
        <dashboard-table-status-weekly
          :manager="manager"
          :expanded-row="expandedRowType"
          @click="onClickExpandRow"
        />
      </div>
    </div>

    <dashboard-installations
      v-else
      :manager="manager"
      :projects="projects"
      :time-unit="currentTable.value === 1 ? 'weekly' : 'monthly'"
    />
  </section>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { DashboardScreenManager } from '@/logic/DashboardScreen';

import {
  DashboardProjectSelection,
  DashboardTableStatusTotals,
  DashboardTableStatusWeekly,
  DashboardInstallations,
} from '@/components/DashboardScreen';

import { sortStringWithNumbers } from '@/logic/common';

import type { WeekRange } from '@/interfaces';

@Component({
  name: 'DashboardView',
  components: {
    DashboardProjectSelection,
    DashboardTableStatusTotals,
    DashboardTableStatusWeekly,
    DashboardInstallations,
  },
})
export default class DashboardView extends Vue {
  protected manager = new DashboardScreenManager(this.$router);

  protected weekRange: WeekRange = { ...this.manager.weekRange }

  protected expandedRowType = '';

  protected currentTable = this.tableOptions[0];

  get projects() {
    const { statistics } = this.manager;
    if (!(
      statistics.installations.monthly
      && Object.keys(statistics.installations.monthly).length > 0
    )) return [];

    const projects = Object.keys(Object.values(statistics.installations.monthly)[0].projects)
      .sort(sortStringWithNumbers);

    return projects;
  }

  get tableOptions() {
    return ['Task statuses', 'Installations weekly', 'Installations monthly']
      .map((option, idx) => ({
        text: option,
        value: idx,
      }));
  }

  protected onWeekRangeChange(weekRange: WeekRange) {
    this.weekRange = weekRange;
    this.manager.updateWeekRange(this.weekRange);
  }

  protected resetWeekRange() {
    this.weekRange = { ...this.manager.defaultWeekRange };
    this.manager.updateWeekRange(this.weekRange);
  }

  protected onClickExpandRow(event: MouseEvent, type: string) {
    const target = event.target as HTMLElement;
    if (target.tagName === 'INPUT') return;

    this.expandedRowType = type !== this.expandedRowType ? type : '';
  }
}
</script>

<style lang="scss">
.dashboard-screen {

  .dashboard-controls {
    position: relative;
    z-index: 2;
  }

  .dashboard-table-selector {
    font-size: 0.6rem;

    .select::after {
      border-color: #000 !important;
    }
  }

  .table-wrapper {
    max-width: 100%;
    overflow-x: auto;
  }

  .dashboard-table {
    font-size: 0.6rem;
    margin-bottom: auto !important;

    th {
      background-color: #444;
      color: #fff !important;

      &.current {
        background-color: #486f14;
      }
    }

    tbody {
      tr {
        background-color: #f5f5f5;
        &:nth-child(2n) {
          background-color: #fff;
        }
      }

      .fixed-height {
        height: 1rem;
      }
    }

    .dashboard-totals-header {
      min-width: 10rem;
    }
  }

  .dashboard-table-header {
    background-color: #444;
    color: #fff;
    height: 2.5rem;
  }

  .week-header {
    min-height: 3rem;
    min-width: 8rem;
  }

  .week-header-wide {
    min-width: 10rem;
    min-height: 3rem;
  }

  .forecast-input {
    width: 1.75rem;
    height: 0.8rem;
    text-align: right;
    font-size: inherit;

    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
</style>
