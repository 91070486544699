<template>
  <div class="container">
    <div class="box">
      <div class="content">
        <h4>Nieuwe groep aanmaken</h4>
      </div>
      <!-- Group Name -->
      <div class="field">
        <label class="label" style="font-size: 14px;">Groep naam</label>
        <text-input
          @change="editor.onChangeName"
          :value="editor.group.name"
          :data-id="'group-create-name?name=' + editor.group.name"
          placeholder="Groep naam..."
        />
      </div>
      <!-- Group Apps -->
      <div class="field">
        <label class="label" style="font-size: 14px;">Bijbehorende App(s)</label>
        <multiple-select-dropdown
          :items="editor.availableApps"
          :preselect="editor.group.apps"
          data-id="group-create-apps"
          display="name"
          @change="(selectedItems) => editor.setGroupInfo({ apps: selectedItems })"
        />
      </div>
      <div class="field">
        <label class="label" style="font-size: 14px;">Bijbehorende Woco</label>
        <!-- put item on group -->
        <dropdown
          v-if="editor.wocoOptions.length"
          :items="wocoDropdown"
          :preselect="editor.group.woco"
          :data-id="'group-create-woco?woco=' + editor.group.woco"
          display="label"
          @change="(item) => item.handler()"
        />
      </div>
      <div v-if="editor.group.woco" class="field">
        <label class="label" style="font-size: 14px;">Bijbehorende Project</label>
        <dropdown
          v-if="editor.projectOptions.length"
          :items="projectDropdown"
          :preselect="editor.group.project"
          :data-id="'group-create-project?project=' + editor.group.project"
          display="label"
          @change="(item) => item.handler()"
        />
        <span v-else class="has-text-danger" style="font-size: 0.8rem;">
          Er bestaan momenteel geen projecten voor {{ editor.group.woco }}
        </span>
      </div>
      <!-- Group Extend -->
      <div class="field">
        <label
          class="label"
          style="font-size: 12px;"
        >
          Naam van de groep die je wilt kopiëren (optioneel)
          <tooltip
            value="
              Wanneer je hier de naam van een bestaande groep invult, worden alle addressen die op
              die groep staan ook aan deze groep toegevoegd"
            >
            <i class="fas fa-info-circle" data-id="group-create-extend-info" ></i>
          </tooltip>
        </label>
        <text-input
          @change="(event) => editor.setState({ search: event.target.value })"
          v-datalist-selection="(name) => manager.onGroupExtendSelect(name)"
          :value="editor.state.search"
          :data-id="'group-create-extend?=' + editor.state.search"
          placeholder="Groep naam..."
          list="groups"
        />
        <datalist id="groups">
          <option
            v-for="group in manager.groupList.filteredItems.filter((group) => {
              return group.project === editor.group.project
            })"
            :key="group.name"
            :value="group.name"
          >
            {{ group.name }}
          </option>
        </datalist>
      </div>
      <!-- Group Visibility -->
      <label class="checkbox">
        <input
          type="checkbox"
          @change="() => editor.setGroupInfo({ is_private: !editor.group.is_private })"
          :checked="editor.group.is_private"
          :data-id="'group-create-privacy?private=' + editor.group.is_private"
        >
        Privé groep
      </label>
      <!-- Group Create Button -->
      <div class="buttons is-right">
        <button
          :class="`button is-ecorus-green ${manager.isLoading && 'is-loading'}`"
          @click="() => manager.createGroup()"
          :disabled="editor.buttonDisabled"
          data-id="group-create-submit"
        >Aanmaken</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import { GroupCreationScreenManager } from '@/logic/GroupCreationScreen';

@Component({
  name: 'GroupCreate',
})
export default class GroupCreate extends Vue {
  @Prop() readonly manager!: GroupCreationScreenManager;

  get editor() {
    return this.manager.editor;
  }

  get projectDropdown() {
    if (!this.editor) return [];

    const dropdown = this.editor.projectOptions.map((project) => ({
      label: project,
      handler: () => this.editor!.setGroupInfo({ project }),
    }));
    dropdown.unshift({
      label: 'Selecteer een project',
      handler: () => this.editor!.setGroupInfo({ project: '' }),
    });
    return dropdown;
  }

  get wocoDropdown() {
    if (!this.editor) return [];

    const dropdown = this.editor.wocoOptions.map((woco) => ({
      label: woco,
      handler: () => this.editor!.setGroupInfo({ woco }),
    }));
    dropdown.sort((a, b) => a.label.localeCompare(b.label));
    dropdown.unshift({
      label: 'Selecteer een woco',
      handler: () => this.editor!.setGroupInfo({ woco: '' }),
    });

    return dropdown;
  }

  @Watch('editor.group.woco')
  public updateProjects() {
    this.editor!.getProjects();
  }
}
</script>

<style>

</style>
