<template>
  <div
    v-if="selectedTicket && selectedTicket.actions.cancel_ticket_button"
    class="box cancel-ticket"
  >
    <details-box-heading label="Ticket cancellation" />
    <div class="box-content">
      <input
        class="input is-small mb-2"
        type="text"
        placeholder="Cancel comment"
        v-model="cancelComment"
      />
      <details-ticket-button
        v-if="selectedTicket.actions.cancel_ticket_button"
        field="cancel_ticket_button"
        :component="selectedTicket.actions.cancel_ticket_button"
        :post-data="{ cancel_comment: cancelComment }"
        @click="refreshDetails"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import DetailsPaneBase from './DetailsPaneBase.vue';

interface Ticket {
  id: string;
  actions: { [prop: string]: unknown };
  ticket: { [prop: string]: unknown };
}

@Component({
  name: 'DetailsTicketCancellationBox',
})
export default class DetailsTicketCancellationBox extends DetailsPaneBase {
  @Prop({ default: [] }) tickets!: Array<Ticket>;
  @Prop({ }) selected!: number;

  protected cancelComment = '';

  get selectedTicket() {
    if (this.selected === null) return null;

    return this.tickets[this.selected];
  }
}
</script>
