import { isDeepEqual, print } from '@/util';
import MultiSelectList from '@/logic/common/MultiSelectList';

interface Dictionary {
  [key: string]: Dictionary;
}

export default class MultiSelectDropdownController<T> {
  public isCollapsed = true;
  public list: MultiSelectList<T>

  constructor(items: Array<T>, preselectedItems: Array<T>) {
    this.list = new MultiSelectList(items, {
      autoRemoveDuplicates: true,
    });
    this.setPreselectedItems(preselectedItems);
  }

  public close = () => {
    this.isCollapsed = true;
  }

  public open = () => {
    this.isCollapsed = false;
  }

  public toggleCollapse = () => {
    this.isCollapsed = !this.isCollapsed;
  }

  public onClickItem = (item: T, event: MouseEvent) => {
    this.list.onClickItem(item, event);

    if (!event.ctrlKey && !event.metaKey && !event.shiftKey) {
      this.close();
    }
  }

  public getDisplayPropertyValue = (item: T, displayPropery: string) => (
    displayPropery.split('.').reduce((v: Dictionary, k) => v[k], item as unknown as Dictionary)
  )

  public setPreselectedItems = (preselectedItems: Array<T>) => {
    this.list.setPreselectedItems(this.getItemsForPreselection(preselectedItems));
  }

  protected getItemsForPreselection = (preselectedItems: Array<T>) => {
    const targetItems = preselectedItems
      .map((preselectItem) => this.list.items.find((item) => isDeepEqual(item, preselectItem)))
      .filter((item) => item !== undefined);
    return targetItems as Array<T>;
  }
}
