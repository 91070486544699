<template>
  <div class="tooltip">
    <slot />
    <span class="tooltiptext" :class="position">{{ value }}</span>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({ name: 'Tooltip' })
export default class Tooltip extends Vue {
  @Prop() readonly value!: string;

  @Prop({ default: 'bottom' }) readonly position!: string;
}
</script>

<style lang="scss" scoped>
$base-color: rgb(46, 46, 46);

.left {
  top: -10px;
  right: 150%;
}

.bottom {
  top: 120%;
  left: 50%;
  transform: translateX(-50%);
  min-width: 300px !important;

  &::after {
    content: " ";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent $base-color transparent;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted $base-color;

  &:hover .tooltiptext {
    visibility: visible;
  }

  .tooltiptext {
    visibility: hidden;
    min-width: 150px;
    width: auto;
    background-color: $base-color;
    color: #fff;
    border-radius: 6px;
    padding: 7px;
    font-size: 12px;

    position: absolute;
    z-index: 1;
  }
}
</style>
