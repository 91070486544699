<template>
  <div class="detail-content">
    <!-- CANCEL DESIGN -->
    <div
      v-if="shouldRenderComponentBox(components.cancel_design)"
      class="is-flex is-flex-wrap-wrap"
    >
      <div class="box cancel-design">
        <details-box-heading :label="components.cancel_design.name" />
        <div class="box-content">
          <input class="input is-small mb-2"
            type="text"
            placeholder="Cancel comment"
            v-model="designCancelComment"
          />
          <details-ticket-button
            :key="designCancelComment"
            field="cancel_ticket_button"
            :component="components.cancel_design.cancel_design_button"
            :post-data="{ reason: designCancelComment }"
            @click="refreshDetails"
          />
        </div>
      </div>
    </div>

    <div class="is-flex is-flex-wrap-wrap">

      <!-- TECHNICAL INFO -->
      <div
        v-if="shouldRenderComponentBox(components.technical_info)"
        class="box technical-info"
      >
        <details-box-heading :label="components.technical_info.name" />
        <div class="box-content">
          <template v-for="[field, component] in Object.entries(components.technical_info)">

            <details-numeric-input
              v-if="componentType(component) === 'numberInput'"
              :key="field"
              :field="field"
              :component="component"
              @change="updateMasterData"
            />

            <details-search-selector
              v-if="componentType(component) === 'selector'"
              :key="field"
              :field="field"
              :component="component"
              @change="updateMasterData"
            />

          </template>
        </div>
      </div>

      <!-- PRODUCTS -->
      <div
        v-if="shouldRenderComponentBox(components.products)"
        class="box products"
      >
        <details-box-heading :label="components.products.name" />
        <div class="box-content">
          <template v-for="[productType, productTypeData] in products">
            <details-box-heading :key="`product-${productType}`" :label="capitalize(productType)" />
            <template v-for="item in productTypeData.items">
              <template
                v-for="[field, component] in item"
              >

                <details-delete-cross
                  v-if="componentType(component) === 'deleteCross'"
                  :key="field"
                  field="value"
                  :component="component"
                  @click="refreshDetails"
                />

                <details-numeric-input
                  v-if="(
                    componentType(component) === 'numberInput'
                    && (hasAssociatedProductType(field) || hasPower(field, productTypeData.items))
                  )"
                  :key="field"
                  :field="field"
                  :component="component"
                  @change="(value) => onProductChange(field, value, updateMasterData)"
                />

                <details-search-selector
                  v-if="componentType(component) === 'selector'"
                  :key="field"
                  :field="field"
                  :component="normalizedProductComponent(component)"
                  display="value"
                  submit-value="code"
                  @change="(value) => onProductChange(field, value, updateMasterData)"
                />

                <details-checkbox-input
                  v-if="(
                    componentType(component) === 'checkboxInput'
                    && hasAssociatedProductType(field)
                  )"
                  :key="field"
                  :field="field"
                  :component="component"
                  @change="(value) => onProductChange(field, value, updateMasterData)"
                />

              </template>
            </template>

            <!-- ADD PRODUCT BUTTON -->
            <div :key="`add-${productType}`" class="buttons is-right">
              <details-action-button
                v-if="productTypeData.actions.length > 0"
                :component="productTypeData.actions[0]"
                @click="refreshDetails"
              />
            </div>
          </template>
        </div>
      </div>

    </div>

    <div class="is-flex is-flex-wrap-wrap">
      <!-- IMAGES -->
      <div
        v-if="shouldRenderComponentBox(components.images)"
        class="box design-images"
      >
        <details-box-heading :label="components.images.name" />
        <!-- IMAGE FIELDS -->
        <div class="box-content is-flex is-flex-wrap-wrap is-align-items-center">
          <details-image-field
            v-for="[field, component] in designImageFields"
            class="mr-1"
            :key="`image-${field}-${component.value}`"
            :field="field"
            :component="component"
            @click="onClickImageField"
            @mouseenter="onHoverImageField"
          />
        </div>
        <!-- IMAGE PREVIEW / UPLOAD -->
        <details-image-preview
          v-if="hasImage(activeImage)"
          :field="activeImageField"
          :component="activeImageComponent"
          @click="onClickImageField"
        />
        <details-image-upload
          v-else-if="activeImage"
          :field="activeImageField"
          :component="activeImageComponent"
          @uploadstart="onUploadImageStart"
          @uploadend="onUploadImageEnd"
        />
      </div>
    </div>

    <!-- IMAGE MODAL -->
    <details-image-modal
      v-if="showImageModal"
      :key="`${designImages.length}-${activeImageIndex}`"
      type="design"
      :images="designImages"
      :initial-index="activeImageIndex"
      :delete="showImageDeleteButton ? onDeleteImage : null"
      :close="onCloseImageModal"
    />
  </div>
</template>

<script lang="ts">
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from 'vue-property-decorator';
import requests from '@/requests';
import { STATUS_OK } from '@/constants';
import type { CustomResponse } from '@/interfaces';
import { print, capitalize } from '@/util';
import DetailsPaneBase from './DetailsPaneBase.vue';

interface ProductComponent {
  value: string;
  values: Array<{ value: string; code: string }>;
}

interface ImageComponent {
  value: string;
  action: { [prop: string]: string };
}

type ImageField = [string, ImageComponent]

@Component({
  name: 'DetailsPaneDesign',
  methods: {
    capitalize,
  },
})
export default class DetailsPaneDesign extends DetailsPaneBase {
  protected activeImage: ImageField | null = null;
  protected showImageModal = false;
  protected isProcessingImage = false;

  protected designCancelComment = '';

  protected productFieldValues: { [field: string]: number | string } = {};

  get productTypes() {
    if (!this.components.products) return [];

    const products = Object.entries(this.components.products).filter(([productType]) => (
      productType !== 'name'
    ));

    return products;
  }

  get products() {
    const fieldWeights: { [field: string]: number } = {
      delete: 1,
      type: 2,
      quantity: 3,
    };

    const products = this.productTypes.map(([productType, productData]: [string, any]) => ([
      productType,
      {
        ...productData,
        items: productData.items.map((item: any) => Object.entries(item).sort(([a], [b]) => (
          fieldWeights[a.split('_')[1]] - fieldWeights[b.split('_')[1]]
        ))),
      },
    ]));

    return products;
  }

  get activeImageField() {
    return this.activeImage ? this.activeImage[0] : null;
  }

  get activeImageComponent() {
    return this.activeImage ? this.activeImage[1] : null;
  }

  get showImageDeleteButton() {
    return this.status && !['approved', 'cancelled'].includes(this.status);
  }

  get designImageFields() {
    if (!this.components.images) return [];

    return Object.entries(this.components.images)
      .filter(([imageField]) => imageField !== 'name') as Array<[string, ImageComponent]>;
  }

  get designImages() {
    return this.designImageFields.filter(([imageField, imageData]) => {
      if (imageField === 'name') return false;
      return !!(imageData as ImageComponent).value;
    }) as Array<[string, ImageComponent]>;
  }

  get activeImageIndex() {
    if (!this.activeImage) return 0;

    for (let idx = 0; idx < this.designImages.length; idx += 1) {
      if (this.designImages[idx][0] === this.activeImage[0]) {
        return idx;
      }
    }

    return 0;
  }

  protected onProductChange(field: string, value: string | number, callback: Function) {
    if (field.startsWith('panel_type')) {
      const panel_power = field.replace('type', 'power');
      this.productFieldValues[panel_power] = '';
    }
    this.productFieldValues = { ...this.productFieldValues, [field]: value };
    callback();
  }

  protected hasAssociatedProductType(quantityField: string) {
    const typeField = quantityField.replace(/quantity|is_installed/, 'type');
    return !!this.productFieldValues[typeField];
  }

  protected hasPower(quantityField: string, items: any) {
    const typeField = quantityField.replace(/quantity|power/, 'type');
    for (let idx = 0; idx < items[0].length; idx += 1) {
      if (items[0][idx][0].startsWith('panel_power') && !this.productFieldValues[typeField]) {
        return true;
      }
    }
    return false;
  }

  protected hasImage(imageField: ImageField) {
    return imageField && !!imageField[1].value;
  }

  protected onCloseImageModal() {
    this.showImageModal = false;
  }

  protected async onDeleteImage(image: ImageField) {
    this.isProcessingImage = true;

    const [field, component] = image;
    const response = await requests.details.deleteTaskDetailForClient(
      `api/v1/${component.action.delete}`,
      { [field]: component.value },
    );

    if (response.status === STATUS_OK) {
      component.value = '';
      this.components.images = { ...this.components.images };
      if (this.designImages.length < 1) {
        this.onCloseImageModal();
      }
    }

    this.isProcessingImage = false;
  }

  protected onClickImageField([field, component]: ImageField) {
    if (this.isProcessingImage) return;

    this.activeImage = [field, component];
    if (component.value) {
      this.showImageModal = true;
    }
  }

  protected onHoverImageField([field, component]: ImageField) {
    if (this.isProcessingImage) return;

    this.activeImage = [field, component];
  }

  protected onUploadImageStart() {
    this.isProcessingImage = true;
  }

  protected onUploadImageEnd(response: CustomResponse) {
    this.isProcessingImage = false;
    if (response.status === STATUS_OK) {
      const responseData = response.data[0];

      for (let idx = 0; idx < this.designImageFields.length; idx += 1) {
        const [field, component] = this.designImageFields[idx];
        if (field === responseData.attribute_name) {
          component.value = responseData.attribute_value;
          this.components.images = { ...this.components.images };
          return;
        }
      }
    }
  }

  protected normalizedProductComponent(component: ProductComponent) {
    const selectedProduct = component.values.find(({ code }) => code === component.value);
    return {
      ...component,
      value: selectedProduct ? selectedProduct.value : 'Select a product',
    };
  }

  protected setProductFieldValues() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.products.forEach(([_, productData]) => {
      productData.items.forEach((item: any) => item.forEach(([field, { value }]: any) => {
        if (!field.match('delete')) {
          this.productFieldValues[field] = value;
        }
      }));
    });
  }

  created() {
    this.activeImage = this.designImages.length > 0 ? this.designImages[0] : null;
    this.setProductFieldValues();
  }
}
</script>
