<template>
  <InfoContainer title="Bestanden" v-if="files">
    <div v-if="isLoading">
      Loading...
    </div>
    <div v-else-if="files">
      <div class="tw-grid tw-grid-cols-2 tw-gap-1 tw-mt-3">
        <div v-for="file in files" class="tw-flex tw-flex-col tw-gap-2 tw-border tw-border-gray-200 tw-rounded-xs tw-p-2">
          <div>
            <a
              :href="file.url"
              target="_blank"
              class="tw-w-10 tw-h-10"
            >
              <div class="tw-flex tw-items-center tw-text-black tw-h-full" v-if="!file.thumbnail_url">
                <DocumentIcon  class="tw-w-10 tw-h-10" />
                <div class="tw-text-md">
                  {{ nameFromKey(file.key) }}
                </div>
              </div>
              <img
                v-else
                :src="file.thumbnail_url || file.url"
                :data-alternative-url="file.url"
                @error="checkAlternativeImgUrl"
                class="tw-w-full tw-h-full"
              />
            </a>
          </div>
          <div>
            <button @click="deleteFile(file.key)" class="tw-p-1 tw-text-xs tw-justify-center tw-items-center tw-bg-red-200 tw-border-gray-600 tw-text-gray-600 tw-border hover:tw-bg-red-100 tw-rounded tw-flex">
              <div><TrashIcon class="tw-w-3 tw-h-3" /></div>
              <div>delete</div>
            </button>
          </div>
        </div>
      </div>

      <div v-if="files.length === 0">
        Geen bestanden
      </div>

      <div v-if="deleteImageLoading" class="tw-text-gray-500">Deleting...</div>
      <div v-if="uploadImageLoading" class="tw-text-gray-500">
        Uploading...
      </div>

      <div v-else>
        <FormField
          label="Upload file"
          type="file"
          @change="uploadFileToS3($event.target.files[0])"
          accept="image/*,.pdf"
        />
      </div>
    </div>

  </InfoContainer>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query';
import { notifications } from '@/util';
import fetchData from '@/util/fetch';
import InfoContainer from '@/components/shared/InfoContainer.vue';
import FormField from '@/components/shared/FormField.vue';
import {
  DocumentIcon,
  TrashIcon,
} from '@vue-hero-icons/outline';

const props = defineProps({
  caseDetail: Object,
});

const queryClient = useQueryClient();

const { data: files, isLoading } = useQuery({
  queryKey: ['caseFiles', props.caseDetail.id],
  queryFn: async () => {
    try {
      const result = await fetchData(`/tickets/api/v1/cases/${props.caseDetail.id}/files`);
      return result.data;
    } catch (error) {
      notifications.addNotification({
        message: `Failed to get case files: ${error.message}`,
        type: 'danger',
      });
      throw new Error('Failed to fetch case files');
    }
  },
  refetchOnWindowFocus: false,
});

function invalidateFiles() {
  queryClient.invalidateQueries({ queryKey: ['caseFiles', props.caseDetail.id] });
}

const { mutate: uploadFileToS3, isLoading: uploadImageLoading } = useMutation({
  mutationFn: async (file) => {
    let uploadInfo = props.caseDetail._s3.upload_image
    if (file.name.toLowerCase().endsWith('.pdf')) {
      uploadInfo = props.caseDetail._s3.upload_pdf
    }

    if (!uploadInfo) alert('No upload credentials, something went wrong')
    if (!file) alert('Invalid file, try again')

    const key = uploadInfo.fields.key.replace(
      '${filename}',
      file.name,
    )

    const formData = new FormData()
    Object.keys(uploadInfo.fields).forEach((key) =>
      formData.append(key, uploadInfo.fields[key])
    )

    formData.append('Content-Type', file.type)
    formData.set('key', key)
    formData.append('file', file)

    const result = await fetch(uploadInfo.url, {
      method: 'POST',
      body: formData,
    })
    if (!result.ok) alert(`error in fetch ${result.status}`)
    if (!result.ok) throw `Fout bij uploaden, foutcode ${result.status}`

    return {
      key,
    }
  },
  onSuccess() {
    invalidateFiles()
  },
  onError(error) {
    notifications.addNotification({
      message: `Failed to upload file: ${error.message}`,
      type: 'danger',
    });
  },
})

function nameFromKey(key) {
  return key.split('/').pop()
}

const { mutate: deleteFile, isLoading: deleteImageLoading } = useMutation({
  mutationFn: async (key) => {
    try {
      const name = nameFromKey(key)
      await fetchData(`/tickets/api/v1/cases/${props.caseDetail.id}/files/${name}`, {
        method: 'DELETE',
      });
    } catch (error) {
      notifications.addNotification({
        message: `Failed to delete file: ${error.message}`,
        type: 'danger',
      });
      throw new Error('Failed to delete file');
    }
  },
  onSuccess() {
    invalidateFiles()
  },
})

function checkAlternativeImgUrl(e) {
  const alternative = e.target.dataset.alternativeUrl
  // we want to check if it's not already set, otherwise we get in an endless loop
  // if an url really doesn't exist
  if (e.target.src != alternative) {
    e.target.src = alternative
  }
}
</script>
