<template>
  <div class="tw-flex tw-flex-col">
    <div class="tw-font-bold">
      <div class="tw-bg-white tw-drop-shadow-xl tw-md:p-3 tw-rounded-b-lg tw-p-3">
        <div class="tw-flex tw-justify-between">
          <div v-if="show" class="tw-mb-3 tw-flex">
            <h1 class="tw-font-bold tw-tracking-wider tw-uppercase tw-text-gray-500 tw-mt-3">
              Filters
            </h1>
          </div>
          <button @click="show = !show" class="tw-flex tw-uppercase tw-text-gray-500">
            <span v-if="show" class="tw-w-[20px]">
              <i class="fas fa-caret-left" aria-hidden="true"></i>
            </span>
            <span v-else class="tw-w-[20px]">
              <i class="fas fa-caret-right" aria-hidden="true"></i>
            </span>
          </button>
        </div>

        <div v-if="show" class="tw-w-[300px]">
          <slot></slot>
          <div class="tw-flex tw-justify-between">
            <button
              class="tw-bg-gray-500 tw-rounded tw-p-1 tw-text-white tw-mt-3"
              @click="$emit('resetFilters')"
            >
              Reset filters
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const show = ref(true);
</script>
