<template>
  <info-container title="Tickets" v-if="selectedTicket">
    <div class="tw-flex tw-justify-between tw-space-x-4 tw-text-gray-700">
      <div class="tw-flex">
        Ticket ID:
        <a
          :href="`https://tickets.ecorus.com/v2/tickets/${selectedTicket.id}`"
          target="_blank"
          class="tw-text-white tw-bg-gray-500 tw-rounded tw-px-2 tw-ml-3 tw-text-white tw-flex tw-justify-center"
        >
          {{ selectedTicket.id }}
        </a>
      </div>
      <span
        class="tw-rounded-lg tw-inline tw-px-1 tw-text-white"
        :class="getStatusClass(selectedTicket.ticket?.current_status?.toLowerCase())"
        >{{ selectedTicket.ticket?.current_status }}</span
      >
    </div>

    <div class="tw-flex tw-justify-between tw-space-x-4 tw-text-gray-700 tw-my-3">
      <div>Asbuilt</div>
      <div
        v-if="asbuiltURL"
        class="tw-bg-gray-500 tw-rounded tw-px-1 tw-text-white tw-flex tw-justify-center"
      >
        <a :href="asbuiltURL" target="_blank" class="tw-text-white"> Download asbuilt </a>
      </div>
      <div v-else>Not generated yet</div>
    </div>

    <div class="tw-flex tw-justify-between tw-space-x-4 tw-text-gray-700">
      <span>Created:</span>
      <span class="tw-font-bold">{{
        formatDateTime(selectedTicket.ticket.status[0]?.created_at) || ''
      }}</span>
    </div>
    <div class="tw-flex tw-justify-between tw-space-x-4 tw-text-gray-700">
      <span>Installer:</span>
      <span class="tw-font-bold">{{ selectedTicket.installer }}</span>
    </div>
    <div class="tw-flex tw-justify-between tw-space-x-4 tw-text-gray-700 tw-mb-3">
      <span>Appointment date:</span>
      <span class="tw-font-bold" v-if="isScheduledAppointment">{{ appointmentDate }}</span>
      <span v-else>To be planned</span>
    </div>
    <div v-if="selectedTicket?.ticket?.note?.length > 0">
      <span>Appointment notes:</span>
      <p
        v-for="note in selectedTicket?.ticket?.note"
        :key="`work-${note}`"
        class="tw-card tw-mb-3 tw-cursor-pointer tw-p-2 tw-border tw-border-gray-200 tw-rounded-lg tw-bg-blue-100"
      >
        {{ note }}
      </p>
    </div>
    <div
      class="tw-flex tw-justify-between tw-space-x-4 tw-text-gray-700"
      v-if="selectedTicket.ticket?.status[selectedTicket.ticket?.status?.length - 1].comment"
    >
      <span>Remarks:</span>
      <span class="tw-font-bold">{{
        selectedTicket.ticket?.status[selectedTicket.ticket?.status?.length - 1].comment
      }}</span>
    </div>
    <hr class="tw-my-5" v-if="oldTickets?.length" />
    <div v-if="oldTickets" v-for="ticket in oldTickets">
      <div class="tw-flex tw-justify-between tw-space-x-4 tw-text-gray-700 tw-mt-5">
        <div class="tw-flex">
          Ticket ID:
          <a
            :href="`https://tickets.ecorus.com/v2/tickets/${ticket.id}`"
            target="_blank"
            class="tw-text-white tw-bg-gray-500 tw-rounded tw-px-2 tw-ml-3 tw-text-white tw-flex tw-justify-center"
          >
            {{ ticket.id }}
          </a>
        </div>
        <span
          class="tw-rounded-lg tw-inline tw-px-1 tw-text-white"
          :class="getStatusClass(ticket.ticket?.current_status?.toLowerCase())"
          >{{ ticket.ticket?.current_status }}</span
        >
      </div>

      <div class="tw-flex tw-justify-between tw-space-x-4 tw-text-gray-700">
        <span>Created:</span>
        <span class="tw-font-bold">{{ formatDateTime(ticket.ticket?.status[0]?.created_at) }}</span>
      </div>
      <div class="tw-flex tw-justify-between tw-space-x-4 tw-text-gray-700">
        <span>Installer:</span>
        <span class="tw-font-bold">{{ ticket.installer }}</span>
      </div>
      <div
        class="tw-flex tw-justify-between tw-space-x-4 tw-text-gray-700"
        v-if="ticket.ticket?.status[ticket.ticket?.status?.length - 1]?.comment"
      >
        <span>Remarks:</span>
        <span class="tw-font-bold">{{
          ticket.ticket?.status[ticket.ticket?.status?.length - 1].comment
        }}</span>
      </div>
    </div>
  </info-container>
</template>

<script setup>
import InfoContainer from '@/components/shared/InfoContainer.vue';
import { computed } from 'vue';
import { formatDateTime } from '@/util';
import { getStatusClass } from './utils.js';

const props = defineProps({
  components: {
    type: Object,
    required: true,
  },
});

const selectedTicket = computed(() => {
  return props.components.tickets && props.components.tickets[props.components.tickets?.length - 1];
});

const isScheduledAppointment = computed(() => {
  return selectedTicket?.value?.ticket?.appointment?.start;
});
const appointmentDate = computed(() => {
  return formatDateTime(selectedTicket?.value?.ticket?.appointment?.start);
});

const asbuiltURL = computed(() => {
  return props.components.asbuilt?.asbuilt_pdf_viewer.url;
});

const oldTickets = computed(() => {
  return props.components.tickets.filter((ticket) => ticket.id !== selectedTicket.value.id);
});
</script>
