<template>
  <dropdown-slotted
    v-if="fields.length > 0"
    label="Data"
    align="left"
    icon-color="#fff"
    close-key="escape"
  >
    <scroll-container max-height="10rem">
      <div
        v-for="field in fields"
        :key="`data-${field}`"
        class="is-flex is-align-items-center"
        @click="(event) => onFieldClick(event, field)"
      >
        <input
          class="mr-1"
          type="checkbox"
          :checked="fieldList.isSelected(field)"
        >
        <p class="has-text-black">{{ capitalize(field) }}</p>
      </div>
    </scroll-container>
    <div class="is-flex is-justify-content-end">
      <button
        class="button tiny-button is-info is-bold"
        style="margin-right: 0.6rem;"
        @click="fieldList.toggleSelectAll"
      >
        toggle all
      </button>
    </div>
  </dropdown-slotted>
</template>

<script lang="ts">
import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';

import type { TaskTypeKey } from '@/interfaces';
import type { TasksScreenManager } from '@/logic/TasksScreen';
import { capitalize, composeSort } from '@/util';
import { MultiSelectList } from '@/logic/common';
import { actions } from '@/store';

@Component({
  name: 'MasterDataFilter',
  methods: {
    capitalize,
  },
})
export default class MasterDataFilter extends Vue {
  @Prop({}) manager!: TasksScreenManager;
  @Prop({}) type!: TaskTypeKey;

  protected fieldList = new MultiSelectList<string>([], {
    checkboxMode: true,
    autoSort: true,
    sorters: {
      byName: composeSort((a: string, b: string) => a.localeCompare(b)),
    },
  });

  get fields() {
    return this.fieldList.items;
  }

  get list() {
    return this.manager.list;
  }

  @Watch('fieldList.selectedItems')
  protected onSelectionChange() {
    this.$forceUpdate();
    actions.tasks.commitMasterData({
      type: this.type,
      fields: this.fields.reduce((acc, field) => ({
        ...acc,
        [field]: !this.fieldList.isSelected(field),
      }), {}),
    });
  }

  protected onFieldClick(event: MouseEvent, field: string) {
    this.fieldList.onClickItem(field, event);
  }

  protected setAvailableFields() {
    const storedFields = actions.tasks.readMasterData()[this.type];
    const fields: Set<string> = this.list.getItems().reduce((fieldSet, item) => {
      const taskData = item.getTaskData(this.type);
      if (taskData) {
        Object.keys(taskData).forEach((field) => fieldSet.add(field));
      }
      return fieldSet;
    }, new Set() as Set<string>);

    this.fieldList.setItems(Array.from(fields));
    const fieldsToBeSelected = Array.from(fields).filter((field) => !storedFields[field]);
    this.fieldList.setPreselectedItems(fieldsToBeSelected);
  }

  mounted() {
    this.setAvailableFields();
  }
}

</script>
