<template>
  <div
    class="is-flex"
    :class="[flexClasses, styleClass]"
  >
    <div class="is-flex is-justify-content-space-between" :class="align === 'vertical' && 'my-1'">
      <slot>
        <span class="mx-1" style="font-weight: normal; align-self: center;">from</span>
      </slot>
      <input
        type="date"
        name="start"
        class="is-size-7"
        :value="start"
        @change="onDateChange"
      />
    </div>
    <div class="is-flex is-justify-content-space-between">
      <slot>
        <span class="mx-1" style="font-weight: normal; align-self: center;">until</span>
      </slot>
      <input
        type="date"
        name="end"
        class="is-size-7"
        :value="end"
        @change="onDateChange"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'DateRangePicker',
})
export default class DateRangePicker extends Vue {
  @Prop({ default: 'vertical' }) align!: string;
  @Prop({ default: '' }) start!: string;
  @Prop({ default: '' }) end!: string;
  @Prop({ default: '' }) styleClass!: string;

  get flexClasses() {
    return this.align === 'vertical'
      ? 'is-flex-direction-column'
      : 'is-align-items-center';
  }

  protected onDateChange(event: InputEvent) {
    const target = event.target as HTMLInputElement;
    const range = this.getRange(target);

    this.$emit('change', range);
    this.$forceUpdate();
  }

  protected getRange(target: HTMLInputElement) {
    const startValue = target.name === 'start' ? target.value : this.start;
    const endValue = target.name === 'end' ? target.value : this.end;

    const dateStart = new Date(startValue);
    const dateEnd = new Date(endValue);

    if (Number.isNaN(+dateStart) || Number.isNaN(+dateEnd)) {
      return { start: startValue, end: endValue };
    }

    return dateStart <= dateEnd
      ? { start: startValue, end: endValue }
      : { start: endValue, end: startValue };
  }
}

</script>
