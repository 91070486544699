<template>
  <div class="is-flex is-align-items-center">
    <select class="select" @change="onChange">
      <option
        :selected="selectedInstaller === ''"
        :value="''"
      >
        Select installer
      </option>
      <option
        v-for="installer in installers"
        :key="installer"
        :selected="installer === selectedInstaller"
      >{{ installer }}</option>
    </select>
    <span class="tag" style="font-size: 0.6rem;">{{ this.actionList.length }}</span>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { TasksScreenManager } from '@/logic/TasksScreen';
import type MasterItem from '@/logic/TasksScreen/MasterItem';

import type { Action, TaskTypeKey } from '@/interfaces';

interface StoredAction {
  item: MasterItem;
  callback: Function;
  action: Action;
  taskType: TaskTypeKey;
}

@Component({})
export default class UserSelect extends Vue {
  @Prop({}) actionList!: Array<StoredAction>;
  @Prop({}) manager!: TasksScreenManager;
  @Prop({}) masterItem!: MasterItem;

  protected selectedInstaller = '';
  protected installerOptions = this.installers;

  get installers() {
    const installers = [...this.actionList[0].action.data as Array<string>];
    return installers.sort((a, b) => a.localeCompare(b));
  }

  protected onChange = (event: InputEvent) => {
    const target = event.target as HTMLSelectElement;

    this.selectedInstaller = target.value;
    this.updateSelection(this.selectedInstaller);
  }

  protected updateSelection(installer: string) {
    this.manager.setActionDataForItems({ assignedInstaller: installer }, this.actionList);
  }

  mounted() {
    this.updateSelection(this.selectedInstaller);
  }
}

</script>

<style lang="scss" scoped>
.select {
  margin-right: 5px;
  font-size: 10px;
  height: 14px !important;
}
</style>
