import { isDeepEqual } from '@/util';
import MultiSelectList from '@/logic/common/MultiSelectList';

interface Dictionary {
  [key: string]: Dictionary;
}

export default class DropdownController<T> {
  public list: MultiSelectList<T>

  protected displayKey: string;

  constructor(items: Array<T>, displayKey: string, preselectedItem: T) {
    this.displayKey = displayKey;
    this.list = new MultiSelectList(items, {
      autoRemoveDuplicates: true,
      autoSort: false,
      multiSelectEnabled: false,
    });
    this.setPreselectedItems(preselectedItem);
  }

  public onClickItem = (item: T, event: MouseEvent) => {
    this.list.onClickItem(item, event);
  }

  public getDisplayPropertyValue = (item: T) => {
    if (!this.displayKey) return item;

    return this.displayKey
      .split('.')
      .reduce((v: Dictionary, k) => v[k], item as unknown as Dictionary);
  }

  public setPreselectedItems = (preselectedItem: T) => {
    this.list.setPreselectedItems(this.getItemsForPreselection(preselectedItem));
  }

  protected getItemsForPreselection = (preselectedItem: T) => {
    const targetItems = [preselectedItem]
      .map(
        (preselectItem) => this.list.items.find((item) => (
          isDeepEqual(item, preselectItem)
          || this.getDisplayPropertyValue(item) === preselectedItem
        )),
      )
      .filter((item) => item !== undefined);
    return targetItems as Array<T>;
  }
}
