<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import BaseButtonBulk from './BaseButtonBulk.vue';

@Component({
  name: 'StartButtonBulk',
})
export default class StartButtonBulk extends BaseButtonBulk {
  // protected label = 'start';
  protected color = 'is-info';
}
</script>
