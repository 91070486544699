<template>
  <button
    class="delete"
    :class="[size, spacing, loading]"
    :data-id="dataId"
    @click="$emit('click', $event)"
  >
  </button>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({ name: 'ButtonDelete' })
export default class ButtonDelete extends Vue {
  @Prop({ default: '' }) spacing!: string;
  @Prop({ default: false }) isLoading!: boolean;
  @Prop({}) dataId!: boolean;
  size = 'is-small';

  loading = this.isLoading ? 'is-loading' : '';
}
</script>
