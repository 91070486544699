<template>
  <table-task :config="tableConfig" :manager="manager" />
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import type { TasksScreenManager } from '@/logic/TasksScreen';

import TableTask from './TableTask.vue';
import AddressTableRow from './AddressTableRow.vue';
import TableHeadSelectAll from './TableHeadSelectAll.vue';
import TableHeadAddresses from './TableHeadAddresses.vue';

@Component({
  name: 'AddressTable',
  components: {
    TableTask,
    AddressTableRow,
    TableHeadSelectAll,
    TableHeadAddresses,
  },
})
export default class AddressTable extends Vue {
  @Prop() manager!: TasksScreenManager;

  protected tableConfig = {
    headers: [
      {
        component: TableHeadSelectAll,
        props: {
          list: this.manager.list,
        },
        width: '25px',
      },
      {
        component: TableHeadAddresses,
        props: {
          manager: this.manager,
        },
      },
    ],
    row: {
      component: AddressTableRow,
      props: {
        manager: this.manager,
      },
    },
  }
}
</script>
