<template>
  <div class="is-flex is-justify-content-space-between is-align-items-center">
    <p class="static-data-field mr-2">{{ field }}</p>
    <slot>
      <p
        class="static-data-value"
        :class="{ 'static-data-value-wrap': wrap }"
      >
        {{ value }}
      </p>
    </slot>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'DetailsStaticDataField',
})
export default class DetailsStaticDataField extends Vue {
  @Prop({ default: '' }) field!: string;
  @Prop({ default: '' }) value!: string;
  @Prop({ default: false }) wrap?: boolean;
}

</script>

<style lang="scss" scoped>
.static-data-field,
.static-data-value {
  font-size: 0.73rem;
  white-space: nowrap;
}

.static-data-value-wrap {
  white-space: normal;
  text-align: right;
}
</style>
