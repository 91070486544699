<template>
  <dropdown-slotted>
    <template v-slot:trigger>
      <button-outline
        label="copy details"
        @click="() => showActionResult = false"
      />
      <!-- <small-tag
        label="copy details"
        color="is-primary"
      /> -->
    </template>

    <template v-slot:default>
      <div
        v-for="[groupKey, groupProps] in Object.entries(fields)"
        :key="groupProps.name"
      >
        <!-- GROUP -->
        <input
          type="checkbox"
          :checked="!!groupProps.selected"
          :disabled="isLoading"
          @change="() => setSelectedFieldsForGroup(groupKey)"
        />
        <dropdown-slotted>
          <template v-slot:trigger>
            <span style="margin-left: 0.2rem;">{{ groupProps.name }}</span>
            <i class="fas fa-angle-down pl-2" aria-hidden="true"></i>
          </template>

          <!-- GROUP FIELDS -->
          <template v-slot:default>
            <div
              v-for="field in groupProps.fields"
              :key="field.tag"
              class="is-flex is-align-items-center"
            >
              <input
                type="checkbox"
                :checked="field.selected"
                :disabled="isLoading"
                @change="() => field.selected = !field.selected"
              />
              <span style="margin-left: 0.2rem;">{{ field.name }}</span>
            </div>
          </template>
        </dropdown-slotted>
      </div>

      <!-- SUBMIT -->
      <div class="action-control">
        <button-outline
          color="is-primary"
          :loading="isLoading"
          :disabled="isLoading"
          label="perform copy"
          @click="onSubmit"
        />
        <small-tag
          v-if="showActionResult"
          :label="actionSuccess ? 'success' : 'failed'"
          :color="actionSuccess ? 'is-success is-light' : 'is-danger is-light'"
        />
      </div>
    </template>

  </dropdown-slotted>
</template>

<script lang="ts">
import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import type { TasksScreenManager } from '@/logic/TasksScreen';
import request from '@/requests';

import {} from './BaseButton.vue';
import { STATUS_OK } from '@/constants';

interface FieldsData {
  [groupKey: string]: {
    name: string;
    selected?: boolean;
    fields: Array<{
      name: string;
      tag: string;
      selected?: boolean;
    }>;
  };
}

interface SelectedFields {
  [groupKey: string]: Array<string>;
}

@Component({
  name: 'CopyDetailsDesign',
})
export default class CopyDetailsDesign extends Vue {
  @Prop() manager!: TasksScreenManager;
  @Prop() fieldsData!: FieldsData;

  protected fields = this.fieldsData;
  protected isLoading = false;
  protected actionSuccess = false;
  protected showActionResult = false;

  get selectedMasterItem() {
    return this.manager.selectedMasterItem;
  }

  protected async onSubmit() {
    const source = this.manager.selectedMasterItem.address;
    const targetAddresses = this.manager.list.selectedItems.map((item) => item.address);
    const selectedFields = Object.entries(this.fields).reduce((acc, [groupName, groupProps]) => ({
      ...acc,
      [groupName]: groupProps.fields.reduce((selected, field) => {
        if (field.selected) {
          selected.push(field.tag);
        }
        return selected;
      }, [] as Array<string>),
    }), {} as SelectedFields);

    this.isLoading = true;

    const response = await request.tasks.submitCopyDesign(source, targetAddresses, selectedFields);
    if (response.status === STATUS_OK) {
      await Promise.all(targetAddresses.map((address) => {
        const masterItem = this.manager.getMasterItemByAddress(address)!;
        return this.manager.updateTaskDataForItem(masterItem);
      }));
    }

    this.actionSuccess = response.status === STATUS_OK;
    this.showActionResult = true;
    this.isLoading = false;
  }

  protected setSelectedFieldsForGroup(groupKey: string) {
    const selectValue = !this.fields[groupKey].selected;

    this.fields[groupKey].selected = selectValue;
    this.fields[groupKey].fields.forEach((field) => {
      // eslint-disable-next-line no-param-reassign
      field.selected = selectValue;
    });
  }

  protected setInitialFieldSelection() {
    Object.values(this.fields).forEach((groupProps) => {
      groupProps.fields.forEach((field) => {
        // eslint-disable-next-line no-param-reassign
        field.selected = true;
      });
      // eslint-disable-next-line no-param-reassign
      groupProps.selected = true;
    });
  }

  mounted() {
    this.setInitialFieldSelection();
  }
}

</script>
