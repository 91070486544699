<template>
  <div class="is-flex is-align-items-center" style="width: 100%;">
    <sort-arrows
      margin="mr-2"
      :list="list"
      :sorters="{
        asc: 'sortAddressAscending',
        desc: 'sortAddressDescending',
      }"
    />
    <span style="display: none;">Addresses</span>
    <search-bar
      :value="list.filter.state.searchValue"
      dataId="addresses-filter"
      placeholder="Search address"
      @change="onAddressSearchChange"
    />
    <span
      class="px-1"
      :class="hasCopyAddressPermission && 'is-clickable'"
      @click="copyAddressToClipboard"
    >
      {{ list.filteredItems.length }}
    </span>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { hasPermission, actionPermissions } from '@/logic/common/Permissions';
import { notifications } from '@/util';

import type { TasksScreenManager } from '@/logic/TasksScreen';

@Component({
  name: 'TableHeadAddresses',
})
export default class TableHeadAddresses extends Vue {
  @Prop() manager!: TasksScreenManager;

  get list() {
    return this.manager.list;
  }

  get hasCopyAddressPermission() {
    return hasPermission(actionPermissions.copyAddresses);
  }

  protected onAddressSearchChange(event: KeyboardEvent) {
    this.list.filter!.handler('search')(event);
    this.manager.updateURLQueryParams();
  }

  protected copyAddressToClipboard() {
    if (!this.hasCopyAddressPermission) return;

    const { selectedWoco, selectedProject, selectedGroup } = this.manager;

    const addresses = this.list.filteredItems.map((item) => item.address);
    let plainText = `${selectedWoco} | ${selectedProject} | ${selectedGroup}\n\n`;
    addresses.forEach((address) => {
      plainText += `${address}\n`;
    });

    const copyAddressHandler = (event: ClipboardEvent) => {
      event.clipboardData!.setData('text/plain', plainText);
      event.preventDefault();

      notifications.addNotification({
        type: 'success',
        message: `${addresses.length} addresses copied to clipboard`,
      });

      document.removeEventListener('copy', copyAddressHandler);
    };

    document.addEventListener('copy', copyAddressHandler);
    document.execCommand('copy');
  }
}

</script>

<style lang="scss" scoped>
::v-deep .search-bar {
  margin: 0 4px !important;

  .input {
    font-size: 10px;
    padding-left: 24px !important;
  }

  .icon {
    font-size: 10px !important;
  }
}
</style>
