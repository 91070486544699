<script lang="ts">
import { Component } from 'vue-property-decorator';
import BaseButton from './BaseButton.vue';

@Component({})
export default class AssignInstallerButton extends BaseButton {
  protected color = 'is-assign';

  get disabled() {
    return (!this.masterItem.actionData.assignedInstaller);
  }

  protected onClick() {
    if (!this.masterItem) return;

    this.masterItem.setActionData({
      ...this.masterItem.actionData,
    });

    if (this.masterItem.actionData.assignedInstaller) {
      this.manager.submitMasterItemAction(this.masterItem, this.action);
    }
  }
}
</script>
