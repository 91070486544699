<template>
  <div v-if="isLoading" class="tw-flex tw-gap-5 tw-items-center">
    <div
      class="tw-border-gray-300 tw-h-10 tw-w-10 tw-animate-spin tw-rounded-full tw-border-8 tw-border-t-green-600"
    />
    Loading...
  </div>
  <div v-else-if="error || !task || task.status === 'error'">
    Something went wrong or task details do not exist
  </div>
  <div v-else>
    <div v-if="address">
      <div class="tw-font-bold tw-tracking-wider tw-text-lg tw-text-gray-500 tw-my-5">
        {{ address }}
      </div>

      <div v-if="!isLoading && route.params.type !== 'address'">
        <div
          class="tw-bg-white tw-drop-shadow-xl tw-rounded-b-lg tw-p-3 tw-flex tw-justify-between tw-items-center"
        >
          <h1 class="tw-uppercase tw-font-bold tw-tracking-wider tw-text-gray-700">
            {{ type }}
          </h1>
          <div
            class="tw-rounded-lg tw-inline tw-p-1 tw-text-white tw-cursor-pointer"
            :class="getStatusClass(task.components?.status?.status?.toLowerCase())"
          >
            {{ task.components.status?.status }}
          </div>
        </div>

        <task-comments
          v-if="typeof task.components.comment_id === 'string'"
          :starredComment="task.components.comment_id"
          :woco="task.components.woco"
        />
        <task-comments v-else />

        <task-detail-installation
          v-if="type === TASK_TYPE_INSTALLATION"
          :components="task.components"
        />
      </div>

      <div v-if="!isLoading && route.params.type === 'address'">
        <h1 class="tw-font-bold tw-tracking-wider tw-uppercase tw-text-gray-500 tw-mt-3">
          Contact Details
        </h1>
        <div
          v-for="(value, key) in task.components.tickets[0].ticket.client_info"
          :key="key"
          class="tw-flex tw-justify-between tw-space-x-4 tw-text-gray-700"
        >
          <span class="tw-capitalize">{{ key }}:</span>
          <span v-if="typeof value === 'object'" class="tw-font-bold">{{
            Object.values(value).join(', ')
          }}</span>
          <span v-else class="tw-font-bold">{{ value }}</span>
        </div>
      </div>
    </div>

    <div v-else-if="error" class="m-3">
      <div class="tw-bg-red-200 tw-rounded tw-border tw-border-red-600 p-2">
        Something went wrong: {{ error.detail }}
      </div>
    </div>
  </div>
</template>

<script setup>
import fetchData from '@/util/fetch';
import { useQuery } from '@tanstack/vue-query';
import { computed } from 'vue';
import { useRoute } from 'vue-router/composables';
import { getStatusClass } from './utils.js';
import TaskComments from './TaskComments.vue';
import TaskDetailInstallation from './TaskDetailInstallation.vue';
import { TASK_TYPE_INSTALLATION } from '@/constants';

const route = useRoute();

const type = computed(() =>
  route.params?.type === 'address' ? 'installation' : route.params?.type
);
const address = computed(() => route.params?.address);

const {
  data: task,
  isLoading,
  error,
} = useQuery({
  queryKey: computed(() => ['task', address.value, type.value]),
  queryFn: async () => {
    try {
      const result = await fetchData(
        `/api/v1/tasks/task_type/${type.value}/address/${address.value}/detail`
      );
      return result.data;
    } catch (error) {
      throw new Error('Failed to fetch task data');
    }
  },
  refetchOnWindowFocus: false,
});
</script>
