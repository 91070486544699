import { render, staticRenderFns } from "./DataBox.vue?vue&type=template&id=58bdb460&scoped=true"
import script from "./DataBox.vue?vue&type=script&lang=ts"
export * from "./DataBox.vue?vue&type=script&lang=ts"
import style0 from "./DataBox.vue?vue&type=style&index=0&id=58bdb460&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58bdb460",
  null
  
)

export default component.exports