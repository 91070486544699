<template>
  <div
    class="tw-rounded-md tw-inline tw-p-1 tw-text-white tw-text-sm"
    :class="colors[status]"
  >
    {{ status }}
  </div>
</template>

<script setup>
defineProps({
  status: {
    type: String,
    required: true,
  },
});

const colors = {
  finished: 'tw-bg-green-500',
  cancelled: 'tw-bg-red-500',
  open: 'tw-bg-yellow-500',
  muted: 'tw-bg-gray-500',
  created: 'tw-bg-green-500',
};
</script>
