<template>
  <div v-if="images.length > 0" class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ name }}</p>
        <button class="delete" aria-label="close" @click="onCloseModal"></button>
      </header>
      <section
        class="modal-card-body is-flex is-align-items-center is-justify-content-center"
      >
        <img
          v-if="isImage"
          class="image-modal"
          :src="imageSource"
          :draggable="false"
          @mousedown="onMouseDown"
          @mouseup="() => mouseDown = false"
          @mousemove="onMouseMove"
          :style="`
            transform: scale(${size}) translate(${xPos}px, ${yPos}px);
            cursor: ${mouseDown ? 'grabbing' : 'grab'};`"
        >
        <div v-else class="is-relative pb-2 has-text-center">
          <h3 class="is-size-4 mb-2">File cannot be embedded.</h3>
          <button class="button download-button is-small">
            <a class="has-text-black" :href="imageSource" target="_blank">
              Download {{ fileExtension.toUpperCase() }}
            </a>
          </button>
        </div>
      </section>
      <footer class="modal-card-foot p-5">

        <button
          v-if="showDelete"
          class="button is-danger"
          :class="{ 'is-loading': isLoading }"
          :disabled="isLoading"
          data-id="detail-image-delete"
          @click="onDelete"
        >
          Delete
        </button>

        <div class="controls is-flex is-align-items-center">
          <i
            v-if="images.length > 1"
            class="fas fa-chevron-left"
            @click="() => goToNextImage(-1)"
          ></i>

          <div class="size-selector box">
            <span class="icon">
              <i class="fas fa-minus"></i>
            </span>
            <input type="range" v-model="imageSize">
            <span class="icon">
              <i class="fas fa-plus"></i>
            </span>
          </div>

          <i
            v-if="images.length > 1"
            class="fas fa-chevron-right"
            @click="() => goToNextImage(1)"
          ></i>

        </div>

      </footer>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { hasImageFileExtension, getFileExtensionFromURL } from '@/util';

import type { TaskTypeKey } from '@/interfaces';

@Component({
  name: 'DetailsImageModal',
})
export default class DetailsImageModal extends Vue {
  @Prop({}) type!: TaskTypeKey;
  @Prop({}) close!: Function;
  @Prop({}) delete!: Function;
  @Prop({ default: [] }) images!: Array<any>;
  @Prop({ default: 0 }) initialIndex!: number;

  protected isLoading = false;

  protected currentImageIndex = this.initialIndex;

  protected imageSize = 1;
  protected mouseDown = false;
  protected xPos = 0;
  protected yPos = 0;

  protected lastXPos = 0;
  protected lastYPos = 0;

  get name() {
    const { name } = this.images[this.currentImageIndex][1];
    return name;
  }

  get action() {
    return 'delete';
  }

  get enabled() {
    return true;
  }

  get size() {
    const calculatedSize = this.imageSize / 70 + 1;
    if (calculatedSize < 1) return 1;
    return calculatedSize;
  }

  get imageSource() {
    const source = this.images[this.currentImageIndex][1].value;
    return (typeof source === 'string' && source.includes('dl=0'))
      ? source.replace('dl=0', 'dl=1')
      : source;
  }

  get isImage() {
    if (typeof this.imageSource === 'string' && this.imageSource.match(/data:\w+\/\w+;/)) {
      return this.imageSource.includes('image');
    }
    return hasImageFileExtension(this.imageSource);
  }

  get showDelete() {
    return !!this.delete;
  }

  get fileExtension() {
    if (typeof this.imageSource === 'string' && this.imageSource.match(/data:\w+\/\w+;/)) {
      const match = this.imageSource.match(/data:\w+\/(?<ext>\w+);/);
      if (!match) return '';
      return match.groups!.ext;
    }
    return getFileExtensionFromURL(this.imageSource);
  }

  protected onMouseDown(e: MouseEvent) {
    e.preventDefault();
    if (!this.isImage) return;

    this.mouseDown = true;
    this.lastXPos = e.pageX;
    this.lastYPos = e.pageY;
  }

  protected onMouseMove(e: MouseEvent) {
    e.preventDefault();
    if (this.mouseDown) {
      this.xPos -= (this.lastXPos - e.pageX) / this.size;
      this.yPos -= (this.lastYPos - e.pageY) / this.size;

      this.lastXPos = e.pageX;
      this.lastYPos = e.pageY;
    }
  }

  protected async onDelete() {
    if (!this.delete) return;

    const lastIndex = this.currentImageIndex;
    this.isLoading = true;
    await this.delete(this.images[this.currentImageIndex]);
    this.currentImageIndex = lastIndex - 1 < 0 ? 0 : lastIndex - 1;
    this.isLoading = false;
  }

  protected onCloseModal() {
    this.close();
  }

  protected resetImage() {
    this.imageSize = 1;
    this.mouseDown = false;
    this.xPos = 0;
    this.yPos = 0;

    this.lastXPos = 0;
    this.lastYPos = 0;
  }

  protected goToNextImage(direction: number) {
    if (this.images.length < 2 || this.isLoading) return;

    const nextIndex = this.currentImageIndex + direction;
    if (nextIndex >= 0 && nextIndex < this.images.length) {
      this.currentImageIndex = nextIndex;
    } else {
      this.currentImageIndex = nextIndex < 0 ? this.images.length - 1 : 0;
    }
    this.resetImage();
  }

  protected onKeyDown(event: KeyboardEvent) {
    if (this.isLoading) return;

    switch (event.code) {
      case 'ArrowLeft':
        this.goToNextImage(-1);
        break;
      case 'ArrowRight':
        this.goToNextImage(1);
        break;
      case 'Escape':
        this.close();
        break;
      default:
        break;
    }
  }

  beforeDestroy() {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  created() {
    this.currentImageIndex = this.initialIndex;
    document.addEventListener('keydown', this.onKeyDown);
  }
}

</script>

<style lang="scss" scoped>
.modal {
  .modal-card {
    width: 90vw;
    height: 90vh;
  }

  .modal-card-foot {

    .controls {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    .fa-chevron-right,
    .fa-chevron-left {
      cursor: pointer;
      font-size: 1.5rem;
    }

    .size-selector {
      display: flex;
      padding: 5px;
      margin: 0 1rem;

      input {
        cursor: pointer;
      }
    }
  }

  .image-modal {
    height: auto;
    max-height: 100%;
    width: auto;
    overflow: hidden;
    display: block;
    margin: 0 auto;
  }

  .download-button {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
