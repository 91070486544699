<template>
  <div class="is-flex">
    <div class="installations-table">
      <!-- PROJECTS COLUMN -->
      <div class="table-column projects-column">

        <!-- HEADER ROW -->
        <div class="table-head">
          <div class="table-cell is-flex is-align-items-flex-end">
            <p>Project</p>
          </div>
        </div>

        <!-- PROJECT ROWS -->
        <div
          v-for="(project, rowIndex) in projects"
          :key="`project-row-${project}`"
          class="table-row"
          :class="[(rowIndex === hovered.row) && 'hover']"
          @mouseenter="() => onMouseEnterRow(null, rowIndex)"
          @mouseleave="onMouseLeaveRow"
        >
          <div class="table-cell">
            <p>{{ project }}</p>
          </div>
        </div>

        <!-- FOOTER ROW -->
        <div class="table-foot">
          <div class="table-cell">
            <p><b>Total</b></p>
          </div>
        </div>

      </div>

      <!-- INSTALLATIONS COLUMNS -->
      <div
        v-for="([date, data], columnIndex) in Object.entries(statistics.installations[timeUnit])"
        :key="`column-${date}`"
        class="table-column installations-column"
      >

        <!-- HEADER ROW -->
        <div class="table-head" :class="isCurrent(date) && 'current'">
          <div class="table-cell">
            <p class="has-text-centered">{{ formatDate(date) }}</p>
            <div class="is-flex has-text-right">
              <p class="sub-column tooltip" style="flex: 1;" title="Forecast">
                For.
              </p>
              <p class="sub-column tooltip" style="flex: 1;" title="Planned">
                Plan.
              </p>
              <p class="sub-column tooltip" style="flex: 1;" title="Approved">
                Appr.
              </p>
            </div>
          </div>
        </div>

        <!-- PROJECT ROWS -->
        <div
          v-for="(project, rowIndex) in projects"
          :key="`column-${date}-${project}`"
          class="table-row"
          :class="[(rowIndex === hovered.row || columnIndex === hovered.col) && 'hover']"
          @mouseenter="() => onMouseEnterRow(columnIndex, rowIndex)"
          @mouseleave="onMouseLeaveRow"
        >
          <div
            class="table-cell is-flex has-text-right"
            :class="[(rowIndex === hovered.row && columnIndex === hovered.col) && 'target']"
          >
            <!-- FORECAST -->
            <p
              class="sub-column" style="flex: 1;"
              :class="[data.projects[project].forecast && 'highlight']"
            >
              {{ data.projects[project].forecast }}
            </p>
            <!-- PLANNED -->
            <p
              class="sub-column" style="flex: 1;"
              :class="[data.projects[project].planned && 'highlight']"
            >
              {{ data.projects[project].planned }}
            </p>
            <!-- APPROVED -->
            <p
              class="sub-column" style="flex: 1;"
              :class="[data.projects[project].approved && 'highlight']"
            >
              {{ data.projects[project].approved }}
            </p>
          </div>
        </div>

        <!-- TOTALS ROW -->
        <div class="table-foot">
          <div class="table-cell is-flex has-text-right">
            <p class="sub-column" style="flex: 1;">{{ data.totals.forecast }}</p>
            <p class="sub-column" style="flex: 1;">{{ data.totals.planned }}</p>
            <p class="sub-column" style="flex: 1;">{{ data.totals.approved }}</p>
          </div>
        </div>

      </div>

      <!-- TOTALS COLUMN -->
      <div class="table-column totals-column">

        <!-- HEADER ROW -->
        <div class="table-head is-flex has-text-right">
          <div class="table-cell">
            <p class="has-text-centered">Total</p>
            <div class="is-flex has-text-right">
              <p class="sub-column tooltip" style="flex: 1;" title="Forecast">
                For.
              </p>
              <p class="sub-column tooltip" style="flex: 1;" title="Planned">
                Plan.
              </p>
              <p class="sub-column tooltip" style="flex: 1;" title="Approved">
                Appr.
              </p>
            </div>
          </div>
        </div>

        <!-- PROJECT ROWS -->
        <div
          v-for="(project, rowIndex) in projects"
          :key="`totals-column-row-${project}`"
          class="table-row"
          :class="[(rowIndex === hovered.row) && 'hover']"
          @mouseenter="() => onMouseEnterRow(null, rowIndex)"
        >
          <div class="table-cell is-flex has-text-right">
            <p
              class="sub-column" style="flex: 1;"
              :class="[statistics.installations.total.projects[project].forecast && 'highlight']"
            >
              {{ statistics.installations.total.projects[project].forecast }}
            </p>
            <p
              class="sub-column" style="flex: 1;"
              :class="[statistics.installations.total.projects[project].planned && 'highlight']"
            >
              {{ statistics.installations.total.projects[project].planned }}
            </p>
            <p
              class="sub-column" style="flex: 1;"
              :class="[statistics.installations.total.projects[project].approved && 'highlight']"
            >
              {{ statistics.installations.total.projects[project].approved }}
            </p>
          </div>
        </div>

        <!-- TOTALS ROW -->
        <div class="table-foot">
          <div class="table-cell is-flex has-text-right">
            <p class="sub-column" style="flex: 1;">
              {{ statistics.installations.total.forecast }}
            </p>
            <p class="sub-column" style="flex: 1;">
              {{ statistics.installations.total.planned }}
            </p>
            <p class="sub-column" style="flex: 1;">
              {{ statistics.installations.total.approved }}
            </p>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

import {
  getISOWeek,
  isSameISOWeek,
  isSameMonth,
  getISOWeekYear,
  format,
} from 'date-fns';

import type { DashboardScreenManager } from '@/logic/DashboardScreen';

@Component({
  name: 'DashboardInstallations',
})
export default class DashboardInstallations extends Vue {
  @Prop({}) manager!: DashboardScreenManager;
  @Prop({ default: [] }) projects!: Array<string>;
  @Prop({ default: 'weekly' }) timeUnit!: string;

  protected hovered: { col: number | null; row: number | null } = {
    col: null,
    row: null,
  }

  get statistics() {
    return this.manager.statistics;
  }

  protected isCurrent(date: string) {
    return this.timeUnit === 'weekly'
      ? isSameISOWeek(new Date(date), Date.now())
      : isSameMonth(new Date(date), Date.now());
  }

  protected formatDate(date: string) {
    const d = new Date(date);
    return this.timeUnit === 'weekly'
      ? `Week ${getISOWeek(d)}, ${getISOWeekYear(d)}`
      : format(d, "LLL' 'u");
  }

  protected onMouseEnterRow(column: number, row: number) {
    this.hovered = {
      col: column,
      row,
    };
  }

  protected onMouseLeaveRow() {
    this.hovered = {
      col: null,
      row: null,
    };
  }
}

</script>

<style lang="scss">
$current-date-bg-color: #486f14;

$table-max-height: calc(100vh - 8rem);

$table-font-size: 0.6rem;
$table-line-height: 1rem;

$table-head-height: 2.5rem;
$table-foot-height: 1.5rem;

$table-head-bg-color: #444;
$table-head-color: #fff;
$table-row-bg-odd: #f5f5f5;
$table-row-bg-even: #fff;
$table-row-hover-bg-color: #dce5e8;
$table-cell-hover-bg-color: #ddd;
$table-border-color: #ccc;

$table-installation-column-width: 8rem;

$table-cell-padding: 0.25rem;

.installations-table {
  display: flex;
  position: relative;
  font-size: $table-font-size;
  max-width: 100%;
  max-height: $table-max-height;
  overflow: auto;

  .table-column {

    .table-cell {
      border-left: 2px solid $table-border-color;

      .highlight {
        font-weight: 700;
        text-decoration: underline;
      }
    }

    &:last-child {
      .table-cell {
        border-right: 2px solid $table-border-color;
      }
    }
  }

  .table-row {
    &:nth-child(2n+1) {
      background-color: $table-row-bg-odd;
    }

    &:nth-child(2n+2) {
      background-color: $table-row-bg-even;
    }

    &:last-child {
      .table-cell {
        border-bottom: 1px solid $table-border-color;
      }
    }

    &.hover {
      .table-cell {
        background-color: $table-row-hover-bg-color;

        &.target {
          background-color: $table-cell-hover-bg-color;
        }
      }
    }

    .table-cell {
      border-top: 1px solid $table-border-color;
    }
  }

  .table-cell {
    padding: $table-cell-padding;
    width: 100%;

    p, span {
      height: $table-line-height;
    }

    .sub-column {
      padding: 0 0.25rem;
      margin: 0;
    }
  }

  .projects-column {
    position: sticky;
    z-index: 1;
    flex-shrink: 0;
    left: 0;
    font-weight: 700;

    .table-head {
      position: sticky;
      top: 0;
      width: 100%;

      .table-cell {
        height: 100%;
      }
    }

    .table-cell {
      border-right: 1px solid $table-border-color;
    }
  }

  .installations-column,
  .totals-column {
    flex: 1;
    min-width: $table-installation-column-width;
    max-width: $table-installation-column-width;

    .table-row {
      .sub-column {
        &:not(:last-child) {
          border-right: 1px dashed $table-border-color;
        }
      }
    }
  }

  .totals-column {
    position: sticky;
    z-index: 1;
    right: 0;
  }

  .table-head {
    display: flex;
    align-items: flex-end;
    position: sticky;
    top: 0;
    height: $table-head-height;
    color: $table-head-color;
    background-color: $table-head-bg-color;
    font-weight: 700;

    &.current {
      background-color: $current-date-bg-color;
    }
  }

  .table-foot {
    position: sticky;
    bottom: 0;
    color: $table-head-color;
    background-color: $table-head-bg-color;
    font-weight: 700;
  }

  .tooltip {
    cursor: help;
  }
}
</style>
