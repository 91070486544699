<template>
  <div v-if="input" class="box ticket-input">
    <!-- HEADER / UPDATE TICKET BUTTON -->
    <div class="is-flex is-justify-content-space-between is-align-items-center mb-2">
      <details-box-heading label="Input" />
      <button
        v-if="canEdit"
        class="button thin-button is-small is-info"
        @click="() => $emit('edit', null)
      ">
        Edit
      </button>
      <button
        v-else
        class="button thin-button is-small is-info"
        @click="() => $emit('edit-finance')
      ">
        Edit finance
      </button>
    </div>

    <!-- CLIENT DETAILS -->
    <details-box-heading label="Client details" class="mb-1" />
    <div class="box-content mb-2">
      <template
        v-for="[field, fieldValue] in Object.entries(selectedTicket.ticket.client_info)"
      >
        <p
          v-if="typeof fieldValue === 'string'"
          :key="`client-${field}`"
          :class="{ 'is-updated': hasChanged(`client_info.${field}`) }"
        >
          <b>{{ capitalized(field) }}: </b><span>{{ fieldValue }}</span>
        </p>
      </template>
    </div>

    <!-- EXTRA FIELDS -->
    <details-box-heading label="Extra fields" class="mb-1" />
    <div class="box-content mb-2">
      <template
        v-for="[field, fieldValue] in Object.entries(input.extra_fields)"
      >
        <p
          v-if="typeof fieldValue === 'string'"
          :key="`client-${field}`"
          :class="{ 'is-updated': hasChanged(`extra_fields.${field}`) }"
        >
          <b>{{ capitalized(field) }}: </b><span>{{ fieldValue }}</span>
        </p>
      </template>
    </div>

    <!-- EXTRA FIELDS -->
    <details-box-heading label="Finance" class="mb-1" />
    <div class="box-content mb-2">
      <template
        v-for="[field, fieldValue] in Object.entries(input.finance || {})"
      >
        <p
          v-if="typeof fieldValue === 'string'"
          :key="`client-${field}`"
          :class="{ 'is-updated': hasChanged(`finance.${field}`) }"
        >
          <b>{{ capitalized(field) }}: </b><span>{{ fieldValue }}</span>
        </p>
      </template>
    </div>

     <!-- PRODUCTS -->
    <details-box-heading label="Products" class="mb-1" />
    <div class="box-content mb-2">
      <template v-for="[productType, products] in Object.entries(ticketProductsInput)">
        <div
          v-for="product in products"
          :key="`${productType}-${product.code}`"
          class="product-entry mb-2"
          :class="{ 'is-updated': hasChanged(`products.${productType}`) }"
        >
          <!-- PRODUCT NAME / COUNT -->
          <div class="is-flex is-justify-content-space-between">
            <p>
              <b style="text-transform: capitalize"> {{ productType }}: </b>
              <span>{{ product.description }}</span>
            </p>
            <span class="tag" style="font-size: 0.6rem;">{{ product.amount }}</span>
          </div>

        </div>
      </template>
    </div>

    <!-- IMAGES -->
    <details-ticket-images
      :manager="manager"
      :images="input.images"
    />

  </div>
</template>

<script lang="ts">
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Prop } from 'vue-property-decorator';
import DetailsPaneBase from './DetailsPaneBase.vue';
import DetailsTicketImages from './DetailsTicketImages.vue';

interface Dictionary {
  [prop: string]: Dictionary;
}

@Component({
  name: 'DetailsMaintenanceInputBox',
  components: {
    DetailsTicketImages,
  },
})
export default class DetailsMaintenanceInputBox extends DetailsPaneBase {
  @Prop({ default: {} }) input!: Dictionary;
  @Prop({}) selectedTicket!: any;

  get canEdit() {
    return !!this.selectedTicket.can_edit;
  }

  get editableFields() {
    return this.selectedTicket.editable_fields || {};
  }

  get ticketProductsInput() {
    return this.input.products || [];
  }

  get ticketServicesInput() {
    return this.input.services || {};
  }

  get ticketCommentsInput() {
    return this.input.comments || [];
  }

  protected capitalized(str: string) {
    return (str[0].toUpperCase() + str.slice(1)).replace(/[_-]/g, ' ');
  }

  protected hasChanged(field: string) {
    const { validation } = this.selectedTicket.actions;
    if (!(validation && validation.input)) return false;

    return !!field.split('.').reduce((v: Dictionary | boolean, k) => (
      !v ? false : (v as Dictionary)[k]
    ), validation.input);
  }
}

</script>

<style lang="scss">
.is-updated {
  background-color: rgb(255, 215, 141);
}
</style>
