<template>
  <div id="app" @click="handleClick" @mouseover="handleMouseOver" @change="handleChange">
    <app-header />
    <div class="notifications" style="position: absolute; top: 75px; right: 10px;">
      <notification
        v-for="notification in notifications"
        :key="`notification-message-${notification.message}`"
        :notification="notification"
      />
    </div>
    <unauthorized v-if="
      status === statuses.STATUS_UNAUTHORIZED
      && $route.meta.permissions
      && $route.meta.permissions.length"
    />
    <forbidden v-else-if="status === statuses.STATUS_FORBIDDEN" />
    <not-found v-else-if="status === statuses.STATUS_NOT_FOUND" />
    <router-view v-else />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { AppHeader } from '@/components/AppHeader';
import { NotFound, Forbidden, Unauthorized } from '@/components/common';
import { actions } from '@/store';
import {
  STATUS_UNAUTHORIZED,
  STATUS_FORBIDDEN,
  STATUS_NOT_FOUND,
  EVENT_RESIZE_START,
  EVENT_RESIZE_END,
} from '@/constants';
import { events } from '@/util';
import request from '@/requests';
import type { Notification } from './interfaces';

@Component({
  name: 'App',
  components: {
    AppHeader,
    NotFound,
    Forbidden,
    Unauthorized,
  },
  computed: { ...mapState('common', ['status']) },
})
export default class App extends Vue {
  private resizeEventTimeoutId: number | null = 0;

  get notifications(): Array<Notification> {
    return actions.common.readNotifications();
  }

  get statuses() {
    return {
      STATUS_FORBIDDEN,
      STATUS_NOT_FOUND,
      STATUS_UNAUTHORIZED,
    };
  }

  protected textFormatter = (text: string) => (
    (text.split('-').map((w) => w.charAt(0).toUpperCase() + w.slice(1)).join(' '))
  );

  public handleChange(e: MouseEvent) {
    const element = e.target as HTMLElement;

    const elements = ['select', 'input'];

    if (!elements.includes(element.localName)) return;

    this.$nextTick(() => {
      const context = this.$route.path.replaceAll('%20', ' ').trim().split('/')[1];

      if (!element.dataset.id || !element.localName || !context) return;

      request.interactions.createInteraction({
        type: element.localName,
        value: element.dataset.id,
        context,
      });
    });
  }

  public handleClick(e: MouseEvent) {
    let element = e.target as HTMLElement;

    const elements = ['button', 'span', 'td', 'a'];

    if (!elements.includes(element.localName)) return;

    // get table row for table cell
    if (element.localName === 'td') {
      element = element.parentElement as HTMLElement;
    }

    this.$nextTick(() => {
      const context = this.$route.path.replaceAll('%20', ' ').trim().split('/')[1];

      if (!element.dataset.id || !element.localName || !context) return;

      request.interactions.createInteraction({
        type: element.localName,
        value: element.dataset.id,
        context,
      });
    });
  }

  public handleMouseOver(e: MouseEvent) {
    const element = e.target as HTMLElement;

    const elements = ['i', 'div'];

    if (!elements.includes(element.localName)) return;

    this.$nextTick(() => {
      const context = this.$route.path.replaceAll('%20', ' ').trim().split('/')[1];

      if (!element.dataset.id || !element.localName || !context) return;

      request.interactions.createInteraction({
        type: element.localName,
        value: element.dataset.id,
        context,
      });
    });
  }

  private onWindowResize = () => {
    if (!this.resizeEventTimeoutId) {
      events.emit(EVENT_RESIZE_START);
    } else {
      clearTimeout(this.resizeEventTimeoutId);
    }

    this.resizeEventTimeoutId = setTimeout(() => {
      events.emit(EVENT_RESIZE_END);
      this.resizeEventTimeoutId = null;
    }, 200);
  }

  mounted() {
    window.addEventListener('resize', this.onWindowResize);
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.onWindowResize);
  }
}
</script>

<style lang="css">
@tailwind base;
@tailwind components;
@tailwind utilities;
</style>
