<template>
  <div :class="`notification is-${notification.type}`">
    <button @click="onDelete" class="delete"></button>
    {{ notification.message }}
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Notification } from '@/interfaces/';
import { actions } from '@/store';

@Component({})
export default class NotificationComponent extends Vue {
  @Prop() notification!: Notification;

  onDelete() {
    const notifications = actions.common.readNotifications().filter((n) => n !== this.notification);
    actions.common.commitNotifications(notifications);
  }
}
</script>

<style lang="scss" scoped>
.notification {
  width: 500px;
  z-index: 100;
  padding: 0.75rem;
}
</style>
