<template>
  <div class="field search-bar is-flex" :class="spacing">
    <p class="control control-search-bar is-small has-icons-left">
      <input
        :id="id"
        :class="['input', size]"
        :type="type"
        :placeholder="placeholder"
        :value="value"
        :data-id="dataId"
        @input="onChange"
      />
      <span class="icon is-small is-left">
        <i class="fas fa-search"></i>
      </span>
    </p>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({ name: 'SearchBar' })
export default class SearchBar extends Vue {
  @Prop({ default: 'Search' }) placeholder!: string;
  @Prop({ default: 'text' }) type!: string;
  @Prop({ default: '' }) value!: string;
  @Prop({ default: '' }) spacing!: string;
  @Prop({ default: 'is-small' }) size!: string;
  @Prop({ default: '' }) id!: string;
  @Prop({}) dataId!: string;

  onChange(event: InputEvent) {
    this.$emit('change', event);
    this.$forceUpdate();
  }
}
</script>

<style lang="scss">
  .search-bar {
    flex: 1;
  }
  .control-search-bar {
    width: 100%;
  }
</style>
