<template>
  <div class="app-logout is-flex" style="margin-left: auto;">
    <div
      class="dropdown is-right"
      :class="!isCollapsed && 'is-active'"
      v-outer-click="() => isCollapsed = true"
    >
      <div class="dropdown-trigger">
        <label
          class="label my-0 mx-2 has-text-white is-unselectable"
          @click="isCollapsed = !isCollapsed"
          style="cursor: pointer;"
        >
          {{ user.split('@')[0] }}
        </label>
      </div>
      <div class="dropdown-menu" style="width: 210px;">
        <div class="dropdown-content p-3">
          <div>
            <div class="dropdown-item">
              <p><b>Gebruikers informatie</b></p>
            </div>
            <hr class="dropdown-divider">
            <div class="dropdown-item">
              <p><b>Rechten</b></p>
              <ul>
                <li
                  v-for="permission in actions"
                  :key="permission"
                >
                  - {{ formatText(permission) }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button class="button is-light is-small" @click="auth.logout">Logout</button>
  </div>
</template>

<script lang="js">
import store from '@/store'
import { ref } from 'vue'

export default {
  props: ['auth'],
  setup() {
    const isCollapsed = ref(true)

    function formatText(text) {
      const newText = text.toLowerCase().replaceAll('_', ' ');
      return newText.charAt(0).toUpperCase() + newText.slice(1);
    }

    const {permissions, user} = store.state.auth
    const actions = permissions.actions.filter((a, index, all) => all.findIndex(e => e === a) === index)

    return {
      user,
      actions,
      isCollapsed,
      formatText,
    }
  },
}
</script>
