<template>
  <div class="container" :class="className">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({ name: 'Container' })
export default class Container extends Vue {
  @Prop({ default: '' }) className!: string;
}
</script>
