<template>
  <div class="app-login is-flex is-align-items-centered" style="margin-left: auto">
    <!-- OTP LOGIN -->
    <div class="otp-login">
      <div v-if="!state.showOTPInput" class="otp-user is-flex is-align-items-center">
        <input
          class="input is-small my-1"
          type="text"
          v-model="state.username"
          placeholder="gebruiker"
        />
        <button
          class="button is-small mx-0"
          :class="state.isLoading && 'is-loading'"
          @click="requestOTP"
        >
          Verstuur
        </button>
      </div>
      <div v-else class="otp-code is-flex is-align-items-center">
        <i
          class="fas fa-user has-text-white mx-1 is-clickable"
          title="Verander gebruiker"
          @click="changeUser"
        ></i>
        <i
          class="fas fa-redo-alt has-text-white mx-1 mr-2 is-clickable"
          title="Vraag nieuwe OTP code aan"
          @click="requestOTP"
        ></i>
        <input class="input is-small my-1" type="text" v-model="state.otp" placeholder="OTP code" />
        <button
          class="button is-small mx-0"
          :class="state.isLoading && 'is-loading'"
          @click="sendOTP"
        >
          Login
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { reactive } from 'vue';
import { actions } from '@/store'

export default {
  props: ['auth'],
  setup(props) {
    const state = reactive({
      showOTPInput: false,
      username: '',
      otp: '',
      loading: false,
    })

    async function requestOTP() {
      state.loading = true
      const response = await props.auth.requestOTP(state.username.toLowerCase());
      state.loading = false
      if (response.status === 'OK') {
        state.showOTPInput = true;
        actions.common.commitNotifications([{
          message: 'OTP code verstuurd',
          type: 'success',
        }]);
      } else {
        actions.common.commitNotifications([{
          message: 'Er is iets misgegaan, probeer het opnieuw',
          type: 'danger',
        }]);
      }
    }

    async function sendOTP() {
      state.loading = true
      const response = await props.auth.loginWithOTP(state.username.toLowerCase(), state.otp);
      state.loading = false

      if (response.status === 'OK') {
        state.showOTPInput = false;
        actions.common.commitNotifications([]);
      } else {
        actions.common.commitNotifications([{
          message: 'OTP code is verkeerd of verlopen',
          type: 'danger',
        }]);
      }
      state.otp = '';
    }

    async function changeUser() {
      state.showOTPInput = false;
      state.otp = '';
      actions.common.commitNotifications([]);
    }

    return {
      state,
      requestOTP,
      sendOTP,
      changeUser,
    }
  },
}
</script>
