import { actions } from '@/store';
import request from '@/requests';
import notifications from '@/util/Notifications';
import {
  BaseScreenManager,
  MultiSelectList,
  ScrollSyncHandler,
} from '@/logic/common';

import { hasPermission, panePermissions } from '@/logic/common/Permissions';

import {
  STATUS_OK,
  STATUS_WARNING,
  TASK_TYPE_DESIGN,
  TASK_TYPE_SALES,
  TASK_TYPE_INSTALLATION,
  TASK_TYPE_CLIENT,
  TASK_TYPE_FINANCE,
  TASK_TYPE_INSPECTION,
  TASK_TYPE_MAINTENANCE,
  STATUS_ERROR,
} from '@/constants';

import type {
  Address,
  ExistingGroup,
  Action,
  ActionData,
  TaskTypeKey,
  CustomResponse,
  // StoredAction,
} from '@/interfaces';

import type VueRouter from 'vue-router';
import MasterItem from './MasterItem';
import MasterItemList from './MasterItemList';
import TasksPaneManager from './TasksPaneManager';

interface StoredAction {
  item: MasterItem;
  callback: Function;
  action: Action;
  taskType: TaskTypeKey;
}

interface Project {
  name: string;
  type: string;
  year: number;
}

interface TextValue {
  [value: string]: number;
}

type TaskDataField = string | number | { [field: string]: string | number }

interface TaskDataCategoryTotals {
  total: number;
  fields: {
    [field: string]: number;
  };
}

interface TaskDataTotals {
  [attribute: string]: number | TextValue | TaskDataCategoryTotals;
}

export default class TasksScreenManager extends BaseScreenManager {
  public groups: Array<string> = [];
  public wocos: Array<string> = [];
  public projects: Array<Project> = [];
  public selectedGroup: string | null = null;
  public selectedProject: string | null = null;
  public selectedWoco: string | null = null;
  public selectedMasterItem: null | any = null;

  public wocoRequiredOnly = false; // true when used to get all maintenance tasks regardless of project / group selection

  public list: MultiSelectList<MasterItem>;
  public panes: TasksPaneManager;
  public scroll = new ScrollSyncHandler();

  public isLoading = false;
  public isLoadingDetail = false;

  constructor(router: VueRouter) {
    super(router);
    this.list = MasterItemList.createList();
    this.panes = new TasksPaneManager({
      update: {
        [TASK_TYPE_DESIGN]: async () => this.getDataForTaskType(TASK_TYPE_DESIGN),
        [TASK_TYPE_INSTALLATION]: async () => this.getDataForTaskType(TASK_TYPE_INSTALLATION),
        [TASK_TYPE_SALES]: async () => this.getDataForTaskType(TASK_TYPE_SALES),
        [TASK_TYPE_FINANCE]: async () => this.getDataForTaskType(TASK_TYPE_FINANCE),
        [TASK_TYPE_INSPECTION]: async () => this.getDataForTaskType(TASK_TYPE_INSPECTION),
        [TASK_TYPE_MAINTENANCE]: async () => this.getDataForTaskMaintenance(TASK_TYPE_MAINTENANCE),
        [TASK_TYPE_CLIENT]: this.getClients,
      },
      updateQueryParams: this.updateURLQueryParams,
      params: this.router.currentRoute.query || {},
    });
    this.init();
  }

  get hasAddresses() {
    return (
      this.list.items.some((masterItem: MasterItem) => masterItem.address !== undefined)
    );
  }

  protected init = async () => {
    const wocos = await this.getWocos();
    const q = this.router.currentRoute.query;
    const selectedWoco = q.woco as string || actions.common.readSelectedWoco();
    if (typeof selectedWoco === 'string' && wocos.includes(selectedWoco)) {
      this.selectedWoco = selectedWoco;
    }
    this.wocos = wocos;

    if (this.selectedWoco !== 'All maintenance') {
      const projects = await this.getProjects();
      const selectedProject = q.project as string || actions.common.readSelectedProject();
      if (typeof selectedProject === 'string' && projects.find((project: Project) => (
        project.name === selectedProject
      ))) {
        this.selectedProject = selectedProject;
      }
      this.projects = projects;

      const groups = await this.getGroups();
      const selectedGroup = q.group as string || actions.common.readSelectedGroup();
      if (typeof selectedGroup === 'string' && groups.includes(selectedGroup)) {
        this.setSelectedGroup(selectedGroup, true);
      }
      this.groups = groups;
    } else {
      await this.getAllMaintenanceAddresses();
    }

    const search = q.search || '';
    this.list.filter!.handler('search')(search);
  }

  public updateURLQueryParams = (keepAddressDetail = false) => {
    const { address, detail } = this.router.currentRoute.query || {};
    const query = {} as { [prop: string]: string };

    const openTaskTypes = this.panes.getTaskTypesOpenPanes();
    const detailTaskType = this.selectedMasterItem ? this.selectedMasterItem.detailTaskType : null;
    const filterState = this.list.filter!.state;

    if (filterState.searchValue) query.search = filterState.searchValue as string;
    if (this.selectedWoco) query.woco = this.selectedWoco;
    if (this.selectedProject) query.project = this.selectedProject;
    if (this.selectedGroup) query.group = this.selectedGroup;
    if (this.panes.openPanes.length > 0) query.panes = this.panes.openPanes.join(',');
    if (this.selectedMasterItem && !openTaskTypes.includes(detailTaskType)) {
      this.selectedMasterItem = null;
    } else if (this.selectedMasterItem) {
      query.address = this.selectedMasterItem.address;
      query.detail = this.selectedMasterItem.detailTaskType;
    } else if (
      keepAddressDetail
      && address
      && detail
      && openTaskTypes.includes(detail as TaskTypeKey)
    ) {
      query.address = address as string;
      query.detail = detail as string;
    }

    const queryString = new URLSearchParams(query).toString();
    const url = window.location.href.split('?')[0];
    window.location.replace(`${url}?${queryString}`);
  }

  public getActionsForSelectedItems = (taskType: TaskTypeKey) => {
    const items = this.list.selectedItems;
    const taskActions: { [prop: string]: Array<StoredAction> } = items.reduce((map, item) => {
      const task = item[taskType];
      if (task.actions) {
        task.actions.forEach((action: Action) => {
          if (action.permissions && !hasPermission(action.permissions)) return;

          if (map[action.component] === undefined) {
            // eslint-disable-next-line no-param-reassign
            map[action.component] = [];
          }
          const actionList = map[action.component]!;
          actionList.push({
            item,
            callback: () => this.submitMasterItemAction(item, action),
            action,
            taskType,
          });
        });
      }

      return map;
    }, {} as { [prop: string]: StoredAction[] });

    const filteredActions = Object.entries(taskActions)
      .reduce((acc, [actionComponent, actionList]) => {
        if (actionList.length === items.length) {
          return { ...acc, [actionComponent]: actionList };
        }
        return acc;
      }, {} as { [actionComponent: string]: Array<StoredAction> });

    return filteredActions;
  }

  public setActionDataForItems = (actionData: ActionData, list: Array<StoredAction>) => {
    list.forEach((entry) => entry.item.setActionData({
      ...entry.item.actionData,
      ...actionData,
    }));
  }

  public submitActionForItems = async (actionList: Array<StoredAction>) => {
    const { action, taskType } = actionList[0];
    let responses: Array<CustomResponse> = [];

    if (action.action === 'assign_installer' && ['installation', 'inspection'].includes(taskType)) {
      for (let idx = 0; idx < actionList.length; idx += 1) {
        // eslint-disable-next-line no-await-in-loop
        const response = await actionList[idx].callback();
        responses.push(response);
      }
    } else {
      responses = await Promise.all(actionList.map((entry) => entry.callback()));
    }

    const [successCount, failedCount] = responses.reduce((acc, response) => {
      if (response.status === STATUS_OK) {
        acc[0] += 1;
      } else {
        acc[1] += 1;
      }
      return acc;
    }, [0, 0]);

    actions.common.commitNotifications([]);
    if (successCount > 0) {
      notifications.addNotification({
        message: `${successCount} successful ${successCount > 1 ? 'updates' : 'update'}`,
        type: 'success',
      });
    }
    if (failedCount > 0) {
      notifications.addNotification({
        message: `${failedCount} failed ${failedCount > 1 ? 'updates' : 'update'}`,
        type: 'danger',
      });
    }
  }

  public setSelectedGroup = async (newGroup: string, isInitialSelection = false) => {
    actions.common.commitSelectedGroup(newGroup);
    this.selectedGroup = newGroup;
    this.selectedMasterItem = null;

    if (!isInitialSelection) {
      actions.common.commitPanes([]);
      this.panes.syncPaneSelectionWithStore(true);
    }

    if (newGroup) {
      await this.getAddresses();
      this.isLoading = false;
    }

    this.updateURLQueryParams(isInitialSelection);
  }

  public getDataForTaskType = async (taskType: TaskTypeKey) => {
    if ((!this.selectedGroup && !this.wocoRequiredOnly) || !this.list.items.length) return;

    const groups = this.wocoRequiredOnly
      ? this.getGroupsFromMasterItems()
      : [{ group: this.selectedGroup!, addresses: [] }];

    const responses = await Promise.all(groups.map(({ group, addresses }) => (
      request.tasks.getAllTasksForGroup(group, taskType)
    )));

    const taskData = responses.reduce((acc, response) => {
      if (response.status !== STATUS_OK) {
        notifications.addNotificationFromResponse(response);
      }
      return { ...acc, ...response.data };
    }, {});

    this.setTaskData(taskData, taskType);
    // const response = await request.tasks.getAllTasksForGroup(this.selectedGroup, taskType);

    // if ([STATUS_OK, STATUS_WARNING].includes(response.status)) {
    //   this.setTaskData(response.data, taskType);
    // }

    // if ([STATUS_WARNING, STATUS_ERROR].includes(response.status)) {
    //   notifications.addNotificationFromResponse(response);
    // }
  }

  public getDataForTaskMaintenance = async (taskType: TaskTypeKey) => {
    if (this.selectedWoco === 'All maintenance') {
      const response = await request.tasks.getAllTasksForType(taskType);
      const taskData = response.data;
      this.setTaskData(taskData, taskType);
    }
    else {
      this.getDataForTaskType(taskType);
    }
  }

  public getClients = async () => {
    if (!this.selectedGroup || !this.list.items.length) return;

    const response = await request.clients.getClientsForGroup(this.selectedGroup);
    if (response.status === STATUS_OK) {
      this.setTaskData(response.data, TASK_TYPE_CLIENT);
    } else {
      notifications.addNotificationFromResponse(response);
    }
  }

  public generateTaskDataStatistics = (
    taskType: TaskTypeKey,
    fields: Array<string> = [],
    useExclusionForFields = false,
  ) => {
    const data = this.list.filteredItems.map((item) => item.getTaskData(taskType));
    const totals = data.reduce((stats, itemData) => {
      const acc = { ...stats };
      Object.entries(itemData).forEach(([attr, value]) => {
        if (
          fields.length > 0
          && (useExclusionForFields ? fields.includes(attr) : !fields.includes(attr))
        ) return;

        const v = value as TaskDataField;
        const isNumericValue = !Number.isNaN(+v);

        if (acc[attr] === undefined) {
          if (typeof value !== 'object') {
            acc[attr] = isNumericValue ? 0 : {};
          } else {
            acc[attr] = { total: 0, fields: {} };
          }
        }

        if (isNumericValue) {
          (acc[attr] as number) += +v;
        } else if (typeof v === 'string') {
          if ((acc[attr] as TextValue)[v] === undefined) {
            (acc[attr] as TextValue)[v] = 0;
          }
          (acc[attr] as TextValue)[v] += 1;
        } else {
          const fieldGroup = acc[attr] as TaskDataCategoryTotals;
          Object.entries(v as { [field: string]: string }).forEach(([field, val]) => {
            fieldGroup.total += +val;
            if (fieldGroup.fields[field] === undefined) {
              fieldGroup.fields[field] = 0;
            }
            fieldGroup.fields[field] += +val;
          });
        }
      });

      return acc;
    }, {} as TaskDataTotals);

    return totals;
  }

  public setSelectedProject = async (newProject: string) => {
    actions.common.commitSelectedProject(newProject);
    this.selectedProject = newProject;
    this.setSelectedGroup('');

    if (newProject.trim()) {
      this.groups = await this.getGroups();
    } else {
      this.groups = [];
    }
  }

  public setSelectedWoco = async (newWoco: string) => {
    this.wocoRequiredOnly = false;
    actions.common.commitSelectedWoco(newWoco);
    this.selectedWoco = newWoco;
    if (newWoco.toLowerCase() === 'all maintenance') {
      this.wocoRequiredOnly = true;
      this.getAllMaintenanceAddresses();
      return;
    }

    this.setSelectedProject('');

    if (newWoco.trim()) {
      this.projects = await this.getProjects();
    } else {
      this.projects = [];
    }
  }

  public setSelectedMasterItem = async (
    masterItem: MasterItem,
    taskType: string,
    forceUpdate = false,
  ) => {
    if (
      this.selectedMasterItem
      && masterItem.address === this.selectedMasterItem.address
      && this.selectedMasterItem.detailTaskType === taskType
      && !forceUpdate
    ) return;

    if (!masterItem.getStatus(taskType as TaskTypeKey)) return;

    if (this.selectedMasterItem && this.selectedMasterItem.address !== masterItem.address) {
      this.selectedMasterItem.setDetails(undefined);
    }

    this.isLoadingDetail = true;

    const response = await request.tasks.getTaskDetail(taskType, masterItem.address);
    if (response.status === STATUS_OK) {
      const { components } = response.data;
      this.selectedMasterItem = {
        ...masterItem,
        components,
        detailTaskType: taskType,
      };
      masterItem.setDetails(components);
      this.updateURLQueryParams();
    }
    this.isLoadingDetail = false;
  }

  public hideDetails = async () => {
    this.selectedMasterItem = null;
  }

  public updateList = async (address: string, taskType?: string) => {
    const masterItem = this.getMasterItemByAddress(address);
    if (!masterItem) {
      console.warn(`Failed to find masterItem for address [${address}]`);
      return;
    }

    masterItem.setIsLoading(true);

    await this.updateTaskDataForItem(masterItem);
    masterItem.setIsLoading(false);
  }

  public submitMasterItemAction = async (masterItem: MasterItem, taskAction: Action) => {
    const { url, action } = taskAction;
    const taskTypeURL = masterItem.getTaskTypeFromURL(url!);
    if (!taskTypeURL) {
      console.warn('Failed to extract task type from URL:', url);
      return null;
    }

    masterItem.setIsLoading(true);

    const response = await request.tasks.submitAction(url!, masterItem.actionData);

    if ([STATUS_OK, STATUS_WARNING].includes(response.status)) {
      masterItem.resetActionData();
      await this.updateTaskDataForItem(masterItem);

      if (this.selectedMasterItem && this.selectedMasterItem.address === masterItem.address) {
        this.setSelectedMasterItem(masterItem, this.selectedMasterItem.detailTaskType, true);
      }

      if (response.status === STATUS_WARNING) {
        notifications.addNotificationFromResponse(response);
      }
    } else {
      notifications.addNotificationFromResponse(response);
    }

    masterItem.setIsLoading(false);
    return response;
  }

  public getMasterItemByAddress = (address: string) => {
    const masterItem = this.list.items.find((item) => item.address === address);
    return masterItem;
  }

  public updateTaskDataForItem = async (masterItem: MasterItem) => {
    const taskTypes = this.panes.getTaskTypesOpenPanes();
    const requests = taskTypes.map((taskType) => new Promise((resolve) => {
      request.tasks.getTaskData(taskType, masterItem.address).then((taskUpdateResponse) => {
        resolve({
          response: taskUpdateResponse,
          taskType,
        });
      });
    }));

    const results = await Promise.all(requests) as Array<{
      response: CustomResponse;
      taskType: string;
    }>;

    results.forEach((result) => {
      if ([STATUS_OK, STATUS_WARNING].includes(result.response.status)) {
        const taskData = result.response.data[masterItem.address];
        masterItem.setTypeData(result.taskType, taskData);
      }
    });
  }

  protected createNewMasterItem = (address: Address) => (
    new MasterItem(address)
  )

  protected getGroupsFromMasterItems = () => {
    const projects = this.list.getItems().reduce((acc, item) => {
      if (acc[item.project] === undefined) {
        return {
          ...acc,
          [item.project]: {
            addresses: [item.address],
            group: item.groups[0],
          },
        };
      }
      acc[item.project].addresses.push(item.address);
      return acc;
    }, {} as { [project: string]: { addresses: Array<string>; group: string } });

    return Object.values(projects);
  }

  protected getAllMaintenanceAddresses = async () => {
    this.wocoRequiredOnly = true;
    this.isLoading = true;
    this.list.filter!.setState({ ...MasterItemList.createFilterState() });
    this.selectedProject = '';
    this.projects = [];
    this.selectedGroup = '';
    this.groups = [];
    const response = await request.tasks.getAllMaintenanceAddresses();
    if ([STATUS_OK, STATUS_WARNING].includes(response.status)) {
      this.list.setItems(response.data.map((address: Address) => (
        this.createNewMasterItem(address)
      )));
    } else {
      notifications.addNotificationFromResponse(response);
      this.list.setItems([]);
    }
    this.updateURLQueryParams();
    this.isLoading = false;
  }

  protected getGroups = async () => {
    if (!this.selectedWoco || !this.selectedProject) return [];

    const response = await request.groups.getAllGroupsForProject(
      this.selectedWoco,
      this.selectedProject,
      actions.auth.readUser(),
    );

    // replace with real value once roles are implemented
    const isAdmin = false;

    if (response.status === STATUS_OK) {
      return response.data
        .filter((group: ExistingGroup) => (
          !group.is_private || group.created_by === actions.auth.readUser() || isAdmin
        ))
        .map((group: ExistingGroup) => group.name);
    }
    notifications.addNotificationFromResponse(response);
    return [];
  }

  protected getWocos = async () => {
    const response = await request.groups.getAllWocos();

    if (response.status === STATUS_OK) {
      return hasPermission(panePermissions.maintenance)
        ? [...response.data, 'All maintenance']
        : response.data;
    }
    notifications.addNotificationFromResponse(response);
    return [];
  }

  protected getProjects = async () => {
    if (!this.selectedWoco) return [];
    const response = await request.groups.getAllProjectsForWoco(this.selectedWoco);

    if (response.status === STATUS_OK) {
      return response.data;
    }
    notifications.addNotificationFromResponse(response);
    return [];
  }

  protected getAddresses = async () => {
    if (!this.selectedGroup) return;

    const initialFilterState = MasterItemList.createFilterState();
    this.list.filter!.setState({ ...initialFilterState });

    this.isLoading = true;
    const response = await request.groups.getGroupVisibleForUser(
      this.selectedGroup,
      actions.auth.readUser(),
    );

    if (response.status === STATUS_OK) {
      this.list.setItems(response.data.addresses.map((address: Address) => (
        this.createNewMasterItem(address)
      )));
    } else {
      this.list.setItems([]);
      notifications.addNotificationFromResponse(response);
    }

    this.isLoading = false;
  }

  protected setTaskData = (tasks: { [address: string]: object }, taskType: string) => {
    const masterItems = this.list.items;
    const params = this.router.currentRoute.query || {};

    masterItems.forEach((masterItem) => {
      const taskData = tasks[masterItem.address];
      if (taskData === undefined) {
        return;
      }
      masterItem.setTypeData(taskType, taskData);
      if (!this.selectedMasterItem && masterItem.address === params.address) {
        this.setSelectedMasterItem(masterItem, params.detail as string);
      }
    });

    this.list.setItems(masterItems);
  }
}
