import type {
  ListControllerConfig,
} from '@/interfaces';

export default class MultiSelectListController<T> {
  private lists: ListControllerConfig<T>;

  constructor(config: ListControllerConfig<T> = {}) {
    this.lists = config;
  }

  public setConfig = (config: ListControllerConfig<T>) => {
    this.lists = config;
  }

  public moveSelectedItems = (a: string, b: string) => {
    if (!(this.instance(a) && this.instance(b))) {
      console.warn(`Failed to find list(s): [${a}, ${b}]`);
      return;
    }
    this.instance(b)!.append(this.instance(a)!.removeSelected());
  }

  public swapSelectedItems = (a: string, b: string) => {
    if (!(this.instance(a) && this.instance(b))) {
      console.warn(`Failed to find list(s): [${a}, ${b}]`);
      return;
    }
    const selectedA = this.instance(a)!.removeSelected();
    const selectedB = this.instance(b)!.removeSelected();

    this.instance(a)!.append(selectedB);
    this.instance(b)!.append(selectedA);
  }

  public items = (listName: string) => (
    this.lists[listName] ? this.lists[listName].instance.filteredItems as Array<T> : [] as Array<T>
  )

  public instance = (listName: string) => {
    if (this.lists[listName]) {
      return this.lists[listName].instance;
    }
    return null;
  }
}
