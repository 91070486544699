<template>
  <div class="sort-arrow-container is-flex is-flex-direction-column" :class=[margin]>
    <i
      class="sort-arrow fas fa-sort-up is-clickable"
      @click="() => list.sort(sorters.desc)"
    ></i>
    <i
      class="sort-arrow fas fa-sort-down is-clickable"
      @click="() => list.sort(sorters.asc)"
    ></i>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import type { BaseList, MultiSelectList } from '@/logic/common';

@Component({
  name: 'SortToggle',
})
export default class SortToggle extends Vue {
  @Prop() list!: BaseList<unknown> | MultiSelectList<unknown>;
  @Prop() sorters!: {
    asc: string;
    desc: string;
  }
  @Prop({ default: '' }) margin!: string;
}

</script>

<style lang="scss">
  .sort-arrow-container {
    flex: 0;
  }
  .sort-arrow {
    line-height: 0.3rem;
  }
</style>
