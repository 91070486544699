<template>
  <div
    class="dropdown"
    :class="[!isCollapsed && 'is-active', size, `is-${align}`]"
    :style="fullWidth && 'width: 100%;'"
    v-outer-click="close"
  >
    <div
      class="dropdown-trigger is-unselectable is-clickable is-flex is-align-items-center"
      :style="fullWidth && 'width: 100%;'"
      @click="onToggleCollapse"
    >
      <slot name="trigger" v-bind:toggleCollapse="toggleCollapse">
        <span> {{ label }}</span>
        <span class="icon">
          <i
            class="fas fa-angle-down"
            aria-hidden="true"
            :style="`color: ${iconColor}; font-size: 1.125em;`"
          ></i>
        </span>
      </slot>
    </div>
    <div
      class="dropdown-menu"
      role="menu"
      :style="menuStyle"
    >
      <div
        class="dropdown-content"
        style="border: 1px solid #ccc;"
        :style="width && `width: ${width}`"
      >
        <slot name="default" v-bind:close="close"></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'DropdownSlotted',
})
export default class DropdownSlotted extends Vue {
  @Prop({ default: 'Toggle' }) label!: string;
  @Prop({ default: 'is-small' }) size!: string;
  @Prop({ default: '#000' }) iconColor!: string;
  @Prop({ default: 'left' }) align!: string;
  @Prop({ default: false }) fullWidth!: boolean;
  @Prop({ default: '' }) minWidth!: string;
  @Prop({ default: '' }) marginTop!: string;
  @Prop({ default: null }) closeKey!: string;
  @Prop({ default: '' }) width!: string;
  @Prop({ default: false }) customToggle!: boolean;

  protected isCollapsed = true;

  get menuStyle() {
    return [
      this.minWidth ? `min-width: ${this.minWidth} !important` : '',
      this.marginTop ? `margin-top: ${this.marginTop} !important` : '',
    ].join('; ');
  }

  protected toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }

  protected close() {
    this.isCollapsed = true;
  }

  protected onKeyDown(event: KeyboardEvent) {
    if (this.closeKey && this.closeKey.toLowerCase() === event.code.toLowerCase()) {
      this.close();
    }
  }

  protected onToggleCollapse() {
    if (!this.customToggle) {
      this.toggleCollapse();
    }
  }

  beforeDestroy() {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  created() {
    document.addEventListener('keydown', this.onKeyDown);
  }
}

</script>

<style lang="scss" scoped>
  .dropdown {
    > .dropdown-menu {
      display: none !important;
    }

    &.is-active {
      > .dropdown-menu {
        z-index: 1;
        display: block !important;
      }
    }

    .dropdown-content {
      $horizontal-padding: 5px;
      padding-left: $horizontal-padding;
      padding-right: $horizontal-padding;
    }
  }
</style>
