<template>
  <div v-if="caseDetail.status != 'created'">
    <info-container title="Tickets">
      <div class="tw-flex tw-flex-col">
        <div v-if="loadingTickets">Laden...</div>
        <div v-if="tickets.length > 0">
          <small-ticket-list-item
            v-for="ticket in tickets"
            :key="ticket.id"
            :ticket="ticket"
            @edit="selectedTicket = ticket"
            :editable="true"
            :show-case="false"
          />
        </div>
        <div>
          <button
            :disabled="!canCreateTicket"
            class="tw-bg-gray-500 tw-rounded tw-p-1 tw-text-white tw-mt-3 disabled:tw-opacity-50 disabled:tw-cursor-not-allowed"
            @click="showCreateTicketModal = true"
          >
            Maak onderhoudsticket aan
          </button>
        </div>
      </div>
    </info-container>
    <generic-modal
      v-if="showCreateTicketModal"
      @close="showCreateTicketModal = false"
      @submit="createTicket"
      :title="'Create Maintenance Ticket for address: ' + address"
      :width="'600px'"
      :disabled="!form.installer || !form.type || !form.description || !form.category"
    >
      <div v-if="ticketDetail?.id">
        <div class="tw-text-gray-500 tw-text-xs tw-font-bold">
          Installatieticket:
          <a
            :href="`https://tickets.ecorushome.com/v2/tickets/${ticketDetail?.id}`"
            class="hover:tw-underline tw-text-l tw-cursor-pointer"
            target="_blank"
          >
            {{ ticketDetail?.id }}
          </a>
        </div>
        <div class="tw-text-gray-500 tw-text-xs tw-font-bold">
          Partner: {{ ticketDetail?.data?.partner }}
        </div>
        <div
          class="tw-text-gray-500 tw-text-xs tw-font-bold"
          :class="{
            'tw-text-red-500':
              differenceInYears(new Date(), parseISO(ticketDetail.appointment_date)) < 1,
          }"
        >
          Afspraak: {{ formatDate(ticketDetail.appointment_date) }}
        </div>
        <div class="tw-text-gray-500 tw-text-xs tw-font-bold">
          Daktype: {{ ticketDetail?.data?.input.extra_fields.roof_type }}
        </div>
        <div class="tw-text-gray-500 tw-text-xs tw-font-bold">
          Afbeeldingen van installatieticket en design task worden automatisch toegevoegd aan het
          onderhoudsticket
        </div>
      </div>
      <div v-else class="tw-text-gray-500 tw-text-xs tw-font-bold">
        Geen installatieticket gevonden voor dit adres
      </div>
      <form-field
        type="textarea"
        label="Title"
        rows="1"
        v-model="form.title"
        maxlength="70"
        placeholder="Niet te lang! max 70 karakters"
      />
      <form-field type="textarea" label="Description" rows="3" v-model="form.description" />
      <div class="tw-flex tw-flex-column tw-gap-5">
        <form-field
          label="Service team"
          :options="partners"
          null-option="Select service team"
          type="select"
          v-model="form.installer"
        />
        <form-field
          label="Category"
          :options="categories"
          type="select"
          v-model="form.category"
          null-option="Select category"
        />
      </div>
      <div class="tw-flex tw-flex-column tw-gap-5">
        <form-field
          label="Maintenance type"
          :options="maintenanceTypes"
          null-option="Select type"
          type="select"
          v-model="form.type"
        />
        <form-field label="Priority" :options="priority" type="select" v-model="form.priority" />
      </div>
      <div class="tw-flex tw-flex-column tw-gap-5">
        <form-field
          label="Ref number for billing"
          v-model="form.refNumber"
          placeholder="Ref number for billing"
        />
        <form-field label="Number of people needed" type="number" v-model="form.teamSize" />
      </div>
      <form-field type="textarea" label="Notes about key/access" v-model="form.note" />
    </generic-modal>
    <generic-modal
      v-if="selectedTicket"
      @close="selectedTicket = null"
      :show-actions="false"
      :title="'Ticket details for ' + selectedTicket.title"
      :width="'600px'"
    >
      <div class="tw-flex tw-flex-column tw-gap-5">
        <div class="tw-flex tw-mt-2">
          <span>Ticket ID: </span>
          <span class="tw-font-bold tw-ml-2">
            <a
              :href="`https://tickets.ecorushome.com/v2/tickets/${selectedTicket?.id}`"
              class="hover:tw-underline tw-text-l tw-cursor-pointer tw-px-2"
              target="_blank"
            >
              {{ selectedTicket.id }}
            </a></span
          >
        </div>
        <div class="tw-flex tw-mt-2">
          <span>Status: </span>
          <span class="tw-font-bold tw-ml-2">{{ selectedTicket.status }}</span>
        </div>
        <div class="tw-flex tw-mt-2">
          <span>Partner: </span>
          <span class="tw-font-bold tw-ml-2 tw-mb-2">{{ selectedTicket.partner }}</span>
        </div>
      </div>
      <div class="tw-flex tw-flex-column tw-gap-5">
        <div class="tw-flex tw-mt-2">
          <form-field
            class="tw-font-bold tw-mb-2"
            label="Prioriteit"
            :options="priority"
            type="select"
            v-model="editForm.priority"
            @change="updateTicket({ priority: editForm.priority })"
          />
        </div>
        <div class="tw-flex tw-mt-2">
          <form-field
            class="tw-font-bold tw-ml-2 tw-mb-2"
            label="Ref number"
            v-model="editForm.refNumber"
            @change="updateTicket({ refNumber: editForm.refNumber })"
          />
        </div>
      </div>
      <div class="tw-flex tw-flex-column tw-gap-5">
        <div class="tw-flex tw-mt-2">
          <form-field
            class="tw-font-bold tw-mb-2"
            label="Categorie"
            :options="categories"
            type="select"
            v-model="editForm.category"
            @change="updateTicket({ category: editForm.category })"
          />
        </div>
        <div class="tw-flex tw-mt-2">
          <form-field
            class="tw-font-bold tw-ml-2 tw-mb-2"
            label="Maintenance Type"
            :options="maintenanceTypes"
            type="select"
            v-model="editForm.maintenanceType"
            @change="updateTicket({ maintenanceType: editForm.maintenanceType })"
          />
        </div>
      </div>
      <div class="tw-my-2">
        <form-field
          class="tw-font-bold tw-mb-2 tw-flex-full"
          label="Omschrijving"
          type="textarea"
          v-model="editForm.description"
          rows="3"
        />
        <button
          class="tw-bg-green-600 tw-text-white tw-p-2 tw-rounded tw-w-full disabled:tw-bg-gray-200"
          :disabled="editForm.description === selectedTicket.description"
          @click="updateTicket({ description: editForm.description })"
        >
          Update omschrijving
        </button>
      </div>
      <div
        class="tw-flex tw-flex-col tw-w-full"
        v-if="selectedTicket.status !== 'cancelled' && selectedTicket.status !== 'approved'"
      >
        <form-field
          hideLabel="true"
          type="textarea"
          label="Comment/remark"
          rows="2"
          v-model="comment"
          placeholder="Comment/remark"
        />
        <div
          class="tw-flex tw-my-3 tw-w-full tw-gap-3"
          v-if="selectedTicket.status === 'partner_approved'"
        >
          <button
            class="tw-bg-green-600 tw-text-white tw-p-2 tw-rounded disabled:tw-bg-gray-200 tw-w-1/2"
            @click="approveTicket(selectedTicket)"
          >
            Goedkeuren
          </button>
          <button
            class="tw-bg-red-500 tw-text-white tw-p-2 tw-rounded disabled:tw-bg-gray-200 tw-w-1/2"
            :disabled="!comment"
            @click="rejectTicket(selectedTicket)"
          >
            Afkeuren
          </button>
        </div>

        <button
          class="tw-bg-red-500 tw-text-white tw-p-2 tw-rounded disabled:tw-bg-gray-200"
          :disabled="!comment"
          v-if="selectedTicket.status !== 'cancelled' || selectedTicket.status !== 'approved'"
          @click="cancelTicket(selectedTicket)"
        >
          Cancel
        </button>
      </div>
    </generic-modal>
  </div>
</template>

<script setup>
import { watch, ref, computed } from 'vue';
import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query';
import fetchData from '@/util/fetch';
import { actions } from '@/store';
import { formatDate, notifications } from '@/util';
import InfoContainer from '@/components/shared/InfoContainer.vue';
import FormField from '@/components/shared/FormField.vue';
import GenericModal from '@/components/shared/GenericModal.vue';
import { parseISO, differenceInYears } from 'date-fns';
import SmallTicketListItem from '@/components/shared/SmallTicketListItem.vue';

const props = defineProps({
  caseDetail: {
    type: Object,
    required: true,
  },
});

const queryClient = useQueryClient();
const id = computed(() => props.caseDetail.id);
const address = computed(() => props.caseDetail.address);
const category = computed(() => props.caseDetail.category || 'O&M');

const comment = ref('');
const selectedTicket = ref(null);

const showCreateTicketModal = ref(false);
const token = actions.auth.readToken();

const form = ref({
  installer: '',
  priority: 'Normal',
  category: category.value,
  refNumber: '',
  teamSize: 1,
  note: '',
  type: '',
  description: '',
  title: props.caseDetail.description,
});

watch(
  () => props.caseDetail.id,
  () => {
    form.description = '';
    form.title = props.caseDetail.description;
    form.category = props.caseDetail.category || 'O&M';

    comment.value = {};
  }
);

const { data: ticketsQuery, isLoading: loadingTickets } = useQuery({
  queryKey: computed(() => ['tickets', 'case', id.value]),
  keepPreviousData: true,
  queryFn: async () => {
    try {
      const result = await fetchData(`/tickets/api/v1/tickets?view=list&case_id=${id.value}`);
      return result.data;
    } catch (error) {
      console.error('Error fetching tickets:', error);
      throw error;
    }
  },
});

const tickets = computed(() => ticketsQuery.value || []);

const getPartners = () => fetchData('/tickets/api/v1/partners');
const partnersQuery = useQuery({
  queryKey: ['partners'],
  queryFn: getPartners,
});
const partners = computed(() => partnersQuery.data?.value?.data.map((p) => p.legacy_name) || []);

const { data: installationTicketQuery } = useQuery({
  queryKey: computed(() => ['installationTicket', address.value]),
  keepPreviousData: true,
  queryFn: async () => {
    try {
      const formattedAddress = address.value.replace(/\s+/g, '').toLowerCase();
      const result = await fetchData(
        `/tickets/api/v1/tickets?view=list&query=${formattedAddress}&type=installation&status=approved`
      );
      return result.data;
    } catch (error) {
      console.error('Error fetching comments:', error);
      throw error;
    }
  },
});

const oldestInstallationTicket = computed(() => {
  if (installationTicketQuery?.value?.length === 0) return null;
  return installationTicketQuery?.value?.reduce((prev, current) =>
    prev.id < current.id ? prev : current
  );
});

// Fetch ticket details based on the installation ticket
const { data: ticketDetail } = useQuery({
  queryKey: computed(() => ['ticket', oldestInstallationTicket.value?.id]),
  queryFn: async () => {
    if (!oldestInstallationTicket.value) {
      return null;
    }

    const url = `/tickets/api/v1/tickets/${oldestInstallationTicket.value.id}`;
    const result = await fetchData(url);
    return result.data;
  },
  refetchOnWindowFocus: false,
  enabled: computed(() => !!oldestInstallationTicket.value),
});

// Fetch current maintenance ticket
const { data: currentTicketDetail } = useQuery({
  queryKey: computed(() => ['currentTicket', selectedTicket.value?.id]),
  queryFn: async () => {
    if (!oldestInstallationTicket.value) {
      return null;
    }

    const url = `/tickets/api/v1/tickets/${selectedTicket.value.id}`;
    const result = await fetchData(url);
    return result.data;
  },
  refetchOnWindowFocus: false,
  enabled: computed(() => !!selectedTicket.value),
});

const editForm = ref({
  priority: selectedTicket?.value?.priority,
  description: selectedTicket?.value?.description,
  category: selectedTicket?.value?.category,
  maintenanceType: currentTicketDetail?.value?.data?.input.finance.malfunction_category,
  refNumber: currentTicketDetail?.value?.data?.input.finance.client_reference,
});

watch(
  () => currentTicketDetail.value,
  () => {
    editForm.value.priority = selectedTicket.value?.priority;
    editForm.value.description = selectedTicket.value?.description;
    editForm.value.category = currentTicketDetail.value?.data?.input.extra_fields.category;
    editForm.value.maintenanceType =
      currentTicketDetail?.value?.data?.input.finance.malfunction_category;
    editForm.value.refNumber = currentTicketDetail?.value?.data?.input.finance.client_reference;
  }
);

// Fetch address details if there is no older installation ticket
const { data: addressDetail } = useQuery({
  queryKey: computed(() => ['address', address.value]),
  queryFn: async () => {
    const url = `/api/v1/data/address/${address.value}`;
    const result = await fetchData(url);
    return result.data;
  },
  refetchOnWindowFocus: false,
});

const maintenanceTypes = ['Storing', 'Service', 'Lekkage', 'Schadeherstel', 'Herstel inspectie'];
const categories = ['O&M', 'KAM', 'Klantenservice'];
const priority = ['Leak (6 hours)', 'Urgent', 'Normal'];

const updateTicket = async (body, successMessage = 'Ticket updated successfully') => {
  const url = `/tickets/api/v1/tickets/${selectedTicket.value.id}`;
  try {
    const result = await fetch(url, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (result.status === 200) {
      notifications.addNotification({
        message: successMessage,
        type: 'success',
      });
      queryClient.invalidateQueries({ queryKey: ['cases'] });
      queryClient.invalidateQueries({ queryKey: ['tickets'] });
    } else {
      notifications.addNotification({
        message: `Failed to update ticket: ${result.statusText}`,
        type: 'danger',
      });
    }
    return result.data;
  } catch (error) {
    console.error(`Error updating ticket ${ticketId}:`, error);
    notifications.addNotification({
      message: `Something went wrong while updating ticket ${ticketId} with error: ${error}`,
      type: 'danger',
    });
  }
};

const cancelTicket = async (ticket) => {
  comment.value = '';
  return updateTicket(
    { signal: 'ecorus_cancel', cancel_comment: comment.value || 'Cancelled by Ecorus' },
    `Ticket ${ticket.id} cancelled successfully`
  );
};

const approveTicket = async (ticket) => {
  comment.value = '';
  return updateTicket(
    { signal: 'ecorus_approve', approve_remark: comment.value },
    `Ticket ${ticket.id} approved successfully`
  );
};

const rejectTicket = async (ticket) => {
  comment.value = '';
  return updateTicket(
    { signal: 'ecorus_reject', reject_remark: comment.value },
    `Ticket ${ticket.id} rejected successfully`
  );
};

const { mutate: createTicket } = useMutation({
  mutationFn: () => {
    let productsList = [];
    let images = [];
    let roofAngle = '';
    let roofType = '';

    const ticketData = ticketDetail.value?.data;
    if (ticketData) {
      const productsSource = Object.values(ticketData.output.products).some(
        (product) => Object.keys(product).length > 0
      )
        ? ticketData.output.products
        : ticketData.input.products;

      productsList = Object.entries(productsSource).flatMap(([type, items]) =>
        Object.entries(items)
          .filter((item) => item[1]?.amount > 1)
          .map(([code, details]) => ({
            code: code,
            amount: Number(details.amount),
            type: type,
            is_installed: true,
          }))
      );

      images = [...ticketData.input.images, ...ticketData.output.images]
        .filter((image) => image.key !== null && image.key !== undefined)
        .map((image) => ({
          url: image.key,
          type: image.type,
        }));
      roofAngle = ticketData.input.extra_fields.roof_angle;
      roofType = ticketData.input.extra_fields.roof_type;
    } else if (addressDetail?.value?.tasks?.design) {
      const designTask = addressDetail?.value?.tasks?.design;

      productsList = designTask.products.filter((product) => product.amount > 1 && product.code);
      images =
        designTask.images
          .filter((image) => image.key && image.key !== null && image.key !== undefined)
          .map((image) => ({
            url: image.key,
            type: image.type,
          })) || [];
      roofAngle = designTask.extra_fields?.roof_angle;
      roofType = designTask.extra_fields?.roof_type;
    }
    const project = addressDetail?.value?.project;
    const client = {
      address: addressDetail?.value?.address,
      ...addressDetail?.value?.client,
    };

    if (!project) {
      notifications.addNotification({
        message: `Geen installatieticket of design task gevonden, kan geen ticket maken`,
        type: 'danger',
      });
    }

    if (!form.value.category) {
      notifications.addNotification({
        message: `Kan geen ticket maken zonder categorie`,
        type: 'danger',
      });
    }

    const payload = {
      type: 'maintenance',
      partner: form.value.installer,
      client_info: client,
      images: images,
      extra_fields: {
        priority: form.value.priority,
        title: form.value.title,
        description: form.value.description,
        requiredTeamSize: form.value.teamSize,
        roof_angle: roofAngle,
        roof_type: roofType,
        category: form.value.category,
      },
      finance: {
        client_reference: form.value.refNumber,
        malfunction_category: form.value.type,
      },
      case_id: id.value,
      project_name: project,
      note: form.value.note,
      products: productsList,
    };

    return fetchData('/tickets/api/v1/tickets', {
      method: 'POST',
      body: JSON.stringify(payload),
    });
  },
  onError: (e) => {
    console.error('Error on creating ticket', e);
    notifications.addNotification({
      message: `Something went wrong while creating the ticket with error: ${e.message}`,
      type: 'danger',
    });
  },
  onSuccess: () => {
    form.value = {
      installer: '',
      priority: 'Normal',
      refNumber: '',
      teamSize: 1,
      note: '',
      type: 'Defect',
      description: '',
      title: '',
    };

    notifications.addNotification({
      message: 'Ticket created successfully',
      type: 'success',
    });

    showCreateTicketModal.value = false;

    queryClient.invalidateQueries({ queryKey: ['tickets'] });
    queryClient.invalidateQueries({ queryKey: ['cases'] });
  },
});

const canCreateTicket = computed(() => ['open', 'created'].includes(props.caseDetail.status));
</script>
