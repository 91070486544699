import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import { StoreState } from './state';
import AuthModule, { makeAuthActions } from './auth';
import CommonModule, { makeCommonActions } from './common';
import TasksModule, { makeTasksActions } from './tasks';

const vuexLocal = new VuexPersistence<StoreState>({
  storage: window.localStorage,
  reducer: (state: StoreState) => ({ // only persist state defined in the reducer
    auth: {
      user: state.auth.user,
      token: state.auth.token,
      permissions: state.auth.permissions,
    },
    common: {
      selectedGroup: state.common.selectedGroup,
      selectedProject: state.common.selectedProject,
      selectedWoco: state.common.selectedWoco,
      selectedCallCentreAddress: state.common.selectedCallCentreAddress,
      panes: state.common.panes,
    },
    tasks: {
      masterData: state.tasks.masterData,
    },
  }),
  modules: ['auth'],
});

Vue.use(Vuex);

export const createStore = () => new Vuex.Store<StoreState>({
  modules: {
    auth: AuthModule,
    common: CommonModule,
    tasks: TasksModule,
  },
  plugins: [vuexLocal.plugin],
});

export const store = createStore();

export const actions = {
  auth: makeAuthActions(store),
  common: makeCommonActions(store),
  tasks: makeTasksActions(store),
};
