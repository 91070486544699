<template>
  <div class="m-5">
    <div class="mb-3" v-if="canCreate">
      <b-button @click="$router.push({ name: 'new-project' })" class="mr-2">Nieuw project</b-button>
    </div>
    <div v-if="projects">
      <b-table :data="projects">
        <b-table-column field="id" label="ID" v-slot="project">
          {{ project.row.id }}
        </b-table-column>
        <b-table-column field="name" label="Naam" v-slot="project">
          <router-link :to="{ name: SCREEN_PROJECTS_EDIT, params: { id: project.row.id } }">
            {{ project.row.name }}
          </router-link>
        </b-table-column>
        <b-table-column field="installers" label="Installateurs" v-slot="project">
          <template v-if="project.row.installers.length > 0">
            {{ project.row.installers.join(', ') }}
          </template>
          <template v-else>
            <span>No installers found.</span>
          </template>
        </b-table-column>
        <b-table-column field="customer_survey" label="Klantonderzoek" v-slot="project">
          {{ project.row.customer_survey ? 'Yes' : 'No' }}
        </b-table-column>
        <b-table-column field="c4a_meters" label="C4a meters" v-slot="project">
          {{ project.row.c4a_meters ? 'Yes' : 'No' }}
        </b-table-column>
        <b-table-column field="process" label="Proces " v-slot="project">
          {{ project.row.process.name }}
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>

<script setup>
import { useQuery } from '@tanstack/vue-query';
import fetchData from '@/util/fetch';
import { computed } from 'vue';
import { SCREEN_PROJECTS_EDIT } from '@/constants'
import store from '@/store'

const canCreate = computed(() => {
  const {actions, roles} = store.getters['auth/getPermissions']
  if (!actions || !roles) return false

  return actions.includes('ERP:create_project') || roles.includes('superadmin')
})


const getProjects = () => fetchData('/config/api/v1/projects/');
const projectsQuery = useQuery({
  queryKey: ['projects'],
  queryFn: getProjects,
});

const projects = computed(() => projectsQuery.data.value && projectsQuery.data.value.data)
</script>
