<template>
  <div class="is-flex is-align-items-center">
    <select class="select" @change="onChange">
      <option :selected="selectedUser === ''" :value="''">Select user</option>
      <option
        v-for="user in users"
        :key="user"
        :selected="user === selectedUser"
      >{{ user }}</option>
    </select>
    <span class="tag" style="font-size: 0.6rem;">{{ this.actionList.length }}</span>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { TasksScreenManager } from '@/logic/TasksScreen';
import request from '@/requests';
import type MasterItem from '@/logic/TasksScreen/MasterItem';

import type { Action, TaskTypeKey } from '@/interfaces';

interface StoredAction {
  item: MasterItem;
  callback: Function;
  action: Action;
  taskType: TaskTypeKey;
}

@Component({})
export default class UserSelect extends Vue {
  @Prop({}) actionList!: Array<StoredAction>;
  @Prop({}) manager!: TasksScreenManager;
  @Prop({}) masterItem!: MasterItem;
  @Prop({}) updateActions!: Function;

  protected userOptions = this.users;

  protected selectedUser = '';

  get users() {
    const users = [...this.actionList[0].action.data as Array<string>];
    return users.sort((a, b) => a.localeCompare(b));
  }

  protected onChange = async (event: InputEvent) => {
    const target = event.target as HTMLSelectElement;

    this.selectedUser = target.value;
    this.updateSelection(this.selectedUser);

    await this.updateTaskDetails();
    await Promise.all(this.actionList.map(({ item }) => (
      this.manager.updateList(item.address)
    )));

    this.updateActions();
  }

  protected updateSelection(user: string) {
    this.manager.setActionDataForItems({ userToApprove: user }, this.actionList);
  }

  protected async updateTaskDetails() {
    await Promise.all(this.actionList.map((entry) => (
      request.details.updateTaskDetailForClient(
        `api/v1/${entry.action.url}`,
        entry.item.actionData,
      )
    )));
  }

  // mounted() {
  //   this.updateSelection(this.selectedUser);
  // }
}

</script>

<style lang="scss" scoped>
.select {
  margin-right: 5px;
  font-size: 10px;
  height: 14px !important;
}
</style>
