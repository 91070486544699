<template>
  <div
    class="scrollable-container-outer"
    ref="container"
    :style="style"
  >
    <div class="scrollable-container-inner">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({ name: 'ScrollContainer' })
export default class ScrollContainer extends Vue {
  @Prop({ default: 'auto' }) height!: number | string;
  @Prop({ default: 0 }) minHeight!: number | string;
  @Prop({ default: 'none' }) maxHeight!: number | string;
  @Prop({ default: 'none' }) border!: string;
  @Prop({ default: 'transparent' }) background!: string;
  @Prop({ default: 'auto' }) overflow!: string;

  style = [
    `overflow-y: ${this.overflow};`,
    `height: ${this.height};`,
    `min-height: ${this.minHeight};`,
    `max-height: ${this.maxHeight};`,
    `border: ${this.border};`,
    `background: ${this.background};`,
  ].join(' ')
}
</script>

<style lang="scss" scoped>
  .scrollable-container-outer::-webkit-scrollbar {
    width: 0.35rem;
    height: 0.35rem;
    color: #868686;
  }
  .scrollable-container-outer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }
  .scrollable-container-outer::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    cursor: grab;
  }
</style>
