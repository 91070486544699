<script lang="ts">
import { Component } from 'vue-property-decorator';
import BaseButton from './BaseButton.vue';

@Component({})
export default class AssignButton extends BaseButton {
  protected color = 'is-assign';

  protected onClick() {
    const now = new Date();
    const deadline = new Date(now.getTime() + 604800000);

    if (!this.masterItem) return;

    this.masterItem.setActionData({
      ...this.masterItem.actionData,
      deadline,
    });

    if (this.masterItem.actionData.userToApprove) {
      this.manager.submitMasterItemAction(this.masterItem, this.action);
    }
  }
}
</script>
