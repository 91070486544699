<template>
  <div class="group-table">
    <div class="selection mb-2 is-flex is-flex-direction-column is-justify-content-start">
      <div class="is-flex is-flex-direction">
        <dropdown
          class="dropdown"
          v-if="manager.wocos.length"
          :items="wocoDropdown"
          :preselect="manager.selectedWoco"
          :key="wocoDropdown.join()"
          :dataId="'task-woco?woco=' + manager.selectedWoco"
          width="130"
          @change="(e) => manager.setSelectedWoco(e)"
        />
        <dropdown
          class="dropdown"
          :disabled="!manager.projects.length || !manager.selectedWoco"
          :items="projectDropdown"
          :preselect="manager.selectedProject"
          :key="projectDropdown.join()"
          :dataId="'task-project?project=' + manager.selectedProject"
          width="130"
          @change="(e) => manager.setSelectedProject(e)"
        />
      </div>
      <searchable-dropdown
        display="text"
        :items="groupDropdown"
        :preselect="manager.selectedGroup"
        :dataId="'task-group?group=' + manager.selectedGroup"
        :disabled="!manager.groups.length || !manager.selectedProject"
        :key="groupDropdown.join()"
        @change="(item) => manager.setSelectedGroup(item.value)"
        width="240"
      />
    </div>
    <div v-if="manager.isLoading">Loading...</div>
    <address-table
      v-else-if="manager.selectedGroup || manager.wocoRequiredOnly"
      :manager="manager"
    />
    <div v-else>Please select a woco, project and then group</div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { TasksScreenManager } from '@/logic/TasksScreen';
import { sortStringWithNumbers } from '@/logic/common';
import AddressTable from './table/AddressTable.vue';

@Component({
  name: 'AddressPane',
  components: { AddressTable },
})
export default class AddressPane extends Vue {
  @Prop() manager!: TasksScreenManager;

  get filter() {
    return this.manager.list.filter;
  }

  get wocoDropdown() {
    const dropdown = [...this.manager.wocos].sort((a, b) => {
      const compareA = a === 'All maintenance' ? -1 : 0;
      const compareB = b === 'All maintenance' ? -1 : 0;
      return compareA - compareB === 0 ? sortStringWithNumbers(a, b) : compareA - compareB;
    });
    if (!this.manager.selectedWoco) {
      dropdown.unshift('Woco');
      this.manager.setSelectedProject('');
      this.manager.setSelectedGroup('');
      this.manager.setSelectedWoco('');
    }
    return dropdown;
  }

  get projectDropdown() {
    const dropdown = [...this.manager.projects.map((project: any) => project.name)]
      .sort(sortStringWithNumbers);
    if (!this.manager.selectedProject) {
      dropdown.unshift('Project');
    }
    return dropdown;
  }

  get groupDropdown() {
    const groups = [...this.manager.groups].sort(sortStringWithNumbers);
    const dropdown = groups.map((group) => ({ text: group, value: group }));
    if (!this.manager.selectedGroup) {
      dropdown.unshift({ text: 'Group', value: '' });
    }
    return dropdown;
  }
}

</script>

<style lang="scss" scoped>
.selection {
  max-width: 25em;

  .dropdown {
    text-overflow: clip !important;
    white-space: nowrap !important;
    overflow: hidden !important;
  }
}
</style>
