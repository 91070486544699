export const imageFileExtensions = ['jpg', 'jpeg', 'png', 'jfif'];
export const tableFileExtensions = ['xlsx', 'xlsm', 'csv'];

export const getFileExtensionFromURL = (source: string) => {
  if (typeof source !== 'string') return null;

  const lastDotIndex = source.lastIndexOf('.');
  const re = /\.(?<ext>[a-z]+)/i;
  if (lastDotIndex === -1) {
    return null;
  }

  const match = source.slice(lastDotIndex - 1).match(re);
  if (!match) {
    console.warn(`Failed to extract file extension from ${source}`);
    return null;
  }

  return match.groups!.ext.toLowerCase();
};

export const hasImageFileExtension = (source: string) => (
  typeof source === 'string'
  && imageFileExtensions.some((ext) => source.toLowerCase().includes(ext))
);

export const hasTableFileExtension = (source: string) => (
  typeof source === 'string'
  && tableFileExtensions.some((ext) => source.toLowerCase().includes(ext))
);
