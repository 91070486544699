<template>
  <dropdown-slotted>
    <!-- TRIGGER -->
    <template v-slot:trigger>
      <div class="is-size-7">
        <span>Projects</span>
        <span>
          <i class="fas fa-angle-down pl-2" aria-hidden="true"></i>
        </span>
      </div>
    </template>

    <!-- DROPDOWN CONTENT -->
    <template>

      <!-- PROJECTS BY YEAR -->
      <div class="is-flex is-flex-wrap-wrap is-align-items-center mb-1">
        <small-tag
          v-for="year in projectYearOptions"
          :key="`projects-year-${year}`"
          class="mr-1 ml-0"
          :label="year"
          color="is-dark"
          @click="() => onSelectProjectsByYear(year)"
        />
      </div>

      <!-- (DE)SELECT ALL -->
      <div class="is-flex is-align-items-center is-size-7">
        <input
          type="checkbox"
          :checked="selection && list.selectAllActive"
          @click="list.toggleSelectAll"
        >
        <span class="mx-1">All Projects</span>
      </div>

      <hr class="my-1">

      <!-- PROJECTS -->
      <scroll-container max-height="calc(85vh - 8rem)">
        <div
          v-for="item in list.items"
          :key="`project-${item}`"
          class="is-flex is-align-items-center is-size-7"
        >
          <input
            type="checkbox"
            :checked="selection && list.isSelected(item)"
            @click="(event) => list.onClickItem(item, event)"
          >
          <span class="mx-1" style="white-space: nowrap;">{{ item }}</span>
        </div>
      </scroll-container>

    </template>

  </dropdown-slotted>
</template>

<script lang="ts">
import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import { MultiSelectList } from '@/logic/common';
import type { DashboardScreenManager } from '@/logic/DashboardScreen';

@Component({
  name: 'DashboardProjectSelection',
})
export default class DashboardProjectSelection extends Vue {
  @Prop() manager!: DashboardScreenManager;

  protected list = new MultiSelectList<string>([], {
    checkboxMode: true,
  });

  get selection() {
    return this.list.selectedItems;
  }

  get projectYearOptions() {
    const years = new Set();

    this.manager.projects.forEach((project) => {
      const match = project.match(/\d{4}/);
      if (match) {
        years.add(+match[0]);
      }
    });

    return Array.from(years).sort();
  }

  @Watch('selection')
  protected onProjectSelectionChange() {
    this.manager.setSelectedProjects(this.selection);
  }

  protected updateList() {
    this.list.setItems(this.manager.projects);
    this.list.setPreselectedItems(this.manager.selectedProjects);
  }

  protected onSelectProjectsByYear(year: number) {
    const projectsInSelectedYear = this.manager.projects.filter((project) => (
      !!project.match(year.toString())
    ));
    this.manager.setSelectedProjects(projectsInSelectedYear);
    this.updateList();
  }

  mounted() {
    this.updateList();
    if (this.selection.length === this.manager.projects.length) {
      this.list.selectAllActive = true;
    }
  }
}
</script>
