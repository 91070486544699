import Vue from 'vue';
import VueRouter, { RouteConfig, Route } from 'vue-router';

import Home from '@/views/Home.vue';
import TasksView from '@/views/TasksView.vue';
import NotFoundView from '@/views/NotFoundView.vue';
import GroupCreationView from '@/views/GroupCreationView.vue';
import GroupEditView from '@/views/GroupEditView.vue';
import InteractionsView from '@/views/InteractionsView.vue';
import DashboardView from '@/views/DashboardView.vue';
import CallCentreView from '@/views/CallCentreView.vue';
import TicketsView from '@/views/TicketsView.vue';
import PartnerOrderView from '@/views/PartnerOrderView.vue';
import PartnerOrderDetailView from '@/views/PartnerOrderDetailView.vue';
import ListUsersView from '@/views/ListUsersView.vue';
import EditUserView from '@/views/EditUserView.vue';
import NewUserView from '@/views/NewUserView.vue';
import NewProjectView from '@/views/NewProjectView.vue';
import GroupsView from '@/views/GroupsView.vue';
import TasksV2View from '@/views/TasksV2View.vue';
import PartnerListView from '@/views/PartnerListView.vue';
import AlarmsView from '@/views/AlarmsView.vue';
import CasesView from '@/views/CasesView.vue';
import PollingView from '@/views/PollingView.vue';

import { Authentication } from '@/logic/common';
import { screenPermissions } from '@/logic/common/Permissions';
import { actions } from '@/store';

import {
  SCREEN_CLIENTS,
  SCREEN_GROUP_EDIT,
  SCREEN_HOME,
  SCREEN_TASKS,
  SCREEN_NOT_FOUND,
  SCREEN_INTERACTIONS,
  SCREEN_DASHBOARD,
  SCREEN_CALL_CENTRE,
  SCREEN_TICKETS,
  SCREEN_PARTNER_ORDERS,
  SCREEN_PARTNER_ORDER_DETAILS,
  SCREEN_PROJECTS,
  SCREEN_PROJECTS_EDIT,
} from '@/constants';
import ProjectsSectionView from '@/views/ProjectsSectionView.vue';
import ProjectDetailView from '@/views/ProjectDetailView.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: SCREEN_HOME,
    component: Home,
  },
  {
    path: '/clients',
    name: SCREEN_CLIENTS,
    component: GroupCreationView,
    meta: {
      permissions: screenPermissions.clients,
    },
  },
  {
    path: '/clients/group/:name',
    name: SCREEN_GROUP_EDIT,
    component: GroupEditView,
    meta: {
      permissions: screenPermissions.groupEdit,
    },
  },

  {
    path: '/tasks',
    name: SCREEN_TASKS,
    component: TasksView,
    meta: {
      permissions: screenPermissions.tasks,
    },
  },
  {
    path: '/interactions',
    name: SCREEN_INTERACTIONS,
    component: InteractionsView,
    meta: {
      permissions: screenPermissions.interactions,
    },
  },
  {
    path: '/dashboard',
    name: SCREEN_DASHBOARD,
    component: DashboardView,
    meta: {
      permissions: screenPermissions.dashboard,
    },
  },
  {
    path: '/call-centre',
    name: SCREEN_CALL_CENTRE,
    component: CallCentreView,
    meta: {
      permissions: screenPermissions.callCentre,
    },
  },
  {
    path: '/tickets',
    name: SCREEN_TICKETS,
    component: TicketsView,
    meta: {
      permissions: screenPermissions.callCentre,
    },
  },
  {
    path: '/projects',
    name: SCREEN_PROJECTS,
    component: ProjectsSectionView,
    meta: {
      permissions: screenPermissions.projectsView,
    },
  },
  {
    path: '/projects/new',
    name: 'new-project',
    component: NewProjectView,
    meta: {
      permissions: 'ERP:create_project',
    },
  },
  {
    path: '/projects/:id',
    name: SCREEN_PROJECTS_EDIT,
    component: ProjectDetailView,
    meta: {
      permissions: screenPermissions.projectsEdit,
    },
  },
  {
    path: '/partner-orders',
    name: SCREEN_PARTNER_ORDERS,
    component: PartnerOrderView,
    meta: {
      permissions: 'partner_orders_manager',
    },
  },
  {
    path: '/partner-orders/:id',
    name: SCREEN_PARTNER_ORDER_DETAILS,
    component: PartnerOrderDetailView,
    meta: {
      permissions: 'partner_orders_manager',
    },
  },
  {
    path: '/users',
    name: 'list-users',
    component: ListUsersView,
    meta: {
      permissions: 'ERP:list_users',
    },
  },
  {
    path: '/users/new',
    name: 'new-user',
    component: NewUserView,
    meta: {
      permissions: 'ERP:new_user',
    },
  },
  {
    path: '/users/:username',
    name: 'edit-user',
    component: EditUserView,
    meta: {
      permissions: 'ERP:edit_user',
    },
  },
  {
    path: '/groups',
    name: 'view-groups',
    component: GroupsView,
    meta: {
      permissions: screenPermissions.clients,
    },
  },
  {
    path: '/partners',
    name: 'partner-list',
    component: PartnerListView,
    meta: {
      permissions: 'ERP:list_partner_users',
    },
  },
  {
    path: '/v2/tasks',
    name: 'tasks-v2',
    component: TasksV2View,
    meta: {
      permissions: 'ERP:view_tasks_v2',
    },
  },
  {
    path: '/v2/alarms',
    name: 'alarms',
    component: AlarmsView,
    meta: {
      permissions: 'Eleena:view_alarms',
    },
  },
  {
    path: '/v2/cases',
    name: 'cases',
    component: CasesView,
    props: { showModal: false, address: '', alarmId: null },
    meta: {
      permissions: 'Tickets:view_cases',
    },
  },
  {
    path: '/v2/cases/:id',
    name: 'case-detail',
    component: CasesView,
    meta: {
      permissions: 'Tickets:view_cases',
    },
  },
  {
    path: '/v2/polling',
    name: 'polling',
    component: PollingView,
    meta: {
      permissions: 'ERP:view_polling',
    },
  },
  {
    path: '/v2/polling/:id',
    name: 'polling-detail',
    component: PollingView,
    meta: {
      permissions: 'ERP:view_polling',
    },
  },
  {
    path: '/v2/tasks/:address/:type',
    name: 'task-detail',
    component: TasksV2View,
    meta: {
      permissions: 'ERP:view_tasks_v2',
    },
  },
  {
    path: '*', // catch-all route - 404
    name: SCREEN_NOT_FOUND,
    component: NotFoundView,
  },
];

const router = new VueRouter({ routes });

const auth = new Authentication(router);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
router.afterEach((_to: Route, _from: Route) => {
  auth.updateStatus();
  document.title = `${_to.name} | ERP | Ecorus` || 'ERP | Ecorus';
  actions.common.commitNotifications([]);
});

export default router;
