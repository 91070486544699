<template>
  <div class="m-5" v-if="groups && partners">
    <h4 class="title">New User</h4>

    <div class="columns">
      <div class="column is-2 has-text-grey-light">Email</div>
      <div class="column">
        <input
          type="email"
          v-model="form.email"
          class="tw-border-2 tw-border-gray-300 tw-p-2 tw-rounded-lg tw-min-w-[350px]"
        />
      </div>
    </div>

    <b-button @click="form.username = form.fullName = form.email.split('@')[0]"
      >Copy from email</b-button
    >

    <div class="columns">
      <div class="column is-2 has-text-grey-light">Username (must be unique)</div>
      <div class="column is-2">
        <input
          type="text"
          v-model="form.username"
          class="tw-border-2 tw-border-gray-300 tw-p-2 tw-rounded-lg tw-min-w-[350px]"
        />
      </div>
    </div>

    <div class="columns">
      <div class="column is-2 has-text-grey-light">Full name</div>
      <div class="column">
        <input
          type="text"
          v-model="form.fullName"
          class="tw-border-2 tw-border-gray-300 tw-p-2 tw-rounded-lg tw-min-w-[350px]"
        />
      </div>
    </div>

    <h3 class="subtitle">Scope</h3>

    <div class="columns">
      <div class="column is-1 has-text-grey-light">Groups</div>
      <div class="column">
        <div>
          <label for="all_groups">
            <input type="checkbox" v-model="form.allGroups" id="all_groups" />
            Access to all groups
          </label>
        </div>
        <Multiselect
          v-show="!form.allGroups"
          v-model="form.groups"
          :options="groups.map((g) => ({ value: g.id, label: `${g.project} / ${g.name}` }))"
          :searchable="true"
          mode="tags"
          class="custom-multiselect"
        >
          <template v-slot:option="{ option }">
            {{ option.label }}
          </template>
        </Multiselect>
      </div>
    </div>
    <div class="columns">
      <div class="column is-1 has-text-grey-light">Partners</div>
      <div class="column">
        <div v-if="type === 'installer'">
          <strong>Select the name of the installation partner</strong>
        </div>
        <div v-else-if="type === 'b2b'">
          <strong>Select the name of the woco</strong>
        </div>
        <div v-else>
          <label for="all_partners">
            <input type="checkbox" v-model="form.allPartners" id="all_partners" />
            Access to all partners
          </label>
        </div>
        <Multiselect
          v-show="!form.allPartners"
          v-model="form.partners"
          :options="partners"
          :searchable="true"
          mode="tags"
          class="custom-multiselect"
        >
          <template v-slot:option="{ option }">
            {{ option.label }}
          </template>
        </Multiselect>
      </div>
    </div>

    <h3 class="subtitle">Permissions</h3>

    <div class="columns">
      <div class="column is-3">
        <Multiselect
          v-if="users"
          v-model="form.copyFromUser"
          :options="users.map((u) => ({ label: u.email, value: u.username }))"
          :searchable="true"
          class="custom-multiselect"
        ></Multiselect>
      </div>
      <div class="column">
        <b-button @click="copyFromUser">Copy from user</b-button>
      </div>
    </div>

    <div class="columns">
      <div class="column is-1 has-text-grey-light">User groups</div>
      <div class="column">
        <Multiselect
          v-model="form.permissions.groups"
          :options="permissionGroups"
          :searchable="true"
          mode="tags"
          class="custom-multiselect"
        ></Multiselect>
      </div>
    </div>

    <div class="columns">
      <div class="column is-1 has-text-grey-light">Roles</div>
      <div class="column">
        <Multiselect
          v-model="form.permissions.roles"
          :options="permissionRoles"
          :searchable="true"
          mode="tags"
          class="custom-multiselect"
        ></Multiselect>
      </div>
    </div>

    <div class="columns">
      <div class="column is-1 has-text-grey-light">Actions</div>
      <div class="column">
        <Multiselect
          v-model="form.permissions.actions"
          :options="permissionActions"
          :searchable="true"
          mode="tags"
          class="custom-multiselect"
        ></Multiselect>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <b-button label="Save" type="is-primary" @click="saveUser" :loading="mutateUserLoading" />
      </div>
      <div class="column" style="color: red" v-if="errors">
        <div>Errors:</div>
        <div v-for="(list, field) in errors" :key="field">
          <div v-for="error in list" :key="error">
            <b>{{ field }}:</b> {{ error }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';
import fetchData from '@/util/fetch';
import { computed, reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import Multiselect from '@vueform/multiselect/dist/multiselect.vue2';

const route = useRoute();

let formDefault = {
  copyFromUser: '',
  email: '',
  username: '',
  fullName: '',
  groups: [],
  allGroups: false,
  partners: [],
  allPartners: false,
  permissions: {
    groups: [],
    roles: [],
    actions: [],
  },
};
if (route.query.type === 'ecorus') {
  formDefault = {
    ...formDefault,
    allGroups: true,
    allPartners: true,
    permissions: {
      ...formDefault.permissions,
      groups: ['erp_users'],
    },
  };
} else if (route.query.type === 'installer') {
  formDefault = {
    ...formDefault,
    allGroups: true,
    permissions: {
      ...formDefault.permissions,
      groups: ['tickets_v2'],
    },
  };
} else if (route.query.type === 'b2b') {
  formDefault = {
    ...formDefault,
    allGroups: true,
    permissions: {
      ...formDefault.permissions,
      groups: ['b2b_external_users'],
    },
  };
}

const form = reactive(formDefault);

// partners
const getPartners = () => fetchData('/tickets/api/v1/partners');
const partnersQuery = useQuery({
  queryKey: ['partners'],
  queryFn: getPartners,
});

// wocos
const wocoPartnersQuery = useQuery({
  queryKey: ['wocos'],
  queryFn: () => fetchData('/api/v1/config/wocos?view=full'),
});

// groups
const getGroups = () => fetchData('/groups/api/v1/group');
const groupsQuery = useQuery({
  queryKey: ['groups'],
  queryFn: getGroups,
});

// permissions
const getPermissions = () => fetchData('/auth/api/v1/permissions');
const permissionsQuery = useQuery({
  queryKey: ['permissions'],
  queryFn: getPermissions,
});

const getUsers = () => fetchData('/auth/api/v1/users?limit=500');
const usersQuery = useQuery({
  queryKey: ['users'],
  queryFn: getUsers,
});

const users = computed(() => usersQuery.data.value?.data);
const partners = computed(() => {
  const ipa = (partnersQuery.data.value?.data || []).map((p) => ({
    value: p.name,
    label: `IPA: ${p.full_name}`,
  }));

  const woco = (wocoPartnersQuery.data.value?.data || [])
    .filter((w) => w.shortname)
    .map((w) => ({ value: w.shortname, label: `Woco: ${w.name}` }));

  if (route.query.type === 'installer') return ipa;
  if (route.query.type === 'b2b') return woco;

  return ipa.concat(woco);
});

const groups = computed(() => groupsQuery.data.value?.data);
const permissions = computed(() => permissionsQuery.data.value?.data);

const permissionGroups = computed(() =>
  permissions.value ? Object.keys(permissions.value.groups) : []
);
const permissionRoles = computed(() =>
  permissions.value ? Object.keys(permissions.value.roles) : []
);
const permissionActions = computed(() => permissions.value?.actions);

const postUser = () => {
  const payload = {
    email: form.email,
    username: form.username,
    full_name: form.fullName,
    scope: {
      partners: form.allPartners ? '*' : form.partners,
      groups: form.allGroups ? '*' : form.groups,
    },
    permissions: form.permissions,
  };

  return fetchData('/auth/api/v1/users', {
    method: 'POST',
    body: JSON.stringify(payload),
  });
};

const queryClient = useQueryClient();
const router = useRouter();
const errors = ref(null);
const { mutate: saveUser, isLoading: mutateUserLoading } = useMutation({
  mutationFn: postUser,
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: ['users'] });
    router.push({ name: 'list-users' });
  },
  onError: (error) => {
    errors.value = error.errors || error;
  },
});

function copyFromUser() {
  const user = users.value.find((u) => u.username === form.copyFromUser);
  if (!user) return;
  const { permissions: p } = user;

  form.permissions.groups = p.groups;
  form.permissions.roles = p.roles;
  form.permissions.actions = p.actions;
}

const type = computed(() => route.query.type);
</script>
