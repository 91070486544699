<script lang="ts">
import { Component } from 'vue-property-decorator';
import BaseButton from './BaseButton.vue';

@Component({
  name: 'AssignPartnerButton',
})
export default class AssignPartnerButton extends BaseButton {
  protected color = 'is-assign';

  get disabled() {
    return (
      (this.masterItem.isLoading || !this.action.enabled)
      || !this.masterItem.actionData.assignedPartner
    );
  }

  protected onClick() {
    if (!this.masterItem) return;

    this.masterItem.setActionData({
      ...this.masterItem.actionData,
    });

    if (this.masterItem.actionData.assignedPartner) {
      this.manager.submitMasterItemAction(this.masterItem, this.action);
    }
  }
}
</script>
