<template>
  <div class="eleena-data">
    <span
      style="padding-bottom: 0.5rem;"
      v-if="!products[0]"><i>No product information available for smartmeter</i></span>
    <div class="eleena-data" v-else>
      <span><b>Id:</b> {{products[0].id}}</span>
      <span><b>Description:</b> {{products[0].description}}</span>
      <span @click="copySerialNumber">
        <b>Serial number:</b> <span
          class="copy-text"
          ref="serialNumber"
        >{{products[0].serialNumbers[0]}}</span>
      </span>
    </div>

    <button
      style="width: 10rem; padding: 0.2rem;"
      class="button is-success is-small"
      :class="{ 'is-loading': isLoading }"
      @click="sendCredentials"
    >Send credentials</button>

    <p v-if="isEmailsLoading">Loading...</p>
    <p v-else-if="emails.length > 0">
      Credentials last send at {{manager.formatDate(new Date(lastEmailSend.mail_timestamp), true)}}
    </p>
    <p v-else><i>No emails send to client</i></p>

    <p style="font-size: 1.1em; text-decoration: underline;">
      <a target="_blank" :href="eleenaUrl">Go to Eleena</a>
    </p>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import request from '@/requests';
import { CallCentreScreenManager } from '@/logic/CallCentreScreen';
import notifications from '@/util/Notifications';

@Component({})
export default class CallCentreEleenaData extends Vue {
  @Prop({}) client!: any;
  @Prop({}) manager!: CallCentreScreenManager;

  private isLoading = false;
  private isEmailsLoading = false;

  private emails = [];

  get lastEmailSend() {
    return this.emails
      .sort((a: any, b: any) => (a.mail_timestamp > b.mail_timestamp ? -1 : 1))
      .filter((mail: any) => mail.event_type === 'Delivery')[0];
  }

  public async setEmails() {
    this.isEmailsLoading = true;

    const response = await request.eleena.getEmailData(this.manager.query);

    if (response.status_code === 200) {
      this.emails = response.data;
    }

    this.isEmailsLoading = false;
  }

  public async sendCredentials() {
    this.isLoading = true;

    const response = await request.eleena.sendCredentials(this.manager.query);

    if (response.status_code === 200) {
      notifications.addNotification({
        message: response.message,
        type: 'success',
      });

      this.setEmails();
    } else {
      notifications.addNotification({
        message: response.message,
        type: 'danger',
      });
    }

    this.isLoading = false;
  }

  public copySerialNumber() {
    const serialNumber = this.products[0].serialNumbers[0];
    const tempElement = document.createElement('input');

    tempElement.value = serialNumber.trim();
    document.body.appendChild(tempElement);
    tempElement.select();

    document.execCommand('copy');
    document.body.removeChild(tempElement);
  }

  private formatProducts(products: any) {
    const newProducts: Array<any> = [];

    Object.entries(products).forEach(([type, value]: Array<any>) => {
      if (!value) return;

      if (type !== 'smartmeter') return;

      Object.entries(value).forEach((prod: Array<any>) => {
        const newProduct = {
          id: '',
          description: '',
          amount: 0,
          serialNumbers: [],
        };

        const { amount } = prod[1];
        const serialNumbers = prod[1].serial_numbers;
        const productType = prod[1].description;
        const id = prod[0];

        if (productType) {
          newProduct.description = productType;
        }
        if (amount) {
          newProduct.amount = amount;
        }
        if (serialNumbers) {
          newProduct.serialNumbers = serialNumbers;
        }
        if (id) {
          newProduct.id = id;
        }

        newProducts.push(newProduct);
      });
    });

    return newProducts;
  }

  get products() {
    let products: Array<any> = [];

    if (
      !this.client.tasks
      || !this.client.tasks.installation
      || !this.client.tasks.installation.tickets
    ) return products;

    Object.values(this.client.tasks.installation.tickets).forEach((ticket: any) => {
      if (!ticket.output || !ticket.output.products) return;
      products = [
        ...products,
        ...this.formatProducts(ticket.output.products),
      ];
    });

    return products;
  }

  get eleenaUrl() {
    const postalcode = this.client.address.postal_code.substring(0, 4).trim();
    const postalcodeExt = this.client.address.postal_code.slice(
      this.client.address.postal_code.length - 2,
    ).trim();
    const houseNumber = this.client.address.house_number;

    // eslint-disable-next-line max-len
    return `https://reporting.eleena.eu/client-overview/?postcode=${postalcode}&postcode_ext=${postalcodeExt}&housenmbr=${houseNumber}`;
  }

  created() {
    this.setEmails();
  }
}
</script>

<style lang="scss" scoped>
.eleena-data {
  display: flex;
  flex-direction: column;

  .copy-text {
    &:hover {
      cursor: pointer;
    }
  }

  & > * {
    b {
      padding-right: 0.3rem;
    }
  }
}
</style>
