import Vue from 'vue';
// @ts-ignore
import { Modal, Button, Input, Field, Icon, ConfigProgrammatic, Select, Table, Dropdown, Autocomplete, Tag } from 'buefy';
import { VueQueryPlugin } from '@tanstack/vue-query';
import '@/directives';
import App from './App.vue';
import router from './router';
import store, { actions } from './store';
import './main.scss';
import 'buefy/dist/buefy.css';

// Use FontAwesome as icon pack for Buefy
ConfigProgrammatic.setOptions({
  defaultIconPack: 'fas',
});

Vue.use(Modal);
Vue.use(Input);
Vue.use(Select);
Vue.use(Button);
Vue.use(Field);
Vue.use(Icon);
Vue.use(Table);
Vue.use(Dropdown);
Vue.use(Autocomplete);
Vue.use(Tag);
Vue.use(VueQueryPlugin);

Vue.config.productionTip = false;

const requireComponent = require.context(
  './components/globals',
  true,
  /.*\.vue$/,
);

requireComponent.keys().forEach((fileName) => {
  // Get the component config
  const componentConfig = requireComponent(fileName);
  // Get the PascalCase version of the component name
  const componentName = fileName
    .split('/')
    .pop()!
    .replace(/^\.\//, '')
    .replace(/\.\w+$/, '')
    .split('-')
    .map((kebab) => kebab.charAt(0).toUpperCase() + kebab.slice(1))
    .join('');

  // Globally register the component
  Vue.component(componentName, componentConfig.default || componentConfig);
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
