<template>
  <info-container title="Maintenance / Malfunction">
    <button
      class="tw-bg-gray-500 tw-rounded tw-p-1 tw-text-white tw-mt-3 tw-w-full tw-flex tw-justify-center"
      @click="createMaintananceTask"
    >
      Create maintanace task
    </button>
  </info-container>
</template>

<script setup>
import InfoContainer from '@/components/shared/InfoContainer.vue';

const props = defineProps({
  selectedTicket: {
    type: Object,
    required: true,
  },
});

const createMaintananceTask = () => {
  console.log('create maintanance task');
};
</script>
