<template>
  <dropdown-slotted>
    <template v-slot:trigger>
      <div class="is-flex is-align-items-center">
        <button-outline
          label="Reject tickets"
          color="is-danger"
        />
        <span class="tag" style="font-size: 0.6rem;">{{ items.length }}</span>
      </div>
    </template>
    <template>
        <input
          class="reject-comment input mb-2"
          type="text"
          placeholder="Reject comment"
          v-model="rejectComment"
          style="width: 100%;"
        >
        <div class="is-flex is-align-items-center">
          <select
            style="font-size: 0.75rem;"
            @change="(event) => selectedRejectType = event.target.value"
          >
            <option
              v-for="option in rejectTypes"
              :selected="option === selectedRejectType"
              :key="`reject-type-${option}`"
            >{{ option }}</option>
          </select>
          <button-outline
            style="margin-left: auto;"
            label="Reject"
            color="is-danger"
            :loading="isLoading"
            :disabled="!enabled"
            @click="submitReject"
          />
        </div>
      <!-- </div> -->
    </template>
  </dropdown-slotted>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import type { TasksScreenManager, MasterItem } from '@/logic/TasksScreen';
import type { CustomResponse, TaskTypeKey } from '@/interfaces';
import requests from '@/requests';
import { actions } from '@/store';
import { notifications } from '@/util';
import { STATUS_OK, STATUS_WARNING } from '@/constants';

interface RejectResponse {
  item: MasterItem;
  response: CustomResponse;
}

@Component({
  name: 'RejectTickets',
})
export default class RejectTickets extends Vue {
  @Prop({}) manager!: TasksScreenManager;
  @Prop({}) items!: Array<{ item: MasterItem; actionURL: string }>;
  @Prop({}) type!: TaskTypeKey;
  @Prop({}) rejectTypes!: Array<string>;
  @Prop({}) updateActions!: Function;

  protected isLoading = false;
  protected selectedRejectType = this.rejectTypes[0];
  protected rejectComment = '';

  get enabled() {
    return !!this.rejectComment;
  }

  protected async submitReject() {
    this.isLoading = true;

    // Prepare and submit reject requests
    const postData = {
      reject_approve_remark: this.rejectComment,
      reject_type: this.selectedRejectType,
    };

    const rejectResponses = await Promise.all(this.items.map((entry) => (async () => {
      const response = await requests.details.updateTaskDetailForClient(entry.actionURL, postData);
      return {
        item: entry.item,
        response,
      };
    })()));

    const itemsSucceeded: Array<RejectResponse> = [];
    const itemsFailed: Array<RejectResponse> = [];

    rejectResponses.forEach((entry) => {
      const list = [STATUS_OK, STATUS_WARNING].includes(entry.response.status)
        ? itemsSucceeded
        : itemsFailed;
      list.push(entry);
    });

    // Update master items that succeeded
    await Promise.all(itemsSucceeded.map((entry) => (
      this.manager.updateTaskDataForItem(entry.item)
    )));

    // Update details pane if it matches a successfully updated master item
    if (this.manager.selectedMasterItem) {
      const { address, detailTaskType } = this.manager.selectedMasterItem;
      const matchingEntry = itemsSucceeded.find((entry) => entry.item.address === address);
      if (matchingEntry && detailTaskType === this.type) {
        this.manager.setSelectedMasterItem(matchingEntry.item, this.type, true);
      }
    }

    this.isLoading = false;

    // Add notifcations
    actions.common.commitNotifications([]);
    notifications.addNotification({
      message: `${itemsSucceeded.length} ticket(s) succesfully updated`,
      type: 'success',
    });

    if (itemsFailed.length > 0) {
      notifications.addNotification({
        message: `${itemsFailed.length} ticket(s) produced an error`,
        type: 'danger',
      });
    }

    this.updateActions();
  }
}

</script>

<style lang="scss">
.reject-comment {
  font-size: 0.6rem !important;
  padding: 0.25rem;
}
</style>
