<template>
  <div class="button-input">
    <button
      class="button is-small m-0"
      data-id="detail-ticket-button"
      :class="[classes]"
      @click="onClick"
      :disabled="disabled"
    >
      <span v-if="required"
        ><b>{{ name }}</b></span
      >
      <span v-else>{{ name }}</span>
    </button>
  </div>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import { STATUS_ERROR, STATUS_WARNING } from '@/constants';
import { notifications } from '@/util';

import DetailsDataComponentBase from './DetailsDataComponentBase.vue';

interface PostData {
  [prop: string]: string | number;
}

@Component({
  name: 'DetailsTicketButton',
})
export default class DetailsTicketButton extends DetailsDataComponentBase {
  @Prop({ default: null }) postData!: PostData;
  @Prop({ default: false }) optionalPostData?: boolean;

  get disabled() {
    if (this.postData && !this.optionalPostData) {
      return Object.values(this.postData).some((value) => !value);
    }

    if (this.enabled !== undefined) {
      return !this.enabled;
    }

    return false;
  }

  get classes() {
    return {
      'is-loading': this.actionStatus.type === 'loading',
      'is-info': ['send', 'resend', 'update', 'create'].includes(this.action!),
      'is-success': ['check', 'approve'].includes(this.action!),
      'is-danger': ['delete', 'reject', 'cancel'].includes(this.action!),
    };
  }

  protected async onClick() {
    // for approve action we show a confirmation dialog before proceeding
    if (this.action === 'approve') {
      if (!confirm('Are you sure you want to approve the ticket?')) return;
    }

    const response = this.postData
      ? await this.submitAction(this.postData)
      : await this.submitAction();

    this.$emit('click', response);

    if ([STATUS_WARNING, STATUS_ERROR].includes(response.status)) {
      notifications.addNotificationFromResponse(response);
    }
  }
}
</script>

<style lang="scss" scoped>
.button-input {
  .button {
    font-size: calc(10px + 0.2vh);
    margin: 0.5rem 0;
    padding: 10px;
    height: calc(23px + 0.2vh);

    &:nth-child(n + 1) {
      margin: 0.5rem 0.1rem;
    }
  }
}
</style>
