/* eslint-disable import/prefer-default-export */
import notifications from './Notifications';

export function copyToClipboard(input: string, notificationMessage?: string) {
  const copy = (event: ClipboardEvent) => {
    event.clipboardData!.setData('text/plain', input);
    event.preventDefault();

    document.removeEventListener('copy', copy);

    if (notificationMessage) {
      notifications.addNotification({
        message: notificationMessage,
        type: 'success',
      });
    }
  };

  document.addEventListener('copy', copy);
  document.execCommand('copy');
}
