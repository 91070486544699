<template>
  <div class="m-5">
    <b-button @click="$router.push({ name: 'new-user', query: { type: 'ecorus' } })" class="mr-2"
      >New Ecorus User</b-button
    >
    <b-button @click="$router.push({ name: 'new-user', query: { type: 'installer' } })"
      >New IPA User</b-button
    >
    <b-button @click="$router.push({ name: 'new-user', query: { type: 'b2b' } })" class="ml-2"
      >New B2B User</b-button
    >

    <div v-if="users">
      <b-table :data="users">
        <b-table-column field="username" label="Username" v-slot="user">
          {{ user.row.username }}
        </b-table-column>
        <b-table-column field="email" label="Email" v-slot="user">
          {{ user.row.email }}
        </b-table-column>
        <b-table-column field="scope" label="Partner scope" v-slot="user">
          <template v-if="user.row.scope.partners == '*'"> All partners </template>
          <template v-else>
            {{ user.row.scope.partners.join(', ') }}
          </template>
        </b-table-column>
        <b-table-column field="scope" label="Group scope" v-slot="user">
          <template v-if="user.row.scope.groups == '*'"> All groups </template>
          <template v-else> {{ user.row.scope.groups.length }} groups </template>
        </b-table-column>
        <b-table-column field="permissions" label="User groups" v-slot="user">
          {{ user.row.permissions.groups.join(', ') }}
        </b-table-column>
        <b-table-column label="Actions" v-slot="props">
          <router-link :to="{ name: 'edit-user', params: { username: props.row.username } }">
            Edit
          </router-link>
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>

<script setup>
import { useQuery } from '@tanstack/vue-query';
import fetchData from '@/util/fetch';
import { computed } from 'vue';

const getUsers = () => fetchData('/auth/api/v1/users?limit=500');
const usersQuery = useQuery({
  queryKey: ['users'],
  queryFn: getUsers,
});

const users = computed(() => usersQuery.data.value && usersQuery.data.value.data);
</script>
