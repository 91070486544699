import type VueRouter from 'vue-router';
import Authentication from './Authentication';

export default class BaseScreenManager {
  protected router: VueRouter;
  protected auth: Authentication;

  constructor(router: VueRouter) {
    this.router = router;
    this.auth = new Authentication(router);
  }
}
