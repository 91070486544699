<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import BaseButtonBulk from './BaseButtonBulk.vue';

@Component({
  name: 'ReopenButtonBulk',
})
export default class ReopenButtonNulk extends BaseButtonBulk {
  // protected label = 'reopen';
  protected color = 'is-info';
}
</script>
