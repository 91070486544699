<template>
  <div class="installation-content">
    <template v-if="hasTicketData && isEcorusTicket">
      <!-- TICKET CANCEL -->
      <div
        v-if="selectedTicket && selectedTicket.actions.cancel_ticket_button"
        class="is-flex is-flex-wrap-wrap"
      >
        <details-ticket-cancellation-box
          :manager="manager"
          :tickets="tickets"
          :selected="selectedTicketIndex"
        />
      </div>

      <!-- TICKET APPROVAL -->
      <div
        v-if="(
          selectedTicket.actions.approve_ticket_button
          || selectedTicket.actions.reject_ticket_button
        )"
        class="is-flex is-flex-wrap-wrap"
      >
        <details-ticket-approval-box
          :manager="manager"
          :tickets="tickets"
          :selected="selectedTicketIndex"
        />
      </div>
    </template>

    <template v-else-if="hasTicketData">
      <div class="box">
        <details-box-heading label="Ticket" />
        <div style="margin-bottom: 0.8rem;">
          <span><b>Installer: </b>{{selectedTicket.installer}}</span><br>
          <span><b>Ticket ID: </b>{{selectedTicket.id}}</span>
        </div>
        <div
          style="padding-bottom: 0.1rem;"
          v-for="[key, value] in getNonEcorusTicketFields" :key="key"
        >
          <span><b>{{formatKey(key)}}:</b> {{value}}</span>
        </div>
        <details-ticket-images
          :manager="manager"
          :images="images"
        />
      </div>
    </template>

    <!-- MAINTENANCE / MALFUNCTION TASK CREATION -->
    <div
      v-if="createTaskButton"
      class="is-flex is-flex-wrap-wrap"
    >
      <div class="box create-task-card">
        <details-box-heading label="Maintenance / Malfunction" />
        <div class="box-content">
          <details-ticket-button
            :component="createTaskButton"
            class="ml-0"
            @click="refreshDetails"
          />
        </div>
      </div>
    </div>

    <!-- C4A UPDATE INFO -->
    <div
      v-if="UpdateInfoButton"
      class="is-flex is-flex-wrap-wrap"
    >
      <div class="box update_info-card">
        <details-box-heading label="Cast4all" />
        <div class="box-content mb-2">
          <p
            v-for="[field, fieldValue] in Object.entries(components.update_info_c4a.c4a_data)"
            :key="`extra-field-${field}`"
          >
            <b>{{ capitalized(field) }}: </b>{{ fieldValue }}
          </p>
        </div>
        <div class="box-content">
          <details-ticket-button
            :component="UpdateInfoButton"
            class="ml-0"
            @click="refreshDetails"
          />
        </div>
      </div>
    </div>

    <!-- ASBUILT -->
    <div v-if="showAsbuiltBox" class="is-flex is-flex-wrap-wrap">
      <div class="box asbuilt-card">
        <details-box-heading label="Asbuilt" />
        <div class="box-content">
          <details-PDF-viewer
            v-if="(
              components.asbuilt
              && components.asbuilt.asbuilt_pdf_viewer
              && components.asbuilt.asbuilt_pdf_viewer.url
            )"
            :title="components.asbuilt.asbuilt_pdf_viewer.name"
            label="Download asbuilt"
            :source="components.asbuilt.asbuilt_pdf_viewer.url"
          />
          <p v-else>Once generated, asbuilt will be shown here.</p>
        </div>
      </div>
    </div>

    <div class="is-flex is-flex-wrap-wrap">
      <!-- TICKETS -->
      <details-ticket-box
        v-if="shouldRenderComponentBox(components.tickets)"
        :manager="manager"
        :tickets="tickets"
        :selected="selectedTicketIndex"
        @change="updateTicketSelection"
      />

      <!-- ELEENA -->
      <details-eleena-box
        v-if="components.eleena_data"
        :manager="manager"
      />
    </div>

    <!-- APPOINTMENT -->
    <div v-if="hasTicketData" class="is-flex is-flex-wrap-wrap">
      <details-ticket-appointment-box
        :ticket="selectedTicket"
        :manager="manager"
      />
    </div>

    <!-- OUTPUT / INPUT -->
    <div class="is-flex is-flex-wrap-wrap">

      <details-installation-output-box
        v-if="hasTicketOutput"
        :output="selectedTicket.ticket.output"
        :manager="manager"
      />

      <details-installation-input-box
        v-if="hasTicketInput"
        :key="selectedTicketIndex"
        :input="selectedTicket.ticket.input"
        :selectedTicket="selectedTicket"
        :manager="manager"
      />

    </div>
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { formatDateTime, formatDateTimeAlt } from '@/util';

import DetailsPaneBase from './DetailsPaneBase.vue';
import DetailsTicketBox from './DetailsTicketBox.vue';
import DetailsTicketAppointmentBox from './DetailsTicketAppointmentBox.vue';
import DetailsInstallationOutputBox from './DetailsInstallationOutputBox.vue';
import DetailsInstallationInputBox from './DetailsInstallationInputBox.vue';
import DetailsTicketImages from './DetailsTicketImages.vue';
import DetailsTicketApprovalBox from './DetailsTicketApprovalBox.vue';
import DetailsTicketStatusCommentBox from './DetailsTicketStatusCommentBox.vue';
import DetailsTicketCancellationBox from './DetailsTicketCancellationBox.vue';
import DetailsEleenaBox from './DetailsEleenaBox.vue';

interface Dictionary {
  [key: string]: Dictionary;
}

@Component({
  name: 'DetailsPaneInstallation',
  components: {
    DetailsTicketBox,
    DetailsTicketAppointmentBox,
    DetailsInstallationOutputBox,
    DetailsInstallationInputBox,
    DetailsTicketApprovalBox,
    DetailsTicketImages,
    DetailsTicketStatusCommentBox,
    DetailsTicketCancellationBox,
    DetailsEleenaBox,
  },
})
export default class DetailsPaneInstallation extends DetailsPaneBase {
  protected selectedTicketIndex: number | null = null;

  get selectedTicket() {
    if (this.selectedTicketIndex === null) return null;

    return this.tickets[this.selectedTicketIndex];
  }

  get tickets() {
    return this.components.tickets;
  }

  get isEcorusTicket() {
    return this.hasTicketData && ['input', 'output', 'appointment', 'status']
      .every((field) => Object.keys(this.selectedTicket.ticket).includes(field));
  }

  get hasTicketData() {
    return !!(this.selectedTicket && this.selectedTicket.ticket);
  }

  get hasTicketOutput() {
    return !!(this.isEcorusTicket && this.selectedTicket.ticket.output);
  }

  get hasTicketInput() {
    return !!(this.hasTicketData && this.selectedTicket.ticket.input);
  }

  get showAsbuiltBox() {
    return !!(
      this.hasTicketData
      && ['approved', 'cancelled', 'rejected'].includes(this.selectedTicket.ticket.current_status)
    );
  }

  get getNonEcorusTicketFields() {
    return Object.entries(this.selectedTicket.ticket)
      .filter((entry: [string, unknown]) => entry[1])
      .filter((entry: [string, unknown]) => (
        !entry[0].toLowerCase().includes('foto')
        && !entry[0].toLowerCase().includes('screenshot')
        && !entry[0].toLowerCase().includes('output')
      ));
  }

  get images() {
    return Object.entries(this.selectedTicket.ticket)
      .filter((entry: [string, unknown]) => entry[0]
        .toLowerCase().includes('foto') || entry[0].toLowerCase().includes('screenshot'))
      .filter((entry: [string, unknown]) => entry[1])
      .map((entry: [string, unknown]) => ({ type: entry[0], url: entry[1] }));
  }

  get createTaskButton() {
    return this.components.create_maintenance_button || this.components.create_malfunction_button;
  }

  get UpdateInfoButton() {
    return this.components.update_info_c4a
      && this.components.update_info_c4a.c4a_update_info_button;
  }

  protected capitalized(str: string) {
    return (str[0].toUpperCase() + str.slice(1)).replace(/([A-Z])/g, ' $1').trim();
  }

  protected formatKey(key: string) {
    let newKey = key.replaceAll('_', ' ');
    newKey = newKey.replace(/([A-Z][a-z])/g, ' $1');
    newKey = newKey.toLowerCase();
    newKey = newKey.charAt(0).toUpperCase() + newKey.slice(1);
    return newKey;
  }

  protected updateTicketSelection(ticketIndex: number) {
    this.selectedTicketIndex = ticketIndex;
  }

  protected formatDateTime(input: string) {
    return formatDateTime(input);
  }

  created() {
    if (this.tickets && this.tickets.length > 0) {
      this.selectedTicketIndex = this.tickets.length - 1;
    }
  }
}

</script>
