<template>
  <div class="sales-data">
    <span class="pb-2"><b>Status:</b> {{client.tasks.sales.status}}</span>
    <span><b>Contract type:</b>  {{client.tasks.sales.contract_type}}</span>
    <span><b>Panel quantity:</b> {{client.tasks.sales.panel_quantity}}</span>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class SalesData extends Vue {
  @Prop({}) client!: object;
}
</script>

<style lang="scss" scoped>
.sales-data {
  display: flex;
  flex-direction: column;

  & > * {
    b {
      padding-right: 0.3rem;
    }
  }
}
</style>
