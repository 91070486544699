<template>
  <div>
    <div v-if="isLoading" class="tw-flex tw-gap-5 tw-items-center">
      <div
        class="tw-border-gray-300 tw-m-5 tw-animate-spin tw-rounded-full tw-border-8 tw-border-t-green-600"
      />
      Loading...
    </div>

    <div v-else>
      <div class="tw-mb-3 tw-flex">
        <h1 class="tw-font-bold tw-tracking-wider tw-uppercase tw-text-gray-500 tw-mt-3">
          Voltage Info
        </h1>
      </div>
      <div v-if="serialNumber.startsWith('I')">
        <div class="tw-flex tw-items-center tw-justify-center tw-mb-4">
          <button @click="$emit('changeDate', -1)" class="tw-mr-2">
            <i class="fas fa-arrow-left tw-text-xl tw-text-blue-500"></i>
          </button>
          <button
            @click="$emit('changeDate', 0)"
            class="tw-bg-blue-500 tw-text-white tw-py-1 tw-px-4 tw-rounded tw-mx-2"
          >
            TODAY
          </button>
          <button @click="$emit('changeDate', 1)" class="tw-ml-2">
            <i class="fas fa-arrow-right tw-text-xl tw-text-blue-500"></i>
          </button>
        </div>

        <div class="tw-flex tw-justify-center tw-font-semibold tw-text-lg tw-mb-4">
          Selected Date: {{ formattedDate }}
        </div>

        <div class="tw-relative tw-max-h-50">
          <SomeLine :data="chartData" :options="chartOptions" />
        </div>
        <h1 class="tw-font-bold tw-tracking-wider tw-uppercase tw-text-gray-500 tw-mt-3 tw-mt-5">
          Summary
        </h1>
      </div>

      <div class="tw-flex tw-justify-between tw-items-center">
        <div>
          <div class="tw-flex tw-mt-2">
            <span class="tw-font-bold tw-mr-2">Meter ID:</span>
            <span>{{ summary.meter_id }}</span>
          </div>
          <div class="tw-flex tw-mt-2">
            <span class="tw-font-bold tw-mr-2">Number of Failures:</span>
            <span>{{ summary.n_failures }}</span>
          </div>
          <div class="tw-flex tw-mt-2">
            <span class="tw-font-bold tw-mr-2">Number of Long Failures:</span>
            <span>{{ summary.n_long_failures }}</span>
          </div>
          <div class="tw-flex tw-mt-2">
            <span class="tw-font-bold tw-mr-2">Number of Voltage Over Limit (Overall):</span>
            <span>{{ summary.n_voltage_over_limit }}</span>
          </div>
          <div class="tw-flex tw-mt-2">
            <span class="tw-font-bold tw-mr-2">Voltage Over Limit L1:</span>
            <span>{{ summary.n_voltage_over_limit_l1 }}</span>
          </div>
          <div class="tw-flex tw-mt-2">
            <span class="tw-font-bold tw-mr-2">Voltage Over Limit L2:</span>
            <span>{{ summary.n_voltage_over_limit_l2 }}</span>
          </div>
          <div class="tw-flex tw-mt-2">
            <span class="tw-font-bold tw-mr-2">Voltage Over Limit L3:</span>
            <span>{{ summary.n_voltage_over_limit_l3 }}</span>
          </div>
          <div class="tw-flex tw-mt-2">
            <span class="tw-font-bold tw-mr-2">Voltage Missing L1:</span>
            <span>{{ summary.n_voltage_missing_l1 || 'N/A' }}</span>
          </div>
          <div class="tw-flex tw-mt-2">
            <span class="tw-font-bold tw-mr-2">Last Long Failure Duration (hours):</span>
            <span>{{ (summary.last_long_failure_duration / 60 / 60).toFixed(2) }}</span>
          </div>
          <div class="tw-flex tw-mt-2">
            <span class="tw-font-bold tw-mr-2">Time Threshold for Long Failure (minutes):</span>
            <span>{{ summary.time_threshold_for_long_failure / 60 }}</span>
          </div>
          <div class="tw-flex tw-mt-2">
            <span class="tw-font-bold tw-mr-2">Last Power Check:</span>
            <span>{{ formatDate(summary.last_power_check) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, defineEmits } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import fetchData from '@/util/fetch';
import { format, parseISO, addDays } from 'date-fns';
import { Line as SomeLine } from 'vue-chartjs';
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Tooltip, Legend);

const props = defineProps({
  serialNumber: {
    type: String,
    required: true,
  },
  selectedDate: {
    type: Date,
    required: true,
  },
});

const emit = defineEmits(['changeDate']);

const selectedDate = computed(() => props.selectedDate);
const formattedDate = computed(() => format(selectedDate.value, 'yyyy-MM-dd'));

const fetchVoltageData = async () => {
  const dateFrom = format(new Date(selectedDate.value), 'yyyy-MM-dd');
  const dateUntil = format(new Date(addDays(selectedDate.value, 1)), 'yyyy-MM-dd');
  const urlParams = new URLSearchParams({ date_from: dateFrom, date_until: dateUntil });
  const result = await fetchData(`/eleena/api/v1/meters/${props.serialNumber}/power?${urlParams}`);
  return result?.status === 'OK' ? result.data : [];
};

const { data: voltageData, isLoading } = useQuery({
  queryKey: computed(() => ['voltageData', props.serialNumber, formattedDate.value]),
  queryFn: () => fetchVoltageData(props.serialNumber),
  refetchOnWindowFocus: false,
  keepPreviousData: true,
});

const formatDate = (dateString) => format(new Date(Date.parse(dateString)), 'yyyy-MM-dd HH:mm:ss');

const summary = computed(() => voltageData?.value.power_failure_summary[0]);
const profile = computed(() => voltageData?.value.power_quality_profile);

const datesArray = computed(() =>
  profile.value?.map((item) => {
    const parsedDate = parseISO(item.date);
    return `${String(parsedDate.getUTCHours()).padStart(2, '0')}:${String(
      parsedDate.getUTCMinutes()
    ).padStart(2, '0')}`;
  })
);

const mainDataValues = computed(() => profile.value?.map((item) => item.avg_voltage_l1));

const chartData = computed(() => ({
  labels: datesArray.value,
  datasets: [
    {
      label: 'Voltage',
      backgroundColor: 'rgba(75, 192, 192, 0.2)',
      borderColor: 'rgba(75, 192, 192, 1)',
      borderWidth: 2,
      pointBackgroundColor: 'rgba(75, 192, 192, 1)',
      pointRadius: 3,
      fill: true,
      data: mainDataValues.value,
      tension: 0.4,
    },
  ],
}));

const chartOptions = {
  responsive: true,
  maintainAspectRatio: true,
  plugins: {
    legend: {
      display: true,
    },
    title: {
      display: false,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};
</script>
