<template>
  <div class="m-5">
    <div class="mb-5">
      <router-link :to="{ name: SCREEN_PROJECTS }">Terug naar projecten</router-link>
    </div>
    <div v-if="exactProjectOptions">
      <div class="columns is-vcentered">
        <div class="column has-text-grey is-one-fifth">
          <label for="project-installers">Exact project</label>
        </div>
        <div class="column">
          <Multiselect
            v-model="form.exactProject"
            :options="exactProjectOptions"
            :searchable="true"
            class="custom-multiselect"
          />
        </div>
      </div>
      <div v-if="!form.exactProject">
        De meest recente projecten (60) uit Exact waarvoor nog geen project bestaat worden getoond.
      </div>
    </div>
    <div v-else>
      Exact projecten laden...
    </div>
    <div style="max-width: 800px;" class="mt-5" v-if="form.exactProject">
      <section>
        <div class="columns">
          <div class="column is-one-fifth has-text-grey">
            Exact code
          </div>
          <div class="column">
            {{ form.exactProject }}
          </div>
        </div>
        <div class="columns">
          <div class="column has-text-grey is-one-fifth">
            Naam
          </div>
          <div class="column">
            {{ removeDashes(exactProject.name) }}
          </div>
        </div>
        <div class="columns">
          <div class="column has-text-grey is-one-fifth">
            Jaar
          </div>
          <div class="column">
            <input type="number" min="2020" max="2030" v-model="form.year" class="tw-border tw-border-gray-300 tw-p-2 tw-rounded-sm" />
          </div>
        </div>
        <div class="columns">
          <div class="column has-text-grey is-one-fifth">
            Woco
          </div>
          <div class="column">
            <Multiselect
              v-model="form.woco"
              :options="wocoOptions"
              :searchable="true"
              class="custom-multiselect"
            />
          </div>
        </div>
        <div class="columns">
          <div class="column has-text-grey is-one-fifth">
            Type
          </div>
          <div class="column">
            <select v-model="form.type">
              <option value="hoogbouw">hoogbouw</option>
              <option value="laagbouw">laagbouw</option>
            </select>
          </div>
        </div>
        <div class="columns">
          <div class="column has-text-grey is-one-fifth">
            <label for="monitoring">
              Monitoring</label>
          </div>
          <div class="column">
            <select id="monitoring" v-model="form.monitoring">
              <option
                v-for="option in monitoringOptions"
                :value="option.value"
                :key="option.value"
                :selected="option.value === form.monitoring"
              >
                {{ option.label }}
              </option>
            </select>
          </div>
        </div>
        <div class="columns">
          <div class="column has-text-grey is-one-fifth">
            <label for="customer-survey">
              Klantonderzoek</label>
          </div>
          <div class="column">
            <input type="checkbox" id="customer-survey" v-model="form.customerSurvey" />
          </div>
        </div>
        <div class="columns">
          <div class="column has-text-grey is-one-fifth">
            <label for="enable-c4a">
              C4a meters</label>
          </div>
          <div class="column">
            <input type="checkbox" id="enable-c4a" v-model="form.c4aMeters" />
          </div>
        </div>
        <div class="columns is-vcentered">
          <div class="column has-text-grey is-one-fifth">
            <label for="project-installers">Installateurs</label>
          </div>
          <div class="column">
            <Multiselect
              v-model="form.installers"
              :options="installerOptions"
              mode="tags"
              :searchable="true"
              tag-position="top"
              open-direction="top"
              :options-limit="2"
              class="custom-multiselect"
            />
          </div>
        </div>
        <div class="columns is-vcentered">
          <div class="column has-text-grey is-one-fifth">
            <label for="project-installers">Proces</label>
          </div>
          <div class="column">
            <Multiselect
              v-model="form.process"
              :options="processOptions"
              :searchable="true"
              class="custom-multiselect"
            />
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <b-button label="
Opslaan" type="is-primary" @click="saveProject" />
          </div>
          <div class="column" style="color: red;" v-if="errors">
            <div>Errors:</div>
            <template v-if="typeof errors === 'object'">
              <div v-for="list, field in errors" :key="field">
                <div v-for="error in list" :key="error">
                  <b>{{ field }}:</b> {{ error }}
                </div>
              </div>
            </template>
            <div v-else>
              {{ errors }}
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script setup>
import fetchData from '@/util/fetch';
import Multiselect from '@vueform/multiselect/dist/multiselect.vue2';
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';
import { useRouter } from 'vue-router/composables';
import { computed, reactive, ref, watchEffect } from 'vue';
import { SCREEN_PROJECTS, monitoringOptions } from '@/constants'

const queryClient = useQueryClient();
const router = useRouter()
const form = reactive({
  type: 'laagbouw',
  year: '2024',
  exactProject: null,
  installers: null,
  customerSurvey: null,
  c4aMeters: null,
  process: null,
  woco: null,
  monitoring: 'monitoring_maintenance',
});

const errors = ref(null);

const getPartners = () => fetchData('/api/v1/tickets/partners');
const partners = useQuery({
  queryKey: ['partners'],
  queryFn: getPartners,
});
const installerOptions = computed(() => partners.data.value?.data.map(p => p.legacy_name));

const getExactProjects = () => fetchData('/config/api/v1/exact-projects/');
const exactProjects = useQuery({
  queryKey: ['exact-projects'],
  queryFn: getExactProjects,
});
const exactProjectOptions = computed(() => exactProjects.data.value?.data.map(p => ({
  label: p.name,
  value: p.code,
})));

// Fetch projects and filter the desired project
const getWocos = () => fetchData('/config/api/v1/wocos/?view=full');
const wocos = useQuery({
  queryKey: ['wocos'],
  queryFn: getWocos,
});
const wocoOptions = computed(() => wocos.data.value?.data.map(w => ({
  label: w.name,
  value: w.id,
})))

const getProcesses = () => fetchData('/config/api/v1/project-processes/');
const processes = useQuery({
  queryKey: ['project-processes'],
  queryFn: getProcesses,
});
const processOptions = computed(() => processes.data.value?.data.map(p => ({
  label: p.name,
  value: p.id,
})));

const exactProject = computed(() => {
  if (form.exactProject) {
    return exactProjects.data.value?.data.find(p => p.code === form.exactProject)
  }
  return null
});
watchEffect(() => {
  if (exactProject.value) {
    const name = String(exactProject.value?.name)

    if (name.includes('-HB-') || name.includes('-CVZ-')) {
      form.type = 'hoogbouw'
    }
    else {
      form.type = 'laagbouw'
    }

    form.year = extractYear(name)

    const woco = wocoOptions.value?.find(w => name.includes(w.label))
    console.log(wocoOptions.value[0], woco)
    if (woco) {
      form.woco = woco.value
    }
  }
})

function removeDashes(name) {
  // this is to make the project names from exact slightly more readable
  return name
    .replace(/^([^-]+)-([0-9]+)-(HB|LB|ELO|CVZ)-([0-9]{1,3})-/, '$1 $2 $3 $4 ')
    .replace(/^([^-]+)-([0-9]+)-(HB|LB|ELO|CVZ)-([0-9]{1,3})/, '$1 $2 $3 $4')
}

function extractYear(name) {
  return name.replace(/(.*)(20[0-9]{2})(.*)/, '$2')
}

const postProject = () => {
  const payload = {
    name: removeDashes(exactProject.value.name),
    exact_id: form.exactProject,
    installers: form.installers,
    customer_survey: form.customerSurvey,
    c4a_meters: form.c4aMeters,
    process: form.process,
    year: form.year,
    type: form.type,
    woco: form.woco,
    monitoring: form.monitoring,
  };

  return fetchData('/config/api/v1/projects/', {
    method: 'POST',
    body: JSON.stringify(payload),
  });
};

const { mutate: saveProject } = useMutation({
  mutationFn: postProject,
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: ['projects'] });
    router.push({name: SCREEN_PROJECTS})
  },
  onError: (error) => {
    errors.value = error.errors || 'Server error';
  },
});
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
