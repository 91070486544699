<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import BaseButtonBulk from './BaseButtonBulk.vue';

@Component({
  name: 'BaseButtonBulk',
})
export default class ApproveButtonNulk extends BaseButtonBulk {
  // protected label = 'approve';
  protected color = 'is-success';
}
</script>
