<template>
  <div class="range-picker is-flex is-align-items-center">
    <span class="mx-1">from</span>
    <week-picker
      :selected="start"
      :hide-button="true"
      @change="(weekSelection) => onWeekSelectionChange(weekSelection, 'start')"
    />
    <span class="mx-1">until</span>
    <week-picker
      :selected="end"
      :hide-button="true"
      @change="(weekSelection) => onWeekSelectionChange(weekSelection, 'end')"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { isAfter } from 'date-fns';
import type { SelectedWeek } from '@/interfaces';

@Component({
  name: 'WeekRangePicker',
})
export default class WeekRangePicker extends Vue {
  @Prop({ default: null }) start!: SelectedWeek | null;
  @Prop({ default: null }) end!: SelectedWeek | null;

  protected onWeekSelectionChange(weekSelection: SelectedWeek, position: 'start' | 'end') {
    const weekRange = { start: this.start, end: this.end };
    weekRange[position] = weekSelection;
    if (weekRange.start && weekRange.end) {
      if (isAfter(weekRange.start.start, weekRange.end.start)) {
        const { start, end } = weekRange;
        weekRange.start = end;
        weekRange.end = start;
      }
    }

    this.$emit('change', weekRange);
  }
}
</script>

<style lang="scss" scoped>
.range-picker > span {
  font-size: 0.7rem;
}
</style>
