<template>
  <div v-if="isLoading" class="tw-flex tw-gap-5 tw-items-center">
    <div
      class="tw-border-gray-300 tw-m-5 tw-h-10 tw-w-10 tw-animate-spin tw-rounded-full tw-border-8 tw-border-t-green-600"
    />
    Loading...
  </div>
  <div v-else-if="error || meterDetail.status === 'error'">
    Something went wrong or meter does not exist.
    {{ error }}
  </div>
  <div v-else class="tw-mb-10">
    <meter-status :serial-number="serialNumber || ''" />
    <line-chart
      :serial-number="serialNumber || ''"
      :selectedDate="selectedDate"
      @changeDate="changeDate"
    />
    <div class="tw-bg-white tw-drop-shadow-xl tw-rounded-lg tw-p-5 tw-mb-5">
      <button
        v-if="showVoltage === false"
        class="tw-bg-blue-500 hover:tw-bg-blue-400 tw-text-white tw-rounded tw-py-2 tw-px-4 tw-font-semibold tw-ml-5 disabled:tw-opacity-50 disabled:tw-cursor-not-allowed"
        @click="showVoltage = true"
      >
        Check voltage info
      </button>
      <voltage-info
        v-if="showVoltage"
        :serial-number="serialNumber || ''"
        :selectedDate="selectedDate"
        @changeDate="changeDate"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router/composables';
import { useQuery } from '@tanstack/vue-query';
import { notifications } from '@/util';
import fetchData from '@/util/fetch';
import { addDays, startOfToday } from 'date-fns';
import MeterStatus from './MeterStatus.vue';
import LineChart from './LineChart.vue';
import VoltageInfo from './VoltageInfo.vue';

const route = useRoute();

const selectedDate = ref(startOfToday());
const showVoltage = ref(false);
const serialNumber = computed(() => route.params?.id);

const changeDate = (days) => {
  selectedDate.value = days === 0 ? startOfToday() : addDays(selectedDate.value, days);
};

const {
  data: meterDetail,
  isLoading,
  error,
} = useQuery({
  queryKey: computed(() => ['meterDetail', serialNumber.value]),
  queryFn: async () => {
    try {
      const result = await fetchData(`/eleena/api/v1/meters/${serialNumber.value}`);
      if (result.status !== 'OK' || !result) {
        notifications.addNotification({
          message: `Failed to get the meter: ${result.statusText}`,
          type: 'danger',
        });
        return;
      }

      return result?.data;
    } catch (error) {
      notifications.addNotification({
        message: `Failed to get the meter, ${e.message}`,
        type: 'danger',
      });
      throw new Error('Failed to fetch meter data');
    }
  },
  refetchOnWindowFocus: false,
});

watch(
  () => serialNumber.value,
  () => {
    showVoltage.value = false;
  }
);
</script>
