<template>
  <div class="is-flex is-align-items-center">
    <master-data-filter :manager="manager" type="sales" />
    <!-- SIGNED AT DATE RANGE -->
    <div class="is-flex is-align-items-center mr-2">
      <span class="mr-1">Signed at:</span>
      <dropdown-slotted min-width="0" margin-top="0.6rem">
        <template v-slot:trigger>
          <i class="far fa-calendar-alt has-text-white"></i>
        </template>

        <template v-slot:default="{ close }">
          <div class="has-text-black">
            <div class="is-flex is-align-items-center">
              <small-tag
                label="apply"
                margin="2px 4px"
                @click="() => onApplyStatusAt(close)"
              />
              <small-tag
                label="clear"
                margin="2px 0"
                color="is-info"
                @click="() => onClearStatusAt(close)"
              />
            </div>
            <date-range-picker
              :start="statusAt.start"
              :end="statusAt.end"
              @change="(range) => statusAt = range"
            />
          </div>
        </template>
      </dropdown-slotted>
    </div>

    <!-- DATA TOTALS -->
    <data-box v-if="totals.contract_type">
      <p class="data-box-type">Contract type</p>
      <div class="is-flex">
        <span
          v-for="[type, value] in Object.entries(totals.contract_type)"
          :key="`contract-type-${type}`"
          class="data-box-value"
        >
          {{ type }}: {{ value }}
        </span>
      </div>
    </data-box>
  </div>
</template>

<script lang="ts">
import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';

import { TASK_TYPE_SALES } from '@/constants';
import type { TasksScreenManager } from '@/logic/TasksScreen';
import MasterDataFilter from './MasterDataFilter.vue';

@Component({
  name: 'TableHeadDataSales',
  components: {
    MasterDataFilter,
  },
})
export default class TableHeadDataSales extends Vue {
  @Prop() manager!: TasksScreenManager;

  protected statusAt = {
    start: '',
    end: '',
  }

  get filter() {
    return this.manager.list.filter!;
  }

  get totals() {
    const dataFields: Array<string> = ['contract_type'];
    const data = this.manager.generateTaskDataStatistics(TASK_TYPE_SALES, dataFields);
    return data;
  }

  protected onClearStatusAt(closeCallback: Function) {
    this.statusAt = { start: '', end: '' };
    this.filter.setState({ salesSignedAt: this.statusAt });
    closeCallback();
  }

  protected onApplyStatusAt(closeCallback: Function) {
    this.filter.setState({ salesSignedAt: this.statusAt });
    closeCallback();
  }
}

</script>
