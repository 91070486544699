<template>
  <div class="tw-bg-white tw-drop-shadow-xl tw-rounded-lg tw-p-5 tw-mb-5">
    <div>
      <div v-if="!meterLoading">
        <div class="tw-flex tw-justify-between tw-items-center">
          <div class="tw-flex tw-items-center">
            <span class="tw-font-semibold tw-text-gray-700">
              {{ serialNumber }} {{ meterDetail?.status ? meterDetail?.status : '' }}
            </span>
            <div
              class="tw-w-4 tw-h-4 tw-ml-3 tw-rounded-full"
              :class="{
                'tw-bg-gray-500': isFetching || isLoading,
                'tw-bg-red-500': !isLoading && !isFetching && isAlive === false,
                'tw-bg-green-500': !isLoading && !isFetching && isAlive === true,
              }"
            ></div>
            <button
              class="tw-bg-blue-500 hover:tw-bg-blue-400 tw-text-white tw-rounded tw-py-1 tw-px-2 tw-font-semibold tw-ml-5 disabled:tw-opacity-50 disabled:tw-cursor-not-allowed"
              @click="refetch"
              :disabled="isFetching"
            >
              <span v-if="isFetching">Checking...</span>
              <span v-else>Ping Meter</span>
            </button>
            <button
              class="tw-bg-blue-500 hover:tw-bg-blue-400 tw-text-white tw-rounded tw-py-1 tw-px-2 tw-font-semibold tw-ml-5 disabled:tw-opacity-50 disabled:tw-cursor-not-allowed"
              @click="manualPolling"
              :disabled="isLoadingPollingDetail"
            >
              <span v-if="isLoadingPollingDetail">Checking...</span>
              <span v-else>Poll Meter</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="tw-flex tw-justify-between tw-items-center">
      <div>
        <div class="tw-flex tw-mt-2">
          <span class="tw-font-bold tw-mr-2">Date activated </span>
          <span>{{ formatDate(meterDetail.date_activated) }}</span>
        </div>
        <div class="tw-flex tw-mt-2">
          <span class="tw-font-bold tw-mr-2">Last successfull connection</span>
          <span>{{ formatDate(meterDetail.last_successful_communication) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query';
import fetchData from '@/util/fetch';
import { formatDate, notifications } from '@/util';

const props = defineProps({
  serialNumber: {
    type: String,
    required: true,
  },
});

const queryClient = useQueryClient();

const {
  data: isAlive,
  isLoading,
  isFetching,
  refetch,
} = useQuery({
  queryKey: computed(() => ['healthCheck', props.serialNumber]),
  queryFn: async () => {
    try {
      const result = await fetchData(`/eleena/api/v1/meters/${props.serialNumber}/ping`, {
        method: 'POST',
      });
      if (result.status !== 'OK' || !result) {
        notifications.addNotification({
          message: `Failed to get meter status: ${result.statusText || 'Unknown error'}`,
          type: 'danger',
        });
        return;
      }
      return result?.data?.alive || false;
    } catch (e) {
      notifications.addNotification({
        message: `Failed to get meter status: ${e.message}`,
        type: 'danger',
      });
      throw new Error('Failed to fetch meter status');
    }
  },
  enabled: false,
  refetchOnWindowFocus: false,
});

const { data: meterDetail, isLoading: meterLoading } = useQuery({
  queryKey: computed(() => ['meterDetail', props.serialNumber]),
  queryFn: async () => {
    try {
      const result = await fetchData(`/eleena/api/v1/meters/${props.serialNumber}`);
      if (result.status !== 'OK' || !result) {
        notifications.addNotification({
          message: `Failed to get the meter: ${result.statusText}`,
          type: 'danger',
        });
        return;
      }
      return result?.data;
    } catch (error) {
      notifications.addNotification({
        message: `Failed to get the meter, ${e.message}`,
        type: 'danger',
      });
      throw new Error('Failed to fetch meter data');
    }
  },
  refetchOnWindowFocus: false,
});

const pollMeter = async () =>
  fetchData(`/eleena/api/v1/meters/${props.serialNumber}/manual-poll`, {
    method: 'POST',
  });

const { mutate: manualPolling, isLoading: isLoadingPollingDetail } = useMutation({
  mutationFn: pollMeter,
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: ['meterGraph'] });
    notifications.addNotification({
      message: `We fetched the latest data`,
      type: 'success',
    });
  },
  onError: (e) => {
    notifications.addNotification({
      message: `Failed to poll the meter, ${e.message}`,
      type: 'danger',
    });
    throw new Error('Failed to fetch poll data');
  },
});
</script>
