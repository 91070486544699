<template>
  <dropdown-slotted v-if="hasActions">
    <template v-slot:trigger>
      <span>Actions</span>
      <span>
        <i class="fas fa-angle-down pl-2" aria-hidden="true"></i>
      </span>
    </template>

    <template v-slot:default>
      <div style="color: #111;">
        <component
          v-for="action in specialActions"
          :key="action.key"
          :is="action.component"
          v-bind="action.props"
        />
        <row-actions
          :manager="manager"
          :type="type"
        />
      </div>
    </template>
  </dropdown-slotted>
  <span v-else>Actions</span>
</template>

<script lang="ts">
import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';

import { RowActions } from '@/components/TasksScreen/actions';

import type { TasksScreenManager } from '@/logic/TasksScreen';
import type { TaskTypeKey } from '@/interfaces';

interface SpecialAction {
  key: string;
  component: typeof Vue | string;
  props: { [key: string]: unknown };
}

@Component({
  name: 'TableHeadActionsBase',
  components: { RowActions },
})
export default class TableHeadActionsBase extends Vue {
  @Prop() manager!: TasksScreenManager;
  @Prop() type!: TaskTypeKey;

  protected specialActions: Array<SpecialAction> = [];

  protected actions = [];

  get hasActions() {
    const actions = this.manager.getActionsForSelectedItems(this.type);
    return !!Object.keys(actions).length || !!this.specialActions.length;
  }

  get selectedMasterItem() {
    return this.manager.selectedMasterItem;
  }

  get selectedItems() {
    return this.manager.list.selectedItems;
  }

  @Watch('selectedMasterItem')
  onSelectedMasterItemChange() {
    this.updateSpecialActions();
  }

  @Watch('selectedItems')
  onSelectedItemsChange() {
    this.updateSpecialActions();
  }

  protected async updateSpecialActions() {
    const specialActions = [] as Array<SpecialAction>;
    this.specialActions = specialActions;
  }
}
</script>
