var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"box tickets"},[_c('details-box-heading',{attrs:{"label":"Tickets"}}),_c('div',{staticClass:"is-flex is-align-items-center"},[(_vm.selectedTicket)?[_c('span',{staticStyle:{"margin-right":"0.25rem"}},[_vm._v("ID:")]),(!_vm.hasMultipleTickets)?_c('span',{staticClass:"tag",staticStyle:{"margin-right":"0.25rem"}},[_vm._v(" "+_vm._s(_vm.selectedTicket.id)+" ")]):_c('select',{staticClass:"tw-border",on:{"change":_vm.onTicketSelectionChange}},_vm._l((_vm.tickets.map((ticket) => ticket.id)),function(id){return _c('option',{key:`ticket-${id}`,domProps:{"selected":_vm.selectedTicket && _vm.selectedTicket.id === id}},[_vm._v(" "+_vm._s(id)+" ")])}),0),_c('i',{staticClass:"fas fa-copy is-clickable copy-to-clipboard",attrs:{"title":"Copy ticket ID"},on:{"click":() =>
            _vm.copyToClipboard(
              _vm.selectedTicket.id,
              `Ticket ID ${_vm.selectedTicket.id} copied to clipboard`
            )}}),(_vm.hasRefreshTicketButton)?_c('details-ticket-button',{staticClass:"ml-1",attrs:{"field":"refresh_ticket_button","component":this.components.ticket_info.refresh_ticket_button},on:{"click":_vm.refreshDetails}}):_vm._e(),_c('span',{staticStyle:{"margin-left":"1rem","font-size":"0.65rem","font-style":"italic"}},[_vm._v(" Last refresh: "+_vm._s(_vm.taskLastRefresh)+" ")])]:_vm._e(),(['maintenance'].includes(_vm.type))?[_c('div',{staticClass:"button-input"},[_c('button',{staticClass:"button thin-button is-small is-info m-1",on:{"click":() => _vm.$emit('new-ticket', null)}},[_vm._v(" New Ticket ")])]),(
          _vm.hasTicket &&
          !_vm.selectedTicket.error &&
          ['created', 'planned'].includes(_vm.selectedTicket.ticket.current_status)
        )?_c('div',{staticClass:"button-input"},[_c('button',{staticClass:"button thin-button is-small is-info m-1",on:{"click":() => _vm.$emit('change-installer', null)}},[_vm._v(" Change installer ")])]):_vm._e()]:_vm._e()],2),(_vm.hasTicket && !_vm.selectedTicket.error)?[_c('details-static-data-field',{staticClass:"my-1",attrs:{"field":"Created","value":_vm.formatDate(_vm.ticketCreatedAt)}}),_c('details-static-data-field',{staticClass:"my-1",attrs:{"field":"Installer","value":_vm.selectedTicket.ticket.partner}}),_c('details-static-data-field',{staticClass:"my-1",attrs:{"field":"Status"}},[_c('status-label',{staticStyle:{"margin":"0"},attrs:{"label":_vm.selectedTicket.ticket.current_status.replaceAll('_', ' ')}})],1),(_vm.currentStatusRemark)?_c('details-static-data-field',{staticClass:"my-1",attrs:{"field":"Remarks","value":_vm.currentStatusRemark,"wrap":true}}):_vm._e()]:(_vm.hasTicket)?_c('p',{staticClass:"mt-2"},[_c('i',{staticClass:"fas fa-exclamation-triangle has-text-danger"}),_vm._v(" "+_vm._s(_vm.selectedTicket.error)+" ")]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }