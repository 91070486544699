<template>
  <div class="box ticket-status-comment">
    <h4 class="label group-heading">
      Reason for {{
        selectedTicket.ticket.current_status === 'rejected' ? 'rejection' : 'cancellation'
      }}
    </h4>
    <p>{{ selectedTicketStatusData.comment || 'Missing comment' }}</p>
  </div>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import DetailsPaneBase from './DetailsPaneBase.vue';

interface Ticket {
  id: string;
  actions: { [prop: string]: unknown };
  ticket: { [prop: string]: unknown };
}

@Component({
  name: 'DetailsTicketStatusCommentBox',
})
export default class DetailsTicketStatusCommentBox extends DetailsPaneBase {
  @Prop({ default: [] }) tickets!: Array<Ticket>;
  @Prop({ }) selected!: number;

  get selectedTicket() {
    if (this.selected === null) return null;

    return this.tickets[this.selected];
  }

  get selectedTicketStatusData() {
    if (!this.selectedTicket) return {};
    const statuses = this.selectedTicket.ticket.status as Array<unknown>;
    return statuses[statuses.length - 1];
  }
}

</script>
