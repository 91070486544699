<template>
  <button
    class="button"
    :class="[color, !loading && 'is-outlined', loading && 'is-loading']"
    @click="$emit('click', $event)"
    :disabled="disabled"
  >
    {{ label }}
  </button>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class ButtonOutline extends Vue {
  @Prop({ default: 'button' }) label!: string;
  @Prop({ default: 'is-primary' }) color!: string;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: false }) loading!: boolean;
}
</script>

<style scoped lang="scss">
.button {
  font-size: 0.55rem !important;
  padding: 0.3rem;
  margin-right: 0.25rem;
  height: 1rem;
}

</style>
