<template>
  <div class="week-calendar">
    <div
      class="calendar-controls is-flex is-justify-content-center is-align-items-center"
      v-outer-click="() => isCollapsed = true"
    >
      <i class="calendar-control fas fa-chevron-left" @click="prevMonth()"></i>
      <div class="calendar-selection" @click="toggleCollapse">{{ controller.activeSelection }}</div>
      <i class="calendar-control fas fa-chevron-right" @click="nextMonth()"></i>
      <!-- MONTH / YEAR SELECTION DROPDOWN -->
      <div
        v-show="!isCollapsed"
        class="calendar-dropdown"
      >
        <div class="months">
          <p
            v-for="month in months"
            :key="month"
            class="calendar-option"
            :class="month === controller.getMonth(controller.activeMonth) && 'selected'"
            @click="() => onMonthChange(month)"
          >
            {{ month }}
          </p>
        </div>
        <div class="years">
          <p
            v-for="year in years"
            :key="year"
            class="calendar-option"
            :class="year === controller.activeYear && 'selected'"
            @click="() => onYearChange(year)"
          >
            {{ year }}
          </p>
        </div>
      </div>
    </div>
    <!-- CALENDAR GRID -->
    <div class="calendar-grid is-flex is-flex-wrap-wrap">
      <!-- HEADERS -->
      <div class="calendar-headers is-flex">
        <div
          v-for="header in headers"
          :key="`heading-${header}`"
          class="calendar-heading"
        >
          {{ header }}
        </div>
      </div>
      <!-- DATE ROWS -->
      <div
        v-for="[week, days] in calendarDays"
        :key="`week-${week}`"
        class="is-flex week-row"
        :class="[
          isCurrentWeek(+week) && 'current',
          isSelectedWeek(days) && 'selected',
        ]"
        @click="() => onSelectWeek(week)"
      >
        <div
          class="week week-row-item"
          :class="isCurrentWeek(+week) && 'current'"
        >
          {{ week }}
        </div>
        <div
          v-for="{ date, day } in days"
          :key="`week-${week}-${day}`"
          class="calendar-day week-row-item"
          :class="[
            (isCurrentWeek(+week) && day === currentDay) && 'current',
            !isActiveMonth(date) && 'fade'
          ]"
        >
          {{ day }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { WeekCalendarController } from '@/logic/common';
import { isSameDay } from 'date-fns';
import type { SelectedWeek } from '@/interfaces';

@Component({
  name: 'WeekCalendar',
})
export default class WeekCalendar extends Vue {
  @Prop({ default: null }) selectedWeek!: SelectedWeek | null;

  protected controller = new WeekCalendarController(
    this.selectedWeek ? this.selectedWeek.start : null,
  );

  protected isCollapsed = true;

  get headers() {
    return this.controller.calendarHeaders;
  }

  get calendarDays() {
    return Array.from(this.controller.calendarDays);
  }

  get currentWeek() {
    return this.controller.currentWeek;
  }

  get currentDay() {
    return this.controller.currentDay;
  }

  get months() {
    return this.controller.months;
  }

  get years() {
    return this.controller.years;
  }

  get nextMonth() {
    return this.controller.nextMonth;
  }

  get prevMonth() {
    return this.controller.prevMonth;
  }

  protected isCurrentWeek(week: number) {
    return (
      week === this.currentWeek
      && this.controller.currentYear === this.controller.activeYear
    );
  }

  protected isSelectedWeek(days: Array<{ date: Date; day: number }>) {
    return this.selectedWeek && isSameDay(days[0].date, this.selectedWeek.start);
  }

  protected isActiveMonth(date: Date) {
    return this.controller.isActiveMonth(date);
  }

  protected onMonthChange(month: string) {
    this.controller.setActiveMonth(month);
  }

  protected onYearChange(year: number) {
    this.controller.setActiveYear(year);
  }

  protected toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }

  protected onSelectWeek(week: number) {
    const selectedWeek = this.controller.createSelectedWeekObject(week);
    this.$emit('change', selectedWeek);
  }
}
</script>

<style lang="scss" scoped>
$border-color: #ccc;
$current-color: #486f14;

%border-style {
  border-left: 1px solid $border-color;

  &:last-child {
    border-right: 1px solid $border-color;
  }
}

%box-style {
  flex: 1;
  padding: 0.15rem;
  text-align: center;
}

.week-calendar {
  position: relative;
  font-size: 0.6rem;
  width: 12rem;
}

.calendar-control {
  font-size: 0.5rem;
  padding: 0.2rem 0.3rem;
  border: 1px solid $border-color;
  margin: 0.25rem;
  cursor: pointer;
}

.calendar-selection {
  position: relative;
  border: 1px solid $border-color;
  padding: 0 0.3rem;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.calendar-dropdown {
  position: absolute;
  display: flex;
  top: 100%;
  left: 0;
  min-height: 100%;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  padding: 0.25rem;

  .months,
  .years {
    flex: 1;
    padding: 0.2rem;
    text-align: center;

    .calendar-option {
      cursor: pointer;

      &:not(.selected) {
        background-color: #f5f5f5;

        &:nth-child(2n+1) {
          background-color: #eee;
        }
      }

      &.selected {
        background-color: $current-color;
        color: #fff;
      }

      &:hover {
        background-color: lightblue !important;
      }
    }
  }
}

.calendar-headers {
  width: 100%;
}

.week-row {
  width: 100%;

  &.current {
    background-color: $current-color;
    color: #fff;
  }

  &.selected {
    .week-row-item {
      background-color: lightblue !important;
    }
  }

  &:not(:first-child) {
    border-top: 1px solid $border-color;
  }

  &:hover {
    .week-row-item {
      background-color: #eee;
      color: #000;

      &.fade {
        color: #ccc;
      }
    }
  }
}

.week-row-item {
  font-weight: 700;

  &.current {
    background-color: lighten($current-color, 10%);
  }

  &.fade {
    color: #aaa;
    font-weight: 400;
    background-color: #eee;

    &.current {
      background-color: #ccc;
      color: #000;
    }
  }
}

.calendar-heading {
  @extend %box-style;
  @extend %border-style;
  border-color: #000 !important;
  background-color: #333;
  color: #fff;
}

.week {
  @extend %box-style;
  @extend %border-style;
  background-color: #ddd;
  color: #000;

  border-top-color: #000;

  &.current {
    background-color: lighten(#486f14, 15%);
    color: #fff;
  }
}

.calendar-day {
  @extend %border-style;
  @extend %box-style;
}
</style>
