<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import requests from '@/requests';
import type { CustomResponse } from '@/interfaces';
import { STATUS_OK } from '@/constants';
import DetailsDataFieldLabel from './DetailsDataFieldLabel.vue';

interface ActionObject {
  [key: string]: string;
}

interface Component {
  required: boolean;
  enabled: boolean;
  name: string;
  value: string | number;
  values: Array<unknown>;
  action: ActionObject | string;
  url: string;
  label: string;
  imageType: string;
}

@Component({
  name: 'DetailsDataComponentBase',
  components: {
    DetailsDataFieldLabel,
  },
})
export default class DetailsDataComponentBase extends Vue {
  @Prop({}) component!: Component;
  @Prop({ default: '' }) field!: string;
  @Prop({ default: null }) actionKey!: string;
  @Prop({ default: '' }) valueOverride!: string | number;

  protected actionStatus: { type: string; message: string } = {
    type: '',
    message: '',
  }

  get required() {
    return !!this.component.required;
  }

  get enabled() {
    if (this.component.enabled === undefined) return true;

    return !!this.component.enabled;
  }

  get disabled() {
    return this.actionStatus.type === 'loading' ? true : !this.enabled;
  }

  get name() {
    return this.component.name;
  }

  get value() {
    if (this.valueOverride) return this.valueOverride;
    if (this.component.value === undefined) return '';

    return this.component.value;
  }

  get options() {
    return this.component.values;
  }

  get action() {
    if (!this.component.action) return null;

    if (this.actionKey) return this.actionKey;

    return typeof this.component.action === 'string'
      ? this.component.action
      : Object.keys(this.component.action)[0];
  }

  get classes() {
    return {
      'is-success': this.actionStatus.type === 'success',
      'is-danger': this.actionStatus.type === 'error',
    };
  }

  get actionURL() {
    if (this.component.url) return this.component.url;

    if (typeof this.component.action === 'object') {
      return this.component.action[this.action!];
    }

    return '';
  }

  protected async submitAction(data: object = {}, formData?: FormData) {
    this.actionStatus.type = 'loading';
    this.actionStatus.message = '';

    let response: CustomResponse;
    const actionURL = this.actionURL.includes('api/') ? this.actionURL : `api/v1/${this.actionURL}`;
    if (!actionURL) console.warn('Missing action URL');

    if (['update', 'reject', 'cancel', 'approve'].includes(this.action!)) {
      response = await requests.details.updateTaskDetailForClient(actionURL, data, formData);
    } else if (['add', 'create'].includes(this.action!)) {
      response = await requests.details.addTaskDetailForClient(actionURL, data, formData);
    } else if (this.action === 'delete') {
      response = await requests.details.deleteTaskDetailForClient(actionURL, data);
    } else if (['send', 'resend'].includes(this.action!)) {
      response = await requests.details.sendTaskDetailForClient(actionURL, data);
    } else {
      response = await requests.details.getTaskDetailForClient(actionURL);
    }

    this.actionStatus.type = response.status === STATUS_OK ? 'success' : 'error';
    this.actionStatus.message = response.message;

    setTimeout(() => {
      this.resetActionStatus();
    }, 2500);

    return response;
  }

  protected resetActionStatus() {
    this.actionStatus.type = '';
    // this.actionStatus.message = '';
  }
}
</script>
