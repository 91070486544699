<template>
  <span
    class="tag"
    :class="[color, clickable && 'is-clickable is-unselectable']"
    :style="style"
    @click="$emit('click', $event)"
  >
    {{ label }}
  </span>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'SmallTag',
})
export default class SmallTag extends Vue {
  @Prop({ default: 'label' }) label!: string;
  @Prop({ default: 'is-primary' }) color!: string;
  @Prop({ default: 'auto' }) margin!: string;

  protected clickable = this.$listeners.click !== undefined;

  get style() {
    return [
      'font-size: 10px;',
      'padding: 6px;',
      `margin: ${this.margin};`,
      'height: 14px;',
    ].join(' ');
  }
}

</script>
