<template>
  <div>
    <div class="checkbox is-flex is-justify-content-space-between" style="cursor: default;" >
      <details-data-field-label :label="name" :required="required" />
      <input
        type="checkbox"
        :class="classes"
        :disabled="!enabled"
        v-model="inputValue"
        @change="onChange"
      >
    </div>
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { STATUS_OK } from '@/constants';
import DetailsDataComponentBase from './DetailsDataComponentBase.vue';

@Component({
  name: 'DetailsCheckboxInput',
})
export default class DetailsCheckboxInput extends DetailsDataComponentBase {
  protected inputValue = false;

  protected async onChange() {
    const response = await this.submitAction({ [this.field]: this.inputValue });
    if (response.status === STATUS_OK) {
      this.$emit('change', this.inputValue);
    }
  }

  created() {
    this.inputValue = Boolean(this.value) || false;
  }
}
</script>
