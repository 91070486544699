import { Timer } from '@/util';

export default class ScrollSyncHandler {
  protected timer: Timer;
  protected elements: Array<Element> = [];
  protected activeScrollElement: HTMLElement | null = null;
  protected lastScrollPosition = 0;
  protected lastUpdate = 0;
  protected scrollEndDelay = 200;
  protected stopScrollLoop = false;

  constructor() {
    this.timer = new Timer({
      autoStart: false,
      tickFrequency: 150,
    });
  }

  public registerElement = (el: HTMLElement) => {
    if (this.elements.includes(el)) return;

    el.addEventListener('scroll', this.onScrollStart);
    this.elements.push(el);

    this.updateElements();
    this.synchronizeScrollPositions();
  }

  public unregisterElement = (el: HTMLElement) => {
    el.removeEventListener('scroll', this.onScrollStart);
    this.elements = this.elements.filter((element) => element !== el);
  }

  public destroy = () => {
    this.clearScrollListeners();
    this.elements = [];
    this.activeScrollElement = null;
  }

  protected onScrollStart = (event: Event) => {
    const target = event.target as HTMLElement;
    this.activeScrollElement = target;

    this.clearScrollListeners();
    this.timer.restart();
    this.stopScrollLoop = false;
    this.scrollLoop();
  }

  protected setScrollListeners = () => {
    this.elements.forEach((element) => (
      element.addEventListener('scroll', this.onScrollStart)
    ));
  }

  protected clearScrollListeners = () => {
    this.elements.forEach((element) => (
      element.removeEventListener('scroll', this.onScrollStart)
    ));
  }

  protected onScrollEnd = () => {
    this.setScrollListeners();
    this.stopScrollLoop = true;
    this.updateElements();
  }

  protected synchronizeScrollPositions = (scrollPosition?: number) => {
    if (!this.activeScrollElement) return;

    const scrollPos = scrollPosition || this.activeScrollElement!.scrollTop;

    this.elements.forEach((element) => {
      element.scrollTo(element.scrollLeft, scrollPos);
    });
  }

  protected scrollLoop = () => {
    if (this.stopScrollLoop) {
      this.stopScrollLoop = false;
      return;
    }

    const scrollPos = this.activeScrollElement!.scrollTop;

    if (this.lastScrollPosition !== scrollPos) {
      this.timer.restart();
      this.lastScrollPosition = scrollPos;
    }

    if (this.timer.expired) {
      this.onScrollEnd();
      return;
    }

    this.synchronizeScrollPositions(scrollPos);

    window.requestAnimationFrame(this.scrollLoop);
  }

  protected updateElements = () => {
    this.elements = this.elements.filter((element) => {
      if (!document.body.contains(element)) {
        element.removeEventListener('scroll', this.onScrollStart);
        return false;
      }

      return true;
    });
  }
}
