<template>
  <div class="maintenance-content">

    <!-- CLIENT INFO -->
    <div class="is-flex is-flex-wrap-wrap">
      <div class="box client-info">
        <details-box-heading label="Client details" />
        <div class="box-content">
          <p><b>Name: </b><span>{{ components.client_info.name }}</span></p>
          <p><b>Phone: </b><span>{{ components.client_info.phone }}</span></p>
          <p><b>Email: </b><span>{{ components.client_info.email }}</span></p>
        </div>
      </div>
    </div>

    <div class="box" >
      <details-box-heading label="Manual" />
      <div v-if="['created'].includes(components.status.status)">
        <details-action-button
          :component="{
            required: false,
            enabled: true,
            name: 'Solve Manually',
            label: 'Solve Manually',
            action: {
              'update':
              `api/v1/address/${address}/task_type/maintenance/action/start`
            },
          }"
          @click="refreshDetails"
        />
      </div>
      <button
        v-else
        class="button is-small is-success"
        @click="refresh"
      >Refresh</button>
    </div>

    <!-- ECORUS TICKET INPUT -->
    <details-maintenance-ticket-form
      v-if="showInputForm"
      :key="selectedTicketIndex"
      :manager="manager"
      :config="components.ticket_form"
      :ticket-item="selectedTicket"
    />

    <template v-if="hasTicketData && !showInputForm">
      <!-- TICKET STATUS COMMENT -->
      <div
        v-if="['cancelled', 'rejected'].includes(selectedTicket.ticket.current_status)"
        class="is-flex is-flex-wrap-wrap"
      >
        <details-ticket-status-comment-box
          :manager="manager"
          :tickets="tickets"
          :selected="selectedTicketIndex"
        />
      </div>

      <!-- TICKET CANCEL -->
      <div
        v-if="selectedTicket && selectedTicket.actions.cancel_ticket_button"
        class="is-flex is-flex-wrap-wrap"
      >
        <details-ticket-cancellation-box
          :manager="manager"
          :tickets="tickets"
          :selected="selectedTicketIndex"
        />
      </div>

      <!-- TICKET APPROVAL -->
      <div
        v-if="(
          selectedTicket.actions.approve_ticket_button
          || selectedTicket.actions.reject_ticket_button
        )"
        class="is-flex is-flex-wrap-wrap"
      >
        <details-ticket-approval-box
          :manager="manager"
          :tickets="tickets"
          :selected="selectedTicketIndex"
          :has-reject-type="false"
        />
      </div>
    </template>

    <div v-if="!showInputForm" class="is-flex is-flex-wrap-wrap">
      <!-- TICKETS -->
      <details-ticket-box
        :manager="manager"
        :tickets="tickets"
        :selected="selectedTicketIndex"
        @change="updateTicketSelection"
        @new-ticket="onNewTicket"
        @change-installer="changeInstallerModal = true"
      />

      <!-- ELEENA -->
      <details-eleena-box
        v-if="components.eleena_data"
        :manager="manager"
      />

      <!-- APPOINTMENT -->
      <details-ticket-appointment-box
        v-if="hasTicketData"
        :ticket="selectedTicket"
        :manager="manager"
      />
    </div>

    <!-- OUTPUT / INPUT -->
    <div v-if="!showInputForm" class="is-flex is-flex-wrap-wrap">

      <details-maintenance-output-box
        v-if="hasTicketOutput"
        :output="selectedTicket.ticket.output"
        :manager="manager"
      />

      <details-maintenance-input-box
        v-if="hasTicketInput"
        :key="selectedTicketIndex"
        :input="selectedTicket.ticket.input"
        :selectedTicket="selectedTicket"
        :manager="manager"
        @edit="() => showInputForm = true"
        @edit-finance="editTicketModal = true"
      />

    </div>

    <edit-ticket-modal
      :active.sync="editTicketModal"
      @cancel="editTicketModal = false"
      @finish="editTicketModal = false; refreshDetails()"
      :ticket="selectedTicket"
    />

    <change-installer-modal
      :active.sync="changeInstallerModal"
      @cancel="changeInstallerModal = false"
      @finish="changeInstallerModal = false; refreshDetails()"
      :ticket="selectedTicket"
      :installers="installers"
      :address="address"
    />

  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import requests from '@/requests';

import {
  STATUS_OK,
} from '@/constants';

import type { CustomResponse } from '@/interfaces';

import EditTicketModal from '@/components/tickets/EditTicketModal.vue';
import ChangeInstallerModal from '@/components/tickets/ChangeInstallerModal.vue';
import DetailsPaneBase from './DetailsPaneBase.vue';
import DetailsTicketImages from './DetailsTicketImages.vue';
import DetailsMaintenanceTaskInput from './DetailsMaintenanceTaskInput.vue';
import DetailsMaintenanceInputBox from './DetailsMaintenanceInputBox.vue';
import DetailsMaintenanceOutputBox from './DetailsMaintenanceOutputBox.vue';
import DetailsTicketBox from './DetailsTicketBox.vue';
import DetailsTicketApprovalBox from './DetailsTicketApprovalBox.vue';
import DetailsTicketStatusCommentBox from './DetailsTicketStatusCommentBox.vue';
import DetailsTicketCancellationBox from './DetailsTicketCancellationBox.vue';
import DetailsTicketAppointmentBox from './DetailsTicketAppointmentBox.vue';
import DetailsMaintenanceTicketForm from './DetailsMaintenanceTicketForm.vue';
import DetailsEleenaBox from './DetailsEleenaBox.vue';

// interface ProductComponent {
//   value: string;
//   values: Array<{ value: string; code: string }>;
// }

// interface ImageComponent {
//   value: string;
//   action: { [prop: string]: string };
// }

// type ImageField = [string, ImageComponent]

@Component({
  name: 'DetailsPaneMaintenance',
  components: {
    DetailsTicketImages,
    DetailsMaintenanceTaskInput,
    DetailsMaintenanceTicketForm,
    DetailsMaintenanceInputBox,
    DetailsMaintenanceOutputBox,
    DetailsTicketBox,
    DetailsTicketApprovalBox,
    DetailsTicketStatusCommentBox,
    DetailsTicketCancellationBox,
    DetailsTicketAppointmentBox,
    DetailsEleenaBox,
    EditTicketModal,
    ChangeInstallerModal,
  },
})
export default class DetailsPaneMaintenance extends DetailsPaneBase {
  protected selectedTicketIndex: number | null = null;

  protected showInputForm = false;

  public editTicketModal = false;
  public changeInstallerModal = false;

  get showTaskInputFields() {
    return this.status === 'created';
  }

  get selectedTicket() {
    if (this.selectedTicketIndex === null) return null;

    return this.tickets[this.selectedTicketIndex];
  }

  get tickets() {
    return this.components.tickets || [];
  }

  get installers() {
    return this.components.installers || [];
  }

  get hasTicketData() {
    return !!(this.selectedTicket && this.selectedTicket.ticket);
  }

  get hasTicketOutput() {
    return !!(this.hasTicketData && this.selectedTicket.ticket.output);
  }

  get hasTicketInput() {
    return !!(this.hasTicketData && this.selectedTicket.ticket.input);
  }

  get eleenaGraphURL() {
    const baseURL = 'https://reporting.eleena.eu/client-overview/';

    const { postalCode, houseNumber } = this.selectedMasterItem;
    const digits = postalCode.replace(' ', '').slice(0, 4);
    const ext = postalCode.replace(' ', '').slice(-2);

    const queryParams = [
      `postcode=${digits}`,
      `postcode_ext=${ext}`,
      `housenmbr=${houseNumber}`,
    ];

    return `${baseURL}?${queryParams.join('&')}`;
  }

  get eleenaAlarms() {
    return this.components.eleena_data && this.components.eleena_data.alarms
      ? this.components.eleena_data.alarms
      : [];
  }

  protected updateTicketSelection(ticketIndex: number) {
    this.selectedTicketIndex = ticketIndex;
  }

  protected onNewTicket() {
    this.showInputForm = true;
    this.selectedTicketIndex = null;
  }

  protected refresh() {
    this.refreshDetails();
    this.manager.updateTaskDataForItem(this.manager.selectedMasterItem);
  }

  created() {
    if (this.tickets && this.tickets.length > 0) {
      this.selectedTicketIndex = this.tickets.length - 1;
    }
  }
}

</script>
