<template>
  <div class="is-flex is-flex-wrap-wrap">
    <div class="box">
      <details-box-heading label="Comments" class="mb-3" />

      <div class="mb-1 cards">
        <div v-if="actions.length > 0">
          <div
            v-for="action in actions"
            :key="action.id"
            class="card mb-3 is-clickable"
            :class="{
              inbound: action.is_inbound,
              outbound: !action.is_inbound,
            }"
            @click="() => onSelectAction(action)"
          >
            <i v-if="action === selectedAction" class="fas fa-star marked-comment"></i>
            <div class="card-content">
              <p style="font-size: 1.1em">{{ action.value }}</p>
              <p>{{ action.created_by }}, {{ formatDate(new Date(action.created_at)) }}</p>
            </div>
          </div>
        </div>
        <div v-else>No Internal comments for this address yet.</div>
      </div>

      <textarea
        class="textarea"
        placeholder="Comment..."
        rows="1"
        :disabled="action.isLoading"
        v-model="action.value"
        :class="{
          'is-success': result.type === 'Success',
          'is-danger': result.type === 'Error',
        }"
        @keypress="onKeyPress"
      ></textarea>
      <div style="display: flex; justify-content: space-between; margin-top: 0.8em">
        <p
          class="pl-1"
          :class="{
            success: result.type === 'Success',
            error: result.type === 'Error',
          }"
        >
          {{ result.message }}
        </p>
        <div style="display: flex">
          <label class="mr-2 mb-3 checkbox" style="display: flex; align-items: flex-end">
            <span class="pr-1" style="font-size: 1.1em">Notify</span>
            <input
              type="checkbox"
              @change="() => (action.has_follow_up = !action.has_follow_up)"
              :checked="action.has_follow_up"
            />
          </label>
          <button
            :disabled="!action.value"
            :class="{ 'is-loading': action.isLoading }"
            class="button is-info is-small"
            @click="create"
          >
            Log
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Action, { ExistingAction } from '@/logic/CallCentreScreen/CallCentreAction';
import { actions } from '@/store';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { STATUS_ERROR, STATUS_OK } from '@/constants';
import request from '@/requests';
import { notifications } from '@/util';
import DetailsPaneBase from './DetailsPaneBase.vue';

@Component({
  name: 'DetailsCallCentreTextBox',
})
export default class DetailsCallCentreTextBox extends DetailsPaneBase {
  protected action = new Action();
  protected actions: Array<Action> = [];
  protected result = {
    type: '',
    message: '',
  };

  protected selectedAction: Action | null = null;

  protected onKeyPress(event: KeyboardEvent) {
    if (event.key === 'Enter' && !event.shiftKey && this.action.value.trim().length > 0) {
      this.create();
    }
  }

  protected async onSelectAction(action: Action) {
    if (action === this.selectedAction) return;

    const response = await request.details.updateTaskDataField(this.address, this.type, {
      comment_id: action.id,
    });
    if (response.status !== STATUS_ERROR) {
      this.setSelectedAction(action);
      this.updateMasterData();
    }
    if (response.status !== STATUS_OK) {
      notifications.addNotification({
        message: response.message,
        type: response.status === STATUS_ERROR ? 'danger' : 'warning',
      });
    }
  }

  protected formatDate = (date: Date) => {
    let day = String(date.getDate());
    let month = String(date.getMonth() + 1);
    const year = date.getFullYear();

    let hour = String(date.getHours());
    let minute = String(date.getMinutes());

    if (Number(hour) < 10) hour = `0${hour}`;
    if (Number(minute) < 10) minute = `0${minute}`;
    if (Number(day) < 10) day = `0${day}`;
    if (Number(month) < 10) month = `0${month}`;

    return `${day}/${month}/${year} - ${hour}:${minute}`;
  };

  protected setSelectedAction(action?: Action) {
    if (action) {
      this.selectedAction = action;
    }
  }

  protected subject() {
    switch (this.type) {
      case 'installation':
        return 'Installatie';
      case 'sales':
        return 'Werving';
      case 'design':
        return 'Algemeen/proces';
      case 'inspection':
        return 'Schouwing';
      case 'maintenance':
        return 'Onderhoud';
      default:
        return 'installatie';
    }
  }

  protected async create() {
    this.action.address = this.address;
    this.action.type = 'Comment';
    this.action.woco = this.manager.selectedWoco || '';
    this.action.context = 'Internal';
    this.action.created_by = actions.auth.readUser();
    this.action.subject = this.subject();
    this.action.is_inbound = true;
    this.action.value = this.action.value.trim();
    const response = await this.action.create();

    this.result.message = response.message;
    this.result.type = response.status === 'OK' ? 'Success' : 'Error';

    setTimeout(() => {
      this.result = {
        type: '',
        message: '',
      };
    }, 3000);

    if (response.status === 'OK') {
      this.onSelectAction(this.action);
      this.actions.unshift(this.action);
      this.action = new Action();
    }
  }

  protected async getActions() {
    const response = await request.actions.getActionsForAddress(this.address);

    if (response.data && response.data.actions) {
      return response.data.actions
        .map((action: ExistingAction) => new Action(action))
        .filter(
          (action: ExistingAction) =>
            action.context === 'Internal' && action.subject === this.subject()
        );
    }

    return [];
  }

  async mounted() {
    this.actions = await this.getActions();
    const taskData = this.selectedMasterItem.getTaskExtraData(this.type);
    if (!taskData || !taskData.comment_id || this.actions.length < 1) {
      return;
    }
    const selectedAction = this.actions.find(
      (action) => action.id.toString() === taskData.comment_id
    );
    if (selectedAction) {
      this.selectedAction = selectedAction;
    }
  }
}
</script>

<style scoped lang="scss">
.success {
  color: limegreen;
}

.error {
  color: red;
}

.textarea {
  font-size: 0.75rem;
  padding: 0.25rem;
}

.cards {
  min-height: 2.5em;
  max-height: 15em;
  overflow-y: auto;
  flex-grow: 1;
  padding: 0.5em;

  &::-webkit-scrollbar {
    width: 0.35rem;
    height: 0.35rem;
    color: #868686;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    cursor: grab;
  }
}

.card {
  .card-content {
    padding: 0.8rem !important;

    .content {
      display: flex;
      flex-direction: column;
    }
  }

  .marked-comment {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    color: #c7d6de;
    font-size: 0.75rem;
  }
}

.inbound {
  background-color: #edf6fa;
}

.outbound {
  background-color: #eaf7e1;
}
</style>
