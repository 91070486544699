<template>
  <div v-if="paneInfo.loading">Loading...</div>
  <div v-else>
    <design-table :manager="manager" />
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import BaseTasksPane from './BaseTasksPane.vue';
import DesignTable from './table/DesignTable.vue';

@Component({
  name: 'DesignPane',
  components: {
    DesignTable,
  },
})
export default class DesignPane extends BaseTasksPane {

}
</script>
