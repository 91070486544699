<template>
  <dropdown-slotted
    v-if="Object.keys(dataValues).length > 0"
    class="design-data-filter"
    label="Filter"
    align="left"
    icon-color="#fff"
    min-width="0"
    close-key="KeyQ"
  >
    <div class="is-flex is-flex-direction-column">
      <template v-for="[field, dataList] in Object.entries(filterLists)">
        <dropdown-slotted
          v-if="dataList.items.length > 0"
          :key="`list-${field}`"
          class="has-text-black"
          :full-width="true"
          align="left"
          close-key="escape"
          min-width="0"
          margin-top="0"
          :custom-toggle="true"
        >
          <template v-slot:trigger="{ toggleCollapse }">
            <div class="filter-trigger is-flex is-align-items-center">
              <p
                class="filter-trigger-text mr-1"
                :class="dataList.selectedItems.length > 0 ? 'has-text-black' : 'has-text-grey'"
                @click="toggleCollapse"
              >
                {{ formatField(field) }}
              </p>
              <span class="t-selection-count mr-1 has-text-grey">
                [{{ dataList.selectedItems.length }}/{{ dataList.items.length }}]
              </span>
              <div
                class="trigger-controls is-flex is-align-items-center is-justify-content-center"
              >
                <i
                  class="trigger-control t-select far fa-check-circle mr-1"
                  title="Select all"
                  @click="() => dataList.selectAll()"
                ></i>
                <i
                  class="trigger-control t-deselect far fa-times-circle mr-1"
                  title="Clear selection"
                  @click="() => dataList.deselectAll()"
                ></i>
                <i
                  class="trigger-control t-invert fas"
                  :class="invertedFields[field] ? 'fa-toggle-on': 'fa-toggle-off'"
                  title="Invert - Filter out selection"
                  @click="() => toggleFieldInversion(field)"
                ></i>
              </div>
            </div>
          </template>
          <scroll-container max-height="10rem">
            <div
              v-for="item in dataList.items"
              :key="`${field}-${item}`"
              class="mb-1 is-flex is-align-items-center"
              @click="(event) => dataList.onClickItem(item, event)"
            >
              <input class="mr-1" type="checkbox" :checked="dataList.isSelected(item)">
              <p class="mr-2">{{ field === 'assigned_to' ? formatName(item) : item }}</p>
            </div>
          </scroll-container>
        </dropdown-slotted>
      </template>
    </div>

    <div class="buttons is-right my-1 mx-0">
      <small-tag
        label="clear"
        margin="2px 0"
        color="is-info"
        @click="clearFilter"
      />
    </div>
  </dropdown-slotted>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { events, composeSort, print } from '@/util';
import { MultiSelectList, sortStringWithNumbers } from '@/logic/common';

import { TASK_TYPE_DESIGN } from '@/constants';
import type { TasksScreenManager } from '@/logic/TasksScreen';

interface Dictionary {
  [prop: string]: Array<string>;
}

@Component({
  name: 'TableHeadDataDesignFilter',
})
export default class TableHeadDataDesignFilter extends Vue {
  @Prop() manager!: TasksScreenManager;

  protected invertedFields: { [field: string]: boolean } = {};
  protected filterLists: { [field: string]: MultiSelectList<string> } = {};

  protected productFields = ['inverters', 'smartmeters', 'panels'];
  protected productQuantities = ['panels'];
  protected dataValues: { [field: string]: Array<string> } = {}

  get list() {
    return this.manager.list;
  }

  get filter() {
    return this.list.filter;
  }

  protected toggleFieldInversion(field: string) {
    this.invertedFields = { ...this.invertedFields, [field]: !this.invertedFields[field] };
    this.updateFilter();
  }

  protected createDataValues() {
    const dataValues = this.list.items.reduce((accumulator, item) => {
      const acc = { ...accumulator };
      const taskData = item.getTaskData(TASK_TYPE_DESIGN);

      Object.entries(taskData).forEach(([field, value]) => {
        if (['reason', 'comment'].includes(field)) return;
        const valuesSet = new Set(acc[field] || []);

        if (typeof value !== 'object') {
          valuesSet.add(value as string);
        }

        if (this.productFields.includes(field)) {
          Object.keys(value as object).forEach((product) => valuesSet.add(product));
        }

        acc[field] = Array.from(valuesSet);
        this.invertedFields[field] = false;

        if (this.productQuantities.includes(field)) {
          const fieldQuantity = field.concat('_quantity');
          const valuesSetQuantity = new Set(acc[fieldQuantity] || []);
          Object.values(value as object).forEach((quantity) => valuesSetQuantity.add(quantity));

          acc[fieldQuantity] = Array.from(valuesSetQuantity);
          this.invertedFields[fieldQuantity] = false;
        }
      });

      return acc;
    }, {} as { [prop: string]: Array<string> });
    return Object.fromEntries(Object.entries(dataValues).sort(([a], [b]) => a.localeCompare(b)));
  }

  protected clearFilter() {
    Object.values(this.filterLists).forEach((list) => list.deselectAll());
    this.updateFilter();
  }

  protected updateFilter() {
    const newState = Object.fromEntries(Object.entries(this.filterLists).map(([field, list]) => [
      field,
      {
        inverted: this.invertedFields[field],
        values: list.selectedItems,
      },
    ]));
    this.filter!.setState({ designData: newState });
  }

  protected formatField(field: string) {
    return (field[0].toUpperCase() + field.slice(1)).replaceAll('_', ' ');
  }

  protected formatName(name: string) {
    return name.split('_').map((substr) => substr[0].toUpperCase() + substr.slice(1)).join(' ');
  }

  protected createFilterLists() {
    return Object.entries(this.dataValues).reduce((acc, [field, values]) => ({
      ...acc,
      [field]: new MultiSelectList(values, {
        autoSort: true,
        autoRemoveDuplicates: true,
        checkboxMode: true,
        sorters: {
          field: composeSort(sortStringWithNumbers),
        },
        emitOnChange: {
          namespace: 'design-data-filter',
          eventName: 'select-change',
          key: field,
        },
      }),
    }), {});
  }

  beforeDestroy() {
    events.namespace('design-data-filter').off('select-change', this.updateFilter);
  }

  created() {
    this.dataValues = this.createDataValues();
    this.filterLists = this.createFilterLists();
    this.updateFilter();
    events.namespace('design-data-filter').on('select-change', this.updateFilter);
  }
}

</script>

<style lang="scss">
.design-data-filter {

  .filter-trigger {
    padding: 0.125rem;
    width: 100%;
    // text-align: center;

    &:hover {
      background-color: #eee;
    }
  }

  .filter-trigger-text {
    width: 100%;
  }

  .trigger-controls {
    padding: 0.1rem 0.25rem;
    border-left: 1px solid #ccc;
    justify-self: flex-end;
  }

  .trigger-control {
    margin-bottom: -0.125rem;
    color: #ccc;

    &:hover {
      &.t-select {
        color: blue;
      }

      &.t-deselect {
        color: red;
      }
    }

    &.t-invert {
      color: #222;
    }
  }

  .buttons {
    margin-right: 0.6rem;
  }
}
</style>
