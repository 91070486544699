<template>
  <component
    v-if="$options.components[action.component] && hasPermission()"
    :is="action.component"
    :action="action"
    :masterItem="masterItem"
    :manager="manager"
    :enabled="action.enabled"
  />
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { hasPermission } from '@/logic/common/Permissions';
import type { Action } from '@/interfaces';
import type { MasterItem, TasksScreenManager } from '@/logic/TasksScreen';

import {
  ApproveButton,
  AssignButton,
  AssignInstallerButton,
  FinishButton,
  ReopenButton,
  CreateButton,
  RejectButton,
  StartButton,
  UndoButton,
  UserSelect,
  InstallerSelect,
  PartnerSelect,
  AssignPartnerButton,
} from './actions';
import CancelButton from './actions/CancelButton.vue';

@Component({
  name: 'ActionComponent',
  components: {
    ApproveButton,
    AssignButton,
    AssignInstallerButton,
    FinishButton,
    ReopenButton,
    CreateButton,
    RejectButton,
    CancelButton,
    StartButton,
    UndoButton,
    UserSelect,
    InstallerSelect,
    PartnerSelect,
    AssignPartnerButton,
  },
})
export default class ActionComponent extends Vue {
  @Prop({}) masterItem!: MasterItem;
  @Prop({}) action!: Action;
  @Prop({}) manager!: TasksScreenManager;

  protected hasPermission() {
    return (!this.action.permissions || hasPermission(this.action.permissions));
  }
}

</script>
