<template>
  <div class="m-5" v-if="user && groups">
    <h4 class="title">{{ user.username }}</h4>

    <div class="columns">
      <div class="column is-1 has-text-grey-light">Email</div>
      <div class="column">{{ user.email }}</div>
    </div>

    <h3 class="subtitle">Scope</h3>

    <div class="columns">
      <div class="column is-1 has-text-grey-light">Groups</div>
      <div class="column">
        <div>
          <label for="all_groups">
            <input type="checkbox" v-model="form.allGroups" id="all_groups">
            Access to all groups
          </label>
        </div>
        <Multiselect
          v-show="!form.allGroups"
          v-model="form.groups"
          :options="groups.map(g => ({ value: g.id, label: `${g.project} / ${g.name}` }))"
          :searchable="true"
          mode="tags"
          class="custom-multiselect"
        >
          <template v-slot:option="{ option }">
            {{ option.label }}
          </template>
        </Multiselect>
      </div>
    </div>
    <div class="columns">
      <div class="column is-1 has-text-grey-light">Partners</div>
      <div class="column">
        <div>
          <label for="all_partners">
            <input type="checkbox" v-model="form.allPartners" id="all_partners">
            Access to all partners
          </label>
        </div>
        <Multiselect
          v-show="!form.allPartners"
          v-model="form.partners"
          :options="partners.map(p => ({ value: p.name, label: p.full_name }))"
          :searchable="true"
          mode="tags"
          class="custom-multiselect"
        >
          <template v-slot:option="{ option }">
            {{ option.label }}
          </template>
        </Multiselect>
      </div>
    </div>


    <h3 class="subtitle">Permissions</h3>

    <div class="columns">
      <div class="column is-1 has-text-grey-light">User groups</div>
      <div class="column">
        <Multiselect
          v-model="form.permissions.groups"
          :options="permissionGroups"
          :searchable="true"
          mode="tags"
          class="custom-multiselect"
        ></Multiselect>
      </div>
    </div>

    <div class="columns">
      <div class="column is-1 has-text-grey-light">Roles</div>
      <div class="column">
        <Multiselect
          v-model="form.permissions.roles"
          :options="permissionRoles"
          :searchable="true"
          mode="tags"
          class="custom-multiselect"
        ></Multiselect>
      </div>
    </div>

    <div class="columns">
      <div class="column is-1 has-text-grey-light">Actions</div>
      <div class="column">
        <Multiselect
          v-model="form.permissions.actions"
          :options="permissionActions"
          :searchable="true"
          mode="tags"
          class="custom-multiselect"
        ></Multiselect>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <b-button label="Save" type="is-primary" @click="saveUser" :loading="mutateUserLoading" />
      </div>
      <div class="column" style="color: red;" v-if="errors">
        <div>Errors:</div>
        <div v-for="list, field in errors" :key="field">
          <div v-for="error in list" :key="error">
            <b>{{ field }}:</b> {{ error }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';
import fetchData from '@/util/fetch';
import { computed, reactive, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import Multiselect from '@vueform/multiselect/dist/multiselect.vue2';

const route = useRoute()

const form = reactive({
  groups: [],
  allGroups: false,
  partners: [],
  allPartners: false,
  permissions: {
    groups: [],
    roles: [],
    actions: [],
  }
})

const getUser = () => fetchData(`/auth/api/v1/users/${route.params.username}`);
const userQuery = useQuery({
  queryKey: ['user', route.params.username],
  queryFn: getUser,
});

const user = computed(() => userQuery.data.value?.data)
watch(user, () => {
  const {scope, permissions} = user.value

  if (scope.groups === '*') {
    form.groups = []
    form.allGroups = true
  }
  else {
    form.groups = scope.groups
    form.allGroups = false
  }
  if (scope.partners === '*') {
    form.partners = []
    form.allPartners = true
  }
  else {
    form.allPartners = false
    form.partners = scope.partners
  }

  form.permissions.groups = permissions.groups || []
  form.permissions.roles = permissions.roles || []
  form.permissions.actions = permissions.actions || []
})

// partners
const getPartners = () => fetchData('/tickets/api/v1/partners');
const partnersQuery = useQuery({
  queryKey: ['partners'],
  queryFn: getPartners,
});

// groups
const getGroups = () => fetchData('/groups/api/v1/group');
const groupsQuery = useQuery({
  queryKey: ['groups'],
  queryFn: getGroups,
});

// permissions
const getPermissions = () => fetchData('/auth/api/v1/permissions');
const permissionsQuery = useQuery({
  queryKey: ['permissions'],
  queryFn: getPermissions,
});

const partners = computed(() => partnersQuery.data.value?.data)
const groups = computed(() => groupsQuery.data.value?.data)
const permissions = computed(() => permissionsQuery.data.value?.data)

const permissionGroups = computed(() => permissions.value ? Object.keys(permissions.value.groups) : [])
const permissionRoles = computed(() => permissions.value ? Object.keys(permissions.value.roles) : [])
const permissionActions = computed(() => permissions.value?.actions)

const putUser = () => {
  const {username} = route.params;
  const payload = {
    scope: {
      partners: form.allPartners ? '*' : form.partners,
      groups: form.allGroups ? '*' : form.groups,
    },
    permissions: form.permissions,
  };

  return fetchData(`/auth/api/v1/users/${username}`, {
    method: 'PUT',
    body: JSON.stringify(payload),
  });
};

const queryClient = useQueryClient()
const router = useRouter()
const errors = ref(null)
const { mutate: saveUser, isLoading: mutateUserLoading } = useMutation({
  mutationFn: putUser,
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: ['users'] });
    router.push({name: 'list-users'})
  },
  onError: (error) => {
    errors.value = error.errors;
  },
});
</script>
