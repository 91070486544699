<template>
  <div
    class="is-flex is-align-items-center"
  >
    <div
      class="label-wrapper"
      data-id="task-label"
      @mouseenter="onMouseEnterStatus"
      @mouseleave="onMouseLeaveStatus"
    >
      <status-label :label="masterItem.getStatus(type)"/>
      <!-- POPOVER -->
      <popover
        v-if="showStatusHistory"
        container-class="table-fixed"
      >
        <template v-if="historyData">
          <div
            v-for="{status, at, by} in historyData"
            :key="[status, at, by].join('-')"
            class="history-item"
          >
            <span>status: {{ status }}, at: {{ formatDateTime(at) }}, by: {{ by }}</span>
          </div>
        </template>
        <span v-else>Not available</span>
      </popover>
    </div>
    <!-- STATUS AT, BY -->
    <div class="is-flex is-flex-direction-column ml-1 status-info">
      <span><b>at:</b> {{ formatDateTime(masterItem.getStatusAt(type)) }}</span>
      <span><b>by:</b> {{ masterItem.getStatusBy(type) }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { formatDateTimeAlt } from '@/util';
import {
  ResultLabel,
  StatusLabel,
} from '@/components/TasksScreen/actions';

import type { MasterItem } from '@/logic/TasksScreen';
import type { TaskTypeKey, TaskHistoryData } from '@/interfaces';

@Component({
  name: 'TableCellTaskStatus',
  components: { StatusLabel },
})
export default class TableCellTaskStatus extends Vue {
  @Prop({}) masterItem!: MasterItem;
  @Prop({}) type!: TaskTypeKey;

  protected showStatusHistory = false;
  protected historyData: TaskHistoryData | null = null;

  protected formatDateTime(value: string) {
    return formatDateTimeAlt(value);
  }

  protected async onMouseEnterStatus() {
    this.showStatusHistory = true;
    const historyData = await this.masterItem.getTaskHistory(this.type);
    this.historyData = historyData;
  }

  protected onMouseLeaveStatus() {
    this.showStatusHistory = false;
    this.historyData = null;
  }
}

</script>

<style lang="scss" scoped>
  .status-info {
    font-size: 8px;
  }

  .popover {
    position: fixed;
    background-color: #000;
    color: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
