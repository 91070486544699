<template>
  <div class="tw-mt-4">
    <div v-if="isLoading">Loading...</div>
    <div v-else-if="tickets.length === 0">Geen tickets voor dit adres</div>

    <SmallTicketListItem v-for="ticket in tickets" :key="ticket.id" :ticket="ticket" />
  </div>
</template>

<script setup>
import fetchData from '@/util/fetch';
import { useQuery } from '@tanstack/vue-query';
import SmallTicketListItem from '@/components/shared/SmallTicketListItem.vue';

const props = defineProps({
  address: String,
});

async function fetchTickets() {
  const params = new URLSearchParams();
  params.append('view', 'list');
  params.append('query', props.address);

  const result = await fetchData(`/tickets/api/v1/tickets?${params}`);
  return result.data;
}

const { data: tickets, isLoading } = useQuery({
  queryKey: ['tickets', props.address],
  queryFn: fetchTickets,
});
</script>
