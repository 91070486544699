import type VueRouter from 'vue-router';
import { BaseScreenManager, sortStringWithNumbers } from '@/logic/common';
import request from '@/requests';
import notifications from '@/util/Notifications';
import { STATUS_OK } from '@/constants';
import {
  isBefore,
  isAfter,
  getISOWeek,
  startOfISOWeekYear,
  startOfDay,
  getISOWeekYear,
  startOfISOWeek,
  endOfISOWeek,
  isSameMonth,
  isThisISOWeek,
  format,
  addWeeks,
  addDays,
} from 'date-fns';
import type { WeekRange } from '@/interfaces';

interface ForecastItem {
  at: string;
  by: string;
  value: number;
}

interface TaskStatusStatistics {
  [status: string]: {
    actuals: number;
    ytd: number;
    forecast: number;
  };
}

interface TaskTypeData {
  [status: string]: {
    actuals: number;
    ytd: number;
    forecast: Array<ForecastItem>;
  };
}

interface VariableStatistics {
  actual: number;
  ytd: number;
  diffNumber: number | null;
  diffPercentage: number | null;
  forecast: number;
}

interface WeekStatistics {
  [date: string]: {
    [category: string]: {
      [status: string]: VariableStatistics;
    };
  };
}

interface DashboardData {
  [date: string]: {
    [project: string]: {
      design: TaskTypeData;
      installation: TaskTypeData;
      sales: TaskTypeData;
    };
  };
}

interface InstallationStatistics {
  planned: number;
  approved: number;
  forecast: number;
}

interface ProjectInstallations {
  [date: string]: {
    projects: {
      [project: string]: InstallationStatistics;
    };
    totals: InstallationStatistics;
  };
}

interface TempStatistics {
  week: {
    [date: string]: {
      [category: string]: TaskStatusStatistics;
    };
  };
  total: {
    [category: string]: TaskStatusStatistics;
  };
  installations: {
    weekly: ProjectInstallations;
    monthly: ProjectInstallations;
    total: {
      projects: {
        [project: string]: InstallationStatistics;
      };
      planned: number;
      approved: number;
      forecast: number;
    };
  };
}

interface FinalStatistics {
  total: {
    [category: string]: TaskStatusStatistics;
  };
  week: WeekStatistics;
  installations: {
    weekly: ProjectInstallations;
    monthly: ProjectInstallations;
    total: {
      projects: {
        [project: string]: InstallationStatistics;
      };
      planned: number;
      approved: number;
      forecast: number;
    };
  };
}

export default class DashboardScreenManager extends BaseScreenManager {
  public isLoading = true;

  public data: DashboardData = {};
  public statistics: FinalStatistics = {
    week: {},
    total: {},
    installations: {
      weekly: {},
      monthly: {},
      total: {
        projects: {},
        planned: 0,
        approved: 0,
        forecast: 0,
      },
    },
  };
  public selectedProjects: Array<string> = [];

  public weekRange: WeekRange;
  public defaultWeekRange: WeekRange;

  public showYTD = false;

  constructor(router: VueRouter) {
    super(router);

    const today = new Date();
    const startDate = startOfISOWeekYear(today);
    this.weekRange = {
      start: this.createWeekObject(startDate),
      end: this.createWeekObject(addWeeks(today, 3)),
    };

    this.defaultWeekRange = { ...this.weekRange };

    this.init();
  }

  get projects() {
    const projectsSet = Object.values(this.data).reduce((acc, weekData) => {
      Object.keys(weekData).forEach((project) => acc.add(project));
      return acc;
    }, new Set());

    const projects = (Array.from(projectsSet) as Array<string>).sort(sortStringWithNumbers);

    return projects;
  }

  get currentWeek() {
    const currentWeekIndex = this.getCurrentWeekIndex();
    if (currentWeekIndex === null) return null;

    const dates = Object.keys(this.data);
    return dates[currentWeekIndex];
  }

  protected init = async () => {
    this.updateDashboardData();
  }

  public createWeekObject = (date: Date) => {
    const start = addDays(startOfISOWeek(date), 1);
    return {
      week: getISOWeek(date),
      year: getISOWeekYear(date),
      start: startOfISOWeek(date),
      end: endOfISOWeek(date),
      text: format(start, "'Week' I',' LLL R", { weekStartsOn: 1 }),
    };
  }

  public setSelectedProjects = (projects: Array<string>) => {
    this.selectedProjects = [...projects];
    this.statistics = this.generateStatistics();
  }

  public updateDashboardData = async (range?: { start: string; end: string }) => {
    this.isLoading = true;

    const response = range
      ? await request.dashboard.getDashboardDataInRange(range.start, range.end)
      : await request.dashboard.getDashboardData();

    if (response.status === STATUS_OK) {
      this.data = response.data;
      if (this.selectedProjects.length === 0) {
        this.selectedProjects = this.projects;
      }
      this.statistics = this.generateStatistics();
    } else {
      notifications.addNotificationFromResponse(response);
    }

    this.isLoading = false;
  }

  public insertForecastItem = (
    item: ForecastItem,
    project: string,
    week: string,
    taskType: 'design' | 'installation' | 'sales',
  ) => {
    const forecastList = this.data[week][project][taskType].approved.forecast;
    forecastList.push(item);
    this.statistics = this.generateStatistics();
  }

  public updateWeekRange = async (weekRange: WeekRange) => {
    this.weekRange = weekRange;
    const datesInData = Object.keys(this.data);
    const weekStart = weekRange.start!.start;
    const weekEnd = weekRange.end!.start;
    const firstWeekInData = startOfDay(new Date(datesInData[0]));
    const lastWeekInData = startOfDay(new Date(datesInData[datesInData.length - 1]));

    if (isBefore(weekStart, firstWeekInData) || isAfter(weekEnd, lastWeekInData)) {
      await this.updateDashboardData({
        start: this.formatDateArg(weekStart),
        end: this.formatDateArg(weekRange.end!.end),
      });
      return;
    }
    this.statistics = this.generateStatistics();
  }

  public getEnabledForecastWeeks = () => {
    if (this.selectedProjects.length !== 1) return [];

    const dates = Object.keys(this.data);
    const currentWeekIndex = this.getCurrentWeekIndex();
    if (currentWeekIndex) {
      return dates.slice(currentWeekIndex + 1);
    }

    const lastDate = dates[dates.length - 1];
    return isBefore(new Date(lastDate), Date.now()) ? [] : dates;
  }

  protected getCurrentWeekIndex = () => {
    const dates = Object.keys(this.data);
    for (let idx = 0; idx < dates.length; idx += 1) {
      const date = new Date(dates[idx]);
      if (isThisISOWeek(date)) {
        return idx;
      }
    }

    return null;
  }

  protected createInstallationStatObject = () => ({
    projects: {} as { [project: string]: InstallationStatistics },
    totals: {
      planned: 0,
      approved: 0,
      forecast: 0,
    },
  })

  protected createInstallationTotalsObject = () => ({
    projects: {} as { [project: string]: InstallationStatistics },
    planned: 0,
    approved: 0,
    forecast: 0,
  })

  protected generateStatistics = () => {
    const accumulator = {
      week: {},
      total: {},
      installations: {
        weekly: {},
        monthly: {},
      },
    } as TempStatistics;

    const data = this.filterDataInRange();

    let previousMonthDate = '';
    let monthlyInstallations = this.createInstallationStatObject();
    const installationTotals = this.createInstallationTotalsObject();

    const statistics = data.reduce((stat, [date, projects]) => {
      const acc = { ...stat };
      acc.week[date] = {};

      if (!isSameMonth(new Date(previousMonthDate), new Date(date))) {
        monthlyInstallations = this.createInstallationStatObject();
        previousMonthDate = date;
        acc.installations.monthly[date] = monthlyInstallations;
      }

      const weeklyInstallations = this.createInstallationStatObject();
      acc.installations.weekly[date] = weeklyInstallations;

      Object.entries(projects).forEach(([project, projectData]) => {
        // Generate statistics for project installations
        if (this.selectedProjects.includes(project)) {
          const planned = projectData.installation.planned.actuals;
          const approved = projectData.installation.approved.actuals;
          const forecasts = projectData.installation.approved.forecast;
          const forecast = forecasts.length > 0 ? forecasts[forecasts.length - 1].value : 0;

          const statisticObjects = [monthlyInstallations, weeklyInstallations];
          statisticObjects.forEach((statisticObject) => {
            const obj = statisticObject;

            if (obj.projects[project] === undefined) {
              obj.projects[project] = { planned, approved, forecast };
            } else {
              obj.projects[project].planned += planned;
              obj.projects[project].approved += approved;
              obj.projects[project].forecast += forecast;
            }

            obj.totals.planned += planned;
            obj.totals.approved += approved;
            obj.totals.forecast += forecast;
          });

          installationTotals.planned += planned;
          installationTotals.approved += approved;
          installationTotals.forecast += forecast;

          const projectTotals = installationTotals.projects[project] || {
            planned: 0,
            approved: 0,
            forecast: 0,
          };

          projectTotals.planned += planned;
          projectTotals.approved += approved;
          projectTotals.forecast += forecast;

          installationTotals.projects[project] = projectTotals;
        }

        // Generate task status statistics
        Object.entries(projectData).forEach(([type, statuses]) => {
          if (acc.week[date][type] === undefined) acc.week[date][type] = {};
          if (acc.total[type] === undefined) acc.total[type] = {};

          const taskWeek = acc.week[date][type];
          const taskTotal = acc.total[type];
          Object.entries(statuses).forEach(([status, { actuals, ytd, forecast }]) => {
            // Set initial value
            if (taskWeek[status] === undefined) {
              taskWeek[status] = {
                actuals: 0,
                ytd: 0,
                forecast: 0,
              };
            }
            if (taskTotal[status] === undefined) {
              taskTotal[status] = {
                actuals: 0,
                ytd: 0,
                forecast: 0,
              };
            }

            if (!this.selectedProjects.includes(project)) return;

            // Add task status values for selected projects
            taskWeek[status].actuals += actuals;
            taskTotal[status].actuals += actuals;
            taskWeek[status].ytd += ytd;
            taskTotal[status].ytd += ytd;

            if (status === 'approved' && forecast.length > 0) {
              taskWeek[status].forecast += forecast[forecast.length - 1].value;
              taskTotal[status].forecast += forecast[forecast.length - 1].value;
            }
          });
        });
      });

      return acc;
    }, accumulator);

    statistics.installations.total = installationTotals;

    const weekStatistics = this.generateWeeklyVariations(statistics);

    return { ...statistics, week: weekStatistics };
  }

  protected generateWeeklyVariations = (statistics: TempStatistics) => {
    const weeklyStatistics = {} as {
      [date: string]: {
        [category: string]: {
          [status: string]: VariableStatistics;
        };
      };
    };

    const dates = Object.keys(statistics.week);

    Object.entries(statistics.week).forEach(([date, weekData], idx) => {
      Object.entries(weekData).forEach(([taskType, taskData]) => {
        Object.entries(taskData).forEach(([status, { actuals, ytd, forecast }]) => {
          const previousWeekValue = idx > 0
            ? statistics.week[dates[idx - 1]][taskType][status].actuals
            : null;

          const statObject = {
            ytd,
            actual: actuals,
            forecast: status === 'approved' ? forecast : 0,
            diffNumber: null,
            diffPercentage: null,
          } as VariableStatistics;

          if (previousWeekValue !== null) {
            statObject.diffNumber = actuals - previousWeekValue;
            statObject.diffPercentage = this.getDifferencePercentage(previousWeekValue, actuals);
          }

          if (!weeklyStatistics[date]) weeklyStatistics[date] = {};
          if (!weeklyStatistics[date][taskType]) weeklyStatistics[date][taskType] = {};
          if (!weeklyStatistics[date][taskType][status]) {
            weeklyStatistics[date][taskType][status] = statObject;
          }
        });
      });
    });

    return weeklyStatistics;
  }

  protected getDifferencePercentage = (prevNumber: number, newNumber: number) => {
    if (prevNumber === newNumber) return 0;
    if (prevNumber === 0) return null;

    const percentage = (Math.abs(prevNumber - newNumber) / prevNumber) * 100;
    const roundedDecimals = Math.round((percentage + Number.EPSILON) * 100) / 100;
    return newNumber > prevNumber ? roundedDecimals : -roundedDecimals;
  }

  protected formatDateArg = (date: Date) => {
    const formattedDate = format(date, "yyyy'-'MM'-'dd");
    return formattedDate;
  }

  protected filterDataInRange = () => {
    const { start, end } = this.weekRange;
    const entries = Object.entries(this.data);

    return entries.filter(([weekDate]) => (
      (!start || isAfter(new Date(weekDate), start.start))
      && (!end || isBefore(new Date(weekDate), end.end))
    ));
  }
}
