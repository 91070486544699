var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"is-flex is-flex-wrap-wrap"},[_c('div',{staticClass:"box"},[_c('details-box-heading',{staticClass:"mb-3",attrs:{"label":"Comments"}}),_c('div',{staticClass:"mb-1 cards"},[(_vm.actions.length > 0)?_c('div',_vm._l((_vm.actions),function(action){return _c('div',{key:action.id,staticClass:"card mb-3 is-clickable",class:{
            inbound: action.is_inbound,
            outbound: !action.is_inbound,
          },on:{"click":() => _vm.onSelectAction(action)}},[(action === _vm.selectedAction)?_c('i',{staticClass:"fas fa-star marked-comment"}):_vm._e(),_c('div',{staticClass:"card-content"},[_c('p',{staticStyle:{"font-size":"1.1em"}},[_vm._v(_vm._s(action.value))]),_c('p',[_vm._v(_vm._s(action.created_by)+", "+_vm._s(_vm.formatDate(new Date(action.created_at))))])])])}),0):_c('div',[_vm._v("No Internal comments for this address yet.")])]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.action.value),expression:"action.value"}],staticClass:"textarea",class:{
        'is-success': _vm.result.type === 'Success',
        'is-danger': _vm.result.type === 'Error',
      },attrs:{"placeholder":"Comment...","rows":"1","disabled":_vm.action.isLoading},domProps:{"value":(_vm.action.value)},on:{"keypress":_vm.onKeyPress,"input":function($event){if($event.target.composing)return;_vm.$set(_vm.action, "value", $event.target.value)}}}),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","margin-top":"0.8em"}},[_c('p',{staticClass:"pl-1",class:{
          success: _vm.result.type === 'Success',
          error: _vm.result.type === 'Error',
        }},[_vm._v(" "+_vm._s(_vm.result.message)+" ")]),_c('div',{staticStyle:{"display":"flex"}},[_c('label',{staticClass:"mr-2 mb-3 checkbox",staticStyle:{"display":"flex","align-items":"flex-end"}},[_c('span',{staticClass:"pr-1",staticStyle:{"font-size":"1.1em"}},[_vm._v("Notify")]),_c('input',{attrs:{"type":"checkbox"},domProps:{"checked":_vm.action.has_follow_up},on:{"change":() => (_vm.action.has_follow_up = !_vm.action.has_follow_up)}})]),_c('button',{staticClass:"button is-info is-small",class:{ 'is-loading': _vm.action.isLoading },attrs:{"disabled":!_vm.action.value},on:{"click":_vm.create}},[_vm._v(" Log ")])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }