<template>
  <info-container title="Ticket cancellation">
    <div class="tw-flex tw-flex-col">
      <textarea
        class="tw-mt-2 tw-p-2 tw-border tw-border-gray-200"
        placeholder="Comment..."
        rows="1"
        v-model="cancelComment"
      ></textarea>

      <button
        :disabled="!cancelComment"
        class="tw-bg-gray-500 tw-rounded tw-p-1 tw-text-white tw-mt-3"
        :class="{
          'tw-opacity-50 tw-cursor-not-allowed': !cancelComment,
        }"
        @click="cancel"
      >
        Cancel ticket {{ id }}
      </button>
    </div>
  </info-container>
</template>

<script setup>
import { useMutation } from '@tanstack/vue-query';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router/composables';
import InfoContainer from '@/components/shared/InfoContainer.vue';
import store from '@/store';

const props = defineProps({
  ticket: {
    type: Object,
    required: true,
  },
});

const route = useRoute();

const type = computed(() => route.params?.type);
const address = computed(() => route.params?.address);

const cancelComment = ref('');

const id = computed(() => props.ticket.id);
const partner = computed(() => props.ticket.installer);

const queryParams = `ticket_id=${id.value}&installer=${partner.value}`;

const urlCancel = `api/v1/address/${address.value}/task_type/${type.value}/ticket/cancel?${queryParams}`;

const cancel = () =>
  fetch(urlCancel, {
    method: 'PUT',
    body: JSON.stringify({
      cancel_comment: cancelComment.value,
    }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${store.getters['auth/getToken']}`,
    },
  });
</script>
