// eslint-disable-next-line import/prefer-default-export
import { TASK_TYPE_DESIGN, TASK_TYPE_INSTALLATION, TASK_TYPE_SALES } from '@/constants';
import { pipe } from '@/util';
import type { MasterItem } from '@/logic/TasksScreen';
import type { ExistingGroup, AddressDetails } from '@/interfaces';

export const sortString = (a: string, b: string) => a.toLowerCase().localeCompare(b.toLowerCase());

export const sortNumber = (a: number, b: number) => a - b;

export const sortByDate = (a: Date, b: Date) => a.getTime() - b.getTime();

export const sortStringWithNumbers = (a: string, b: string) => {
  if (a.toLowerCase() === b.toLowerCase()) return 0;
  if (!/\d/.test(a) || !/\d/.test(b)) return sortString(a, b);

  const split = pipe<string[]>(
    (str: string) => str.split(/(\D+)/g), // split on non-digits, keeping seperator
    (arr: string[]) => arr.map((str: string) => str.replace(/[^a-z0-9]/gi, '')), // remove symbols
    (arr: string[]) => arr.filter((str: string) => !!str), // remove empty strings
    (arr: string[]) => arr.map((str: string) => str.toLowerCase()), // convert to lowercase
  );
  const splitA = split(a);
  const splitB = split(b);

  const iterations = splitA.length <= splitB.length ? splitA.length : splitB.length;
  for (let idx = 0; idx < iterations; idx += 1) {
    const valueA = splitA[idx];
    const valueB = splitB[idx];

    if (
      (!Number.isNaN(+valueA) && !Number.isNaN(+valueB))
      && sortNumber(+valueA, +valueB) !== 0
    ) return sortNumber(+valueA, +valueB);

    if (sortString(valueA, valueB) !== 0) return sortString(valueA, valueB);
  }

  if (splitA.length > splitB.length) return 1;
  if (splitA.length < splitB.length) return -1;
  return 0;
};

export const sortAddress = (a: { address: string }, b: { address: string }) => (
  sortStringWithNumbers(a.address, b.address)
);

export const sortStreet = (a: AddressDetails, b: AddressDetails) => {
  if (!a.street || !b.street) return 0;
  return sortString(a.street, b.street);
};

export const sortHouseNumber = (a: AddressDetails, b: AddressDetails) => {
  if (!a.houseNumber || !b.houseNumber) return 0;
  return sortStringWithNumbers(a.houseNumber, b.houseNumber);
};

export const sortPostalCode = (a: AddressDetails, b: AddressDetails) => {
  if (!a.postalCode || !b.postalCode) return 0;
  return sortString(a.postalCode, b.postalCode);
};

export const sortGroupNames = (a: ExistingGroup, b: ExistingGroup) => (
  sortStringWithNumbers(a.name, b.name)
);

export const sortGroupUser = (a: ExistingGroup, b: ExistingGroup) => (
  sortString(a.created_by, b.created_by)
);

export const sortByCreatedAt = (
  a: { created_at: string;[key: string]: unknown },
  b: { created_at: string;[key: string]: unknown },
) => sortByDate(new Date(a.created_at), new Date(b.created_at));

export const sortDesignStatusType = (a: MasterItem, b: MasterItem) => (
  a.design && b.design
    ? sortString(a.getStatus(TASK_TYPE_DESIGN) as string, b.getStatus(TASK_TYPE_DESIGN) as string)
    : 0
);
