<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import BaseButtonBulk from './BaseButtonBulk.vue';

@Component({
  name: 'UndoButtonBulk',
})
export default class UndoButtonBulk extends BaseButtonBulk {
  // protected label = 'start';
  protected color = 'is-dark';
}
</script>
