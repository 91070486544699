<template>
  <div>
    <div class="dropdown-wrapper">
      <dropdown
        :items="paneSelectionDropdown"
        :key="dropdownKey"
        display="text"
        :dataId="'pane-type?type=' + this.selectedPane.split('Pane')[0]"
        @change="(item) => item.handler()"
        :preselect="this.selectedPane.split('Pane')[0]"
      />
    </div>
    <component
      v-if="selectedPane && !manager.isLoading"
      :manager="manager"
      :is="selectedPane"
    />
    <button-delete
      @click="onClosePane"
      dataId="pane-close"
      style="position: absolute; top: 0; right: 0;"
    />
  </div>
</template>

<script lang="ts">
import { TasksScreenManager } from '@/logic/TasksScreen';
import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import DesignPane from './DesignPane.vue';
import SalesPane from './SalesPane.vue';
import InstallationPane from './InstallationPane.vue';
import ClientPane from './ClientsPane.vue';
import FinancePane from './FinancePane.vue';
import InspectionPane from './InspectionPane.vue';
import MaintenancePane from './MaintenancePane.vue';

@Component({
  name: 'PaneRenderer',
  components: {
    DesignPane,
    SalesPane,
    InstallationPane,
    ClientPane,
    FinancePane,
    InspectionPane,
    MaintenancePane,
  },
})
export default class PaneRenderer extends Vue {
  @Prop() manager!: TasksScreenManager;
  @Prop({ default: '' }) preselect!: string;
  @Prop({}) close!: Function;

  protected dropdownKey = Math.random();

  protected paneWeightMap = {
    '': 1,
    Client: 2,
    Design: 3,
    Sales: 4,
    Inspection: 5,
    Installation: 6,
    Maintenance: 7,
    Finance: 8,
  } as { [key: string]: number };

  protected selectedPane = this.preselect;
  protected selectionDropdown = this.paneSelectionDropdown;

  get closedPanes() {
    return this.manager.panes.closedPanes;
  }

  get openPanes() {
    return this.manager.panes.openPanes;
  }

  @Watch('closedPanes')
  protected onClosedPanesChange() {
    this.selectionDropdown = this.paneSelectionDropdown;
  }

  get paneSelectionDropdown() {
    const currentPane = this.selectedPane;
    const options = this.closedPanes.reduce((acc, pane) => {
      if (pane !== this.selectedPane) {
        acc.push({
          text: pane.split('Pane')[0],
          handler: () => this.onPaneChange(pane, currentPane),
        });
      }
      return acc;
    }, [] as Array<{ text: string; handler: Function }>);

    if (!this.selectedPane) {
      options.unshift({
        text: '',
        handler: () => {
          this.manager.hideDetails();
          this.selectedPane = '';
        },
      });
    } else {
      // const currentPane = this.selectedPane;
      options.unshift({
        text: this.selectedPane.split('Pane')[0],
        handler: () => {
          this.selectedPane = currentPane;
        },
      });
    }

    options.sort((a, b) => {
      const weightA = this.paneWeightMap[a.text];
      const weightB = this.paneWeightMap[b.text];
      return weightA - weightB;
    });

    this.dropdownKey = Math.random();

    return options;
  }

  protected onPaneChange(newPane: string, prevSelectedPane: string) {
    this.manager.hideDetails();
    this.selectedPane = newPane;

    if (prevSelectedPane && prevSelectedPane !== newPane) {
      this.manager.panes.onPaneClose(prevSelectedPane);
    }
  }

  protected onClosePane() {
    if (this.selectedPane) {
      this.manager.panes.onPaneClose(this.selectedPane);
    }

    this.close();
  }
}
</script>

<style lang="scss" scoped>
::v-deep

.dropdown-wrapper {
  margin-bottom: 0.7rem;
  margin-top: 1.8rem;
}
</style>
