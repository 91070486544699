import { actions } from '@/store';
import request from '@/requests';

export interface ExistingAction {
  id: number;
  created_at: string;
  created_by: string;
  value: string;
  type: string;
  address: string;
  context: string;
  subject: string;
  is_inbound: boolean;
  has_follow_up: boolean;
  is_resolved: boolean;
  woco: string;
  related_action_id: number;
}

export interface Subject {
  id: number;
  name: string;
}

export default class CallCentreAction {
  public id = 0;
  public created_at = '';
  public created_by = '';
  public value = '';
  public type = '';
  public subject = '';
  public context = '';
  public address = '';
  public woco = '';
  public is_inbound = false;
  public has_follow_up = false;
  public is_resolved = false;
  public related_action_id: number | null = null;

  public isLoading = false;
  public possibleSubjects = [];
  public possibleTypes = ['Comment', 'Complaint'];
  public possibleContext = ['Phone', 'Email', 'Internal'];

  constructor(existingAction?: ExistingAction) {
    if (existingAction) {
      this.created_at = existingAction.created_at;
      this.created_by = existingAction.created_by;
      this.value = existingAction.value;
      this.type = existingAction.type;
      this.address = existingAction.address;
      this.is_inbound = existingAction.is_inbound;
      this.has_follow_up = existingAction.has_follow_up;
      this.is_resolved = existingAction.is_resolved;
      this.id = existingAction.id;
      this.woco = existingAction.woco;
      this.context = existingAction.context;
      this.subject = existingAction.subject;
      this.related_action_id = existingAction.related_action_id;
    } else {
      [this.type] = this.possibleTypes;
      [this.context] = this.possibleContext;
      this.setPossibleSubjects();
    }
  }

  public resolve = async () => {
    this.is_resolved = true;
    const response = await this.update();

    return response;
  }

  public create = async () => {
    this.isLoading = true;

    const response = await request.actions.createAction({
      address: this.address,
      type: this.type,
      woco: this.woco,
      value: this.value,
      context: this.context,
      subject: this.subject,
      has_follow_up: this.has_follow_up,
      is_resolved: this.is_resolved,
      is_inbound: this.is_inbound,
      created_by: actions.auth.readUser(),
      related_action_id: this.related_action_id,
    });

    this.isLoading = false;
    this.id = response.data.id;
    this.created_at = response.data.created_at;

    return response;
  }

  public update = async () => {
    this.isLoading = true;

    const response = await request.actions.updateAction({
      address: this.address,
      type: this.type,
      woco: this.woco,
      value: this.value,
      context: this.context,
      subject: this.subject,
      has_follow_up: this.has_follow_up,
      is_resolved: this.is_resolved,
      is_inbound: this.is_inbound,
      created_by: actions.auth.readUser(),
      related_action_id: this.related_action_id,
    }, this.id);

    this.isLoading = false;
    return response;
  }

  private setPossibleSubjects = async () => {
    const response = await request.actions.getSubjects();

    if (response.data && response.data.subjects) {
      this.possibleSubjects = response.data.subjects
        .filter((subject: Subject) => subject.name !== '' && subject.name !== 'OLD')
        .map((subject: Subject) => subject.name)
        .sort();

      if (!this.subject) [this.subject] = this.possibleSubjects;
    }
  }
}
