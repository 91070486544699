<template>
  <table-task :config="tableConfig" :manager="manager" />
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import type { TasksScreenManager } from '@/logic/TasksScreen';

import TableTask from './TableTask.vue';
import ClientTableRow from './ClientTableRow.vue';

@Component({
  name: 'ClientTable',
  components: {
    TableTask,
    ClientTableRow,
  },
})
export default class ClientTable extends Vue {
  @Prop() manager!: TasksScreenManager;

  protected tableConfig = {
    headers: [
      {
        text: 'ID',
      },
      {
        text: 'Person',
      },
      {
        text: 'Phone number',
      },
      {
        text: 'Email',
      },
      {
        text: 'City',
      },
    ],
    row: {
      component: ClientTableRow,
      props: {
        manager: this.manager,
      },
    },
  }
}
</script>
