/**
 * Date module used:
 * https://www.npmjs.com/package/date-fns
 * https://date-fns.org/docs/Getting-Started
 */
import {
  format,
  getYear,
  getISOWeekYear,
  getMonth,
  getISOWeek,
  getDaysInMonth,
  startOfISOWeek,
  endOfISOWeek,
  eachDayOfInterval,
  getDate,
  isSameMonth,
} from 'date-fns';

export default class WeekCalendarController {
  public currentWeek: number;
  public currentDay: number;
  public currentYear: number;
  public activeYear: number;
  public activeMonth: number;
  public activeWeek: number;
  public formattedWeekDay: string;
  public calendarHeaders = ['Wk', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
  public calendarDays: Map<number, Array<{ date: Date; day: number }>>

  constructor(initialDate?: string | number | Date | null) {
    const currentDate = new Date();
    const date = initialDate ? new Date(initialDate) : currentDate;
    this.currentWeek = getISOWeek(currentDate);
    this.currentDay = getDate(currentDate);
    this.currentYear = getYear(currentDate);
    this.activeYear = getYear(date);
    this.activeMonth = getMonth(date);
    this.activeWeek = getISOWeek(date);
    this.formattedWeekDay = this.formatWeekDate(date);
    this.calendarDays = this.generateCalendarItems();
  }

  get months() {
    return [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
  }

  get years() {
    const min = this.currentYear - 6;
    const max = this.currentYear + 6;
    const years = [];
    for (let year = min; year < max; year += 1) {
      years.push(year);
    }
    return years;
  }

  get activeSelection() {
    return `${this.months[this.activeMonth]} ${this.activeYear}`;
  }

  public getMonth = (index: number) => this.months[index]

  public isActiveMonth = (date: Date) => (
    isSameMonth(date, new Date(this.activeYear, this.activeMonth))
  )

  public setActiveMonth = (month: string) => {
    this.activeMonth = this.getIndexOfMonth(month);
    this.calendarDays = this.generateCalendarItems();
  }

  public getIndexOfMonth = (month: string) => (
    this.months.indexOf(month)
  )

  public nextMonth = () => {
    this.goToNextMonth(1);
  }

  public prevMonth = () => {
    this.goToNextMonth(-1);
  }

  public setActiveYear = (year: number) => {
    this.activeYear = year;
    this.calendarDays = this.generateCalendarItems();
  }

  public formatWeekDate = (date: string | number | Date) => (
    format(new Date(date), "'Week' I',' LLL R", { weekStartsOn: 1 })
  )

  public generateCalendarItems = () => {
    const firstDay = this.getFirstCalendarDay();
    const lastDay = this.getLastCalendarDay();

    const days = eachDayOfInterval({
      start: new Date(firstDay),
      end: new Date(lastDay),
    });

    const calendarDaysMap = days.reduce((acc, date) => {
      const week = getISOWeek(new Date(date));
      if (!acc.has(week)) acc.set(week, []);

      const weekList = acc.get(week)!;
      weekList.push({
        date,
        day: getDate(date),
      });

      return acc;
    }, new Map() as Map<number, Array<{ date: Date; day: number }>>);

    return calendarDaysMap;
  }

  public getFirstCalendarDay = (year?: number, month?: number) => startOfISOWeek(
    new Date(
      year !== undefined ? year : this.activeYear,
      month !== undefined ? month : this.activeMonth,
      1,
    ),
  )

  public getLastCalendarDay = (year?: number, month?: number) => {
    const Y = year !== undefined ? year : this.activeYear;
    const M = month !== undefined ? month : this.activeMonth;

    const daysInMonth = getDaysInMonth(new Date(Y, M));
    return endOfISOWeek(new Date(Y, M, daysInMonth));
  }

  public createSelectedWeekObject = (week: number) => {
    const weekStart = this.calendarDays.get(week)![0];
    const weekEnd = this.calendarDays.get(week)![6];
    return {
      week,
      year: getISOWeekYear(weekStart.date),
      start: weekStart.date,
      end: weekEnd.date,
      text: this.formatWeekDate(weekStart.date),
    };
  }

  protected goToNextMonth = (direction: number) => {
    let nextMonth: number;
    let nextYear = this.activeYear;
    if (this.activeMonth + direction < 0) {
      nextMonth = this.months.length - 1;
      nextYear -= 1;
    } else if (this.activeMonth + direction >= this.months.length) {
      nextMonth = 0;
      nextYear += 1;
    } else {
      nextMonth = this.activeMonth + direction;
    }

    this.activeMonth = nextMonth;
    this.activeYear = nextYear;

    this.calendarDays = this.generateCalendarItems();
  }
}
