<script lang="ts">
import { Component } from 'vue-property-decorator';
import BaseButton from './BaseButton.vue';

@Component({
  name: 'UndoButton',
})
export default class UndoButton extends BaseButton {
  protected color = 'is-dark';
}
</script>
