<template>
  <div>
    <div
      class="tw-text-black tw-flex tw-flex-cols tw-ring-2 tw-rounded tw-mb-2"
      :class="ticketStatusRingColorClass[ticket.status]"
    >
      <div class="tw-place-self-stretch">
        <a :href="`https://tickets.ecorushome.com/v2/tickets/${ticket.id}`" target="_BLANK">
          <div :class="ticketStatusBgColorClass[ticket.status]" class="tw-p-2 tw-h-full tw-text-black tw-items-center tw-flex tw-flex-col tw-gap-2 hover:tw-opacity-80">
            <div>#{{ ticket.id }}</div>
            <div v-if="ticket.case_id && showCase" class="tw-flex tw-items-center tw-mt-1">
              <OutlineBriefcaseIcon size="16" class="tw-mr-1" />
              <div>{{ ticket.case_id }}</div>
            </div>
          </div>
        </a>
      </div>
      <div class="tw-grow tw-p-1">
        <div class="tw-flex tw-flex-cols tw-justify-between tw-mb-1">
          <div v-if="ticket.title" class="tw-col-span-2 tw-font-bold">
            Onderhoud: {{ ticket.title }}
          </div>
          <div v-else class="tw-col-span-2 tw-font-bold">
            {{ ticketTypes[ticket.type] || ticket.type }}
          </div>
          <div>{{ ticket.partner }}</div>
        </div>
        <div class="tw-flex tw-flex-cols tw-justify-between">
          <div class="tw-flex tw-flex-col">
            <div>{{ ticketStatuses[ticket.status] }}</div>
            <div
              v-if="ticket.status !== 'approved' && ticket.status !== 'cancelled'"
              class="tw-text-xs tw-text-gray-400"
            >sinds {{ daysSince(ticket.latest_status_at) }} dagen</div>
          </div>
          <div v-if="ticket.appointment_date">
            Afspraak: {{ format(parseISO(ticket.appointment_date), 'd-MMM-yy') }}
          </div>
          <div
            v-if="editable"
            class="tw-p-1 tw-text-gray-500 tw-cursor-pointer"
            @click.prevent="$emit('edit')"
          >
            <PencilIcon
              class="tw-w-5 tw-h-5 tw-text-gray-500 tw-cursor-pointer"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { format, parseISO, differenceInDays } from 'date-fns';
import { BriefcaseIcon as OutlineBriefcaseIcon, PencilIcon } from '@vue-hero-icons/outline';
import { ticketStatuses, ticketStatusBgColorClass, ticketStatusRingColorClass } from '@/constants';

const emit = defineEmits(['edit']);

const props = defineProps({
  ticket: Object,
  editable: Boolean,
  showCase: {
    type: Boolean,
    default: true,
  },
});

const ticketTypes = {
  maintenance: 'Onderhoud',
  installation: 'Installatie',
  inspection: 'Schouw',
};

function daysSince(date) {
  return differenceInDays(new Date(), parseISO(date));
}
</script>
