<template>
  <div class="box ticket-approval">
    <details-box-heading label="Ticket approval" />
    <div class="box-content">
      <!-- APPROVE / REJECT BUTTONS -->
      <div class="is-flex is-align-items-center mb-2" style="width: 100%;">
        <details-ticket-button
          v-if="selectedTicket.actions.approve_ticket_button"
          class="mr-1"
          field="approve_ticket_button"
          :component="selectedTicket.actions.approve_ticket_button"
          :post-data="{ reject_approve_remark: rejectApproveRemark }"
          :optional-post-data="true"
          @click="refreshDetails"
        />

        <details-ticket-button
          v-if="selectedTicket.actions.reject_ticket_button"
          :key="`${rejectApproveRemark}-${rejectType}`"
          field="reject_ticket_button"
          :component="selectedTicket.actions.reject_ticket_button"
          :post-data="{
            reject_approve_remark: rejectApproveRemark,
            ...(hasRejectType && {reject_type: rejectType}),
          }"
          @click="refreshDetails"
        />
      </div>
      <!-- REJECT INPUTS -->
      <div
        v-if="selectedTicket.actions.reject_ticket_button"
        class="reject-inputs is-flex is-align-items-center"
      >
        <input
          class="input is-small mr-1"
          type="text"
          placeholder="Rejection / approval remark"
          v-model="rejectApproveRemark"
        />
        <select
          v-if="selectedTicket.actions.reject_type_select"
          style="font-size: 0.75rem;"
          @change="(event) => rejectType = event.target.value"
        >
          <option
            v-for="option in selectedTicket.actions.reject_type_select.values"
            :key="`reject-type-${option}`"
          >{{ option }}</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import DetailsPaneBase from './DetailsPaneBase.vue';

interface Ticket {
  id: string;
  actions: { [prop: string]: unknown };
  ticket: { [prop: string]: unknown };
}

interface PostData {
  [prop: string]: string;
}

@Component({
  name: 'DetailsTicketApprovalBox',
})
export default class DetailsTicketApprovalBox extends DetailsPaneBase {
  @Prop({ default: [] }) tickets!: Array<Ticket>;
  @Prop({ }) selected!: number;
  @Prop({ default: true }) hasRejectType!: boolean;

  protected rejectApproveRemark = '';
  protected rejectType: string | null = '';

  get selectedTicket() {
    if (this.selected === null) return null;

    return this.tickets[this.selected];
  }

  protected getInitialRejectType() {
    if (!this.tickets) return null;

    const ticket = this.selectedTicket as any;
    if (!ticket || !ticket.actions || !ticket.actions.reject_type_select) return null;

    return ticket.actions.reject_type_select.values[0] as string;
  }

  created() {
    if (this.hasRejectType) {
      this.rejectType = this.getInitialRejectType();
    }
  }
}

</script>
