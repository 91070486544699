<template>
  <div class="button-input">
    <div
      v-if="value"
      data-id="detail-image-thumbnail"
      :title="field"
      @click="onClick"
      @mouseenter="onHover"
    >
      <!-- IMAGE -->
      <img
        v-if="hasImageFileExtension(value) || fileSource"
        class="image"
        :src="fileSource || value"
      >
      <!-- PDF ICON -->
      <i
        v-else-if="getFileExtensionFromURL(value) === 'pdf'"
        class="fa-file-icon fas fa-file-pdf"
      ></i>
      <!-- EXCEL ICON -->
      <i
        v-else-if="hasTableFileExtension(value)"
        class="fa-file-icon fas fa-file-excel"
      ></i>
    </div>
    <div v-else>
      <button
        class="button is-info is-outlined detail-image-button"
        style=""
        @click="onClick"
        :disabled="disabled"
      >
        <span v-if="required"><b>{{ name }}</b></span>
        <span v-else>{{ name }}</span>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import {
  getFileExtensionFromURL,
  hasImageFileExtension,
  hasTableFileExtension,
} from '@/util';

import DetailsDataComponentBase from './DetailsDataComponentBase.vue';

@Component({
  name: 'DetailsImageField',
})
export default class DetailsImageField extends DetailsDataComponentBase {
  protected imageFileExtensions = ['jpg', 'jpeg', 'png', 'jfif'];
  protected excelFileExtensions = ['xlsx', 'xlsm', 'csv'];

  protected fileSource: string | null = null

  get value() {
    const { value } = this.component;
    return (typeof value === 'string' && value.includes('dl=0'))
      ? value.replace('dl=0', 'dl=1')
      : value;
  }

  get disabled() {
    if (this.component.imageType && this.component.imageType === 'ticket') return true;

    return this.actionStatus.type === 'loading' ? true : !this.enabled;
  }

  protected setSourceFromFile(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.fileSource = reader.result as string;
    };
  }

  protected onClick() {
    this.$emit('click', [this.field, this.component]);
  }

  protected onHover() {
    if (!this.value) return;

    this.$emit('mouseenter', [this.field, this.component]);
  }

  protected getFileExtensionFromURL(source: string) {
    return getFileExtensionFromURL(source);
  }

  protected hasImageFileExtension(source: string) {
    return hasImageFileExtension(source);
  }

  protected hasTableFileExtension(source: string) {
    return hasTableFileExtension(source);
  }

  mounted() {
    if (this.value !== null && typeof this.value === 'object') {
      this.setSourceFromFile(this.value);
    }
  }
}

</script>

<style lang="scss" scoped>
.button-input {
  cursor: pointer;

  .image {
    max-height: 30px;
    width: auto;
    overflow: hidden;
  }

  .button {
    display: block;
    font-size: calc(10px + 0.2vh);
    // margin: 8px 1px;
    padding: 3px;
    width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: calc(23px + 0.2vh);

    &:disabled {
      border-color: #0c3f61 !important;
      span {
        font-weight: 400 !important;
        color: #0c3f61 !important;
      }
    }
  }

  .fa-file-icon {
    font-size: 1.5rem;
  }

  input {
    height: calc(18px + 0.2vh);
    font-size: calc(10px + 0.2vh);
  }
}
</style>
