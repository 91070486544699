<template>
  <div>
    <InfoContainer title="Contactpogingen">
      <div v-if="tenant" class="tw-mb-3">
        <div v-if="tenant.name"><span class="tw-font-bold">Naam: </span>{{ tenant.name }}</div>
        <div v-if="tenant.email">
          <span class="tw-font-bold">Email(s): </span>{{ tenant.email }}
        </div>
        <div v-if="tenant.phone">
          <span class="tw-font-bold">Telefoon nummer(s): </span>{{ tenant.phone }}
        </div>
      </div>
      <div
        v-for="log in logs"
        class="tw-rounded-md tw-p-2 tw-my-1 tw-flex tw-flex-col tw-gap"
        :class="{
          'tw-bg-green-50': log.type === 'phone' && log.success,
          'tw-bg-red-50': log.type === 'phone' && !log.success,
          'tw-bg-blue-50': log.type === 'email',
        }"
      >
        <div class="tw-flex tw-flex-cols tw-gap-2 tw-items-center">
          <div>
            <div title="Telefoon" v-if="log.type === 'phone'">
              <PhoneIcon />
            </div>
            <div title="E-mail" v-else-if="log.type === 'email'">
              <AtSymbolIcon />
            </div>
          </div>
          <div>
            {{ format(parseISO(log.created_at), 'd-MMM HH:ii') }}
          </div>
          <div class="tw-text-xs tw-grow tw-text-right">
            {{ log.created_by.replace('@ecorushome.com', '') }}
          </div>
        </div>
        <div>
          <div>{{ log.note }}</div>
          <div class="tw-text-xs"></div>
        </div>
      </div>
      <div>
        <button
          @click="addLogModal = true"
          class="tw-bg-blue-300 tw-p-2 hover:tw-bg-blue-200 tw-rounded-md"
        >
          Log toevoegen
        </button>
      </div>
    </InfoContainer>
    <GenericModal
      v-show="addLogModal"
      @close="addLogModal = false"
      @submit="addLog()"
      title="Log toevoegen"
    >
      <div>
        <FormField
          type="radio"
          v-model="newLog.type"
          label="Telefoon"
          name="type"
          radio-value="phone"
        />
        <FormField
          type="radio"
          v-model="newLog.type"
          label="E-mail"
          name="type"
          radio-value="email"
        />
        <FormField
          rows="2"
          type="textarea"
          v-model="newLog.note"
          label="Notitie"
          :options="logTypes"
        />
        <div v-show="newLog.type === 'phone'">
          <FormField
            type="radio"
            label="Wel gesproken"
            v-model="newLog.success"
            name="success"
            :radio-value="true"
          />
          <FormField
            type="radio"
            label="Niet gesproken"
            v-model="newLog.success"
            name="success"
            :radio-value="false"
          />
        </div>
      </div>
    </GenericModal>
  </div>
</template>

<script setup>
import { reactive, computed, ref } from 'vue';
import FormField from '@/components/shared/FormField.vue';
import { useMutation, useQueryClient, useQuery } from '@tanstack/vue-query';
import InfoContainer from '@/components/shared/InfoContainer.vue';
import { notifications } from '@/util';
import fetchData from '@/util/fetch';
import GenericModal from '@/components/shared/GenericModal.vue';
import { PhoneIcon, AtSymbolIcon } from '@vue-hero-icons/outline';
import { format, parseISO } from 'date-fns';

const addLogModal = ref(false);

const props = defineProps({
  caseDetail: Object,
});

const newLog = reactive({
  type: '',
  note: '',
  success: null,
});

const queryClient = useQueryClient();

const { mutate: addLog } = useMutation({
  mutationFn: async () => {
    const result = await fetchData(`/tickets/api/v1/cases/${props.caseDetail.id}/contact-logs`, {
      method: 'POST',
      body: JSON.stringify(newLog),
    });
    return result;
  },
  onSuccess: () => {
    notifications.addNotification({
      message: 'Log toegevoegd',
      type: 'success',
    });
    addLogModal.value = false;

    queryClient.invalidateQueries(['caseDetail', String(props.caseDetail.id)]);
    queryClient.invalidateQueries(['cases']);

    newLog.type = '';
    newLog.note = '';
    newLog.success = null;
  },
  onError: (error) => {
    console.error('Error adding log', error);
    let message = error.detail || 'Unknown error';
    if (error.detail[0]) {
      message = error.detail[0].msg;
    }
    notifications.addNotification({
      message: `Failed to add log: ${message}`,
      type: 'danger',
    });
  },
});

const logTypes = [
  {
    value: 'phone',
    label: 'Bellen',
  },
  {
    value: 'email',
    label: 'E-mail',
  },
];

const logs = computed(() => props.caseDetail.contact_logs);

const { data: tenant } = useQuery({
  queryKey: computed(() => ['tenant', String(props.caseDetail.location_id)]),
  queryFn: async () => {
    try {
      const result = await fetchData(
        `/tickets/api/v1/locations/${props.caseDetail.location_id}/tenants`
      );
      if (result.status !== 'OK' || !result) {
        return;
      }
      return result?.data[0];
    } catch (error) {
      throw new Error('Failed to fetch tenants data');
    }
  },
  refetchOnWindowFocus: false,
});
</script>
