<template>
  <div class="">
    <!-- ECORUS INPUT MAINTENANCE INFO -->
    <div class="box input-ecorus flex-none">
      <details-box-heading :label="components.maintenance_information.name" />
      <div class="box-content">
        <template
          v-for="[field, component] in Object.entries(components.maintenance_information)"
        >

          <details-numeric-input
            v-if="componentType(component) === 'numberInput'"
            :key="field"
            :field="field"
            :component="component"
            @change="updateMasterData"
          />

          <details-search-selector
            v-if="componentType(component) === 'selector'"
            :key="field"
            :field="field"
            :component="component"
            @change="updateMasterData"
          />

          <details-text-input
            v-if="componentType(component) === 'textInput'"
            :key="field"
            :field="field"
            :component="component"
            @change="updateMasterData"
          />

          <details-text-area-input
            v-if="componentType(component) === 'textareaInput'"
            :key="field"
            :field="field"
            :component="component"
            @change="updateMasterData"
          />

        </template>
      </div>
    </div>

    <!-- ECORUS INPUT PRODUCTS -->
    <div
      v-if="shouldRenderComponentBox(components.products)"
      class="box products flex-none"
    >
      <details-box-heading :label="components.products.name" />
      <div class="box-content">
        <template v-for="[productType, productTypeData] in productTypes">

          <template v-for="item in productTypeData.items">
            <template
              v-for="[field, component] in Object.entries(item).sort(
                ([fieldA]) => (fieldA.includes('delete') ? -1 : 0)
              )"
            >

              <details-delete-cross
                v-if="componentType(component) === 'deleteCross'"
                :key="field"
                field="value"
                :component="component"
                @click="refreshDetails"
              />

              <details-numeric-input
                v-if="componentType(component) === 'numberInput'"
                :key="field"
                :field="field"
                :component="component"
                @change="updateMasterData"
              />

              <details-search-selector
                v-if="componentType(component) === 'selector'"
                :key="field"
                :field="field"
                :component="normalizedProductComponent(component)"
                display="value"
                submit-value="code"
                @change="updateMasterData"
              />

            </template>
          </template>

          <!-- ADD PRODUCT BUTTON -->
          <div :key="`add-${productType}`" class="buttons is-right">
            <details-action-button
              v-if="productTypeData.actions.length > 0"
              :component="productTypeData.actions[0]"
              @click="refreshDetails"
            />
          </div>
        </template>
      </div>
    </div>

    <!-- ECORUS INPUT IMAGES -->
    <div class="box maintenance-images flex-none">
      <details-box-heading :label="components.images.name" />
      <!-- IMAGE FIELDS -->
      <div class="box-content is-flex is-flex-wrap-wrap is-align-items-center">
        <details-image-field
          v-for="[field, component] in createTicketImageFields"
          class="mr-1"
          :key="`image-${field}-${component.value}`"
          :field="field"
          :component="component"
          @click="onClickImageField"
          @mouseenter="onHoverImageField"
        />
      </div>
      <!-- IMAGE PREVIEW / UPLOAD -->
      <details-image-preview
        v-if="hasImage(activeImage)"
        :field="activeImageField"
        :component="activeImageComponent"
        @click="onClickImageField"
      />
      <details-image-upload
        v-else-if="activeImage"
        :field="activeImageField"
        :component="activeImageComponent"
        @uploadstart="onUploadImageStart"
        @uploadend="onUploadImageEnd"
      />
    </div>

    <!-- IMAGE MODAL -->
    <details-image-modal
      v-if="showImageModal"
      :key="`${createTicketImages.length}-${activeImageIndex}`"
      type="design"
      :images="createTicketImages"
      :initial-index="activeImageIndex"
      :delete="showImageDeleteButton ? onDeleteImage : null"
      :close="onCloseImageModal"
    />

  </div>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import requests from '@/requests';

import {
  STATUS_OK,
} from '@/constants';

import type { CustomResponse } from '@/interfaces';

import DetailsPaneBase from './DetailsPaneBase.vue';

interface ProductComponent {
  value: string;
  values: Array<{ value: string; code: string }>;
}

interface ImageComponent {
  value: string;
  action: { [prop: string]: string };
}

type ImageField = [string, ImageComponent]

@Component({
  name: 'DetailsMaintenanceTaskInput',
})
export default class DetailsMaintenanceTaskInput extends DetailsPaneBase {
  protected activeImage: ImageField | null = null;
  protected showImageModal = false;
  protected isProcessingImage = false;

  get productTypes() {
    if (!this.components.products) return [];

    const products = Object.entries(this.components.products).filter(([productType]) => (
      productType !== 'name'
    ));

    return products;
  }

  get showImageDeleteButton() {
    return this.status === 'created';
  }

  get activeImageField() {
    return this.activeImage ? this.activeImage[0] : null;
  }

  get activeImageComponent() {
    return this.activeImage ? this.activeImage[1] : null;
  }

  get createTicketImageFields() {
    if (!this.components.images) return [];

    return Object.entries(this.components.images)
      .filter(([imageField]) => imageField !== 'name') as Array<[string, ImageComponent]>;
  }

  get createTicketImages() {
    return this.createTicketImageFields.filter(([imageField, imageData]) => {
      if (imageField === 'name') return false;
      return !!(imageData as ImageComponent).value;
    }) as Array<[string, ImageComponent]>;
  }

  get activeImageIndex() {
    if (!this.activeImage) return 0;

    for (let idx = 0; idx < this.createTicketImages.length; idx += 1) {
      if (this.createTicketImages[idx][0] === this.activeImage[0]) {
        return idx;
      }
    }

    return 0;
  }

  protected hasImage(imageField: ImageField) {
    return imageField && !!imageField[1].value;
  }

  protected onCloseImageModal() {
    this.showImageModal = false;
  }

  protected onClickImageField([field, component]: ImageField) {
    if (this.isProcessingImage) return;

    this.activeImage = [field, component];
    if (component.value) {
      this.showImageModal = true;
    }
  }

  protected onHoverImageField([field, component]: ImageField) {
    if (this.isProcessingImage) return;

    this.activeImage = [field, component];
  }

  protected onUploadImageStart() {
    this.isProcessingImage = true;
  }

  protected onUploadImageEnd(response: CustomResponse) {
    this.isProcessingImage = false;
    if (response.status === STATUS_OK) {
      const responseData = response.data[0];

      for (let idx = 0; idx < this.createTicketImageFields.length; idx += 1) {
        const [field, component] = this.createTicketImageFields[idx];
        if (field === responseData.attribute_name) {
          component.value = responseData.attribute_value;
          this.components.images = { ...this.components.images };
          return;
        }
      }
    }
  }

  protected async onDeleteImage(image: ImageField) {
    this.isProcessingImage = true;

    const [field, component] = image;
    const response = await requests.details.deleteTaskDetailForClient(
      `api/v1/${component.action.delete}`,
      { [field]: component.value },
    );

    if (response.status === STATUS_OK) {
      component.value = '';
      this.components.images = { ...this.components.images };
      if (this.createTicketImages.length < 1) {
        this.onCloseImageModal();
      }
    }

    this.isProcessingImage = false;
  }

  protected normalizedProductComponent(component: ProductComponent) {
    const selectedProduct = component.values.find(({ code }) => code === component.value);
    return {
      ...component,
      value: selectedProduct ? selectedProduct.value : 'Select a product',
    };
  }

  created() {
    this.activeImage = this.createTicketImages.length > 0 ? this.createTicketImages[0] : null;
  }
}
</script>
