<template>
  <div class="is-flex is-justify-content-space-between is-align-items-center my-1 is-relative">
    <details-data-field-label :label="name" :required="required" />

    <div class="wrapper">
      <div
        class="dropdown is-right"
        :class="{ 'is-active': !isCollapsed }"
        v-outer-click="collapse"
      >
        <!-- TRIGGER -->
        <div class="dropdown-trigger">
          <button
            class="button is-fullwidth is-outlined flex is-justify-content-space-between"
            data-id="detail-search-selector"
            :class="classes"
            :disabled="disabled"
            @click="toggleCollapse"
          >
            <span>{{ getObjectValue(selection, display) }}</span>
            <span class="icon is-small">
              <i class="fas fa-angle-down" aria-hidden="true"></i>
            </span>
          </button>
        </div>

        <!-- DROPDOWN CONTENT -->
        <div class="dropdown-menu">
          <div class="dropdown-content">
            <input
              class="input"
              type="text"
              v-model="filterInput"
            >
            <div v-if="filteredOptions.length" class="items">
              <a
                v-for="option in filteredOptions"
                :key="getObjectValue(option, display)"
                class="dropdown-item"
                :data-id="`detail-search-selector-item?=${getObjectValue(option, display)}`"
                @click="() => onSelectionChange(option)"
              >
                {{ getObjectValue(option, display) }}
              </a>
            </div>
            <div v-else style="margin: 0.6rem;">No results</div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { STATUS_OK } from '@/constants';
import { Component, Prop } from 'vue-property-decorator';
import { sortStringWithNumbers } from '@/logic/common';
import DetailsSelector from './DetailsSelector.vue';

type SelectOption = string | number | { [prop: string]: unknown };

@Component({
  name: 'DetailsSearchSelector',
})
export default class DetailsSearchSelector extends DetailsSelector {
  @Prop({ default: null }) isDisabled!: boolean | null;

  protected filterInput = '';
  protected isCollapsed = true;
  protected selection: SelectOption = this.value;

  get filteredOptions() {
    const filteredOptions = this.options.filter((option) => {
      const optionStr = this.getObjectValue(option as SelectOption, this.display) as string;
      return optionStr.toLowerCase().includes(this.filterInput.toLowerCase());
    }) as Array<SelectOption>;

    return filteredOptions.sort((a, b) => sortStringWithNumbers(
      this.getObjectValue(a, this.display) as string,
      this.getObjectValue(b, this.display) as string,
    ));
  }

  get disabled() {
    if (this.isDisabled !== null) return this.isDisabled;

    return this.actionStatus.type === 'loading' ? true : !this.enabled;
  }

  protected async onSelectionChange(item: SelectOption) {
    this.selection = item;

    this.collapse();
    this.filterInput = '';

    if (!this.autoUpdate) {
      this.$emit('change', item);
      return;
    }

    const data = this.getObjectValue(item, this.submitValue);
    const response = await this.submitAction({ [this.field]: data });
    if (response.status === STATUS_OK) {
      this.$emit('change', item);
    }
  }

  protected toggleCollapse() {
    this.filterInput = '';
    this.isCollapsed = !this.isCollapsed;
  }

  protected collapse() {
    this.filterInput = '';
    this.isCollapsed = true;
  }

  created() {
    this.selection = this.value;
  }
}

</script>

<style lang="scss" scoped>
.is-success {
  select {
    border-color: rgb(62, 189, 109) !important;

    &:after {
      border-color: rgb(62, 189, 109) !important;
    }
  }
}

.is-danger {
  select {
    border-color: rgb(196, 69, 69) !important;

    &:after {
      border-color: rgb(196, 69, 69) !important;
    }
  }
}

.items {
  overflow-y: scroll;
  max-height: 350px;
}

.dropdown-content {
  width: 300px;

  .dropdown-item {
    font-size: 0.73rem;
  }
}

.button {
  width: 200px !important;
  font-size: 0.73rem;
  height: calc(23px + 0.2vh);

    &:disabled {
      opacity: 0.9;
    }

  span {
    text-overflow: clip !important;
    white-space: nowrap !important;
    overflow: hidden !important;
  }
}

.input {
  font-size: 0.73rem;
}
</style>
