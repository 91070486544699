import { render, staticRenderFns } from "./DetailsInspectionInputBox.vue?vue&type=template&id=26e31e6a"
import script from "./DetailsInspectionInputBox.vue?vue&type=script&lang=ts"
export * from "./DetailsInspectionInputBox.vue?vue&type=script&lang=ts"
import style0 from "./DetailsInspectionInputBox.vue?vue&type=style&index=0&id=26e31e6a&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports