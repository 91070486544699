import { Notification, App } from '@/interfaces/';
import { getStoreAccessors } from 'vuex-typescript';
import { STATUS_ALLOWED } from '@/constants';
import { StoreState, CommonModuleState } from './state';

import type { store as Store } from './store';

const module = {
  namespaced: true,

  state: {
    apps: [],
    wocos: [],
    projects: [],
    groups: [],
    notifications: [],
    panes: [],
    selectedGroup: null,
    selectedWoco: null,
    selectedProject: null,
    selectedCallCentreAddress: null,
    status: STATUS_ALLOWED,
    notificationTimeoutId: undefined,
  },

  getters: {
    getApps(state: CommonModuleState) {
      return state.apps;
    },

    getStatus(state: CommonModuleState) {
      return state.status;
    },

    getWocos(state: CommonModuleState) {
      return state.wocos;
    },

    getProjects(state: CommonModuleState) {
      return state.wocos;
    },

    getGroups(state: CommonModuleState) {
      return state.groups;
    },

    getNotifications(state: CommonModuleState) {
      return state.notifications;
    },

    getSelectedGroup(state: CommonModuleState) {
      return state.selectedGroup;
    },

    getSelectedWoco(state: CommonModuleState) {
      return state.selectedWoco;
    },

    getSelectedProject(state: CommonModuleState) {
      return state.selectedProject;
    },

    getPanes(state: CommonModuleState) {
      return state.panes;
    },

    getSelectedCallCentreAddress(state: CommonModuleState) {
      return state.selectedCallCentreAddress;
    },
  },

  mutations: {
    setApps(state: CommonModuleState, apps: Array<App>) {
      state.apps = apps;
    },

    setStatus(state: CommonModuleState, status: string) {
      state.status = status;
    },

    setWocos(state: CommonModuleState, wocos: Array<string>) {
      state.wocos = wocos;
    },

    setGroups(state: CommonModuleState, groups: Array<string>) {
      state.groups = groups;
    },

    setProjects(state: CommonModuleState, projects: Array<string>) {
      state.projects = projects;
    },

    setSelectedCallCentreAddress(state: CommonModuleState, address: string) {
      state.selectedCallCentreAddress = address;
    },

    setNotifications(state: CommonModuleState, notifications: Array<Notification>) {
      clearTimeout(state.notificationTimeoutId);

      const notificationMap = new Map();
      const notificationSet = notifications.reduce((acc, notification) => {
        if (!notificationMap.has(notification.message)) {
          acc.push(notification);
        }
        notificationMap.set(notification.message, true);
        return acc;
      }, [] as Array<Notification>);

      state.notifications = notificationSet;

      setTimeout(() => { // First timeout makes the callback go into effect after the render is complete
        state.notificationTimeoutId = setTimeout(() => {
          state.notifications = [];
        }, 10000);
      }, 1);
    },

    setSelectedGroup(state: CommonModuleState, selectedGroup: string) {
      state.selectedGroup = selectedGroup;
    },

    setSelectedProject(state: CommonModuleState, selectedProject: string) {
      state.selectedProject = selectedProject;
    },

    setSelectedWoco(state: CommonModuleState, selectedWoco: string) {
      state.selectedWoco = selectedWoco;
    },

    setPanes(state: CommonModuleState, panes: Array<string>) {
      state.panes = panes;
    },
  },
};

const { commit, read } = getStoreAccessors<CommonModuleState, StoreState>('common');
const { mutations, getters } = module;

const readApps = read(getters.getApps);
const readWocos = read(getters.getWocos);
const readProjects = read(getters.getProjects);
const readGroups = read(getters.getGroups);
const readStatus = read(getters.getStatus);
const readPanes = read(getters.getPanes);
const readSelectedGroup = read(getters.getSelectedGroup);
const readSelectedProject = read(getters.getSelectedProject);
const readSelectedWoco = read(getters.getSelectedWoco);
const readNotifications = read(getters.getNotifications);
const readSelectedCallCentreAddress = read(getters.getSelectedCallCentreAddress);

const commitApps = commit(mutations.setApps);
const commitStatus = commit(mutations.setStatus);
const commitWocos = commit(mutations.setWocos);
const commitProjects = commit(mutations.setProjects);
const commitGroups = commit(mutations.setGroups);
const commitPanes = commit(mutations.setPanes);
const commitSelectedGroup = commit(mutations.setSelectedGroup);
const commitSelectedProject = commit(mutations.setSelectedProject);
const commitSelectedWoco = commit(mutations.setSelectedWoco);
const commitNotifications = commit(mutations.setNotifications);
const commitSelectedCallCentreAddress = commit(mutations.setSelectedCallCentreAddress);

export default module;

export const makeCommonActions = (store: typeof Store) => ({
  readApps: () => readApps(store),
  readStatus: () => readStatus(store),
  readWocos: () => readWocos(store),
  readProjects: () => readProjects(store),
  readGroups: () => readGroups(store),
  readPanes: () => readPanes(store),
  readSelectedGroup: () => readSelectedGroup(store),
  readSelectedProject: () => readSelectedProject(store),
  readSelectedWoco: () => readSelectedWoco(store),
  readNotifications: () => readNotifications(store),
  readSelectedCallCentreAddress: () => readSelectedCallCentreAddress(store),
  commitApps: (payload: Array<App>) => commitApps(store, payload),
  commitWocos: (payload: Array<string>) => commitWocos(store, payload),
  commitProjects: (payload: Array<string>) => commitProjects(store, payload),
  commitGroups: (payload: Array<string>) => commitGroups(store, payload),
  commitPanes: (payload: Array<string>) => commitPanes(store, payload),
  commitStatus: (payload: string) => commitStatus(store, payload),
  commitSelectedGroup: (payload: string) => commitSelectedGroup(store, payload),
  commitSelectedProject: (payload: string) => commitSelectedProject(store, payload),
  commitSelectedWoco: (payload: string) => commitSelectedWoco(store, payload),
  commitNotifications: (payload: Array<Notification>) => commitNotifications(store, payload),
  commitSelectedCallCentreAddress: (payload: string) => (
    commitSelectedCallCentreAddress(store, payload)
  ),
});
