<template>
  <select class="select" @change="onChange" data-id="task-action-user-select">
    <option :selected="selectedUser === ''" :value="''">Select user</option>
    <option
      v-for="user in users"
      :key="user"
      :selected="user === selectedUser"
    >{{ user }}</option>
  </select>
</template>

<script lang="ts">
import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import { TasksScreenManager } from '@/logic/TasksScreen';
import type MasterItem from '@/logic/TasksScreen/MasterItem';
import requests from '@/requests';

@Component({})
export default class UserSelect extends Vue {
  @Prop({}) action!: any;
  @Prop({}) manager!: TasksScreenManager;
  @Prop({}) masterItem!: MasterItem;

  protected selectedUser: any = this.userToApprove || '';

  get users() {
    return [...this.action.data].sort((a: string, b: string) => a.localeCompare(b));
  }

  get userToApprove() {
    return this.masterItem.actionData.userToApprove;
  }

  @Watch('userToApprove')
  onSelectedUserChange() {
    this.selectedUser = this.masterItem.actionData.userToApprove;
  }

  public onChange = async (event: InputEvent) => {
    if (!this.masterItem) return;
    const target = event.target as HTMLSelectElement;
    this.masterItem.setActionData({
      ...this.masterItem.actionData,
      userToApprove: target.value,
    });

    await requests.details.updateTaskDetailForClient(
      `api/v1/${this.action.url}`,
      { userToApprove: target.value },
    );

    this.manager.updateList(this.masterItem.address);
  }

  mounted() {
    if (this.action.value && this.action.value !== '') {
      this.masterItem.setActionData({
        ...this.masterItem.actionData,
        userToApprove: this.action.value,
      });
    }
  }
}

</script>

<style lang="scss" scoped>
.select {
  margin-right: 5px;
  font-size: 10px;
  height: 14px !important;
}
</style>
