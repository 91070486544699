import { getStoreAccessors } from 'vuex-typescript';

import { StoreState, TasksModuleState } from './state';
import type { store as Store } from './store';

interface MasterDataPayload {
  type: string;
  fields: { [field: string]: boolean };
}

const module = {
  namespaced: true,

  state: {
    masterData: {
      design: {},
      installation: {},
      inspection: {},
      maintenance: {},
      sales: {},
      finance: {},
    },
  },

  getters: {
    getMasterData(state: TasksModuleState) {
      return state.masterData;
    },
  },

  mutations: {
    setMasterData(
      state: TasksModuleState,
      payload: MasterDataPayload,
    ) {
      const typeData = { ...state.masterData[payload.type], ...payload.fields };
      state.masterData = { ...state.masterData, [payload.type]: typeData };
    },
  },
};

const { commit, read } = getStoreAccessors<TasksModuleState, StoreState>('tasks');
const { mutations, getters } = module;

const readMasterData = read(getters.getMasterData);

const commitMasterData = commit(mutations.setMasterData);

export default module;

export const makeTasksActions = (store: typeof Store) => ({
  readMasterData: () => readMasterData(store),

  commitMasterData: (payload: MasterDataPayload) => commitMasterData(store, payload),
});
