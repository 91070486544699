var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',{staticClass:"tw-m-2"},[(_vm.filteredTypeOptions?.length === 1)?_c('td',{staticClass:"tw-align-middle tw-p-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_setup.selectedAddresses),expression:"selectedAddresses"}],staticClass:"tw-mx-auto",attrs:{"type":"checkbox"},domProps:{"value":_vm.address.id,"checked":Array.isArray(_setup.selectedAddresses)?_vm._i(_setup.selectedAddresses,_vm.address.id)>-1:(_setup.selectedAddresses)},on:{"change":function($event){var $$a=_setup.selectedAddresses,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.address.id,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_setup.selectedAddresses=$$a.concat([$$v]))}else{$$i>-1&&(_setup.selectedAddresses=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_setup.selectedAddresses=$$c}}}})]):_vm._e(),_c('td',{staticClass:"tw-align-middle"},[_c(_setup.RouterLink,{staticClass:"tw-text-gray-800 hover:tw-underline",attrs:{"to":{
        name: 'task-detail',
        params: { address: _vm.address.address, type: 'address' },
      }}},[_vm._v(" "+_vm._s(_vm.address.address)+" ")])],1),_vm._l((_vm.filteredTypeOptions),function(typeOption){return _c('td',{key:typeOption,staticClass:"tw-align-middle"},[_c('div',{staticClass:"tooltip-wrapper",on:{"mouseover":function($event){_setup.showTooltip = true},"mouseleave":function($event){_setup.showTooltip = false}}},[(_vm.address[typeOption + '_status'])?_c(_setup.RouterLink,{attrs:{"to":{
          name: 'task-detail',
          params: { address: _vm.address.address, type: typeOption },
        }}},[_c('div',{staticClass:"tw-rounded-lg tw-inline tw-p-1 tw-text-white tw-cursor-pointer",class:_setup.getStatusClass(_vm.address[typeOption + '_status'])},[_vm._v(" "+_vm._s(_vm.address[typeOption + '_status'])+" ")])]):_vm._e()],1)])}),(_vm.filteredTypeOptions?.length === 1 && _vm.address.actions)?_c('td',{staticClass:"tw-align-middle tw-flex tw-p-2"},_vm._l((_vm.address.actions[_vm.address[_vm.filteredTypeOptions + '_status']]),function(option,index){return _c('div',{key:index,staticClass:"tw-flex tw-items-center"},[(option.name === 'Assign')?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_setup.selectedInstaller),expression:"selectedInstaller"}],key:_vm.address.adress_id,staticClass:"tw-border tw-border-2 tw-border-gray-300 tw-rounded-lg tw-p-1 tw-mr-3",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _setup.selectedInstaller=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"","disabled":""}},[_vm._v("Select installer")]),_vm._l((_setup.availableInstallers(_vm.address.project_id)),function(installer){return _c('option',{domProps:{"value":installer}},[_vm._v(" "+_vm._s(installer)+" ")])})],2):_vm._e(),(option.name === 'Cancel' || option.name === 'Assign')?_c('p',{staticClass:"tw-rounded-lg tw-inline tw-px-1 tw-border-2 tw-border-red-500 tw-cursor-pointer tw-mr-3 cancel tw-flex tw-items-center",class:option.name === 'Cancel' && 'hover:tw-bg-red-500',on:{"click":function($event){option.name === 'Cancel'
            ? _setup.showConfirmationModal()
            : _setup.handleAction(option.action, _vm.address.address, _vm.filteredTypeOptions)}}},[_vm._v(" "+_vm._s(option.name)+" ")]):_vm._e(),(_setup.showModal)?_c(_setup.ConfirmationModal,{attrs:{"action":option.action},on:{"confirm":function($event){return _setup.handleAction(option.action, _vm.address.address, _vm.filteredTypeOptions)},"cancel":_setup.hideConfirmationModal}}):_vm._e()],1)}),0):_vm._e(),_c(_setup.NotificationMessage,{attrs:{"message":_setup.notification.message,"type":_setup.notification.type}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }