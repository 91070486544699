<template>
  <div
    class="buttons is-right mb-1"
  >
    <span
      v-if="actionStatus.type === 'error'"
      class="pr-2"
      style="color: red;"
    >{{ actionStatus.message }}</span>
    <button
      class="delete is-small"
      data-id="detail-remove-button"
      @click="onClick"
      :disabled="disabled"
    ></button>
  </div>
</template>

<script lang="ts">
import { STATUS_OK } from '@/constants';
import { Component, Prop } from 'vue-property-decorator';
import DetailsDataComponentBase from './DetailsDataComponentBase.vue';

@Component({
  name: 'DetailsDeleteCross',
})
export default class DetailsDeleteCross extends DetailsDataComponentBase {
  protected async onClick() {
    const response = await this.submitAction({ [this.field]: this.value });
    if (response.status === STATUS_OK) {
      this.$emit('click');
    }
  }
}
</script>
