<template>
  <div class="box sales-signrequest">
    <details-box-heading :label="signrequest.name" />

    <!-- TEMPLATE -->
    <div class="box-content mb-2">
      <p v-if="!hasEmail" style="color: red; margin-bottom: 0.8em;">
        Unable to create sign request, please make sure the client has an email
      </p>
      <!-- SELECT -->
      <details-search-selector
        v-if="signrequestTemplate"
        field="signrequest_template"
        :component="signrequestTemplate"
        :value-override="signrequestTemplateValue"
        :is-disabled="!hasEmail"
        display="value"
        submit-value="data"
        @change="setSelectedSignrequestTemplate"
      />
      <!-- CREATE -->
      <div class="buttons is-right" v-if="selectedSignrequestTemplate">
        <details-signrequest-button
          v-if="showSendSignrequestButton"
          class="mx-1"
          field="signrequest_send_button"
          :component="signrequest.signrequest_send_button"
          :is-disabled="!selectedSignrequestTemplate || !hasEmail"
          @click="refreshDetails"
        />
        <!-- VIEW -->
        <a
          class="button is-small"
          style="margin: 0;"
          data-id="detail-view-signrequest-template-button"
          :href="`https://ecorus.signrequest.com/#/?template_uuid=${templateUUID}`"
          target="_blank"
        >
          View template
        </a>
      </div>
    </div>

    <!-- SENT AT -->
    <div v-if="signrequest.signrequest_sent_at" class="box-content mb-2">
      <details-static-data-field
        field="Sent at"
        :value="signrequest.signrequest_sent_at.value"
      />
    </div>

    <!-- STATUS -->
    <div v-if="signrequestStatus" class="box-content mb-2">
      <details-static-data-field
        field="Status"
      >

        <div class="is-flex is-align-items-center">
          <span class="tag mx-1">{{ signrequestStatus }}</span>
          <details-signrequest-button
            v-if="signrequest.signrequest_check_button"
            field="signrequest_check_button"
            :component="signrequest.signrequest_check_button"
            @click="refreshDetails"
          />
        </div>

      </details-static-data-field>
    </div>

    <!-- VIEW DOCUMENT -->
    <div v-if="signrequest.signrequest_url" class="box-content mb-2">
      <div class="buttons is-right">
        <a
          class="button is-small"
          style="margin: 0;"
          data-id="detail-view-signrequest-document-button"
          :href="signrequest.signrequest_url.value"
          target="_blank"
        >
          Open document
        </a>
      </div>
    </div>

    <!-- VIEW CONTRACT -->
    <div v-if="signrequest.signrequest_pdf_viewer" class="box-content mb-2">
      <div class="buttons is-left">
        <details-PDF-viewer
          :title="`${signrequest.signrequest_pdf_viewer.name} (${address})`"
          label="Show contract"
          :source="signrequest.signrequest_pdf_viewer.url"
        />
      </div>
    </div>

    <!-- SIGNED AT -->
    <div v-if="signrequestSignedAt" class="box-content mb-2">
      <details-static-data-field
        field="Signed at"
        :value="signrequestSignedAt.vaue"
      />
    </div>

    <!-- PANEL QUANTITY -->
    <div v-if="components.status.signrequest_panel_quantity" class="box-content mb-2">
      <details-numeric-input
        field="signrequest_panel_quantity"
        :component="components.status.signrequest_panel_quantity"
        @change="updateMasterData"
      />
    </div>

  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import DetailsPaneBase from './DetailsPaneBase.vue';

interface TemplateOption {
  value: string;
  data: string;
}

@Component({
  name: 'DetailsSalesSignrequestBox',
})
export default class DetailsSalesSignrequestBox extends DetailsPaneBase {
  protected selectedSignrequestTemplate: TemplateOption | null = null;

  get hasEmail() {
    return !!this.components?.client?.email;
  }

  get signrequest() {
    return this.components.signrequest || {};
  }

  get signrequestStatus() {
    if (!this.signrequest.signrequest_status) return null;
    return this.signrequest.signrequest_status.value;
  }

  get signrequestTemplate() {
    return this.signrequest.signrequest_template;
  }

  get signrequestTemplateValue() {
    if (!this.selectedSignrequestTemplate) return null;

    return this.selectedSignrequestTemplate.value;
  }

  get templateUUID() {
    return this.selectedSignrequestTemplate!.data;
  }

  get showSendSignrequestButton() {
    return (
      this.signrequest.signrequest_send_button
      && !this.signrequest.signrequest_sent_at
    );
  }

  get signrequestSignedAt() {
    if (!(
      this.components.status
      && this.components.status.signrequest_signed_at
    )) return null;

    return this.components.status.signrequest_signed_at;
  }

  protected setSelectedSignrequestTemplate(template: TemplateOption) {
    this.selectedSignrequestTemplate = template;
  }

  protected getInitialSignrequestTemplate() {
    if (!this.signrequestTemplate.value) return null;

    const match = this.signrequestTemplate.values.find((template: TemplateOption) => (
      template.data === this.signrequestTemplate.value
    ));

    return match;
  }

  created() {
    this.selectedSignrequestTemplate = this.getInitialSignrequestTemplate();
  }
}
</script>
