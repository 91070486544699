<template>
  <div
    v-if="hasStatus"
    class="is-flex is-align-items-center"
    style="width: 100%;"
  >
    <sort-arrows
      margin="mr-2"
      :list="manager.list"
      :sorters="{
        asc: `sort${typeTitleCase}StatusTypeAscending`,
        desc: `sort${typeTitleCase}StatusTypeDescending`,
      }"
    />
    <table-head-status :manager="manager" :type="type" />
  </div>
  <span v-else>Status</span>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

import type { TasksScreenManager } from '@/logic/TasksScreen';
import type { TaskTypeKey } from '@/interfaces';

import TableHeadStatus from './TableHeadStatus.vue';

@Component({
  name: 'TableHeadStatusSales',
  components: {
    TableHeadStatus,
  },
})
export default class TableHeadStatusSales extends Vue {
  @Prop() manager!: TasksScreenManager;
  @Prop() type!: TaskTypeKey;

  protected list = this.manager.list;

  get typeTitleCase() {
    return this.type[0].toUpperCase() + this.type.slice(1);
  }

  get hasStatus() {
    return this.list.items.some((item) => item.getStatus(this.type));
  }
}

</script>
