<template>
  <div class="tw-bg-white tw-drop-shadow-xl tw-rounded-lg tw-p-5 tw-mb-5">
    <div class="tw-flex tw-items-center tw-justify-center tw-mb-4">
      <button @click="$emit('changeDate', -1)" class="tw-mr-2">
        <i class="fas fa-arrow-left tw-text-xl tw-text-blue-500"></i>
      </button>
      <button
        @click="$emit('changeDate', 0)"
        class="tw-bg-blue-500 tw-text-white tw-py-1 tw-px-4 tw-rounded tw-mx-2"
      >
        TODAY
      </button>
      <button @click="$emit('changeDate', 1)" class="tw-ml-2">
        <i class="fas fa-arrow-right tw-text-xl tw-text-blue-500"></i>
      </button>
    </div>

    <div class="tw-flex tw-justify-center tw-font-semibold tw-text-lg tw-mb-4">
      Selected Date: {{ formattedDate }}
    </div>

    <div class="tw-relative tw-max-h-50">
      <SomeLine :data="chartData" :options="chartOptions" />
    </div>

    <form-field
      class="tw-w-full tw-mt-5"
      label="Enter serial number"
      type="text"
      v-model="queryInput"
    />
  </div>
</template>

<script setup>
import { ref, computed, reactive, defineEmits } from 'vue';
import { watchDebounced } from '@vueuse/core';
import { useQuery } from '@tanstack/vue-query';
import fetchData from '@/util/fetch';
import { format, parseISO, addDays } from 'date-fns';
import { Line as SomeLine } from 'vue-chartjs';
import FormField from '@/components/shared/FormField.vue';
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Tooltip, Legend);

const props = defineProps({
  serialNumber: {
    type: String,
    required: true,
  },
  selectedDate: {
    type: Date,
    required: true,
  },
});

const emit = defineEmits(['changeDate']);

const queryInput = ref('');
const search = reactive({ query: '' });
const selectedDate = computed(() => props.selectedDate);

watchDebounced(
  queryInput,
  () => {
    search.query = queryInput.value;
  },
  { debounce: 300 }
);

const formattedDate = computed(() => format(selectedDate.value, 'yyyy-MM-dd'));

const fetchMeterData = async (serialNumber) => {
  const dateFrom = new Date(selectedDate.value).toISOString();
  const dateUntil = new Date(addDays(selectedDate.value, 1)).toISOString();
  const urlParams = new URLSearchParams({ date_from: dateFrom, date_until: dateUntil });
  const result = await fetchData(
    `/eleena/api/v1/meters/${serialNumber}/quarter-hour-deltas?${urlParams}`
  );
  return result?.status === 'OK' ? result.data : [];
};

const { data: mainMeterData, isLoading: isFetching } = useQuery({
  queryKey: computed(() => ['meterGraph', props.serialNumber, formattedDate.value]),
  queryFn: () => fetchMeterData(props.serialNumber),
  refetchOnWindowFocus: false,
  keepPreviousData: true,
});

const { data: secondaryMeterData, isLoading: isSecondaryFetching } = useQuery({
  queryKey: computed(() => ['meterGraphSecondary', search.query, formattedDate.value]),
  queryFn: () => (search.query ? fetchMeterData(search.query) : []),
  refetchOnWindowFocus: false,
  enabled: computed(() => !!search.query),
});

const datesArray = computed(() =>
  mainMeterData.value?.map((item) => {
    const parsedDate = parseISO(item.date);
    return format(parsedDate, 'HH:mm');
  })
);

const mainDataValues = computed(() => mainMeterData.value?.map((item) => item.delta_power));
const secondaryDataValues = computed(() =>
  secondaryMeterData.value && secondaryMeterData.value.length > 0
    ? secondaryMeterData.value.map((item) => item.delta_power)
    : []
);

const chartData = computed(() => ({
  labels: datesArray.value,
  datasets: [
    {
      label: 'Main Serial Production',
      backgroundColor: 'rgba(75, 192, 192, 0.2)',
      borderColor: 'rgba(75, 192, 192, 1)',
      borderWidth: 2,
      pointBackgroundColor: 'rgba(75, 192, 192, 1)',
      pointRadius: 3,
      fill: true,
      data: mainDataValues.value,
      tension: 0.4,
    },
    secondaryDataValues.value.length > 0
      ? {
          label: 'Comparison Serial Production',
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 2,
          pointBackgroundColor: 'rgba(255, 99, 132, 1)',
          pointRadius: 3,
          fill: true,
          data: secondaryDataValues.value,
          tension: 0.4,
        }
      : null,
  ].filter(Boolean),
}));

const chartOptions = {
  responsive: true,
  maintainAspectRatio: true,
  plugins: {
    legend: {
      display: true,
    },
    title: {
      display: false,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};
</script>
