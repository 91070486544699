<template>
  <div class="tw-m-5 tw-flex tw-flex-col tw-gap-2">
    <div>
      Deze pagina toont alle emailadressen met toegang tot de tickets portal. Als je een account aanmaakt heeft dat emailadres toegang tot tickets v2.
    </div>
    <div class="tw-p-3 tw-border tw-border-gray-100" v-if="users">
      <div class="tw-font-bold">Partner</div>
      <div>
        <select
          @input="$router.push({ name: $route.name, query: { partner: $event.target.value } })"
          :value="$route.query.partner || ''"
          class="tw-p-2 tw-rounded"
        >
          <option value="">-</option>
          <option :value="partner.name" v-for="partner in partners">{{ partner.full_name }} ({{ users.filter(u => u.scope.partners[0] === partner.name).length }})</option>
        </select>
      </div>
    </div>

    <div v-if="form.errors" class="tw-text-red-500">
      {{ form.errors }}
    </div>

    <div class="tw-p-3 tw-border tw-border-gray-100" v-show="route.query.partner">
      <form @submit.prevent="addUser">
        <div>
          Gebruiker toevoegen {{ partnerByShortName[route.query.partner] }}
        </div>
        <div>
          <input type="text" v-model="form.email" placeholder="Email" class="tw-m-1 tw-p-2 tw-border tw-border-gray-400">
        </div>
        <div>
          <button type="submit" class="tw-bg-blue-200 tw-p-1 tw-rounded">Toevoegen</button>
        </div>
        <div v-if="mutateUserLoading">
          Even geduld...
        </div>
      </form>
    </div>

    <div v-if="filteredUsers">
      <table>
        <thead>
          <tr>
            <th class="tw-w-64 tw-p-1">Partner</th>
            <th class="tw-p-1">Email</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in filteredUsers" class="tw-border-b tw-border-gray-200">
            <td class="tw-p-1">{{ partnerByShortName[user.scope.partners[0]] }}</td>
            <td class="tw-p-1">{{ user.email }}</td>
            <td class="tw-p-1">
              <button type="button" @click="deleteUser(user.username)" class="tw-p-1 tw-bg-red-100">&times;</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { useQuery, useQueryClient, useMutation } from '@tanstack/vue-query';
import fetchData from '@/util/fetch';
import { computed, reactive } from 'vue';
import { useRoute } from 'vue-router/composables';

const route = useRoute()

const getUsers = () => fetchData('/auth/api/v1/users?limit=500');
const usersQuery = useQuery({
  queryKey: ['users'],
  queryFn: getUsers,
});

// partners
const getPartners = () => fetchData('/tickets/api/v1/partners');
const partnersQuery = useQuery({
  queryKey: ['partners'],
  queryFn: getPartners,
});

const form = reactive({
  email: '',
  errors: null,
})

const partners = computed(() => partnersQuery.data.value?.data || [])
const partnerByShortName = computed(() =>
  Object.fromEntries(partners.value.map(p => [p.name, p.full_name]))
)

const users = computed(() => usersQuery.data.value?.data)

// if a partner is selected, return only the users for that partner
// otherwise, return all partners
const filteredUsers = computed(() => {
  const {partner} = route.query
  const allUsers = usersQuery.data.value?.data
  if (!allUsers) return;

  const partnerUsers = allUsers.filter(u => Array.isArray(u.scope.partners) && u.scope.partners.length)

  if (partner) {
    return partnerUsers.filter(u => u.scope.partners[0] === partner)
  }
  else {
    return partnerUsers
  }
})

const postUser = () => {
  form.errors = null
  const payload = {
    email: form.email,
    username: form.email,
    full_name: form.email,
    scope: {
      partners: [route.query.partner],
      groups: '*',
    },
    permissions: {
      groups: ['tickets_v2'],
    },
  };

  return fetchData('/auth/api/v1/users', {
    method: 'POST',
    body: JSON.stringify(payload),
  });
};

const queryClient = useQueryClient()

const { mutate: saveUser, isLoading: mutateUserLoading } = useMutation({
  mutationFn: postUser,
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: ['users'] });
    form.email = ''
  },
  onError: (error) => {
    form.errors = error.errors || error;
  },
});

const { mutate: removeUser } = useMutation({
  mutationFn: (email) => fetchData(`/auth/api/v1/users/${email}`, {
    method: 'DELETE',
  }),
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: ['users'] });
  },
  onError: (error) => {
    form.errors = error.errors || error;
  },
});

function addUser() {
  const {email} = form
  if (!email) return
  saveUser()
}

function deleteUser(email) {
  if (confirm(`Wil je ${email} verwijderen?`)) {
    removeUser(email)
  }
}
</script>
