<template>
  <div
    class="tw-rounded-md tw-inline tw-p-1 tw-text-white tw-text-sm"
    :class="colors[alarm.status]"
  >
    {{ alarm.status }}
  </div>
</template>

<script setup>
defineProps({
  alarm: {
    type: Object,
    required: true,
  },
});

const colors = {
  'open': 'tw-bg-yellow-400',
  'closed': 'tw-bg-red-400',
  'muted': 'tw-bg-gray-400',
};
</script>
