<template>
  <div class="is-flex is-justify-content-space-between is-align-items-center my-1 is-relative">
    <details-data-field-label :label="name" :required="required" />
    <dropdown
      :class="classes"
      :items="options"
      :preselect="selection"
      :disabled="!enabled"
      :display="display"
      width="200"
      data-id="detail-selector"
      @change="onSelectionChange"
    />
  </div>
</template>

<script lang="ts">
import { STATUS_OK } from '@/constants';
import { Component, Prop } from 'vue-property-decorator';
import DetailsDataComponentBase from './DetailsDataComponentBase.vue';

type SelectOption = string | number | { [prop: string]: unknown };

interface Dictionary {
  [prop: string]: Dictionary;
}

@Component({
  name: 'DetailsSelector',
})
export default class DetailsSelector extends DetailsDataComponentBase {
  @Prop({ default: true }) autoUpdate!: boolean;
  @Prop({ default: null }) display!: string;
  @Prop({ default: null }) submitValue!: string;

  protected selection: SelectOption = this.value;

  protected getObjectValue = (item: SelectOption, objectKey: string) => {
    if (!objectKey || typeof item === 'string' || typeof item === 'number') return item;

    return objectKey.split('.').reduce((v: Dictionary, k) => v[k], item as Dictionary);
  }

  protected async onSelectionChange(item: SelectOption) {
    this.selection = item;

    if (!this.autoUpdate) {
      this.$emit('change', item);
      return;
    }

    const data = this.getObjectValue(item, this.submitValue);
    const response = await this.submitAction({ [this.field]: data });
    if (response.status === STATUS_OK) {
      this.$emit('change', item);
    }
  }

  created() {
    this.selection = this.value;
  }
}

</script>

<style lang="scss" scoped>
.is-success {
  select {
    border-color: rgb(62, 189, 109) !important;

    &:after {
      border-color: rgb(62, 189, 109) !important;
    }
  }
}

.is-danger {
  select {
    border-color: rgb(196, 69, 69) !important;

    &:after {
      border-color: rgb(196, 69, 69) !important;
    }
  }
}
</style>
