import type { BaseListConfig, Filter, SortingSettings } from '@/interfaces';
import { NAMESPACE_GLOBAL } from '@/constants';
import { events } from '@/util';

export default class BaseList<T> {
  public name: string;
  public items: Array<T>;
  public filteredItems: Array<T>;
  public filter: Filter | undefined;

  public activeSorter = '';

  protected itemsMap: Map<T, boolean> = new Map();
  protected filteredItemsMap: Map<T, boolean> = new Map();
  protected autoSort: boolean;
  protected autoRemoveDuplicates: boolean;
  protected sorters: SortingSettings<T>;
  protected emitOnChange: { namespace?: string; eventName: string; key?: string } | null;

  constructor(items: Array<T> = [], config: BaseListConfig<T> = {}) {
    this.items = items;
    this.filteredItems = this.items;
    this.name = config.name || 'List';
    this.autoSort = !!config.autoSort;
    this.autoRemoveDuplicates = !!config.autoRemoveDuplicates;
    this.sorters = config.sorters || {};
    this.filter = config.filter || undefined;
    this.emitOnChange = config.emitOnChange || null;

    // manual bind required, arrow functions cause super.method to be undefined in subclass
    this.filterItems = this.filterItems.bind(this);
    this.update = this.update.bind(this);

    if (this.filter) {
      this.filter.setConfig({ callback: this.filterItems });
    }

    this.update();
  }

  get hasSorting() {
    return !!Object.keys(this.sorters).length;
  }

  get sortingNames() {
    return Object.keys(this.sorters);
  }

  get itemCount() {
    return this.items.length;
  }

  get filteredItemCount() {
    return this.filteredItems.length;
  }

  public hasItem = (item: T) => this.itemsMap.has(item)

  public getItems = () => this.items;

  public setItems = (items: Array<T>) => {
    this.items = items;
    this.update();
  }

  public removeItem = (item: T | Array<T>) => {
    const itemsToRemoveMap = new Map();
    if (Array.isArray(item)) {
      item.forEach((i) => itemsToRemoveMap.set(i, true));
    } else {
      itemsToRemoveMap.set(item, true);
    }
    this.items = this.items.filter((i) => !itemsToRemoveMap.has(i));
    this.update(false);
    return item;
  }

  public push = (item: T) => {
    this.items.push(item);
    this.update();
  }

  public pop = () => {
    const removedItem = this.items.pop();
    this.update(false);
    return removedItem;
  }

  public append = (items: Array<T>) => {
    this.items = [...this.items, ...items];
    this.update();
  }

  public prepend = (items: Array<T>) => {
    this.items = [...items, ...this.items];
    this.update();
  }

  public sort = (sorterName?: string, updateAfterSort = true) => {
    if (!this.hasSorting) {
      console.warn('Cannot sort without sorting methods.');
      return;
    }

    if (sorterName) {
      const sortingMethod = this.sorters[sorterName];
      if (!sortingMethod) {
        console.warn(`No sorter with name [${sorterName}] exists.`);
        return;
      }
      this.activeSorter = sorterName;
      sortingMethod(this.items);
    } else {
      if (!this.activeSorter) {
        const [activeSorterName] = Object.keys(this.sorters);
        this.activeSorter = activeSorterName;
      }
      this.sorters[this.activeSorter](this.items);
    }

    if (updateAfterSort) {
      this.update();
    }
  }

  public removeDuplicates = (update = true) => {
    const existingItems: Map<T, boolean> = new Map();
    this.items = this.items.filter((item) => {
      if (!existingItems.has(item)) {
        existingItems.set(item, true);
        return true;
      }
      return false;
    });

    if (update) {
      this.update();
    }
  }

  public filterItems() {
    this.filteredItemsMap = new Map();
    if (!this.filter) {
      this.filteredItems = this.items;
      this.items.forEach((item) => this.filteredItemsMap.set(item, true));
      return;
    }
    this.filteredItems = this.filter.evaluate(this.items);
    this.filteredItems.forEach((item) => this.filteredItemsMap.set(item, true));
  }

  protected update(sortOnUpdate = true) {
    this.itemsMap = new Map();
    this.items.forEach((item) => this.itemsMap.set(item, true));

    if (this.autoRemoveDuplicates) {
      this.removeDuplicates(false);
    }

    if (this.autoSort && sortOnUpdate) {
      this.sort(this.activeSorter, false);
    }

    this.filterItems();
    if (this.emitOnChange) {
      const { namespace, eventName, key } = this.emitOnChange;
      events
        .namespace(namespace || NAMESPACE_GLOBAL)
        .emit(eventName, this, key);
    }
  }
}
