<template>
  <i
    class="is-clickable"
    :data-id="dataId"
    :class="[icon, fontSizeClass]"
    @click="$emit('click', $event)"
  ></i>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({ name: 'ClickableIconSmall' })
export default class ClickableIconSmall extends Vue {
  @Prop({ default: '' }) icon!: string;
  @Prop({}) dataId!: string;

  fontSizeClass = 'is-size-6';
}
</script>
