const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

export const addZeroPadding = (amount: number, value: number | string) => {
  let zeros = '';
  for (let count = 0; count < amount; count += 1) {
    zeros += '0';
  }
  return `${zeros}${value}`.slice(-amount);
};

export const formatDateTime = (dateTime: string) => {
  const date = new Date(dateTime);
  const year = Math.abs(date.getFullYear());
  // const month = addZeroPadding(2, date.getMonth() + 1);
  const monthName = months[date.getMonth()];
  const day = date.getDate();
  // const dayPadded = addZeroPadding(2, day);
  const hour = addZeroPadding(2, date.getHours());
  const minute = addZeroPadding(2, date.getMinutes());
  return `${monthName} ${day}, ${year} ${hour}:${minute}`;
};

export const formatDate = (dateTime: string) => {
  const date = new Date(dateTime);
  const year = Math.abs(date.getFullYear());
  const monthName = months[date.getMonth()];
  const day = date.getDate();
  return `${monthName} ${day}, ${year}`;
};

export const formatDateTimeAlt = (dateTime: string, dateFirst = false) => {
  const date = new Date(dateTime);
  const year = Math.abs(date.getFullYear());
  const month = addZeroPadding(2, date.getMonth() + 1);
  // const monthName = months[date.getMonth()];
  const day = addZeroPadding(2, date.getDate());
  // const dayPadded = addZeroPadding(2, day);
  const hour = addZeroPadding(2, date.getHours());
  const minute = addZeroPadding(2, date.getMinutes());
  return dateFirst
    ? `${day}/${month}/${year}, ${hour}:${minute}`
    : `${hour}:${minute}, ${day}/${month}/${year}`;
};

export const isDateTimeField = (input: string) => {
  if (typeof input !== 'string' || !input.includes('-')) return false;

  if (
    input.match(/^\d{4}-\d{2}-\d{2}(\s|T)?(\d{2}:\d{2}:\d{2})?/) || // 2021-01-03 13:45:02 time optional
    input.match(/^\d{2}-\w{3}-\d{4}(\s|T)?(\d{2}:\d{2}:\d{2})?/) // 03-Jan-2021 13:45:02 time optional
  )
    return true;

  return false;
};
