import { STATUS_OK } from '@/constants';
import request from '@/requests';

/* eslint-disable import/prefer-default-export */
export const downloadExcel = async (items: Array<Array<string>>, filename: string) => {
  const response = await request.excel.createExcel(items, filename);

  if (response.status !== STATUS_OK) {
    console.warn(response.message);
    return;
  }

  if (!response.blob) {
    console.warn(`Failed to download [${filename}] blob undefined`);
    return;
  }

  const blobDownloadLink = window.URL.createObjectURL(response.blob);

  const anchor = document.createElement('a');
  anchor.href = blobDownloadLink;
  anchor.download = `${filename}.xlsx`;
  anchor.click();
  window.URL.revokeObjectURL(blobDownloadLink);
  anchor.remove();
};
