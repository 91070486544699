export default class DetailSlideController {
  protected detailContainer: HTMLDivElement | null = null;

  public onMouseDown = (event: MouseEvent, container: HTMLDivElement) => {
    event.preventDefault();
    this.detailContainer = container;
    window.addEventListener('mouseup', this.onMouseUp);
  }

  public onMouseUp = (event: MouseEvent) => {
    event.preventDefault();
    const paneContainer = this.detailContainer!.parentNode?.querySelector('.pane-container');
    const paneRect = paneContainer?.getBoundingClientRect();
    const detailRect = this.detailContainer?.getBoundingClientRect();
    if (paneRect && detailRect) {
      const cursorX = event.clientX;
      const direction = cursorX > detailRect.left ? -1 : 1;
      const distance = direction * Math.abs(detailRect.left - cursorX);

      this.detailContainer!.style.flex = `${detailRect.width + distance}`;
      (paneContainer as HTMLDivElement).style.flex = `${paneRect.width - distance}`;
    }

    window!.removeEventListener('mouseup', this.onMouseUp);
  }
}
