<template>
  <div>
    <info-container title="Description/notes">
      <div class="tw-flex tw-flex-col">
        <textarea
          class="tw-mt-2 tw-p-2 tw-border tw-border-gray-200"
          rows="3"
          :disabled="isLoading"
          v-model="description"
        ></textarea>

        <button
          class="tw-bg-gray-500 tw-rounded tw-p-1 tw-text-white tw-mt-3"
          @click="updateCaseField('description', description)"
        >
          Update info
        </button>
      </div>
    </info-container>
    <generic-modal
      v-if="showModal"
      @close="showModal = false"
      @submit="confirmAction"
      :title="modalTitle"
    >
      <div>
        {{ caseDetail.address }}
      </div>
    </generic-modal>
  </div>
</template>

<script setup>
import { watch, ref, computed } from 'vue';
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';
import { notifications } from '@/util';
import fetchData from '@/util/fetch';
import InfoContainer from '@/components/shared/InfoContainer.vue';
import GenericModal from '@/components/shared/GenericModal.vue';
import { actions } from '@/store';

const props = defineProps({
  caseDetail: {
    type: Object,
    required: true,
  },
});

const queryClient = useQueryClient();
const description = ref(props.caseDetail.description);
const token = actions.auth.readToken();
const showModal = ref(false);
const modalTitle = ref('');
const actionType = ref('');

function updateCaseCache({ data }) {
  if (data) {
    queryClient.setQueryData(['caseDetail', String(props.caseDetail.id)], data.data);
    queryClient.invalidateQueries({ queryKey: ['cases'] });
  }
}

const { mutate: updateCase, isLoading } = useMutation({
  mutationFn: async (payload) => {
    const response = await fetch(`/tickets/api/v1/cases/${props.caseDetail.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      notifications.addNotification({
        message: `Something went wrong: ${response.statusText}`,
        type: 'danger',
      });
      throw new Error(`Failed to update case: ${response.statusText}`);
    }

    const updatedCase = await response.json();
    notifications.addNotification({
      message: `Case ${props.caseDetail.id} updated successfully`,
      type: 'success',
    });
    updateCaseCache({ data: updatedCase });
    return updatedCase;
  },
});

const updateCaseField = async (field, value) => updateCase({ [field]: value });

const confirmAction = async () => {
  showModal.value = false;
  if (actionType.value === 'cancel') {
    await updateCaseField('cancelled_at', new Date());
  } else if (actionType.value === 'finish') {
    await updateCaseField('finished_at', new Date());
  }
};

const { data: ticketsQuery } = useQuery({
  queryKey: computed(() => ['ticketsQuery', props.caseDetail.id]),
  keepPreviousData: true,
  queryFn: async () => {
    try {
      const result = await fetchData(
        `/tickets/api/v1/tickets?view=list&case_id=${props.caseDetail.id}`
      );
      return result.data;
    } catch (error) {
      console.error('Error fetching tickets:', error);
      throw error;
    }
  },
});

const activeTickets = computed(
  () => ticketsQuery.value?.filter((t) => t.status !== 'finished' || t.status !== 'cancelled') || []
);

watch(
  () => props.caseDetail.id,
  () => {
    description.value = props.caseDetail.description;
  }
);

const isClosed = computed(() => props.caseDetail.status !== 'open');
</script>
