import { actions } from '@/store';
import {
  // task types
  TASK_TYPE_CLIENT,
  TASK_TYPE_DESIGN,
  TASK_TYPE_FINANCE,
  TASK_TYPE_INSPECTION,
  TASK_TYPE_INSTALLATION,
  TASK_TYPE_SALES,
  TASK_TYPE_MAINTENANCE,

  // permissions
  PERMISSION_ADMINISTRATORS,
  PERMISSION_ERP_ADMINS,

  PERMISSION_VIEW_SCREEN_DASHBOARD,
  PERMISSION_VIEW_SCREEN_TASKS,
  PERMISSION_VIEW_SCREEN_CLIENTS,
  PERMISSION_VIEW_SCREEN_CALL_CENTRE,

  PERMISSION_VIEW_PANE_DESIGN,
  PERMISSION_VIEW_PANE_CLIENTS,
  PERMISSION_VIEW_PANE_FINANCE,
  PERMISSION_VIEW_PANE_INSPECTION,
  PERMISSION_VIEW_PANE_INSTALLATION,
  PERMISSION_VIEW_PANE_SALES,
  PERMISSION_VIEW_PANE_MAINTENANCE,

  PERMISSION_ACTION_COPY_ADDRESSES,
  PERMISSION_ACTION_EDIT_PRIVATE_GROUP,
  PERMISSION_VIEW_SCREEN_PROJECTS,
  PERMISSION_EDIT_SCREEN_PROJECTS,
  PERMISSION_CREATE_SCREEN_PROJECTS,

} from '@/constants';

const adminPermissions = [ // permissions that will have access to everything
  PERMISSION_ADMINISTRATORS,
  PERMISSION_ERP_ADMINS,
];

// TODO: Replace with permissions obtained from BE
export const screenPermissions: { [key: string]: Array<string> } = {
  clients: [
    PERMISSION_VIEW_SCREEN_CLIENTS,
  ],
  groupEdit: [
    PERMISSION_VIEW_SCREEN_CLIENTS,
  ],
  tasks: [
    PERMISSION_VIEW_SCREEN_TASKS,
  ],
  interactions: [ // needs at least one permission to count as permission required
    PERMISSION_ADMINISTRATORS,
  ],
  dashboard: [
    PERMISSION_VIEW_SCREEN_DASHBOARD,
  ],
  callCentre: [
    PERMISSION_VIEW_SCREEN_CALL_CENTRE,
  ],
  projectsView: [
    PERMISSION_VIEW_SCREEN_PROJECTS,
  ],
  projectsEdit: [
    PERMISSION_EDIT_SCREEN_PROJECTS,
  ],
  projectsCreate: [
    PERMISSION_CREATE_SCREEN_PROJECTS,
  ],
};

export const panePermissions = {
  [TASK_TYPE_DESIGN]: [
    PERMISSION_VIEW_PANE_DESIGN,
  ],
  [TASK_TYPE_INSTALLATION]: [
    PERMISSION_VIEW_PANE_INSTALLATION,
  ],
  [TASK_TYPE_SALES]: [
    PERMISSION_VIEW_PANE_SALES,
  ],
  [TASK_TYPE_FINANCE]: [
    PERMISSION_VIEW_PANE_FINANCE,
  ],
  [TASK_TYPE_INSPECTION]: [
    PERMISSION_VIEW_PANE_INSPECTION,
  ],
  [TASK_TYPE_MAINTENANCE]: [
    PERMISSION_VIEW_PANE_MAINTENANCE,
  ],
  [TASK_TYPE_CLIENT]: [
    PERMISSION_VIEW_PANE_CLIENTS,
  ],
} as { [taskType: string]: Array<string> };

export const actionPermissions: { [key: string]: Array<string> } = {
  copyAddresses: [
    PERMISSION_ACTION_COPY_ADDRESSES,
  ],
  editPrivateGroup: [
    PERMISSION_ACTION_EDIT_PRIVATE_GROUP,
  ],
};

/**
 *
 * @param targetPermissions - The permissions set that the user needs one of to pass the check
 * @param allowAdmins  - When true, admins will always have permission
 */
export const hasPermission = (targetPermissions: Array<string>, allowAdmins = true) => {
  const userPermissions = actions.auth.readPermissions();

  if (targetPermissions.length === 0) return true;
  if (!userPermissions) return false;

  const permissionList = Object.values(userPermissions).reduce((acc, arr) => [...acc, ...arr]);

  if (
    allowAdmins
    && permissionList.some((permission: string) => adminPermissions.includes(permission))
  ) return true;

  return permissionList.some((permission: string) => targetPermissions.includes(permission));
};
