<template>
  <table-row-task
    :item="masterItem"
    :manager="manager"
    @click="onClickRow"
    :selected="selected"
  >
    <!-- STATUS -->
    <table-cell>
      <table-cell-task-status
        v-if="masterItem[type] && masterItem.getStatus(type)"
        :masterItem="masterItem"
        :type="type"
      />
    </table-cell>

    <!-- DATA -->
    <table-cell>
      <div
        v-for="(pair, pairIndex) in dataPairs"
        :key="`col-${pairIndex + 1}-${pair.length}`"
        class="cell-col is-flex mx-1"
        :class="pair[0].label === 'comment' && 'is-flex-direction-row'"
      >
        <data-label
          v-for="(item, itemIndex) in pair"
          :key="`span-${itemIndex + 1}-${item.label}-${item.value}`"
          class="cell-col-span"
          :class="{
            'is-cancelled': item.label === 'comment' && ['rejected', 'cancelled'].includes(status)
          }"
          :label="translateField(item.label)"
          :value="item.value"
          :color="getDataFieldColor(item.label)"
          :title="item.value"
        />
      </div>
    </table-cell>

    <!-- ACTIONS -->
    <table-cell>
      <div class="is-flex">
        <template v-if="masterItem[type] && masterItem.hasActions(type)">
          <action-component
            v-for="action in masterItem[type].actions"
            :key="action.label"
            :action="action"
            :masterItem="masterItem"
            :manager="manager"
          />
        </template>
        <result-label :type="masterItem.statusType" :label="masterItem.statusLabel"/>
      </div>
    </table-cell>

  </table-row-task>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

import type { TasksScreenManager, MasterItem } from '@/logic/TasksScreen';
import type { TaskTypeKey } from '@/interfaces';

import { actions } from '@/store';
import { formatDateTimeAlt } from '@/util';
import { ResultLabel } from '@/components/TasksScreen/actions';
import TableRowTask from './TableRowTask.vue';
import TableCellTaskStatus from './TableCellTaskStatus.vue';
import ActionComponent from '../ActionComponent.vue';

@Component({
  name: 'TableRowBase',
  components: {
    TableRowTask,
    ActionComponent,
    ResultLabel,
    TableCellTaskStatus,
  },
})
export default class TableRowBase extends Vue {
  @Prop({}) masterItem!: MasterItem;
  @Prop({}) manager!: TasksScreenManager;
  @Prop({}) type!: TaskTypeKey;
  @Prop({ default: false }) selected!: boolean;

  get list() {
    return this.manager.list;
  }

  get status() {
    return this.masterItem[this.type].status.status;
  }

  get dataPairs() {
    if (!this.masterItem) return [];

    const data = this.masterItem.getTaskData(this.type);
    const values = Object.entries(data).reduce((acc, [label, value]) => {
      if (actions.tasks.readMasterData()[this.type][label]) return acc;
      if (value !== null && typeof value === 'object') {
        Object.entries(value as object).forEach(([lab, val]) => {
          acc.push({ label: lab, value: val });
        });
      } else {
        acc.push({ label, value });
      }
      return acc;
    }, [] as Array<{ label: string; value: unknown }>)
      .sort((a) => (a.label === 'comment' ? -1 : 1)); // move comment to start

    const pairs = [];
    while (values.length > 0) {
      // make sure comment doesnt have a second pair to allow for flex-direction row
      if (values[0].label === 'comment') {
        pairs.push(values.splice(0, 1));
      } else {
        pairs.push(values.splice(0, 2));
      }
    }

    return pairs;
  }

  protected onClickRow(event: MouseEvent) {
    const target = event.target as HTMLElement;
    // Ignore elements that need click interactions
    const tagNamesToIgnore = ['input', 'select', 'button', 'textarea'];
    if (tagNamesToIgnore.includes((target.tagName || '').toLowerCase())) {
      return;
    }

    this.manager.setSelectedMasterItem(this.masterItem, this.type, true);
  }

  protected getDataFieldColor(label: string) {
    switch (label) {
      case 'assigned_to':
        return 'is-assign';
      case 'comment':
        return 'is-comment';
      default:
        return 'is-info is-light';
    }
  }

  protected translateField(label: string) {
    const translationMap: { [label: string]: string } = {
      total_margin_percentage: 'total_margin_%',
    };

    return translationMap[label] || label;
  }

  protected formatDateTime(value: string) {
    return formatDateTimeAlt(value);
  }
}

</script>

<style lang="scss" scoped>
  tr:hover {
    cursor: pointer;
  }

  .cell-col {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
  }

  .is-comment {
    background-color: #caf8a6;
    text-overflow: ellipsis;
    width: 15rem;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    padding: 0 0.35rem !important;
    height: 30px !important;
    white-space: normal;
  }

  .is-cancelled {
    background-color: #ff7f62;
  }
</style>
