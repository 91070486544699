<template>
  <div class="is-flex is-align-items-center">
    <button
      class="button is-outlined"
      data-id="task-action-bulk"
      :class="color"
      @click="onClick"
      :disabled="disabled"
    >
      {{ label }}
    </button>
    <span class="tag" style="font-size: 0.6rem">{{ this.actionList.length }}</span>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

import type { TasksScreenManager, MasterItem } from '@/logic/TasksScreen';
import type { Action, TaskTypeKey } from '@/interfaces';

interface StoredAction {
  item: MasterItem;
  callback: Function;
  action: Action;
  taskType: TaskTypeKey;
}

@Component({
  name: 'BaseButtonBulk',
})
export default class BaseButtonBulk extends Vue {
  @Prop({}) manager!: TasksScreenManager;
  @Prop({}) actionList!: Array<StoredAction>;
  @Prop({}) enabled!: boolean;
  @Prop({}) submitActions!: Function;
  @Prop({}) updateActions!: Function;

  protected label = this.actionList[0].action.label;
  protected color = 'is-primary';

  get disabled() {
    return this.actionList.some(({ item }) => item.isLoading) || !this.enabled;
  }

  protected onClick() {
    if (
      !['approve', 'reject', 'cancel'].includes(String(this.label).toLowerCase()) ||
      window.confirm(`Are you sure you want to ${String(this.label).toLowerCase()} chosen tasks?`)
    ) {
      this.submitActions(this.actionList);
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .button {
  font-size: 10px !important;
  padding: 6px;
  margin-right: 6px;
  height: 14px;
  width: 50px;
}
</style>
