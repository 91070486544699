<template>
  <div class="differences" :class="color">
    <i
      class="fas mx-1"
      :class="[data.diffNumber < 0 ? 'fa-sort-down' : 'fa-sort-up']"
    ></i>
    <div class="values">
      <span>{{ data.diffNumber !== null ? data.diffNumber : '0' }}</span>
      <span>
        {{ data.diffPercentage !== null ? `${data.diffPercentage}%` : '-' }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'DashboardWeekActuals',
})
export default class DashboardWeekActuals extends Vue {
  @Prop() data!: {
    actual: number;
    diffNumber: number | null;
    diffPercentage: number | null;
  };

  get color() {
    if (!this.data.diffNumber) return 'has-text-success';
    return this.data.diffNumber < 0 ? 'has-text-danger' : 'has-text-success';
  }
}
</script>

<style lang="scss" scoped>
.differences {
  display: flex;
  align-items: center;

  .fas {
    align-self: center;
  }

  .fa-sort-down {
    margin-bottom: 0.2rem;
  }

  .fa-sort-up {
    margin-bottom: -0.3rem;
  }
}

.values {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  line-height: 0.5rem;
  font-size: 0.5rem;
  text-align: right;
  margin-left: auto;
}
</style>
