<template>
  <div>
    <div class="tw-bg-white tw-drop-shadow-xl tw-rounded-b-lg tw-p-3">
      <div class="tw-flex tw-justify-between tw-items-center">
        <div class="tw-font-bold tw-tracking-wider tw-text-gray-500 tw-my-3">
          <div class="tw-text-sm">
            Case ID: {{ caseDetail.id }} <br />
          </div>
          <div v-if="caseDetail.location" class=" tw-text-lg">
            {{ caseDetail.location.address.street }} {{ caseDetail.location.address.house_number }}
          </div>
        </div>

        <CaseStatus :status="caseDetail.status" />
      </div>
      <div class="tw-flex tw-mt-2">
        <div
          class="tw-flex tw-justify-between tw-items-center tw-w-full"
          v-if="caseDetail.alarm"
        >
          <div>
            <span class="tw-font-bold tw-mr-2">Alarm</span>
            <span>{{ caseDetail.alarm.type }}</span>
          </div>
          <AlarmStatus :alarm="caseDetail.alarm" v-if="caseDetail.alarm" />
        </div>
        <div v-else>
          <span class="tw-font-bold tw-mr-2">This case was not created from alarm</span>
        </div>
      </div>
      <div class="tw-flex tw-mt-2">
        <span class="tw-font-bold tw-mr-2">Eleena</span>
        <a
          :href="eleenaLink"
          target="_blank"
          class="tw-h-5 tw-w-5 tw-mb-2 tw-flex tw-items-center tw-justify-center tw-rounded-lg tw-border-green-600 tw-text-green-600"
        >
          <ChartBarIcon class="tw-h-5 tw-w-5" />
        </a>
      </div>
      <div class="tw-flex tw-mt-2">
        <span class="tw-font-bold tw-mr-2">Woco</span>
        <span>{{ caseDetail.woco_name }}</span>
      </div>
      <div class="tw-flex tw-mt-2">
        <span class="tw-font-bold tw-mr-2">Aangemaakt</span>
        <span>{{ formatDate(caseDetail.created_at) }}</span>
      </div>
      <div class="tw-flex tw-mt-2" v-if="caseDetail.opened_at">
        <span class="tw-font-bold tw-mr-2">Geopend</span>
        <span>{{ formatDate(caseDetail.opened_at) }}</span>
      </div>
      <div class="tw-flex tw-mt-2">
        <span class="tw-font-bold tw-mr-2">Aangemaakt door</span>
        <span>{{ removeDomainFromEmail(caseDetail.created_by) }}</span>
      </div>
      <div class="tw-flex tw-mt-2" v-if="caseDetail.muted_till">
        <span class="tw-font-bold tw-mr-2">Gemute tot</span>
        <span>{{ formatDate(caseDetail.muted_till) }}</span>
      </div>

      <div class="tw-flex tw-my-3 tw-w-full tw-gap-3" v-show="!isClosed">
        <button
          v-show="caseDetail.status === 'created' || caseDetail.status === 'muted'"
          class="tw-bg-blue-500 hover:tw-bg-blue-400 tw-rounded tw-p-1 tw-text-white tw-w-1/2 disabled:tw-opacity-50 disabled:tw-cursor-not-allowed"
          @click="showConfirmation('open')"
        >
          Open
        </button>

        <button
          v-show="canBeClosed"
          class="tw-bg-red-500 hover:tw-bg-red-400 tw-rounded tw-p-1 tw-text-white tw-w-1/2 disabled:tw-opacity-50 disabled:tw-cursor-not-allowed"
          @click="showConfirmation('cancel')"
        >
          Cancel
        </button>

        <button
          v-show="caseDetail.status !== 'muted'"
          class="tw-bg-gray-500 hover:tw-bg-gray-400 tw-rounded tw-p-1 tw-text-white tw-w-1/2 disabled:tw-opacity-50 disabled:tw-cursor-not-allowed"
          @click="showConfirmation('mute')"
        >
          Mute
        </button>

        <button
          v-show="canBeClosed"
          class="tw-bg-green-500 hover:tw-bg-green-400 tw-rounded tw-p-1 tw-text-white tw-w-1/2 disabled:tw-opacity-50 disabled:tw-cursor-not-allowed"
          @click="showConfirmation('finish')"
        >
          Finish
        </button>
      </div>
    </div>

    <generic-modal
      v-if="confirm.action"
      @close="confirm.action = null"
      @submit="confirmAction"
      :title="confirm.title"
    >
      <div>
        {{ caseDetail.address }}
      </div>
      <div v-if="confirm.action === 'mute'">
        <FormField
          label="Periode"
          type="select"
          v-model="confirm.period"
          :options="['1 week', '2 weken', '1 maand', '3 maanden', '1 jaar']"
        />
      </div>
    </generic-modal>

    <div v-if="isLoading">
      Loading...
    </div>
  </div>



</template>

<script setup>
import { formatDate, notifications } from '@/util';
import { computed, reactive } from 'vue';
import { useMutation, useQueryClient, useQuery } from '@tanstack/vue-query';
import GenericModal from '@/components/shared/GenericModal.vue';
import { add, format } from 'date-fns'
import { fetchResponse } from '@/util/fetch';
import FormField from '@/components/shared/FormField.vue';
import fetchData from '@/util/fetch';
import AlarmStatus from './AlarmStatus.vue';
import CaseStatus from './CaseStatus.vue';
import { ChartBarIcon } from '@vue-hero-icons/outline';

const queryClient = useQueryClient();

const confirm = reactive({
  title: '',
  action: null,
  period: '1 week',
})

const props = defineProps({
  caseDetail: {
    type: Object,
    required: true,
  },
});

const eleenaLink = computed(() => {
  const locationId = props.caseDetail.location_id;

  if (locationId && locationId.length > 6) {
    const postalCode = locationId.substring(0, 4);
    const postalCodeExt = locationId.slice(4, 6);
    const houseNumber = locationId.slice(6);

    return `https://reporting.eleena.eu/client-overview/?postcode=${postalCode}&postcode_ext=${postalCodeExt}&housenmbr=${houseNumber}`;
  }

  return '';
});

function updateCaseCache({ data }) {
  if (data) {
    queryClient.setQueryData(['caseDetail', String(props.caseDetail.id)], data.data);
    queryClient.invalidateQueries({ queryKey: ['cases'] });
  }
}

const { mutate: updateCase, isLoading } = useMutation({
  mutationFn: async (payload) => {
    const response = await fetchResponse(`/tickets/api/v1/cases/${props.caseDetail.id}`, {
      method: 'PUT',
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      notifications.addNotification({
        message: `Something went wrong: ${response.statusText}`,
        type: 'danger',
      });
      throw new Error(`Failed to update case: ${response.statusText}`);
    }

    const updatedCase = await response.json();
    notifications.addNotification({
      message: `Case ${props.caseDetail.id} updated successfully`,
      type: 'success',
    });
    updateCaseCache({ data: updatedCase });
    return updatedCase;
  },
});

const showConfirmation = (action) => {
  confirm.action = action;
  if (action === 'mute') {
    confirm.title = 'Voor hoe lang wil je deze case muten?';
  }
  else {
    confirm.title = `Are you sure you want to ${action} this case?`;
  }
  confirm.period = '1 week';
};

const confirmAction = async () => {
  if (confirm.action === 'cancel') {
    updateCase({ 'cancelled_at': new Date() });
  }
  else if (confirm.action === 'finish') {
    updateCase({ 'finished_at': new Date() });
  }
  else if (confirm.action === 'mute') {
    const duration = intervalToDuration(confirm.period);
    const mutedTill = format(add(new Date(), duration), 'yyyy-MM-dd');

    updateCase({ 'muted_till': mutedTill });
  }
  else if (confirm.action === 'open') {
    if (props.caseDetail.status === 'muted') {
      const yesterday = format(add(new Date(), { days: -1 }), 'yyyy-MM-dd');
      updateCase({
        'muted_till': yesterday,
        'opened_at': new Date(),
      });
    }
    else {
      updateCase({ 'opened_at': new Date() });
    }
  }

  confirm.action = null
};

function intervalToDuration(interval) {
  return {
    '1 week': { weeks: 1 },
    '2 weken': { weeks: 2 },
    '1 maand': { months: 1 },
    '3 maanden': { months: 3 },
    '1 jaar': { years: 1 },
  }[interval]
}

const { data: ticketsQuery } = useQuery({
  queryKey: computed(() => ['ticketsQuery', props.caseDetail.id]),
  keepPreviousData: true,
  queryFn: async () => {
    try {
      const result = await fetchData(
        `/tickets/api/v1/tickets?view=list&case_id=${props.caseDetail.id}`
      );
      return result.data;
    } catch (error) {
      console.error('Error fetching tickets:', error);
      throw error;
    }
  },
});

const canBeClosed = computed(() => {
  const activeTickets = ticketsQuery.value?.filter((t) => t.status !== 'approved' && t.status !== 'cancelled') || []
  return ['created', 'open'].includes(props.caseDetail.status) && activeTickets.length === 0;
});

const isClosed = computed(() => !['created', 'open', 'muted'].includes(props.caseDetail.status));

function removeDomainFromEmail(email) {
  return email.replace('@ecorushome.com', '');
}
</script>
