<template>
  <div class="m-5">
    <div v-if="isLoading">Loading...</div>

    <div v-if="order">
      <div class="mb-5">
        <router-link :to="{ name: SCREEN_PARTNER_ORDERS }">Terug naar bestellingen</router-link>
      </div>
      <div style="max-width: 800px">
        <div class="columns" v-for="(val, key) in details" :key="key">
          <div class="column is-3 has-text-grey-light">{{ key }}</div>
          <div class="column">{{ val }}</div>
        </div>

        <div v-for="product in order.products" :key="product.code" class="mb-2 box">
          <div class="columns">
            <div class="column is-one-quarter">{{ product.amount }}x</div>
            <div class="column">
              <div>{{ product.name }}</div>
              <div>{{ product.code }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { useQuery } from '@tanstack/vue-query'
import { format, parseISO } from 'date-fns'
import { nl } from 'date-fns/locale'
import fetchData from '@/util/fetch'
import { computed } from 'vue'
import { useRoute } from 'vue-router/composables'
import { SCREEN_PARTNER_ORDERS } from '@/constants'

function orderToStatus(order) {
  if (!order) return null
  const statusses = ['cancelled', 'approved', 'created']
  for (const status of statusses) {
    if (order[`${status}_at`]) return status
  }
  return null
}

function orderToLocalStatus(order) {
  const status = orderToStatus(order)
  return {
    created: 'aangemaakt',
    approved: 'goedgekeurd',
    cancelled: 'geannuleeerd',
  }[status]
}

function orderToStatusClass(order) {
  const status = orderToStatus(order)
  return {
    created: 'is-primary',
    approved: 'is-success',
    cancelled: 'is-danger is-light',
  }[status]
}

function orderToStatusDate(order) {
  return order[`${orderToStatus(order)}_at`]
}

function localizeDateTime(d) {
  if (!d) return d
  return format(parseISO(d), 'dd LLL yyyy H:mm', { locale: nl })
}

function localizeDate(d) {
  if (!d) return d
  return format(parseISO(d), 'dd LLL yyyy', { locale: nl })
}

export default {
  setup() {
    const route = useRoute()

    const getProjects = () => fetchData('/api/v1/config/projects')
    const projects = useQuery({
      queryKey: ['projects'],
      queryFn: getProjects,
    })

    const getPartnerOrders = () => fetchData(`/api/v1/tickets/partner-orders/${route.params.id}`)
    const { isLoading, data } = useQuery({
      queryKey: ['partner-orders', { id: route.params.id }],
      queryFn: getPartnerOrders,
    })
    const order = computed(() => data.value?.data)

    const details = computed(() => ({
      'Bestelling ID': order.value.id,
      'Partner': order.value.partner_name,
      'Project': projects.data.value?.data.find(p => p.id === order.value.project_id).name,
      'Bezorgadres': `${order.value.address} ${order.value.postal_code} ${order.value.city}`,
      'Bezorgdatum': localizeDate(order.value.date),
      'Bezorgtijd': order.value.time_until ? `van ${order.value.time_from} tot ${order.value.time_until}` : order.value.time_from,
      'Bezorgbenodigdheden': order.value.requirements.join(', ') || 'Geen',
      'Bezorgreferentie': order.value.shipment_unloading_reference || 'Geen',
      'Contact': `${order.value.contact_name} / ${order.value.contact_phone}`,
      'Aangemaakt': localizeDateTime(order.value.created_at),
      'Goedgekeurd': localizeDateTime(order.value.approved_at) || 'Nee',
      'Geannuleerd': localizeDateTime(order.value.cancelled_at) || 'Nee',
    }))

    return {
      SCREEN_PARTNER_ORDERS,
      isLoading,
      localizeDateTime,
      order,
      details,
      projects: computed(() => projects.data.value?.data),
      localStatus: computed(() => orderToLocalStatus(order.value)),
      statusDate: computed(() => orderToStatusDate(order.value)),
      statusClass: computed(() => orderToStatusClass(order.value)),
    }
  },
}
</script>
