<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import type { TasksScreenManager } from '@/logic/TasksScreen';

@Component({
  name: 'BaseTasksPane',
})
export default class BaseTasksPane extends Vue {
  @Prop() manager!: TasksScreenManager;

  protected paneInfo = this.manager.panes.getPaneInfo(this.$options.name!);

  created() {
    this.manager.panes.onPaneOpen(this.$options.name!);
  }

  // beforeDestroy() {
  //   this.manager.panes.onPaneClose(this.$options.name!);
  // }
}
</script>
