<template>
  <div style="font-size: 11px;" class="p-3">
    <div v-if="manager.isLoading">
      Verwerken...
    </div>
    <div v-else>
      <div class="is-flex mb-4 dropdowns is-align-items-center">
        <label class="label">Types: </label>
        <dropdown
          dataId="interactions-filter-type"
          display="label"
          margin="mr-2"
          :preselect="typeDropdown[0]"
          :items="typeDropdown"
          @change="(item) => item.handler()"
        />
        <!-- <label class="label">Values: </label>
        <dropdown
          dataId="interactions-filter-value"
          display="label"
          margin="mr-2"
          :preselect="valueDropdown[0]"
          :items="valueDropdown"
          @change="(item) => item.handler()"
        /> -->
        <label class="label">Context: </label>
        <dropdown
          dataId="interactions-filter-context"
          display="label"
          margin="mr-2"
          :preselect="contextDropdown[0]"
          :items="contextDropdown"
          @change="(item) => item.handler()"
        />
        <label class="label">User: </label>
        <dropdown
          dataId="interactions-filter-user"
          display="label"
          margin="mr-2"
          :preselect="userDropdown[0]"
          :items="userDropdown"
          @change="(item) => item.handler()"
        />
      </div>
      <div v-if="manager.interactions.filteredItems.length > 0" class="table-wrapper">
        <table class="table is-bordered is-hoverable mt-4 is-narrow">
          <thead>
            <tr>
              <th>Type</th>
              <th>Context</th>
              <th>Value</th>
              <th>User</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="interaction in manager.interactions.filteredItems"
              :key="interaction.id"
            >
              <td>{{ interaction.type }}</td>
              <td>{{ interaction.context }}</td>
              <td>{{ interaction.value }}</td>
              <td>{{ interaction.created_by }}</td>
              <td>{{ interaction.created_at }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>No results found</div>
    </div>
  </div>
</template>

<script lang="ts">
import { InteractionsScreenManager } from '@/logic/InteractionsScreen';
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class InteractionsView extends Vue {
  public manager = new InteractionsScreenManager(this.$router);

  get filter() {
    return this.manager.interactions.filter;
  }

  get typeDropdown() {
    const types = [...new Set(this.manager.interactions.items.map((item) => item.type))];
    const dropdown = types.map((type) => ({
      label: type,
      handler: () => this.filter!.setState({ type }),
    }));
    dropdown.unshift({ label: 'All', handler: () => this.filter!.setState({ type: '' }) });
    return dropdown;
  }

  get valueDropdown() {
    const values = [...new Set(this.manager.interactions.items.map((item) => item.value))];
    const dropdown = values.map((value) => ({
      label: value,
      handler: () => this.filter!.setState({ value }),
    }));
    dropdown.unshift({ label: 'All', handler: () => this.filter!.setState({ value: '' }) });
    return dropdown;
  }

  get contextDropdown() {
    const contexts = [...new Set(this.manager.interactions.items.map((item) => item.context))];
    const dropdown = contexts.map((context) => ({
      label: context,
      handler: () => this.filter!.setState({ context }),
    }));
    dropdown.unshift({ label: 'All', handler: () => this.filter!.setState({ context: '' }) });
    return dropdown;
  }

  get userDropdown() {
    const users = [...new Set(this.manager.interactions.items.map((item) => item.created_by))];
    const dropdown = users.map((user) => ({
      label: user,
      handler: () => this.filter!.setState({ createdBy: user }),
    }));
    dropdown.unshift({ label: 'All', handler: () => this.filter!.setState({ createdBy: '' }) });
    return dropdown;
  }
}
</script>

<style lang="scss" scoped>
.dropdowns {
  font-size: 1em !important;

  .label {
    font-size: 1.1em !important;
    margin: 0.3em;
  }
}

.table-wrapper {
  font-size: 0.9em;
  display: inline-block;
  max-height: 80vh;
  overflow-y: scroll;

  table {
    margin-right: 0.3em;
    // min-width: 76em;
    margin-top: 1em;
  }

  &::-webkit-scrollbar {
    width: 0.35rem;
    height: 0.35rem;
    color: #868686;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    margin-top: 1.7em;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    cursor: grab;
  }
}
</style>
