<template>
  <table-task :config="tableConfig" :manager="manager" />
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import type { TasksScreenManager } from '@/logic/TasksScreen';

import { TASK_TYPE_FINANCE } from '@/constants';

import TableTask from './TableTask.vue';
import TableHeadActionsBase from './TableHeadActionsBase.vue';
import TableHeadStatusBase from './TableHeadStatusBase.vue';
import TableHeadDataFinance from './TableHeadDataFinance.vue';
import TableRowBase from './TableRowBase.vue';

@Component({
  name: 'FinanceTable',
  components: {
    TableTask,
  },
})
export default class FinanceTable extends Vue {
  @Prop() manager!: TasksScreenManager;

  protected tableConfig = {
    headers: [
      {
        component: TableHeadStatusBase,
        props: {
          manager: this.manager,
          type: TASK_TYPE_FINANCE,
        },
        width: '20%',
      },
      {
        component: TableHeadDataFinance,
        props: {
          manager: this.manager,
        },
      },
      {
        component: TableHeadActionsBase,
        props: {
          manager: this.manager,
          type: TASK_TYPE_FINANCE,
        },
        width: '25%',
      },
    ],
    row: {
      component: TableRowBase,
      props: {
        manager: this.manager,
        type: TASK_TYPE_FINANCE,
      },
    },
  }
}
</script>
