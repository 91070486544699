<template>
  <div class="is-flex-grow-1" style="max-width: 350px;">
    <!-- Filter & Sort controls -->
    <div class="controls is-flex is-align-items-center is-justify-content-start">
      <div class="is-flex is-align-items-center mr-2">
        <input
          type="checkbox"
          :data-id="'group-edit-addresses-select-all?selected=' + list.selectAllActive"
          :checked="list.selectAllActive"
          @click="onSelectAllCheck"
        >
        <label class="label my-0 ml-2" style="font-size: 11px;">Selecteer alles</label>
      </div>
      <div class="is-flex is-align-items-center mr-2">
        <input
          type="checkbox"
          :data-id="'group-edit-addresses-filter?selected=' + list.filter.isEnabled('noGroup')"
          :checked="list.filter.isEnabled('noGroup')"
          @click="list.filter.toggle('noGroup')"
        >
        <label class="label my-0 ml-2" style="font-size: 11px;">Zonder Groepen</label>
      </div>
      <div class="is-flex is-align-items-center mr-2">
        <input
          type="checkbox"
          :data-id="'group-edit-addresses-filter?selected=' + list.filter.isEnabled('unavailable')"
          :checked="list.filter.isEnabled('unavailable')"
          @click="list.filter.toggle('unavailable')"
        >
        <label class="label my-0 ml-2" style="font-size: 11px;">Beschikbare adressen</label>
      </div>
      <div class="select is-small">
        <select data-id="group-edit-addresses-sort">
          <option @click="list.sort('streetAscending')">Straat [A-Z]</option>
          <option @click="list.sort('streetDescending')">Straat [Z-A]</option>
        </select>
      </div>
    </div>
    <!-- List Counters -->
    <div class="counters">
      <span
        class="has-text-success is-size-7"><b>{{ list.selectedItemCount }}</b> Geselecteerd</span>
      <span> / </span>
      <span class="has-text-link is-size-7"><b>{{ list.filteredItemCount }}</b> Zichtbaar</span>
      <span> / </span>
      <span class="has-text-black is-size-7"><b>{{ list.itemCount }}</b> Totaal</span>
    </div>
    <!-- Search bar -->
    <search-bar
      spacing="my-0"
      :value="list.filter.state.searchValue"
      @change="(event) => list.filter.handler('search')(event)"
    />
    <!-- Address Container -->
    <scroll-container height="35vh" border="0.1em solid #ccc">
      <div
        class="group-address-list"
        style="min-height: 100%;"
      >
        <div
          v-for="item in list.filteredItems"
          :key="item.address"
          :class="[addressItemBaseClasses, getItemBackgroundColor(item), ...getFlexStyling()]"
          @click="(event) => handleClick(item, event)"
        >
          {{ item.address }}
          <p>
            <tooltip
              v-if="item.isAssignedToGroup"
              :value="item.groups.join(', ')"
              position="left"
            >
              <i
                @mouseenter="item.handleMouseEvent"
                @mouseleave="item.handleMouseEvent"
                class="fas fa-info-circle is-size-6 has-text-grey-light"
                data-id="group-edit-address-info"
                style="color: #333;"
              ></i>
            </tooltip>
          </p>
        </div>
      </div>
    </scroll-container>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import type MultiSelectList from '@/logic/common/MultiSelectList';
import type SelectableAddress from '@/logic/GroupEditScreen/SelectableAddress';

@Component({ name: 'GroupAddressList' })
export default class GroupEditView extends Vue {
  @Prop() list!: MultiSelectList<SelectableAddress>;

  addressItemBaseClasses = 'list-item is-unselectable is-clickable px-4 py-1 is-size-7';

  handleClick(item: SelectableAddress, event: MouseEvent) {
    if (item.isAvailable) {
      this.list.filter!.toggle('unavailable');
      this.list.onClickItem(item, event);
      this.list.filter!.toggle('unavailable');
    }
  }

  getFlexStyling() {
    return ['is-flex', 'is-flex-direction-row', 'is-justify-content-space-between'];
  }

  getItemBackgroundColor(item: SelectableAddress) {
    if (!item.isAvailable) return 'disabled-item';
    if (this.list.anchor && item === this.list.anchor) {
      return this.list.isSelected(item) ? 'selection-anchor' : 'deselection-anchor';
    }
    return this.list.isSelected(item) ? 'selected-item' : 'deselected-item';
  }

  onSelectAllCheck() {
    this.list.filter!.toggle('unavailable');
    this.list.toggleSelectAll();
    this.list.filter!.toggle('unavailable');
  }
}
</script>

<style lang="scss" scoped>
::v-deep .search-bar {
  .input {
    background-color: #222 !important;
    color: #f5f5f5 !important;

    &::placeholder,
    &::-moz-placeholder,
    &::-webkit-input-placeholder {
      color: #aaa !important;
    }
  }
}

::v-deep .list-item {
  &:nth-child(n + 2) {
    border-top: 1px solid #ccc;
  }

  &.selection-anchor {
    background-color: #a7c8d3 !important;
  }

  &.deselection-anchor {
    background-color: #eee8e1 !important;
  }

  &.selected-item {
    background-color: #d4edf5 !important;
  }

  &.deselected-item {
    background-color: #f5f5f5 !important;
  }

  &.disabled-item {
    cursor: default !important;
    background-color: lighten(#f5f5f5, 50%);
    color: lighten(black, 70%);

    .fas {
      color: lighten(black, 80%) !important;
    }
  }
}

.controls {

  .label {
    font-size: 10px !important;
  }
}
</style>
