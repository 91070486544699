<template>
  <div class="tw-flex-column tw-h-screen tw-justify-between tw-gap-5">
    <div class="tw-flex tw-flex-row tw-gap-5 tw-ml-5">
      <tasks-filter :filter="filter" :statusOptions="statusOptions" :typeOptions="typeOptions" />
      <div class="tw-w-full tw-flex tw-gap-5">
        <div class="tw-my-2 tw-flex-auto tw-flex-col">
          <tasks-list :filteredTypeOptions="filteredTypeOptions" :filter="filter" />
        </div>
        <div class="tw-mr-5 tw-flex-auto tw-flex-col tw-w-[400px]" v-if="$route.params.address">
          <task-detail :address="$route.params.address" :type="$route.params.type" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, reactive, watch } from 'vue';
import TaskDetail from '@/components/tasksV2/TaskDetail.vue';
import TasksFilter from '@/components/tasksV2/TasksFilter.vue';
import TasksList from '@/components/tasksV2/TasksList.vue';

const defaultFilter = {
  projects: [],
  groups: [],
  statuses: {},
  types: [],
  partners: '',
  wocos: '',
  query: '',
};

const filter = reactive(defaultFilter);

const statusOptions = [
  'no_status',
  'approved',
  'cancelled',
  'assigned',
  'created',
  'ongoing',
  'finished',
  'rejected',
];

const typeOptions = ['design', 'sales', 'inspection', 'installation'];

const filteredTypeOptions = computed(() => {
  if (filter.types.length === 0) {
    return typeOptions;
  } else {
    return typeOptions.filter((option) => filter.types.includes(option));
  }
});

// watch(filter, (newValue, oldValue) => {
//   console.log('new', newValue);
// });
</script>
