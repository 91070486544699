<template>
  <div class="block m-5">
    <article class="message is-danger is-small" v-if="error">
      <div class="message-header">
        <p>{{error.title}}</p>
        <button class="delete is-small" aria-label="delete" @click="error = null"></button>
      </div>
      <div class="message-body">
        {{error.info}}
      </div>
    </article>

    <div class="field">
      <label class="label">Woco</label>
      <div class="control">
        <div class="select">
          <select v-model="woco">
            <option :value="w.name" v-for="w in wocos" :key="w.id">{{w.name}}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="field">
      <div class="control">
        <button
          @click="download"
          class="button is-link"
          :disabled="downloading"
          :class="{'is-loading': downloading}"
        >Export tickets</button>
      </div>
    </div>

    <div class="mt-5 has-text-grey is-size-7">
      <strong class="has-text-grey">Let op</strong>
      <ul>
        <li>Deze export is alleen toegankelijk voor mensen met speciale toegang. Een HTTP 401/403 foutmelding geeft aan dat je geen toegang hebt.</li>
        <li>Deze export bevat geen gegevens van partners die externe ticketing-software gebruiken, zoals DGM en Guidion.</li>
        <li>Als er geen tickets zijn voor de geselecteerde woco dan ontvang je een volledig leeg Excel bestand</li>
      </ul>
    </div>
  </div>
</template>

<script lang="js">
export default {
  data() {
    return {
      wocos: [],
      woco: null,
      downloading: false,
      error: null,
    }
  },
  async mounted() {
    const token = this.$store.getters['auth/getToken']
    const result = await fetch(
      '/api/v1/config/wocos?view=full',
      { headers: { Authorization: `Bearer ${token}` } },
    )
    if (result.ok) {
      this.wocos = (await result.json()).data
      this.woco = this.wocos[0].name
    } else {
      this.error = {
        title: 'Error getting wocos',
        info: `HTTP ${result.status}`,
      }
      console.error(await result.text())
    }
  },
  methods: {
    async download() {
      this.downloading = true

      const token = this.$store.getters['auth/getToken']
      const result = await fetch(
        `/api/v1/tickets?${new URLSearchParams({
          view: 'excel',
          woco: this.woco || '',
        })}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      this.downloading = false

      if (result.ok) {
        const match = /filename="([^"]+)"/.exec(result.headers.get('content-disposition'))
        const filename = match[1]

        const element = document.createElement('a');
        const blob = new Blob([await result.blob()], {type: 'application/vnd.ms-excel'});
        element.href = window.URL.createObjectURL(blob);
        element.setAttribute('download', filename);
        element.click();
      }
      else {
        this.error = {
          title: 'Error getting Excel export',
          info: `HTTP ${result.status}`,
        }
        console.error(await result.text())
      }
    },
  },
}
</script>

<style>

</style>
