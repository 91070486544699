<template>
  <div class="client-editor-wrapper p-2">
    <h3 class="has-text-centered is-size-4 has-text-weight-bold">{{ address }}</h3>
    <button
      class="button thin-button is-info is-outlined"
      @click="addPerson"
    >
      Add person

    </button>
    <template v-if="clientCopy">

      <div
        class="person-outer"
        v-for="(person, personIndex) in clientCopy.people"
        :key="`${personIndex}-${seed}`"
      >
        <div class="person-details row">
          <!-- NAME -->
          <div class="col">
            <p class="buttons">
              <button
                class="button thin-button is-info is-outlined"
                @click="() => removePerson(person)"
              >
                Remove person
              </button>
            </p>
            <input class="input is-small mb-1" type="text" v-model="person.name" placeholder="Name">
          </div>

          <!-- EMAILS -->
          <div class="col">
            <p class="buttons">
              <button
                class="button thin-button is-success is-outlined"
                @click="() => addEmail(person)"
              >
                Add email
              </button>
            </p>
            <template v-for="(email, emailIndex) in person.emails">
              <p
                class="control has-icons-right"
                :key="`${emailIndex}-${seed}`"
              >
                <input
                  type="text"
                  class="input is-small mb-1"
                  v-model="email.address"
                  placeholder="Email"
                >
                <span class="icon is-small is-right" @click="() => removeEmail(person, email)">
                  <i class="fas fa-times"></i>
                </span>
              </p>
            </template>
          </div>

          <!-- PHONES -->
          <div class="col">
            <p class="buttons">
              <button
                class="button thin-button is-success is-outlined"
                @click="() => addPhone(person)"
              >
                Add phone number
              </button>
            </p>
            <template v-for="(phone, phoneIndex) in person.phones">
              <p
                class="control has-icons-right"
                :key="`${phoneIndex}-${seed}`"
              >
                <input
                  type="text"
                  class="input is-small mb-1"
                  v-model="phone.number_text"
                  placeholder="Phone number"
                >
                <span class="icon is-small is-right" @click="() => removePhone(person, phone)">
                  <i class="fas fa-times"></i>
                </span>
              </p>
            </template>
          </div>

        </div>
      </div>

    </template>

    <button
      class="button thin-button is-success is-outlined"
      :class="!saveEnabled && 'is-disabled'"
      :disabled="!saveEnabled"
      @click="onSave"
    >
      Save
    </button>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import request from '@/requests';
import { STATUS_OK } from '@/constants';
import {
  deepCopy,
  isDeepEqual,
  events,
  notifications,
} from '@/util';

type OperationContext = 'household' | 'person' | 'phone' | 'email';

interface Email {
  address: string;
  id?: number;
}

interface Phone {
  number?: string;
  number_text?: string;
  id?: number;
}

interface Person {
  name: string;
  id?: number;
  emails: Array<Email>;
  phones: Array<Phone>;
}

interface Operation {
  type: string;
  context: OperationContext;
  data: any;
}

@Component({
  name: 'ClientEditor',
})
export default class ClientEditor extends Vue {
  @Prop({ default: () => ({}) }) client!: any;
  @Prop({ default: '' }) woco!: string;

  protected seed = Math.random(); // random value used for component keys, changed after updates
  protected clientCopy: any = null;
  protected operations: Array<Operation> = [];

  protected get address() {
    return this.client.household.address;
  }

  protected get saveEnabled() {
    if (!this.clientCopy) return false;

    return this.clientCopy.people.every((person: Person) => {
      if (!person.name) return false;

      const phonesNotEmpty = person.phones.every((phone: Phone) => !!phone.number_text);
      const emailsNotEmpty = person.emails.every((email: Email) => !!email.address);
      return phonesNotEmpty && emailsNotEmpty;
    });
  }

  protected updateSeed() {
    this.seed = Math.random();
  }

  protected async onSave() {
    const operations = this.generateOperations();
    const json = {
      woco: this.woco,
      operations,
    };
    const response = await request.clients.updateClientDetails(this.address, json);
    if (response.status === STATUS_OK) {
      events.emit('client-updated');
      notifications.addNotification({
        type: 'success',
        message: 'Client has been updated',
      });
    } else {
      notifications.addNotification({
        type: 'danger',
        message: `Failed to update client: ${response.message}`,
      });
    }
  }

  protected createOperation(
    type: string,
    context: OperationContext,
    person: Person,
    data: any,
  ): Operation {
    const operationData = { ...data };
    if (context !== 'person') {
      operationData.person_name = person.name;
      operationData.person_id = person.id;
    }
    return {
      type,
      context,
      data: operationData,
    };
  }

  protected addPerson() {
    this.clientCopy.people.push({
      name: '',
      emails: [],
      phones: [],
    });
    this.updateSeed();
  }

  protected addEmail(person: Person) {
    person.emails.push({ address: '' });
    this.updateSeed();
  }

  protected addPhone(person: Person) {
    person.phones.push({ number: '', number_text: '' });
    this.updateSeed();
  }

  protected removePerson(person: Person) {
    this.clientCopy.people = this.clientCopy.people.filter((p: Person) => p !== person);
    if (person.id) {
      this.operations.push(this.createOperation('remove', 'person', person, {
        ...person,
        person_id: person.id,
      }));
    }
    this.updateSeed();
  }

  protected removeEmail(person: Person, email: Email) {
    // eslint-disable-next-line no-param-reassign
    person.emails = person.emails.filter((e: object) => e !== email);
    if (email.id) {
      this.operations.push(this.createOperation('remove', 'email', person, { ...email }));
    }
    this.updateSeed();
  }

  protected removePhone(person: Person, phone: Phone) {
    // eslint-disable-next-line no-param-reassign
    person.phones = person.phones.filter((p: Phone) => p !== phone);
    if (phone.id) {
      this.operations.push(this.createOperation('remove', 'phone', person, { ...phone }));
    }
    this.updateSeed();
  }

  protected generateOperations() {
    const operations = [...this.operations];
    const { people } = this.clientCopy;

    const sortWeigths = {
      household: 3,
      person: 2,
      email: 1,
      phone: 1,
    };

    people.forEach((personNew: Person) => {
      let personOld: Person | null = null;
      let phonesOld: Array<Phone> = [];
      let emailsOld: Array<Email> = [];

      // Add person create / update operations
      if (personNew.id) {
        personOld = this.client.people.find((p: Person) => p.id === personNew.id);
        phonesOld = personOld!.phones;
        emailsOld = personOld!.emails;

        if (personNew.name !== personOld!.name) {
          operations.push({ type: 'update', context: 'person', data: personNew });
        }
      } else {
        operations.push({ type: 'create', context: 'person', data: personNew });
      }

      // Add phone create / update operations
      personNew.phones.forEach((phoneNew: Phone) => {
        if (phoneNew.id) {
          const phoneOld = phonesOld.find((p: Phone) => p.id === phoneNew.id);
          if (!isDeepEqual(phoneNew, phoneOld)) {
            operations.push(this.createOperation('update', 'phone', personNew, phoneNew));
          }
        } else {
          operations.push(this.createOperation('create', 'phone', personNew, phoneNew));
        }
      });

      // Add email create / update operations
      personNew.emails.forEach((emailNew: Email) => {
        if (emailNew.id) {
          const emailOld = emailsOld.find((e: Email) => e.id === emailNew.id);
          if (!isDeepEqual(emailNew, emailOld)) {
            operations.push(this.createOperation('update', 'email', personNew, emailNew));
          }
        } else {
          operations.push(this.createOperation('create', 'email', personNew, emailNew));
        }
      });
    });

    const sortedOperations = operations.sort((a, b) => (
      sortWeigths[b.context] - sortWeigths[a.context]
    ));

    return sortedOperations;
  }

  mounted() {
    this.clientCopy = deepCopy(this.client);
  }
}
</script>

<style lang="scss">
.client-editor-wrapper {
  background-color: #fff;
  min-width: 75vh;

  .close-modal {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }

  .person-outer {
    // border: 1px solid #ccc;
    border-radius: 0.25rem;
    background-color: #F5F5F5;
    padding: 0.75rem;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    .buttons {
      margin: 0;
    }
  }

  .person-controls {
    padding: 0 0.4rem;
  }

  .control {
    .icon {
      pointer-events: all !important;
      cursor: pointer;
      z-index: 1;
      top: 0.25rem !important;
    }
  }

  .row {
    display: flex;
  }

  .col {
    display: flex;
    flex-direction: column;
    padding: 0 0.5rem;
    width: calc(100% / 3);
  }
}
</style>
