<template>
  <info-container title="Eleena data">
    <div v-if="Object.keys(eleenaData).length !== 3 && !eleenaData.date_activated">
      <div class="tw-flex tw-justify-between tw-space-x-4 tw-text-gray-700">
        <span>Serial Number:</span>
        <span class="tw-font-bold">{{ eleenaData.serial_number }}</span>
      </div>
      <div class="tw-flex tw-justify-between tw-space-x-4 tw-text-gray-700">
        <span>Production:</span>
        <span
          class="tw-font-bold"
          :class="{ 'tw-text-red-600': parseInt(eleenaData.production?.split('.')[0]) < 20 }"
          >{{ eleenaData.production }}</span
        >
      </div>
      <div class="tw-flex tw-justify-between tw-space-x-4 tw-text-gray-700">
        <span>Date Activated:</span>
        <span class="tw-font-bold">{{ formatDateTime(eleenaData.date_activated) }}</span>
      </div>
      <div class="tw-flex tw-justify-between tw-space-x-4 tw-text-gray-700">
        <span>Total Panel Power:</span>
        <span class="tw-font-bold">{{ eleenaData.total_panel_power }}</span>
      </div>

      <div v-if="eleenaData.alarms.length > 0">
        <ul>
          <li v-for="(alarm, index) in eleenaData.alarms" :key="index">
            <p>
              <span>{{ '\u2757' }} </span> {{ alarm.alarm_title }} <span>{{ '\u2757' }} </span>
            </p>
            <p>{{ alarm.status }} - {{ formatDateTime(alarm.time_stamp) }}</p>
          </li>
        </ul>
      </div>
      <div class="tw-bg-gray-500 tw-rounded tw-p-1 tw-text-white tw-mt-3 tw-flex tw-justify-center">
        <a :href="eleenaUrl" target="_blank" class="tw-text-white"> Go to Eleena Graph </a>
      </div>
    </div>
    <div v-else>
      <ul>
        <li v-for="(alarm, index) in eleenaData.alarms" :key="index">
          <p>
            <span>{{ '\u2757' }} </span> {{ alarm.alarm_title }} <span>{{ '\u2757' }} </span>
          </p>
          <p>{{ alarm.status }} - {{ formatDateTime(alarm.time_stamp) }}</p>
        </li>
      </ul>
    </div>
  </info-container>
</template>

<script setup>
import InfoContainer from '@/components/shared/InfoContainer.vue';
import { formatDateTime } from '@/util';
import { useRoute } from 'vue-router/composables';
import { computed } from 'vue';

const props = defineProps({
  eleenaData: {
    type: Object,
    required: true,
  },
});

const route = useRoute();
const address = computed(() => route.params?.address);

const eleenaGraphURL = (address) => {
  const baseURL = 'https://reporting.eleena.eu/client-overview/';

  const houseNumber = (address.match(/\b(\d+)\b/) || [])[1] || null;
  const postcode = (address.match(/\b(\d{4,5})\b/) || [])[1] || null;
  const extension = (address.match(/\b([A-Z]+)\b/) || [])[1] || null;

  const queryParams = [
    `postcode=${postcode}`,
    `postcode_ext=${extension}`,
    `housenmbr=${houseNumber}`,
  ];

  return `${baseURL}?${queryParams.join('&')}`;
};

const eleenaUrl = computed(() => eleenaGraphURL(address.value));
</script>
