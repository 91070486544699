<template>
  <component :is="is" :label="label"/>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import SuccessResultLabel from './SuccessResultLabel.vue';
import ErrorResultLabel from './ErrorResultLabel.vue';
import LoadingResultLabel from './LoadingResultLabel.vue';

@Component({
  components: {
    SuccessResultLabel,
    ErrorResultLabel,
    LoadingResultLabel,
  },
})
export default class ResultLabel extends Vue {
  @Prop({}) type!: string;
  @Prop({}) label!: string;

  get is() {
    const components = this.$options.components || {};

    if (components[this.convertComponentName(`${this.type}-result-label`)]) {
      return `${this.type}-result-label`;
    }
    return 'base-label';
  }

  private convertComponentName = (component: string) => (component.split('-').map((kebab) => (
    kebab.charAt(0).toUpperCase() + kebab.slice(1))).join('')
  );
}
</script>
