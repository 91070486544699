import { actions } from '@/store';
// import { requests } from '@/util';
import request from '@/requests';
import { plugins, notifications } from '@/util';
import { STATUS_OK } from '@/constants';

import type { BaseGroup, ExistingGroup, GroupCreate } from '@/interfaces';
import type VueRouter from 'vue-router';
import type { SelectableAddress } from '../GroupEditScreen';

export default class GroupEditor {
  public isActive = false;
  public group: ExistingGroup | BaseGroup;
  public isLoading = false;
  public extendedGroup: ExistingGroup | null = null;
  public wocoOptions: Array<string> = [];
  public projectOptions: Array<string> = [];
  public state = {
    search: '',
  }

  protected router: VueRouter;
  protected services = ['ERP', 'B2B'];

  constructor(router: VueRouter, group?: ExistingGroup) {
    this.getWocos();
    this.router = router;
    if (group) {
      this.group = group;
    } else {
      this.group = this.generateNewGroup();
    }
  }

  get availableApps() {
    return actions.common.readApps();
  }

  get buttonDisabled() {
    return (
      this.group.name.trim().length <= 0
      || this.group.apps.length === 0
      || !this.group.woco
    );
  }

  public async getWocos() {
    const response = await request.groups.getAllWocos();
    if (response.status === STATUS_OK) {
      this.wocoOptions = response.data;
    }
  }

  public async getProjects() {
    const response = await request.groups.getAllProjectsForWoco(this.group.woco);
    if (response.status === STATUS_OK) {
      this.projectOptions = response.data.map((project: any) => project.name);
    }
  }

  public create = async (): Promise<ExistingGroup | null> => { // TODO: feedback for the user
    this.group.name = this.group.name.trim();

    // create new group
    const groupCreateResponse = await request.groups.createGroup(this.groupPostData());
    if (groupCreateResponse.status !== STATUS_OK) {
      notifications.setNotificationFromResponse(groupCreateResponse);
      return null;
    }

    const group = groupCreateResponse.data;
    if (!this.extendedGroup) return group;

    // get extended group data
    const extendedGroupResponse = await request.groups.getGroupVisibleForUser(
      this.extendedGroup.name,
      actions.auth.readUser(),
    );
    if (extendedGroupResponse.status !== STATUS_OK) {
      await request.groups.deleteGroup(group.name); // delete the group if it failed to extend
      notifications.setNotificationFromResponse(extendedGroupResponse);
      return null;
    }

    // add extended group's addresses to newly created group
    const { addresses } = extendedGroupResponse.data;
    const updateAddressResponse = await request.groups.updateGroupAddresses(
      group.name,
      addresses,
    );

    if (updateAddressResponse.status !== STATUS_OK) {
      await request.groups.deleteGroup(group.name); // delete the group if it failed to extend
      notifications.setNotificationFromResponse(updateAddressResponse);
      return null;
    }

    return group;
  }

  public updateAddresses = async (
    name: string,
    addresses: Array<SelectableAddress>,
  ): Promise<void> => {
    const json = addresses.map((address) => address.json());
    const response = await request.groups.updateGroupAddresses(name, json);

    if (response.status === STATUS_OK) {
      const { woco, project } = this.group;
      const addressList = json.map((item) => item.address);
      request.tasks.submitAddressesForTaskInitialization(woco, project as string, addressList)
        .then((createTasksResponse) => {
          if (createTasksResponse.status !== STATUS_OK) {
            console.warn('Task creation failed:', createTasksResponse.message);
          }
        });
    }

    notifications.setNotificationFromResponse(response);
  }

  public updateInfo = async (
    name: string,
  ): Promise<void> => {
    if (this.buttonDisabled) return;

    this.group.name = this.group.name.trim();
    const response = await request.groups.updateGroupInfo(name, this.groupPostData());
    if (response.status === STATUS_OK) {
      if (this.router.currentRoute.params.name !== response.data.name) {
        this.router.push(`/clients/group/${response.data.name}`);
      }
      notifications.setNotificationFromResponse(response);
    } else {
      notifications.setNotificationFromResponse(response.data);
    }
  }

  public onChangeName = (event: InputEvent) => {
    const target = event.target as HTMLInputElement;
    this.setGroupInfo({ name: target.value.replace(/[^a-z0-9\-_\s]/gi, '') });
  }

  public setExtendedGroup = (group: ExistingGroup) => {
    this.extendedGroup = group;
    this.setGroupInfo({
      name: `${this.extendedGroup.name} Copy`,
      woco: this.extendedGroup.woco,
      apps: [...this.extendedGroup.apps],
      is_private: this.extendedGroup.is_private,
      created_by: actions.auth.readUser(),
    });
  }

  public setGroupInfo = (groupData: object) => {
    this.group = { ...this.group, ...groupData };
  }

  public generateNewGroup = () => ({
    name: '',
    apps: [],
    created_by: actions.auth.readUser(),
    woco: '',
    is_private: false,
  })

  public setState = (newState: object) => {
    this.state = { ...this.state, ...newState };
  }

  protected groupPostData = () => ({
    ...this.group,
    apps: this.group.apps.map((app) => app.name),
  }) as GroupCreate
}
