import { actions } from '@/store';
import { STATUS_UNAUTHORIZED, STATUS_FORBIDDEN, STATUS_OK } from '@/constants';
import type { CustomResponse } from '@/interfaces';

export class RequestHandler {
  public delete = async (url: string, body?: object): Promise<CustomResponse> => (
    this.fetchWrapper('DELETE', url, body)
  )

  public get = async (url: string, body?: object): Promise<CustomResponse> => (
    this.fetchWrapper('GET', url, body)
  )

  public post = async (
    url: string,
    body: object,
    formData?: FormData,
    isBlob?: boolean,
  ): Promise<CustomResponse> => (
    this.fetchWrapper('POST', url, body, formData, isBlob)
  )

  public put = async (url: string, body: object, formData?: FormData): Promise<CustomResponse> => (
    this.fetchWrapper('PUT', url, body, formData)
  )

  private errorResponse = () => ({
    status: 'error',
    message: 'Pagina niet beschikbaar, probeer het later opnieuw',
    status_code: 500,
  })

  private forbiddenResponse = () => {
    actions.common.commitStatus(STATUS_FORBIDDEN);

    return {
      status: 'error',
      message: 'Gebruiker heeft geen toegang tot deze gegevens',
      status_code: 403,
    };
  }

  private unauthorizedResponse = () => {
    actions.auth.commitToken('');
    actions.auth.commitPermissions(null);
    actions.common.commitStatus(STATUS_UNAUTHORIZED);

    return {
      status: 'error',
      message: 'Gebruiker is niet ingelogd',
      status_code: 401,
    };
  }

  private fetchWrapper = async (
    method: string,
    url: string,
    data?: object,
    formData?: FormData,
    isBlob?: boolean,
  ) => {
    const token = actions.auth.readToken();

    const options: RequestInit = { method };

    if (token) options.headers = { Authorization: `Bearer ${token}` };
    if (data) {
      options.body = JSON.stringify(data);
      if (!options.headers) {
        options.headers = {};
      }
      options.headers = { ...options.headers, 'Content-Type': 'application/json' };
    }
    if (formData) {
      options.body = formData;
      // Remove default Content-Type header
      if (token) options.headers = { Authorization: `Bearer ${token}` };
    }

    try {
      const response = await fetch(url, options);

      return this.standardizeResponse(response, isBlob);
    } catch (error) {
      console.warn(error);
      return this.errorResponse();
    }
  }

  private standardizeResponse = async (
    response: Response,
    isBlob?: boolean,
  ): Promise<CustomResponse> => {
    try {
      const json = isBlob
        ? await this.createBlobResponse(response)
        : await response.json();

      if (json.status_code === 403) return this.forbiddenResponse();
      if (json.status_code === 401) return this.unauthorizedResponse();

      return json;
    } catch (error) {
      console.log(error);
      return {
        status_code: 500,
        message: 'Er is iets misgegaan, probeer het later opnieuw',
        status: 'error',
      };
    }
  }

  private createBlobResponse = async (response: Response) => ({
    blob: await response.blob(),
    message: 'Created',
    status_code: response.status,
    status: STATUS_OK,
  })
}

export default new RequestHandler();
