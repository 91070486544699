<template>
  <select class="select" @change="onChange">
    <option :selected="selectedInstaller === ''" :value="''">Select installer</option>
    <option
      v-for="installer in installers"
      :key="installer"
      :selected="installer === selectedInstaller"
    >{{ installer }}</option>
  </select>
</template>

<script lang="ts">
import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import { TasksScreenManager } from '@/logic/TasksScreen';
import type MasterItem from '@/logic/TasksScreen/MasterItem';

@Component({})
export default class InstallerSelect extends Vue {
  @Prop({}) action!: any;
  @Prop({}) manager!: TasksScreenManager;
  @Prop({}) masterItem!: MasterItem;
  // @Prop({ default: '' }) selected!: string;

  public selectedInstaller: any = this.assignedInstaller || '';

  get installers() {
    return [...this.action.data].sort((a: string, b: string) => a.localeCompare(b));
  }

  get assignedInstaller() {
    return this.masterItem.actionData.assignedInstaller;
  }

  @Watch('assignedInstaller')
  onSelectedInstallerChange() {
    this.selectedInstaller = this.masterItem.actionData.assignedInstaller;
  }

  public onChange = (event: InputEvent) => {
    if (!this.masterItem) return;
    const target = event.target as HTMLSelectElement;
    this.masterItem.setActionData({
      ...this.masterItem.actionData,
      assignedInstaller: target.value,
    });
  }
}

</script>

<style lang="scss" scoped>
.select {
  margin-right: 5px;
  font-size: 10px;
  height: 14px !important;
}
</style>
