<template>
  <scrollable-table max-height="calc(100vh - 13rem)" min-height="calc(100vh - 13rem)" ref="table">
    <thead>
      <tr>
        <table-header
          v-for="(header, index) in headers"
          :key="`header-${index}`"
          :style="header.width && `width: ${header.width};`"
        >
          <component v-if="header.component" :is="header.component" v-bind="header.props" />
          <template v-else>{{ header.text }}</template>
        </table-header>
      </tr>
    </thead>
    <tbody>
      <component
        v-for="item in list.filteredItems"
        :key="item.address"
        :is="row.component"
        v-bind="row.props"
        :masterItem="item"
        :selected="manager.list.isSelected(item)"
      />
    </tbody>
  </scrollable-table>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import type { TasksScreenManager } from '@/logic/TasksScreen';
import TableRowTask from './TableRowTask.vue';

interface TaskTableConfig {
  headers: Array<{
    text?: string;
    component?: Vue;
    props?: { [propName: string]: unknown };
    width?: string;
  }>;
  row: {
    component: Vue;
    props: { [propName: string]: unknown };
  };
}

@Component({
  name: 'TableTask',
  components: { TableRowTask },
})
export default class TableTask extends Vue {
  @Prop() config!: TaskTableConfig;
  @Prop() manager!: TasksScreenManager;

  get headers() {
    return this.config.headers;
  }

  get row() {
    return this.config.row;
  }

  get list() {
    return this.manager.list;
  }

  mounted() {
    const table = this.$refs.table as Vue;
    if (!table) return;

    this.manager.scroll.registerElement(table.$el as HTMLElement);
  }

  updated() {
    const table = this.$refs.table as Vue;
    if (!table) return;

    this.manager.scroll.registerElement(table.$el as HTMLElement);
  }

  beforeDestroy() {
    const table = this.$refs.table as Vue;
    if (!table) return;

    this.manager.scroll.unregisterElement(table.$el as HTMLElement);
  }
}

</script>
