<template>
  <div class="inspection-data">
    <span><b>Status:</b> {{ client.tasks.inspection.status }}</span>
    <span class="pb-2" v-if="client.tasks.inspection.appointment_date">
      <b>Appointment date:</b> {{ client.tasks.inspection.appointment_date }}
    </span>
    <span v-for="ticket in tickets" :key="ticket.ticketId" class="tickets">
      <span><b>Inspector:</b> {{ ticket.partner }} </span>
      <span><b>Current Status:</b> {{ ticket.current_status }} </span>
      <span><b>Ticket created on:</b> {{ ticket.status[0].created_at }} </span>
      <span><b>Roof Type:</b> {{ ticket.input.extra_fields.roof_type }} </span>
      <span><b>Roof Orientation:</b>{{ ticket.input.extra_fields.roof_orientation }}</span>
      <span><b>Roof Angle:</b>{{ ticket.input.extra_fields.roof_angle }}</span>
      <span><b>Orientation:</b>{{ ticket.input.extra_fields.orientation }}</span>
      <span><b>House Side:</b>{{ ticket.input.extra_fields.house_side }}</span>
      <span><b>Gutter Height:</b>{{ ticket.input.extra_fields.gutter_height }}</span>
      <a :href="ticketUrl(ticket)">Click here to go to the ticket</a>
    </span>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class InspectionData extends Vue {
  @Prop({}) client!: any;

  get tickets() {
    const tickets: Array<any> = [];

    if (!this.client.tasks.inspection.tickets) return tickets;
    for (const ticketId in this.client.tasks.inspection.tickets) {
      if (Object.prototype.hasOwnProperty.call(this.client.tasks.inspection.tickets, ticketId)) {
        const ticket = this.client.tasks.inspection.tickets[ticketId];
        ticket.ticketId = ticketId;
        console.log(ticket.ticketId);

        if (ticket.input || ticket.input.extra_fields) tickets.push(ticket);
      }
    }
    return tickets;
  }

  get inspectors() {
    if (!this.client.tasks.inspection.tickets) return [];

    return [
      ...new Set(
        Object.values(this.client.tasks.inspection.tickets).map((ticket: any) => ticket.partner)
      ),
    ];
  }

  public ticketUrl(ticket: any) {
    const { street } = this.client.address;
    const postalCode = this.client.address.postal_code.substring(0, 4).trim();
    const postalCodeExt = this.client.address.postal_code
      .slice(this.client.address.postal_code.length - 2)
      .trim();
    const houseNumber = this.client.address.house_number;
    const { ticketId, partner } = ticket;

    return (
      `https://tickets.ecorushome.com/${partner}/ticketing?invoice=Alle+tickets&text=` +
      `${street}+${houseNumber}+${postalCode}+${postalCodeExt}&ticket=${ticketId}`
    );
  }
}
</script>

<style lang="scss" scoped>
.inspection-data {
  display: flex;
  flex-direction: column;

  .tickets {
    display: flex;
    flex-direction: column;
    padding-bottom: 0.6rem;

    & > * {
      b {
        padding-right: 0.3rem;
      }
    }
  }

  & > * {
    b {
      padding-right: 0.3rem;
    }
  }
}
</style>
