<template>
  <div class="tw-w-full tw-mb-2">
    <div v-if="type == 'radio'">
      <div class="tw-flex tw-items-center">
        <input
          type="radio"
          :name="name"
          :value="radioValue"
          class="tw-mx-2"
          :id="id"
          :disabled="disabled"
          :checked="modelValue == radioValue"
          @input="$emit('input', radioValue)"
          @change="$emit('change', radioValue)"
        />
        <label
          :for="id"
          class="tw-inline-block tw-uppercase tw-tracking-wide tw-text-gray-700 tw-text-xs tw-font-bold tw-cursor-pointer"
          >{{ label }}</label
        >
      </div>
    </div>
    <div v-if="type == 'checkbox'" class="tw-flex tw-items-start tw-mt-4">
      <input
        type="checkbox"
        class="tw-bg-gray-200 tw-rounded tw-border tw-focus:border-gray-600 tw-mr-1"
        :id="id"
        :checked="value == true"
        :disabled="disabled"
        @input="$emit('input', $event.target.checked)"
      />
      <label
        class="tw-inline-block tw-uppercase tw-tracking-wide tw-text-gray-700 tw-text-xs tw-font-bold tw-cursor-pointer"
        :for="id"
        :class="{ 'tw-sr-only': hideLabel }"
        >{{ label }}
      </label>
    </div>
    <label
      v-if="type !== 'checkbox' && type !== 'radio'"
      class="tw-inline-block tw-uppercase tw-tracking-wide tw-text-gray-700 tw-text-xs tw-font-bold tw-mb-2 tw-cursor-pointer"
      :for="id"
      :class="{ 'tw-sr-only': hideLabel }"
    >
      {{ label }}
    </label>
    <input
      v-if="
        !type ||
        type === 'number' ||
        type === 'date' ||
        type === 'time' ||
        type === 'text' ||
        type === 'email' ||
        type === 'file'
      "
      :type="type || 'text'"
      :disabled="disabled"
      class="tw-block tw-w-full tw-bg-gray-200 tw-rounded tw-text-md tw-text-gray-500 tw-p-2 tw-focus:bg-white tw-border tw-focus:border-gray-600 tw-appearance-none tw-focus:outline-none"
      :value="value || modelValue"
      @input="$emit('input', $event.target.value)"
      @change="$emit('change', $event)"
      :id="id"
      :accept="accept"
      :placeholder="placeholder"
    />
    <textarea
      v-if="type === 'textarea'"
      :disabled="disabled"
      :placeholder="placeholder"
      :rows="rows || 1"
      class="tw-w-full tw-bg-gray-200 tw-rounded tw-text-md tw-text-gray-500 tw-p-2 tw-focus:bg-white tw-border tw-focus:border-gray-600 tw-appearance-none tw-focus:outline-none"
      :value="value || modelValue"
      @input="$emit('input', $event.target.value)"
      :id="id"
      :maxlength="maxlength"
    />
    <select
      @input="$emit('input', $event.target.value)"
      v-else-if="type === 'select'"
      class="tw-block tw-w-full tw-bg-gray-200 tw-rounded tw-text-md tw-text-gray-500 tw-text-md tw-rounded tw-p-2"
      :class="[error && 'ring-1 ring-red-500']"
      :disabled="!options || disabled"
      :id="id"
      @change="$emit('change')"
    >
      <option :value="''" v-if="nullable || nullOption">
        {{ nullOption }}
      </option>
      <option
        v-for="option in listOptions"
        :value="option.value"
        :key="String(option.value)"
        :selected="value === option.value"
      >
        {{ option.label }}
      </option>
    </select>
    <multi-select
      v-else-if="type == 'multi'"
      :value="value"
      @input="$emit('input', $event)"
      @search-change="$emit('search-change', $event)"
      :options="options"
      :multiple="multiple"
      :placeholder="placeholder"
      :disabled="disabled"
      :mode="mode"
      :limit="Number(limit)"
      searchable
    />
  </div>
</template>

<script setup>
import { computed, onMounted, watch } from 'vue';
import MultiSelect from './MultiSelect.vue';
import { defineEmits } from 'vue';

const props = defineProps([
  'modelValue',
  'label',
  'options',
  'type',
  'value',
  'multiple',
  'placeholder',
  'nullable',
  'nullOption',
  'disabled',
  'hideLabel',
  'error',
  'rows',
  'limit',
  'mode',
  'accept',
  'name',
  'radioValue',
  'maxlength',
]);

const emit = defineEmits(['update:modelValue', 'input', 'change']);

const id = computed(() => props.label.replace(/^[a-zA-Z0-9]/, '_'));

const listOptions = computed(() => {
  if (!props.options) return null;
  if (props.options.length < 1) return props.options;
  if (props.options[0].value || props.options[0].label) return props.options;

  return props.options.map((o) => ({
    value: o,
    label: o,
  }));
});

function selectFirstOptionIfNeeded() {
  if (
    props.type === 'select' &&
    props.options?.length &&
    !props.nullable &&
    !props.nullOption &&
    !props.options.find((o) => o.value == props.modelValue)
  ) {
    emit('update:modelValue', props.options[0].value);
  }
}

// if not nullable and no option is selected, select the first option
watch(props, selectFirstOptionIfNeeded);
onMounted(selectFirstOptionIfNeeded);

function log(log) {
  console.log('log', log);
}
</script>
