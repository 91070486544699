import { Interaction } from '@/interfaces';
import { actions } from '@/store';
import requests from '@/util/RequestHandler';

export class Plugins {
  public submitInteraction = async (interaction: Interaction) => {
    const body = {
      type: interaction.type,
      value: interaction.value || '',
      context: interaction.context,
      created_by: actions.auth.readUser(),
    };

    console.log(body);
    // requests.post('/api/v1/interaction', body);
  };
}

export default new Plugins();
