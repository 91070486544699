var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.InfoContainer,{attrs:{"title":"Comments"}},[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('div',{staticClass:"tw-mb-1 tw-flex tw-flex-wrap tw-max-h-40 tw-overflow-y-auto tw-flex-grow"},[(_setup.isLoading)?_c('div',[_vm._v("Loading...")]):(_setup.comments.length > 0)?_c('div',_vm._l((_setup.comments),function(comment){return _c('div',{key:comment.id,staticClass:"tw-card tw-mb-3 tw-cursor-pointer tw-p-2 tw-border tw-border-gray-200 tw-rounded-lg tw-relative",class:{
            'tw-bg-blue-100': comment.is_inbound,
            'tw-bg-green-100': !comment.is_inbound,
          },on:{"click":function($event){return _setup.starComment(comment.id)}}},[(comment.id == _vm.starredComment)?_c('span',{staticClass:"fas fa-star tw-text-gray-400 tw-text-xs tw-absolute tw-top-2 tw-right-2"}):_vm._e(),_c('p',{staticClass:"tw-text-md"},[_vm._v(_vm._s(comment.value))]),_c('p',{staticClass:"tw-text-sm"},[_vm._v(" "+_vm._s(comment.created_by)+", "+_vm._s(_setup.formatDateTimeAlt(comment.created_at, true))+" ")])])}),0):_c('div',{staticClass:"tw-text-gray-500"},[_vm._v("No Internal comments for this address yet.")])]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_setup.newComment),expression:"newComment"}],staticClass:"tw-mt-2 tw-p-2 tw-border tw-border-gray-200",class:{
        'tw-border-green-500': _setup.result.type === 'Success',
        'tw-border-red-500': _setup.result.type === 'Error',
      },attrs:{"placeholder":"Comment...","rows":"1","disabled":_setup.isLoading,"s":""},domProps:{"value":(_setup.newComment)},on:{"input":function($event){if($event.target.composing)return;_setup.newComment=$event.target.value}}}),_c('label',{staticClass:"tw-flex tw-items-center tw-space-x-2 tw-mt-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_setup.notify),expression:"notify"}],staticClass:"tw-form-checkbox tw-h-5 tw-w-5 tw-text-indigo-600",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_setup.notify)?_vm._i(_setup.notify,null)>-1:(_setup.notify)},on:{"change":function($event){var $$a=_setup.notify,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_setup.notify=$$a.concat([$$v]))}else{$$i>-1&&(_setup.notify=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_setup.notify=$$c}}}}),_c('span',{staticClass:"tw-text-md"},[_vm._v("Notify")])]),_c('button',{staticClass:"tw-bg-gray-500 tw-rounded tw-p-1 tw-text-white tw-mt-3",class:{
        'tw-opacity-50 tw-cursor-not-allowed': !_setup.newComment,
        'tw-btn-primary': !_setup.isLoading,
      },attrs:{"disabled":!_setup.newComment},on:{"click":_setup.createComment}},[(_setup.isLoading)?_c('span',{staticClass:"tw-spinner-border tw-spinner-border-sm",attrs:{"role":"status","aria-hidden":"true"}}):_vm._e(),_vm._v(" Add new comment ")])]),_c(_setup.NotificationMessage,{attrs:{"message":_setup.notification.message,"type":_setup.notification.type}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }